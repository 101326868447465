import React from "react";
import { Skeleton } from "@mui/material";

const SearchItemSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        padding: "10px 20px",
      }}
    >
      <Skeleton
        style={{ transform: "scale(1)", width: "100px", height: "100px" }}
      />
      <div>
        <Skeleton
          style={{ transform: "scale(1)", width: "180px", height: "20px" }}
        />
        <Skeleton
          style={{
            transform: "scale(1)",
            width: "120px",
            height: "15px",
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );
};

export default SearchItemSkeleton;
