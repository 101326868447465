import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {Container, SliderWrapper, RightContent, CustomLink} from "./styled";
import adsImg from "../../assets/slider_1.png";
import "../../shared/slider.css";
import {SlickArrowLeft, SlickArrowRight} from "./NextPrevButtons";
import {useGetData} from "../../utils/hooks/hooks";

const UpperSlider = () => {
    const {data} = useGetData(
        `contents?keys[]=firstBanner`,
        [],
    );
    const [firstBanners, setFirstBanners] = useState([])
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SlickArrowRight/>,
        prevArrow: <SlickArrowLeft/>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    useEffect(() => {
        data?.map(item => item.key === "firstBanner" && setFirstBanners(first => [...first, item]))
    }, [data])
    return (
        <Container>
            <SliderWrapper>
                <Slider {...settings}>
                    {firstBanners?.map(item =>
                        <a href={item.link}>
                            <picture key={item.id}>
                                <img src={item?.src} style={{margin: '0 auto'}} alt=""/>
                            </picture>
                        </a>
                    )}
                    {/* <picture>
            <img src={sliderImg_2} alt="" />
          </picture> */}
                </Slider>
            </SliderWrapper>
        </Container>
    );
};

export default UpperSlider;
