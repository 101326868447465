import React from "react";
import { Skeleton } from "@mui/material";

const ReviewSkeleton = () => {
  return (
    <div style={{ margin: "10px 0" }}>
      <Skeleton style={{ transform: "scale(1)", width: "200px" }} />
      <Skeleton
        style={{ transform: "scale(1)", width: "150px", margin: "10px 0" }}
      />
      <Skeleton style={{ transform: "scale(1)", height: "50px" }} />
    </div>
  );
};

export default ReviewSkeleton;
