import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardGrid = styled.div`
  display: grid;
  gap: 114px 14px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    gap: 10px;
  }
  @media (max-width: 440px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CardContainer = styled.div`
  text-decoration: none;
  margin: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  position: relative;
  overflow: hidden;
  &.slider {
    width: 100%;
  }
`;

export const Badges = styled.div`
  position: absolute;
  ${props => !props.single && 
  `left: 10px;`}
  top: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  & span {
    border-radius: 5px;
    font-size: 11px;
    font-weight: 700;
    color: #373e4b;
    text-transform: uppercase;
    padding: 3px;
    &.new {
      background-color: #64f461;
    }
    &.popular {
      background-color: #ffe500;
      color: #000;
    }
    &.discounted {
      background-color: #e83838;
      color: #fff;
    }
    &.bonus {
      background-color: firebrick;
      color: #fff;
    }
    &.saleBonus {
      background: rgb(63,94,251);
      background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(183,70,252,1) 100%);
      color: #fff;
    }
  }
`;

export const IconButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  & img {
    width: 20px;
    height: 20px;
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  & img {
    width: 100%;
    height: auto;
    transition: all 0.2s;
    transform: scale(1);
    margin-bottom: 10px;
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s;
    }
  }
`;

export const DescriptionContainer = styled.div`
  padding: 0 10px;
  margin-top: -10px;
  & p {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
    height: fit-content;
    &.bold {
      font-weight: 700;
    }
    &.desc {
      margin-top: 5px;
    }
  }
  &.in-slide {
    & .bold {
      -webkit-line-clamp: 2 !important;
      height: 36px;
    }
  }
  @media (max-width: 1440px) {
    & p {
      font-size: 16px;
      line-height: 18px;
      -webkit-line-clamp: 3;
      //max-height: 54px;
    }
  }
  @media (max-width: 1024px) {
    & p {
      font-size: 14px;
      line-height: 16px;
      max-height: 48px;
    }
    &.in-slide {
      & .bold {
        height: 32px !important;
      }
    }
  }
  @media (max-width: 768px) {
    & p {
      -webkit-line-clamp: 2;
      max-height: 32px;
    }
  }
`;

export const PriceContainer = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding: 0 10px;
  margin-top: auto;
  & .old {
    text-decoration: line-through;
    text-decoration-color: #e42829;
    color: #737475;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const CounterAddButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  padding-top: 0;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const TitleContainer = styled(PriceContainer)`
  margin-bottom: 3%;
`;

export const MoreButtonContainer = styled(CounterAddButtonContainer)``;

export const LinkBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &::first-letter {
    text-transform: uppercase;
  }
  & .button-text-news-card {
    margin-right: 0;
  }
`;
