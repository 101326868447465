import styled, { css } from "styled-components";

const content = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  display: flex;
  flex-direction: column;
`;

export const DescriptionsContainer = styled.div`
  margin: 40px 0;
`;

export const Content = styled.div`
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 30px 20px;
  ${content}
  gap:20px;
  & .title {
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const Characteristics = styled.div`
  ${content};
  gap: 10px;
`;

export const Character = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  width: 100%;
  & .dotted-border {
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    border-bottom: 1px dotted #373e4b;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: -1px;
      bottom: -1px;
      border-bottom: 2px dotted #fff;
    }
  }
  & .text {
    min-width: max-content;
  }
  @media (max-width: 580px) {
    flex-wrap: wrap;
    & .dotted-border {
      display: none;
    }
    & .text {
      min-width: unset;
      word-break: break-word;
    }
    & .key {
      font-weight: 700;
      &::after {
        content: ":";
      }
    }
  }
`;
