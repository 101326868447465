import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Container, Nav, NavButton, Divider, Typo, List} from "../../pages/delivery/prices/styled";
import clockTwo from '../../assets/delivery/clock-two.svg';
import truck from '../../assets/delivery/truck.svg';
import location from '../../assets/delivery/location.svg';
import moped from '../../assets/delivery/moped.svg';
import moneyBag from '../../assets/delivery/money-bag.svg';
import packageIcon from '../../assets/delivery/package.svg';
import bag from '../../assets/delivery/bag.svg';
import {
    DeliveryItem, DeliveryItemDescription,
    DeliveryItemIcon, DeliveryItemRow, DeliveryItemText,
    DeliveryItemValue,
    DeliveryKey, DeliveryKeyIcon,
    DeliveryKeyName, Line,
    RowSection,
    TableSection
} from "./styled";
import {Outlet} from "react-router";

const DeliveryDetails = () => {
    const {t} = useTranslation();
    const lang = useSelector((state) => state.lang[0].val);
    const deliveryItems = [
        {
            name: t('delivery.type.title'),
            background: '#FFE500',
            items: [
                {
                    title: t('delivery.type.types.express.title'),
                    description: t('delivery.type.types.standard.description'),
                    icon: truck,
                },
                {
                    title: t('delivery.type.types.standard.title'),
                    description: t('delivery.type.types.standard.description'),
                    icon: truck,
                },
                {
                    title: t('delivery.type.types.courier.title'),
                    description: t('delivery.type.types.courier.description'),
                    icon: moped,
                },
                {
                    title: t('delivery.type.types.delivery.title'),
                    description: t('delivery.type.types.delivery.description'),
                    icon: packageIcon,
                },
            ],
        }, {
            name: t('delivery.location.title'),
            icon: location,
            items: [
                {
                    title: t('delivery.location.types.city.title'),
                    description: t('delivery.location.types.city.description')
                },
                {
                    title: t('delivery.location.types.city.title'),
                    description: t('delivery.location.types.city.description')
                },
                {
                    title: t('delivery.location.types.city.title'),
                    description: t('delivery.location.types.city.description')
                },
                {
                    title: t('delivery.location.types.delivery.title'),
                    description: t('delivery.location.types.delivery.description')
                },
            ],
        },{
            name: t('delivery.time.title'),
            icon: clockTwo,
            items: [
                {
                    title: t('delivery.time.types.estimatedHours.title'),
                    description: t('delivery.time.types.estimatedHours.description', { hours: '2-4' })
                },
                {
                    title: t('delivery.time.types.estimatedDays.title', { days: '1-2' })
                },
                {
                    title: t('delivery.time.types.estimatedHours.title'),
                    description: t('delivery.time.types.estimatedHours.description',  { hours: '2' })
                },
                {
                    title: t('delivery.time.types.estimatedDays.title',  { days: '1-3' })
                },
            ],
        },{
            name: t('delivery.price.title'),
            icon: moneyBag,
            items: [
                {
                    title: t('delivery.price.types.exceptions.title'),
                    description: t('delivery.price.types.exceptions.description', { price: 2000 })
                },
                {
                    title: t('delivery.price.types.free.title')
                },
                {
                    title: t('delivery.price.types.deliveryPrice.title', { price: 1500 }),
                },
                {
                    title: t('delivery.price.types.deliveryPrice.title', {price: 700}),
                },
            ],
        },{
            name: t('delivery.notes.title'),
            items: [
                {
                    title: null
                },
                {
                    title: t('delivery.notes.types.exceptions.title'),
                    description: t('delivery.notes.types.exceptions.description')
                },
                {
                    title: t('delivery.notes.types.weightKg.title'),
                    description: t('delivery.notes.types.weightKg.description', { weight: 10 })
                },
                {
                    title: t('delivery.notes.types.weightKg.title'),
                    description: t('delivery.notes.types.weightKg.description', { weight: 5 })
                },
            ],
        },
    ];

    return (
        <Container>
            <TableSection>
                {deliveryItems.map((item) => (
                    <RowSection>
                        <DeliveryKey>
                            <DeliveryKeyIcon src={item.icon}></DeliveryKeyIcon>
                            <DeliveryKeyName>{item.name}</DeliveryKeyName>
                        </DeliveryKey>
                        <DeliveryItemRow>
                            {item.items.map((deliveryItem) => (
                                <DeliveryItem style={{ backgroundColor: item.background }}>
                                    <DeliveryItemText>
                                        <DeliveryItemValue>{deliveryItem.title}</DeliveryItemValue>
                                        {(deliveryItem.title ? '' : <Line/> )}
                                        <DeliveryItemDescription>{deliveryItem.description}</DeliveryItemDescription>
                                    </DeliveryItemText>
                                    <DeliveryItemIcon src={deliveryItem.icon}/>
                                </DeliveryItem>
                            ))}
                        </DeliveryItemRow>
                    </RowSection>
                ))}
            </TableSection>
        </Container>
    );
};

export default DeliveryDetails;
