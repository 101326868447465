import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const showForgetPasswordSlice = createSlice({
  name: "showForgetPassword",
  initialState,
  reducers: {
    makeForgetPasswordVisible: () => true,
    makeForgetPasswordHidden: () => false,
  },
});

export const { makeForgetPasswordHidden, makeForgetPasswordVisible } =
  showForgetPasswordSlice.actions;
export default showForgetPasswordSlice.reducer;
