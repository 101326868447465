import React, {useEffect, useState} from "react";
import {CardGrid} from "../../../components/cards/styled";
import giftCard from "../../../assets/gift-card.svg";
import axios from "axios";
import {baseUrl} from "../../../constants";
import {useLocalStorageGetItem} from "../../../utils/hooks/hooks";
import {GiftCardsWrapper, GiftCardWrapper, PublicId} from "./styled";
import {useTranslation} from "react-i18next";

const GiftCards = () => {
    const { t } = useTranslation();
    const token = useLocalStorageGetItem("token");
    const [cards, setCards] = useState()
    useEffect(() => {
        axios
            .get(`${baseUrl}/users/gifts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            })
            .then((res) => res.data.data)
            .then((data) => {
                setCards(data);
            });
    }, []);

    return (
        <GiftCardsWrapper>
                {cards?.map((card) => (
                        <GiftCardWrapper>
                            <img src={giftCard} alt=""/>
                            <span>{t("texts.card_number")}`</span>
                            <PublicId>{card?.publicId}</PublicId>
                            <span className={"balance"}>{t("texts.card_balance")}`</span>
                            <PublicId balance>{card?.balance}</PublicId>
                        </GiftCardWrapper>
                ))}
        </GiftCardsWrapper>
    );
};

export default GiftCards;