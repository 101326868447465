import React from "react";
import DataContainer from "../DataContainer";
import { Title, List } from "../styled";
import { useSelector } from "react-redux";
import { data } from "./data";

const Refund = () => {
  const lang = useSelector((state) => state.lang[0].val);
  const terms = data[lang];
  return (
    <DataContainer as="div">
      <Title className="title">{terms.title}</Title>
      <div>
        {terms.content.map((item, i) =>
          item.type !== "list" ? (
            <span key={i} className={item.type === "bold" ? "bold" : ""}>
              {item.text}
            </span>
          ) : (
            <List className="inside">
              {item.items.map((elem, i) => (
                <li key={i}>{elem}</li>
              ))}
            </List>
          )
        )}
      </div>
    </DataContainer>
  );
};

export default Refund;
