import React, { useState } from "react";
import { Actions, Item, ItemInfo } from "./styled";
import { FilledButton } from "../../pages/product/styled";
import CounterBasket from "../../components/counter-basket/CounterBasket";
import cartIcon from "../../assets/cartIcon.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PriceContainer } from "../cards/styled";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { useDispatch, useSelector } from "react-redux";
import { changeCartLength } from "../../features/cart/cartSlice";
import axios from "axios";
import { baseUrl } from "../../constants";
import ErrorDialog from "../dialogs/ErrorDialog";
import checkIcon from "../../assets/checkIcon.svg";

const SearchItem = ({ handleClose, product }) => {
  const unit = product?.unit;
  const step = +product?.unit?.step || 1;
  const [count, setCount] = useState(step);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const { t } = useTranslation();

  const fixNum = (init) => {
    return Number.isInteger(init)
      ? parseFloat(init).toFixed(0)
      : parseFloat(init).toFixed(2);
  };

  const fixCount = () => {
    return parseFloat((Math.ceil(count / step) * step).toFixed(2));
  };

  const handleChange = (e) => {
    setCount(e.target.value);
  };

  const handleBlur = () => {
    if (!count) {
      setCount(step);
    } else if(product?.unit?.type === "int"){
      const num = +fixCount();
      setCount(num);
    }
  };

  const handleCountChangeByBtn = (type) => {
    let init = type === "dec" ? +count - step : +count + step;
    const num = fixNum(init);
    setCount(num);
  };

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const ip = localStorage.getItem('ip');
    if (!ip && !token){
      const newIp = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))
      localStorage.setItem("ip", newIp)
    }
    const fixedCount = fixCount();
    const data = { id: product?.id, count: +count };
    let headers = {
      "Accept-Language": lang.val,
    };
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    axios
      .post(`${baseUrl}/carts/add${ip ?`?ip=${ip}`:''}`, data, { headers })
      .then(() => {
        setIsSucceeded(true);
        dispatch(changeCartLength());
        setTimeout(() => {
          setIsSucceeded(false);
          setCount(step);
        }, 3000);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ===
          "validation.cart.count.available.false"
            ? t("texts.unavailable_count")
            : t("texts.unexpected_error");
        setError(true);
        setErrorMessage(message);
        setCount(step);
      });
  };

  return (
    <>
      <Item>
        <div>
          <Link to={`/products/${product?.id}`} onClick={handleClose}>
            <img
              src={product?.images?.main?.src}
              alt="product"
              width="100"
              height="100"
            />
          </Link>
        </div>
        <div className="right-content">
          <ItemInfo>
            <div className="text">
              <Link
                to={`/products/${product?.id}`}
                onClick={handleClose}
                className="name"
              >
                {product?.name || "product name"}
              </Link>
              <PriceContainer>
                <span
                  className={product?.discountedPrice?.[0] !== "0" ? "old" : ""}
                >
                  {product?.price}
                </span>
                {product?.discountedPrice?.[0] !== "0" && (
                  <span className="discounted">{product?.discountedPrice}</span>
                )}
              </PriceContainer>
            </div>
          </ItemInfo>
          <Actions>
            <div className="counter-wrapper">
              <CounterBasket
                count={count}
                handleChange={handleChange}
                handleBlur={handleBlur}
                unit={unit}
                step={step}
                handleCountChange={handleCountChangeByBtn}
              />
            </div>
            <FilledButton
              className={`search ${isSucceeded ? "success" : ""}`}
              onClick={handleAddToCart}
            >
              <div>
                <span>{t("actions.add")}</span>
                <img src={cartIcon} alt="" />
              </div>
              <img
                className="success-icon"
                src={checkIcon}
                alt="sent"
                width="18"
              />
            </FilledButton>
          </Actions>
        </div>
      </Item>
      {error && (
        <ErrorDialog
          open={error}
          handleClose={() => {
            setError(false);
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default SearchItem;
