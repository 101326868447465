import React, {useEffect, useState} from "react";
import {BaseSection, ImageWrapper, SliderWrapper} from "./styled";
import Slider from "react-slick";
import {baseUrl} from "../../constants";
import {useTranslation} from "react-i18next";
import nextArrow from "../../assets/next-arrow.svg";
import prevArrow from "../../assets/prev-arrow.svg";
import {useGetData} from "../../utils/hooks/hooks";
import {DiscountImageWrapper} from "../home-services/styled";

const HomeDiscounts = ({}) => {
    const {t} = useTranslation();
    const [discounts, setDiscounts] = useState([]);

    function SliderNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={nextArrow}
                 alt="Next"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }

    function SliderPrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={prevArrow}
                 alt="Prev"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }

    const settings = {
        initialSlide: 1,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        cssEase: 'linear',
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 20,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const {data} = useGetData(`contents?keys[]=discounts`, []);

    useEffect(() => {
        setDiscounts(data)
    }, [data])

    return (
        <BaseSection className="block">
            {discounts?.length ? <h1>{t('texts.our_discount')}</h1> : null}
            <Slider {...settings} className="slider">
                {discounts?.map((discount, index) => (
                    <DiscountImageWrapper className={"slide"} key={discount.name} to={discount.link} target="_blank">
                        {discount?.src ? (
                            <img style={{height: 'max-content'}} src={discount?.src} alt={discount?.name}/>
                        ) : (
                            <h5>{discount?.name}</h5>
                        )}
                        <h2>{discount?.description}</h2>
                    </DiscountImageWrapper>
                ))}
            </Slider>
        </BaseSection>
    );
};

export default HomeDiscounts;