import React from "react";
import {
  CompanyPageContainer,
  MainContainer,
  NavigationContainer,
  NavigationButton,
} from "./styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { useLocation } from "react-router";

const Company = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const nav = [
    {
      to: "/company",
      name: pathname.includes("company")
        ? t("texts.company")
        : pathname.includes("help")
        ? t("texts.help")
        : t("texts.services"),
    },
  ];

  return (
    <CompanyPageContainer>
      <Breadcrumbs nav={nav} />
      <NavigationContainer>
        <NavigationButton
          to="/info/company/about"
          className={pathname.includes("company") ? "active" : ""}
        >
          {t("texts.company")}
        </NavigationButton>
        {/*<NavigationButton*/}
        {/*  to="/info/services/bonuses"*/}
        {/*  className={pathname.includes("services") ? "active" : ""}*/}
        {/*>*/}
        {/*  {t("texts.services")}*/}
        {/*</NavigationButton>*/}
        <NavigationButton
          to="/info/help/terms"
          className={pathname.includes("help") ? "active" : ""}
        >
          {t("texts.help")}
        </NavigationButton>
      </NavigationContainer>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </CompanyPageContainer>
  );
};

export default Company;
