import React, {useEffect, useState} from "react";
import { Container, CustomLink } from "./styled";
import { useSelector } from "react-redux";
import axios from "axios";
import {baseUrl} from "../../constants";
import {getCategories} from "../../features/categories/categoriesSlice";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";

const HomeCategories = () => {
    const token = useLocalStorageGetItem("token");
    const lang = useSelector((state) => state.lang[0]);
  const [categories, setCategories] = useState([])

  useEffect(() => {
    axios
        .get(`${baseUrl}/categories/simple`, {
          headers: {
            "Accept-Language": lang.val,
              Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.data)
        .then((data) => {
          setCategories(data);
        });
  }, [lang]);

  return (
    <Container>
      {!!categories.length &&
        categories.map((category) => (
          <CustomLink
            key={category.id}
            to={
              category?.subcategoriesCount
                ? `/subcategories/${category.id}`
                : `/sub-category/${category.id}/1`
            }
          >
            <img src={category.image} alt=""/>
            <span>{category.name}</span>
          </CustomLink>
        ))}
    </Container>
  );
};

export default HomeCategories;
