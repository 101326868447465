import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const typo = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

export const UpperSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 20px auto;
  @media (max-width: 1110px) {
    gap: 20px;
  }
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1110px) {
    flex-direction: row;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border: 1px solid #ffe500;
  border-radius: 10px;
  background-color: #fff;
  &.active {
    background-color: #ffe500;
  }
  font-weight: 400;
  ${typo}
  text-decoration: none;
  & .bold {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
  }
  & span {
    width: max-content;
  }
  @media (max-width: 1110px) {
    padding: 10px 15px;
    gap: 5px;
    & .bold {
      ${typo}
    }
  }
`;

export const LowerSection = styled.section`
  margin-top: 60px;
  width: 100%;
  & .filters {
    margin: 0 auto;
    margin-bottom: 30px;
    width: fit-content;
  }
`;
