import styled, { css } from "styled-components";

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const leftSideWidth = css`
  width: 100%;
  max-width: 360px;
  @media (max-width: 1440px) {
    max-width: 300px;
  }
  @media (max-width: 1240px) {
    max-width: 270px;
  }
  @media (max-width: 1024px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const customBorder = css`
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

export const TitleContainer = styled.div`
  ${alignCenter}
  justify-content: space-between;
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 10px;
    & > div {
      width: 100%;
    }
  }
`;

export const FilterBtn = styled.div`
  ${flexCenter}
  gap: 20px;
  padding: 12px 0;
  ${leftSideWidth}
  background: #373e4b;
  border-radius: 10px;
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    text-transform: uppercase;
  }
  button {
    display: flex;
    gap: 2px;
    background-color: #ffe500;
    border-radius: 10px;
    padding: 3px 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    & span {
      color: #373e4b;
    }
  }
  @media (max-width: 768px) {
    cursor: pointer;
  }
`;

export const SelectWrapper = styled.div`
  min-width: 200px;
`;

export const FiltersSidebar = styled.div`
  ${leftSideWidth}
  word-break: break-word;
  & .filters-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    & .MuiPaper-root {
      ${customBorder}
      box-shadow: none;
      overflow: hidden;
      &.Mui-disabled {
        background-color: #fff;
      }
      & .MuiButtonBase-root {
        padding: 15px 10px;
        & .Mui-expanded {
          min-height: unset;
        }
        & .MuiAccordionSummary-content {
          margin: 0;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #373e4b;
        }
      }
      & .MuiAccordionDetails-root {
        padding: 0;
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
    &.show {
      display: unset;
    }
  }
`;
