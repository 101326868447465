import React, {useEffect, useState} from "react";
import {ProductInfo, Tdata, Trow} from "../../cart/styled";
import {Button, Counter, Input} from "../../../components/counter-basket/styled";
import {NumericFormat} from "react-number-format";

const SingleOrderCard = ({
                             checked,
                             total,
                             product,
                             initCount,
                             handleCountChange,
                             reload,
                             isReversable,
                         }) => {
    const step = +product?.product?.unit?.step || 1;
    const [count, setCount] = useState(+initCount);
    useEffect(() => {
        setCount(+initCount);
    }, [initCount, reload]);

    const fixNum = (init) => {
        return Number.isInteger(init)
            ? parseFloat(init).toFixed(0)
            : parseFloat(init).toFixed(2);
    };

    const fixCount = () => {
        return parseFloat((Math.ceil(count / step) * step).toFixed(2));
    };

    const handleBlur = () => {
        if (!count) {
            setCount(initCount);
        } else if (product?.product?.unit?.type === "int") {
            const num = +fixCount();
            setCount(num);
            handleCountChange(num, product.id);
        } else {
            handleCountChange(+count, product.id);
        }
    };

    const handleCountChangeByBtn = (type) => {
        let init = type === "dec" ? +count - step : +count + step;
        const num = +fixNum(init);
        setCount(num)
        handleCountChange(num, product.id);
    };
    return (
        <Trow style={checked ? { opacity: "1"} : {opacity: ".5"}}>
            <Tdata>
                <ProductInfo to={checked && `/products/${product?.product?.id}`}>
                    <img
                        width="140"
                        height="140"
                        src={product?.product?.images?.main?.src}
                        alt="product"
                    />
                    <h5>{product?.product.name || "product name"}</h5>
                </ProductInfo>
            </Tdata>
            {isReversable ?
            <>
                <Tdata className="bold price">
        <span className={product?.product?.discountedPrice?.[0] !== "0" ? "old" : ""}>
          {product?.product?.price}
        </span>
                    {product?.product?.discountedPrice?.[0] !== "0" && (
                        <span>{product?.product?.discountedPrice}</span>
                    )}
                </Tdata>
                <Tdata className="count">
                    <Counter>
                        <Button onClick={() => checked && handleCountChangeByBtn("dec")} disabled={count <= 0}>
                            -
                        </Button>
                        <NumericFormat
                            customInput={Input}
                            type="number"
                            disabled={!checked}
                            value={count}
                            onKeyDown={(e) => {
                                if (
                                    e.key === " " ||
                                    e.key === "-" ||
                                    e.key === "+"
                                ) {
                                    checked && e.preventDefault();
                                }
                            }}
                            onChange={(e) => checked && setCount(e.target.value)}
                            onBlur={checked && handleBlur}
                        />
                        <Button onClick={() => checked && handleCountChangeByBtn("inc")} disabled={count >= +product?.count}>+</Button>
                    </Counter>
                </Tdata>

            </> :
                <Tdata className="bold">
                    <span>{+product.count} {product?.product?.unit.name}</span>
                </Tdata>}
            <Tdata className="bold">
                <span>{total}</span>
            </Tdata>
        </Trow>
    );
};

export default SingleOrderCard;
