import React, { useEffect, useState } from "react";
import { Container, SubCategoryCardsContainer } from "./subcategories.styled";
import { PageTitle } from "./styled";
import SubCategoryCard from "./SubCategoryCard";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ProductsSlider from "../../components/products-slider/ProductsSlider";
import { useGetData } from "../../utils/hooks/hooks";
import { useParams } from "react-router";
import EmptyList from "../../components/empty-list";
import { getBradcrumbs } from "../../utils/funcs/getBreadcrumbs";
import SubCategoryCardSkeleton from "./SubCategoryCardSkeleton";
import SEO from "../../components/seo";

const Categories = () => {
  const [nav, setNav] = useState([]);
  const { subcategoriesId } = useParams();
  const { data: subcategories, isLoading } = useGetData(
    `categories/${subcategoriesId}`,
    [subcategoriesId]
  );
  const { data: breadcrumbs } = useGetData(
    `categories/${subcategoriesId}/breadcrumbs`,
    [subcategoriesId]
  );
  const { data: bestsellings } = useGetData(`products/trends/popular`);

  useEffect(() => {
    if (breadcrumbs?.breadcrumb) {
      const arr = getBradcrumbs(breadcrumbs);
      setNav(arr);
    }
  }, [breadcrumbs?.breadcrumb]);

  return (
    <>
      <Breadcrumbs nav={nav} />
      <PageTitle>{breadcrumbs?.name || ""}</PageTitle>
      <Container>
        {isLoading ? (
          <SubCategoryCardsContainer>
            {Array.from({ length: 8 }).map((x, i) => (
              <SubCategoryCardSkeleton key={i} />
            ))}
          </SubCategoryCardsContainer>
        ) : !!subcategories?.length ? (
          <SubCategoryCardsContainer>
            {subcategories?.map((subcategory) => (
              <SubCategoryCard
                key={subcategory?.id}
                href={
                  subcategory?.subcategoriesCount
                    ? `/subcategories/${subcategory.id}`
                    : `/sub-category/${subcategory.id}/1`
                }
                src={subcategory?.image}
                title={subcategory.name}
              />
            ))}
          </SubCategoryCardsContainer>
        ) : (
          <EmptyList />
        )}
      </Container>
      {!!bestsellings?.length && (
        <ProductsSlider title="best_selling" products={bestsellings} />
      )}
    </>
  );
};

export default Categories;
