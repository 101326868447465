import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const overflowOfMainSlice = createSlice({
  name: "overflowOfMain",
  initialState,
  reducers: {
    makeHidden: () => {
      return true;
    },
    makeAuto: () => {
      return false;
    },
  },
});

export const { makeHidden, makeAuto } = overflowOfMainSlice.actions;
export default overflowOfMainSlice.reducer;
