import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #e5e5e5;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 480px) {
    max-width: 100%;
    margin: 0 10px;
  }
`;

export const LangsWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  & button {
    background-color: transparent;
    & img {
      width: 20px;
    }
  }
`;
