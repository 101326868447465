import React from "react";
import { Skeleton } from "@mui/material";
import styled, { css } from "styled-components";

const fc = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Container = styled.div`
  & .skeleton {
    transform: scale(1);
  }
  & .text {
    max-width: 150px;
    width: 100%;
    height: 10px;
  }
  & .cf {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  & .m {
    margin-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  ${fc}
  width: 50%;
  padding: 20px;
`;

const OfferSkeleton = () => {
  return (
    <Container>
      {Array.from({ length: 2 }).map((x, i) => (
        <div key={i} className="m">
          <Skeleton
            className="skeleton"
            style={{ width: "100%", height: "30px" }}
          />
          <div className="cf">
            <Wrapper>
              <Skeleton
                className="skeleton"
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
              <div>
                <Skeleton className="skeleton text" style={{}} />
                <Skeleton
                  className="skeleton text"
                  style={{
                    margin: "5px 0",
                  }}
                />
                <Skeleton
                  className="skeleton"
                  style={{
                    width: "70px",
                    height: "10px",
                  }}
                />
              </div>
            </Wrapper>
            <div style={{ width: "50%", padding: "20px" }}>
              <div>
                <Skeleton
                  className="skeleton"
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                />
                <Skeleton
                  style={{
                    width: "100%",
                    height: "60px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export default OfferSkeleton;
