import React from "react";
import { useTranslation } from "react-i18next";
import { Section, ServiceSection, Service } from "./styled";
import shinIcon from "../../../assets/shinIcon.svg";
import deliveryIcon from "../../../assets/deliveryIcon.svg";
import callIcon from "../../../assets/callIcon.svg";
import garbageCollectIcon from "../../../assets/garbageCollectIcon.svg";

const ServicesSection = () => {
  const { t } = useTranslation();
  const data = [
    { name: t("texts.consultation"), icon: callIcon },
    { name: t("texts.delivery_and_dumping"), icon: deliveryIcon },
    { name: t("texts.waste_transportation"), icon: garbageCollectIcon },
    { name: t("texts.workforce"), icon: shinIcon },
  ];
  return (
    <Section>
      <h3 className="title">{t("texts.services")}</h3>
      <ServiceSection>
        {data.map((item, i) => (
          <Service key={i}>
            <div className="img-wrapper">
              <img src={item.icon} alt={item.name} />
            </div>
            <p>{item.name}</p>
          </Service>
        ))}
      </ServiceSection>
    </Section>
  );
};

export default ServicesSection;
