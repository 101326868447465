import React from "react";
import { RightContentItem as Item } from "./styled";

const RightContentItem = ({ as, icon, name, to, onClick, onBlur, count }) => {
  return as ? (
    <Item as="button" onClick={onClick} onBlur={onBlur}>
      <div className="img-wrapper">
        <img src={icon} alt="" />
      </div>
      <p className="icon-name">{name}</p>
    </Item>
  ) : (
    <Item to={to}>
      <div className="img-wrapper">
        <img src={icon} alt="" />
        <span className="count">{count}</span>
      </div>
      <p className="icon-name">{name}</p>
    </Item>
  );
};

export default RightContentItem;
