import React from "react";
import { Container, Radio, DeliveryOption } from "./styled";
import AddressRadioContent from "./AddressRadioContent";
import PaymentRadioContent from "./PaymentRadioContent";
import { Link } from "react-router-dom";
import infoIcon from "../../assets/infoIcon.svg";

const selectLink = (name) => {
  const lname = name.toLowerCase();
  return lname.includes("ստանդարտ") || lname.includes("стандарт")
    ? "/delivery-and-dumping/standard"
    : lname.includes("էքսպրես") || lname.includes("экспресс")
    ? "/delivery-and-dumping/express"
    : "/delivery-and-dumping/courier";
};

const CustomRadio = ({
  options,
  selectedOption,
  onChange,
  addresses,
  payments,
  setValue,
  to,
  shinmagClub,
}) => {
  return (
    <Container
      className={`${payments || addresses ? "payment" : ""} ${
        to ? "purchase" : ""
      }`}
    >
      {options?.map((option) => (
          !(payments && !option?.available) &&
        <Radio
          className={payments ? "payment" : ""}
          key={option?.id || option?.slug}
          type="button"
          onClick={() => onChange(option, setValue)}
        >
          <div className="checkbox">
            <div
              className={
                (shinmagClub && option?.name === selectedOption?.name) ||
                option === selectedOption
                  ? "checked"
                  : ""
              }
            />
          </div>
          {addresses ? (
            <AddressRadioContent address={option} />
          ) : payments ? (
            <PaymentRadioContent type={option} />
          ) : (
            <DeliveryOption>
              <span className="name">{option?.name}</span>
              {to && (
                <Link to={selectLink(option?.name || "")} target="_blank">
                  <img src={infoIcon} alt="info" width="16" height="16" />
                </Link>
              )}
            </DeliveryOption>
          )}
        </Radio>
      ))}
    </Container>
  );
};

export default CustomRadio;
