import styled from "styled-components";
import { upCase } from "../styled";

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 1240px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  border-radius: 60px;
  border: 1px solid #373e4b;
  overflow: hidden;
  & .left-side {
    background-color: #ffe500;
    padding: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
  }
  & .right-side {
    width: 100%;
    height: 100%;
    padding: 40px;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    background-color: #373e4b;
    & .title {
      ${upCase}
      margin-bottom: 20px;
      font-weight: 700;
    }
    & ul {
      margin-left: 20px;
    }
  }
  @media (max-width: 1440px) {
    & .left-side,
    & .right-side {
      padding: 20px;
    }
    & .left-side {
      & img {
        width: 100px;
        height: 100px;
      }
    }
    & .right-side {
      & .title {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 1240px) {
    & .left-side,
    & .right-side {
      padding: 20px;
    }
    & .left-side {
      & img {
        width: 120px;
        height: 120px;
      }
    }
  }
  @media (max-width: 768px) {
    & .left-side {
      & img {
        width: 100px;
        height: 100px;
      }
    }
  }
  @media (max-width: 580px) {
    flex-direction: column;
    & .left-side {
      width: 100%;
    }
    & ul {
      margin-bottom: 10px;
    }
  }
`;
