import React, { useState, useRef, useEffect } from "react";
import Login from "./Login";
import Register from "./Register";
import { TextButton, Underline } from "./styled";
import {
  Background,
  Dialog,
  DialogHeader,
  IconButton,
  Divider,
} from "../dialogs/styled";
import closeIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

const Auth = ({ open, handleClose, handleOpenRecoverPassword }) => {
  const [toggle, setToggle] = useState(false);
  const logRef = useRef(null);
  const regRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(20);
  const vHeight = useSelector((state) => state.appHeight.val);

  const { t } = useTranslation();

  useEffect(() => {
    if (logRef) {
      setWidth(logRef.current.offsetWidth);
      setLeft(20);
    }
  }, [logRef]);

  const handleUnsetAndCLose = (recovery) => {
    setToggle(false);
    setLeft(20);
    setWidth(logRef.current.offsetWidth);
    handleClose(recovery);
    if (recovery === "recovery") {
      handleOpenRecoverPassword();
    }
  };
  return createPortal(
    <>
      <Background
        className={`fullwidth ${open ? "open" : ""}`}
        onClick={handleUnsetAndCLose}
        vHeight={vHeight}
      />
      <Dialog className={`fullwidth ${open ? "open" : ""}`} vHeight={vHeight}>
        <DialogHeader>
          <TextButton
            onClick={() => {
              setToggle(false);
              setLeft(20);
              setWidth(logRef.current.offsetWidth);
            }}
            ref={logRef}
            className={!toggle && "active login"}
          >
            {t("texts.login")}
          </TextButton>
          <TextButton
            onClick={() => {
              setToggle(true);
              setLeft(width + 50);
              setWidth(regRef.current.offsetWidth);
            }}
            className={!!toggle && "active"}
            ref={regRef}
          >
            {t("texts.registration")}
          </TextButton>
          <IconButton onClick={handleUnsetAndCLose}>
            <img src={closeIcon} alt="x" />
          </IconButton>
        </DialogHeader>
        <Divider>
          <Underline
            width={width}
            left={left}
            className={`${toggle && "right"} underline`}
          />
        </Divider>
        {toggle ? (
          <Register handleClose={handleUnsetAndCLose} />
        ) : (
          <Login
            handleOpenRecoverPassword={handleUnsetAndCLose}
            handleClose={handleUnsetAndCLose}
          />
        )}
      </Dialog>
    </>,
    document.getElementById("modal-root")
  );
};

export default Auth;
