import React from "react";
import UpperHeader from "./UpperHeader";
import Header from "./Header";

const index = () => {
    return (
        <div>
            <UpperHeader/>
            <Header/>
        </div>
    );
};

export default index;
