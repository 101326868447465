import React, { useEffect } from "react";
import { Wrapper } from "../not-found/styled";
import { useTranslation } from "react-i18next";
import { LinkBtn } from "../../components/cards/styled";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { changeCartLength } from "../../features/cart/cartSlice";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";

const OrderSucceeded = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ip = localStorage.getItem('ip')
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  let headers = {
    "Accept-Language": lang.val,
  };

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  useEffect(() => {
    axios.delete(`${baseUrl}/carts${ip ?`?ip=${ip}`:''}`, { headers }).then((res) => {
      dispatch(changeCartLength());
    });
  }, []);

  return (
    <Wrapper>
      <h1>{t("texts.order_succeeded")}</h1>
      <div className="fl">
        <LinkBtn to="/">{t("texts.main")}</LinkBtn>
        <LinkBtn to="/personal-page/orders/1">{t("texts.my_orders")}</LinkBtn>
      </div>
    </Wrapper>
  );
};

export default OrderSucceeded;
