import React from "react";
import { FilterButton } from "../shared/button/styled";
import { FiltersContainer } from "./styled";

const ProductFilters = ({ filters, selectedFilter, handleChange }) => {
  return (
    <FiltersContainer>
      {filters.map((filter) => (
        <FilterButton
          key={filter.endpoint}
          onClick={() => handleChange(filter)}
          className={
            filter.endpoint === selectedFilter.endpoint ? "active" : ""
          }
        >
          {filter.name}
        </FilterButton>
      ))}
    </FiltersContainer>
  );
};

export default ProductFilters;
