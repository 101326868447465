import styled, { css } from "styled-components";

const border = css`
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  &.payment {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1440px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.purchase {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
  &.giftCard {
    margin-bottom: 20px;
  }
  &.giftCard input {
    border: 1px solid #373e4b;
    outline: none;
    padding: 5px;
    border-radius: 5px;
  }
`;

export const Radio = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  &.payment {
    padding: 10px;
    ${border}
  }
  & .name {
    display: inline-block;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  ${props=> props.disabledColor && `
    opacity: .8
  `};
  & .checkbox {
    width: 30px;
    height: 30px;
    border: 1px solid #373e4b;
    border-radius: 50%;
    padding: 3px;
    & .checked {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #373e4b;
    }
  }
`;

export const Address = styled.ul`
  ${border}
  padding: 20px;
  /* margin-top: 20px; */
  list-style-type: none;
  & li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    text-align: left;
    font-weight: 400;
  }
  width: 100%;
`;

export const PaymentType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  min-width: 140px;
  margin-left: auto;
  & span {
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  & img {
    max-width: 30px;
    height: 20px;
  }
`;

export const DeliveryOption = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
