import React from "react";
import { Container, List, Item } from "./styled";
import { useTranslation } from "react-i18next";

const Breadcrumbs = ({ nav, className }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    { to: "/", name: t("texts.main") },
    ...nav.slice(0, nav?.length - 1),
  ];
  const last = nav[nav?.length - 1];
  return (
    <Container>
      <div className="wrapper">
        <List>
          {breadcrumbs.map((item) => (
            <Item key={item?.to} to={item?.to}>
              <span>{item?.name}</span>
            </Item>
          ))}
          <Item as="h5">
            <span className={className || ""}>{last?.name}</span>
          </Item>
        </List>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
