import React from "react";
import {
    Container,
    ContentWrapper,
    Content,
    ContentLine,
    Item,
    ImageWrapper,
    ItemTitle,
} from "./styled";
import {useTranslation} from "react-i18next";
import bestPriceIcon from "../../assets/best-price.png";
import brandIcon from "../../assets/brand.png";
import clockIcon from "../../assets/clock.png";
import productIcon from "../../assets/new-product.png";
import timeIcon from "../../assets/time-left.png";
import HomeAdvantages from "../home-advantages/HomeAdvantages";

const Advantages = () => {
    const {t} = useTranslation();
    const items = [
        {icon: productIcon, name: t("texts.more_than_products")},
        {icon: timeIcon, name: t("texts.orders_accuracy")},
        {icon: brandIcon, name: t("texts.brands_number")},
        {icon: clockIcon, name: t("texts.time_managment")},
        {icon: bestPriceIcon, name: t("texts.brave_prices")},
    ];

    return (
        <Container>
            <ContentWrapper length={items.length}>
                <Content>
                    {items.map((item, i) => (
                        <Item key={i} length={items.length}>
                            <ItemTitle>{item.name}</ItemTitle>
                            <ImageWrapper>
                                <img src={item.icon} alt={item.name} loading="lazy"/>
                            </ImageWrapper>
                        </Item>
                    ))}
                </Content>
                <HomeAdvantages advantages={items}/>
            </ContentWrapper>
        </Container>
    );
};

export default Advantages;
