import React from "react";
import { DFSection } from "./styled";
import craneIcon from "../../../assets/craneIcon.svg";
import { about } from "../data";
import { useSelector } from "react-redux";

const AboutSection = () => {
  const lang = useSelector((state) => state.lang[0].val);
  const data = about[lang];

  return (
    <DFSection>
      <img src={craneIcon} alt="crane" />
      <div className="right-content">
        <h3>{data?.mainTitle}</h3>
        <p className="up">{data?.title}</p>
        {data?.content?.map((item, i) =>
          item.type === "text" ? (
            <p key={i}>{item?.content}</p>
          ) : (
            <ul key={i}>
              {item?.content?.map((elem, ind) => (
                <li key={ind}>{elem}</li>
              ))}
            </ul>
          )
        )}
      </div>
    </DFSection>
  );
};

export default AboutSection;
