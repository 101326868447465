// isReversable
import { createSlice } from "@reduxjs/toolkit";

const revSlice = createSlice({
    name: "revs",
    initialState: [],
    reducers: {
        revsAdded: (state, action) => {
            return action?.payload;
        },
    },
});

export const { revsAdded } = revSlice.actions;
export default revSlice.reducer;
