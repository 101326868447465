import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export const BaseSection = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 40px;
  overflow: hidden;
  max-width: 100%;
  flex-direction: column;
`;
export const SliderWrapper = styled.div`
  width: 100%;
  & .slick-slider {
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    > div {
      border-radius: 10px;
    }
    & .slick-slide {
      border-radius: 10px;
      overflow: hidden;
      aspect-ratio: 1/1;
      & > div {
        height: 100%;
      }
    }
    & .slick-track {
      width: 100%;
      display: flex;
      gap: 10px;
    }
    & .slick-arrow {
      height: fit-content;
      background-color: transparent;
      & img {
        width: 20px;
      }
      &.slick-prev {
        transform: rotate(90deg);
      }
      &.slick-next {
        transform: rotate(-90deg);
      }
    }
  }
`;

export const ImageWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  & h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden !important;
    max-height: 70px;
    font-size: 16px;
    font-weight: 500;
  }
`;


export const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
  max-width: 100%;
  & .MuiSkeleton-root {
    min-width: 200px;
    aspect-ratio: 1/1;
  }
  @media (max-width: 1110px) {
    & .MuiSkeleton-root {
      min-width: 150px;
    }
  }
`;
