import React, { useState } from "react";
import {
  Background,
  Dialog,
  DialogHeader,
  IconButton,
  Divider,
  Heading,
  Content,
  Typo,
  Form,
  Label,
  Input,
  Textarea,
  TextButton,
} from "./styled";
import { Button } from "../auth/styled";
import closeIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteKey } from "../../utils/funcs/formHandlings";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { ErrorText } from "../../shared/styled";
import checkIcon from "../../assets/checkIcon.svg";
import { useNavigate } from "react-router";

const OfferPriceDialog = ({ open, handleClose, productId }) => {
  const { t } = useTranslation();
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0].val);
  const vHeight = useSelector((state) => state.appHeight.val);

  const headers = {
    "Accept-Language": lang,
    Authorization: `Bearer ${token}`,
  };
  const [price, setPrice] = useState("");
  const [count, setCount] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [errMessage, setErrMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleChange = (val, setVal, key) => {
    setVal(val);
    deleteKey(key, errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { productId, price: +price, count: +count };
    if (message) {
      data.message = message;
    }
    const items = [data];
    axios
      .post(`${baseUrl}/products/price-requests`, { items }, { headers })
      .then(() => {
        setSuccess(true);
        setErrors({});
        handleClose("success");
      })
      .catch((err) => {
        err.response.data?.errors
          ? setErrors(err.response.data?.errors)
          : setErrMessage(err.response?.data?.message);
      });
  };

  return (
    <>
      <Background
        className={`fullwidth ${open && "open"}`}
        onClick={handleClose}
        vHeight={vHeight}
      />
      <Dialog className={`fullwidth ${open && "open"}`} vHeight={vHeight}>
        <DialogHeader>
          <Heading>{t("actions.offer_price")}</Heading>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="close offer price modal" />
          </IconButton>
        </DialogHeader>
        <Divider />
        <Content>
          {errMessage ? (
            <>
              {" "}
              <Typo className="error">{errMessage}</Typo>
              <Button type="button" onClick={handleClose}>
                {t("texts.got_it")}
              </Button>
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Typo>{t("texts.offer_price_reminder")}</Typo>
              <div className="grid">
                <div>
                  <Label htmlFor="my_offer" className="fl">
                    <span className="up">{t("texts.my_offered_price")}</span>
                    <span>{`(${t("texts.as_total")})`}</span>
                  </Label>
                  <Input
                    id="my_offer"
                    className={errors?.["items.0.price"] && "error"}
                    autoComplete="off"
                    type="number"
                    onKeyDown={(e) => {
                      if (
                        (!e.target.value.length && e.key === ".") ||
                        (+e.target.value === 0 && e.key === "0") ||
                        e.key === "e" ||
                        e.key === "-" ||
                        e.key === "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    value={price}
                    onChange={(e) =>
                      handleChange(e.target.value, setPrice, "price")
                    }
                  />
                  {errors?.["items.0.price"] && (
                    <ErrorText>{errors?.["items.0.price"]?.[0]}</ErrorText>
                  )}
                </div>
                <div>
                  <Label htmlFor="amount" className="fl">
                    <span className="up">{t("texts.the_amount")}</span>
                  </Label>
                  <Input
                    id="amount"
                    className={errors?.["items.0.count"] && "error"}
                    autoComplete="off"
                    type="number"
                    onKeyDown={(e) => {
                      if (
                        (!e.target.value.length && e.key === ".") ||
                        (+e.target.value === 0 && e.key === "0") ||
                        e.key === "e" ||
                        e.key === "-" ||
                        e.key === "+"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    value={count}
                    onChange={(e) =>
                      handleChange(e.target.value, setCount, "count")
                    }
                  />
                  {errors?.["items.0.count"] && (
                    <ErrorText>{errors?.["items.0.count"]?.[0]}</ErrorText>
                  )}
                </div>
              </div>
              <div>
                <Label htmlFor="comment">{t("texts.comment")}</Label>
                <Textarea
                  id="comment"
                  className={errors?.["items.0.message"] && "error"}
                  autoComplete="off"
                  value={message}
                  onChange={(e) =>
                    handleChange(e.target.value, setMessage, "message")
                  }
                />
                {errors?.["items.0.message"] && (
                  <ErrorText>{errors?.["items.0.message"]?.[0]}</ErrorText>
                )}
              </div>
              <Button>
                <img src={checkIcon} alt="sent" width="18" />
                <span>{t("actions.offer_price")}</span>
              </Button>
              <TextButton type="button" onClick={handleClose}>
                {t("actions.cancel")}
              </TextButton>
            </Form>
          )}
        </Content>
      </Dialog>
    </>
  );
};

export default OfferPriceDialog;
