import { createSlice } from "@reduxjs/toolkit";

const favsSlice = createSlice({
  name: "favs",
  initialState: [],
  reducers: {
    favsAdded: (state, action) => {
      return action?.payload;
    },
  },
});

export const { favsAdded } = favsSlice.actions;
export default favsSlice.reducer;
