import React, { useState } from "react";
import { Form, Label, Input, Button, InputWrapper, BtnLink } from "./styled";
import PasswordInput from "./PasswordInput";
import { PatternFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../constants";
import { deleteKey, changeState } from "../../utils/funcs/formHandlings";
import { ErrorText } from "../../shared/styled";
import { useSelector } from "react-redux";

const Register = ({ handleClose }) => {
  const lang = useSelector((state) => state.lang[0]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e, key, setValue) => {
    changeState(e.target.value, setValue);
    deleteKey(key, errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fullName = `${firstName.trim()} ${lastName.trim()}`;
    const data = {
      fullName,
      email,
      phone: `+374${phone}`,
      password,
      passwordConfirmation,
    };

    axios
      .post(`${baseUrl}/auth/sign-up`, data, {
        headers: {
          Accept: "application/json",
          "Accept-Language": lang.val,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        localStorage.setItem("initToken", res?.data?.data?.token);
        handleClose("success");
      })
      .catch((err) => setErrors(err?.response?.data?.errors));
  };

  const { t } = useTranslation();

  return (
    <>
      <Form onSubmit={handleSubmit} className="register" autocomplete="off">
        <BtnLink to="/shinmag-club" onClick={handleClose}>
          {t("actions.become_partner")}
        </BtnLink>
        <div>
          <Label htmlFor="register-name">{t("texts.name")}</Label>
          <Input
            id="register-name"
            className={errors?.fullName && "error"}
            value={firstName}
            onChange={(e) => handleChange(e, "fullName", setFirstName)}
          />
          {errors?.fullName && <ErrorText>{errors.fullName?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="register-lastname">{t("texts.lastname")}</Label>
          <Input
            id="register-lastname"
            className={errors?.fullName && "error"}
            value={lastName}
            onChange={(e) => handleChange(e, "fullName", setLastName)}
          />
          {errors?.fullName && <ErrorText>{errors.fullName?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="register-phone">{t("texts.phone")}</Label>
          <InputWrapper>
            <PatternFormat
              customInput={Input}
              placeholder="12 34 56 78"
              format="########"
              mask="_"
              id="register-phone"
              className={`with-prefix ${errors?.phone && "error"}`}
              value={phone}
              onChange={(e) => handleChange(e, "phone", setPhone)}
              type="tell"
            />
            <span className="prefix">(+374)</span>
          </InputWrapper>
          {errors?.phone && <ErrorText>{errors.phone?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="register-email">{t("texts.email")}</Label>
          <Input
            id="register-email"
            className={errors?.email && "error"}
            value={email}
            onChange={(e) => handleChange(e, "email", setEmail)}
            type="email"
          />
          {errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="register-password">{t("texts.password")}</Label>
          <PasswordInput
            show={showPassword}
            handleShow={() => setShowPassword(!showPassword)}
            id="register-password"
            className={errors?.password && "error"}
            value={password}
            onChange={(e) => handleChange(e, "password", setPassword)}
          />
          {errors?.password && <ErrorText>{errors.password?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="register-password-confirm">
            {t("texts.confirm_password")}
          </Label>
          <PasswordInput
            show={showPasswordConfirm}
            handleShow={() => setShowPasswordConfirm(!showPasswordConfirm)}
            id="register-password-confirm"
            className={errors?.passwordConfirmation && "error"}
            value={passwordConfirmation}
            onChange={(e) =>
              handleChange(e, "passwordConfirmation", setPasswordConfirmation)
            }
          />
          {errors?.passwordConfirmation && (
            <ErrorText>{errors.passwordConfirmation?.[0]}</ErrorText>
          )}
        </div>
        <Button>{t("actions.register")}</Button>
      </Form>
    </>
  );
};

export default Register;
