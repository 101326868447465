import React, {useState} from "react";
import EditPassword from "../personal-page/popups/EditPassword";
import {
    PopupBody,
    PopupDivider, PopupFormInputLabel,
    PopupFormInputWrapper, PopupFormSubmitButton,
    PopupFormWrapper,
    PupupHeader
} from "../personal-page/popups/styled";
import closeIcon from "../../assets/closeIcon.svg";
import PasswordInput from "../../components/auth/PasswordInput";
import checkIcon from "../../assets/checkIcon.svg";
import Modal from "../personal-page/popups/Modal";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";
import {changeState, deleteKey} from "../../utils/funcs/formHandlings";
import axios from "axios";
import {baseUrl} from "../../constants";
import {userAdded} from "../../features/user/userSlice";
import {Wrapper} from "../../shared/styled";
import {Container, ContentWrapper} from "../../components/advanatages/styled";
import {useNavigate, useParams} from "react-router";

const ActivateGift = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const lang = useSelector((state) => state.lang[0]);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
        useState(false);

    const [errors, setErrors] = useState({});
    const [succeeded, setSucceeded] = useState(false);

    const token = useLocalStorageGetItem("token");

    const handleChange = (e, key, setValue) => {
        changeState(e.target.value, setValue);
        deleteKey(key, errors);
    };

    const handleToggle = (setValue) => {
        setValue((prev) => !prev);
    };

    const handleSaveNewPassword = (e) => {
        e.preventDefault();
        const data = {
            password,
            newPassword,
            newPasswordConfirmation,
        };
        axios
            .put(`${baseUrl}/gifts/${atob(id)}/use`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept-Language": lang.val,
                },
            })
            .then((res) => {
                setSucceeded(true);
                setTimeout(() => {
                    navigate("/");
                }, 3000);
            })
            .catch((err) => {
                setErrors(err.response.data.errors);
            });
    };
    return (
        <Container white>
            <ContentWrapper length={3}>
                <PupupHeader>
                    <p className="heading">{t("actions.edit_password")}</p>
                </PupupHeader>
                <PopupDivider></PopupDivider>
                <PopupBody>
                    <PopupFormWrapper onSubmit={handleSaveNewPassword}>
                        <PopupFormInputWrapper>
                            <PopupFormInputLabel htmlFor="settings-password">
                                {t("texts.current_password")}
                            </PopupFormInputLabel>
                            <div>
                                <PasswordInput
                                    id="settings-password"
                                    value={password}
                                    onChange={(e) => handleChange(e, "password", setPassword)}
                                    className={`${errors?.password && "error"}`}
                                    show={showPassword}
                                    handleShow={() => handleToggle(setShowPassword)}
                                />
                                {errors?.password && (
                                    <p className="error-message">{errors?.password}</p>
                                )}
                            </div>
                        </PopupFormInputWrapper>
                        <PopupFormInputWrapper>
                            <PopupFormInputLabel htmlFor="settings-new-password">
                                {t("texts.new_password")}
                            </PopupFormInputLabel>
                            <div>
                                <PasswordInput
                                    id="settings-new-password"
                                    value={newPassword}
                                    onChange={(e) => handleChange(e, "newPassword", setNewPassword)}
                                    className={`${errors?.newPassword && "error"}`}
                                    show={showNewPassword}
                                    handleShow={() => handleToggle(setShowNewPassword)}
                                />
                                {errors?.newPassword && (
                                    <p className="error-message">{errors?.newPassword}</p>
                                )}
                            </div>
                        </PopupFormInputWrapper>
                        <PopupFormInputWrapper>
                            <PopupFormInputLabel htmlFor="settings-confirm-new-password">
                                {t("texts.new_password_confirm")}
                            </PopupFormInputLabel>
                            <div>
                                <PasswordInput
                                    id="settings-confirm-new-password"
                                    value={newPasswordConfirmation}
                                    onChange={(e) =>
                                        handleChange(
                                            e,
                                            "newPasswordConfirmation",
                                            setNewPasswordConfirmation
                                        )
                                    }
                                    className={`${errors?.newPasswordConfirmation && "error"}`}
                                    show={showNewPasswordConfirmation}
                                    handleShow={() => handleToggle(setShowNewPasswordConfirmation)}
                                />
                                {errors?.newPasswordConfirmation && (
                                    <p className="error-message">
                                        {errors?.newPasswordConfirmation}
                                    </p>
                                )}
                            </div>
                        </PopupFormInputWrapper>
                        <PopupFormSubmitButton className={succeeded && "success"}>
                            <img src={checkIcon} alt="sent" width="18"/>
                            <span>{t("actions.save")}</span>
                        </PopupFormSubmitButton>
                    </PopupFormWrapper>
                </PopupBody>
            </ContentWrapper>
        </Container>
    )
};

export default ActivateGift