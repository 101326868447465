import React from "react";
import DataContainer from "../DataContainer";
import { Title, List } from "../styled";
import { data } from "./data";
import { useSelector } from "react-redux";

const About = () => {
  const lang = useSelector((state) => state.lang[0].val);
  return (
    <DataContainer as="div">
      {data[lang]?.map((item, i) => (
        <div key={i}>
          <Title className={`title ${item?.main ? "b" : ""}`}>
            {item.title}
          </Title>
          {item.content.map((elem, i) => (
            <div key={i} className="title">
              {elem.type !== "list" ? (
                <span>{elem.text}</span>
              ) : (
                <List>
                  {elem.items.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </List>
              )}
            </div>
          ))}
        </div>
      ))}
    </DataContainer>
  );
};

export default About;
