import React, { useState } from "react";
import { Container } from "../../shared/styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { Heading } from "../shinmag-club/styled";
import { List, Item } from "./styled";
import { useGetData } from "../../utils/hooks/hooks";
import { useParams } from "react-router";
import { CardGrid } from "../../components/cards/styled";
import SkeletonCard from "../../components/cards/SkeletonCard";
import EmptyList from "../../components/empty-list";
import ProductCard from "../../components/cards/ProductCard";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useNavigate } from "react-router";

const BestBrands = () => {
  const { name, page } = useParams();
  const [currentPage, setCurrentPage] = useState(+page || 1);
  const { data, isLoading } = useGetData(
    `brandes/${name}/products?perPage=12&page=${currentPage}`,
    [currentPage]
  );

  const products = data?.products?.data;
  const meta = data?.products?.meta;

  const navigate = useNavigate();

  const nav = [
    {
      to: `/best-brands/${name}`,
      name,
    },
  ];

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/best-brands/${name}/${page}`);
  };

  return (
    <Container>
      <Breadcrumbs nav={nav} />
      <Heading>{name}</Heading>
      {isLoading ? (
        <CardGrid>
          {Array.from({ length: 3 }).map((x, i) => (
            <SkeletonCard key={i} />
          ))}
        </CardGrid>
      ) : !!products?.length ? (
        <CardGrid>
          {products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
        </CardGrid>
      ) : (
        <EmptyList />
      )}
      {meta?.last_page > 1 && (
        <CustomPagination
          page={+currentPage}
          pageCount={meta?.last_page}
          handlePageChange={handlePageChange}
        />
      )}
    </Container>
  );
};

export default BestBrands;
