import React from "react";

const ExpandMoreIcon = () => {
  return (
    <>
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75742 8.96766L0.307861 2.51789C-0.10262 2.10761 -0.10262 1.4424 0.307861 1.03232C0.717977 0.622202 1.38315 0.622202 1.79323 1.03232L7.5001 6.73937L13.2068 1.03248C13.6171 0.622368 14.2822 0.622368 14.6923 1.03249C15.1026 1.4426 15.1026 2.10777 14.6923 2.51805L8.24263 8.96783C8.03746 9.17288 7.76887 9.2753 7.50014 9.2753C7.23127 9.2753 6.96248 9.17268 6.75742 8.96766Z"
          fill="#373E4B"
        />
      </svg>
    </>
  );
};

export default ExpandMoreIcon;
