import React, { useState } from "react";
import ProductCard from "../cards/ProductCard";
import { CardGrid } from "../cards/styled";
import { useGetData } from "../../utils/hooks/hooks";
import ProductFilters from "../product-filters/ProductFilters";
import { useTranslation } from "react-i18next";
import EmptyList from "../empty-list";
import SkeletonCard from "../cards/SkeletonCard";

const HomeProducts = () => {
  const { t } = useTranslation();
  const filters = [
    { name: t("texts.new"), endpoint: "newest" },
    { name: t("texts.best_selling"), endpoint: "popular" },
    { name: t("texts.sale"), endpoint: "discounted" },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filters[1]);
  const { data: products, isLoading } = useGetData(
    `products/trends/${selectedFilter?.endpoint}?perPage=12`,
    [selectedFilter]
  );

  const handleChange = (filter) => setSelectedFilter(filter);

  return (
    <>
      <ProductFilters
        filters={filters}
        selectedFilter={selectedFilter}
        handleChange={handleChange}
      />
      {isLoading ? (
        <CardGrid>
          {Array.from({ length: 4 }).map((x, i) => (
            <SkeletonCard key={i} />
          ))}
        </CardGrid>
      ) : !!products?.length ? (
        <CardGrid>
          {products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
        </CardGrid>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default HomeProducts;
