import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    userAdded: (state, action) => {
      const { payload } = action;
      return payload;
    },
  },
});

export const { userAdded } = userSlice.actions;
export default userSlice.reducer;
