import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export const BaseSection = styled.div`
  display: none;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 40px;
  overflow: hidden;
  max-width: 100%;
  flex-direction: column;
  @media (max-width: 900px) {
    display: flex;
  }
`;
export const SliderWrapper = styled.div`
  width: 100%;
  & .slick-slider {
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    > div {
      border-radius: 10px;
    }
    & .slick-slide {
      border-radius: 10px;
      overflow: hidden;
      aspect-ratio: 1/1;
      & > div {
        height: 100%;
      }
    }
    & .slick-track {
      width: 100%;
      display: flex;
      gap: 10px;
    }
    & .slick-arrow {
      height: fit-content;
      background-color: transparent;
      & img {
        width: 20px;
      }
      &.slick-prev {
        transform: rotate(90deg);
      }
      &.slick-next {
        transform: rotate(-90deg);
      }
    }
  }
`;