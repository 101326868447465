import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  &.open {
    display: block;
  }
  & .MuiCircularProgress-root {
    color: #ffe600;
  }
`;
