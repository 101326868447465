import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & button {
    width: fit-content;
    margin: 0 auto;
  }
  & textarea {
    padding: 15px 10px;
  }
`;

export const GridSection = styled.section`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
