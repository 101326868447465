import React, { useState } from "react";
import { Actions, BackBtn } from "./styled";
import { FilledButton, OutlineButton } from "../../pages/product/styled";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import LeaveReviewDialog from "../dialogs/LeaveReviewDialog";
import ErrorDialog from "../dialogs/ErrorDialog";

const LeaveReview = ({ place, handleBack }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [success, setSuccess] = useState(false);

  const toggleOpenDialog = () => setOpen((o) => !o);

  const handleCloseReview = (suc) => {
    if (suc) {
      setSuccess(true);
    }
    setOpen(false);
  };

  const closeSuccessDialog = () => setSuccess(false);

  return (
    <Actions className={place === "review-page" ? "mt" : ""}>
      <FilledButton onClick={toggleOpenDialog}>
        {t("actions.leave_a_review")}
      </FilledButton>
      {place === "product-page" && (
        <OutlineButton as={Link} to={`${pathname}/reviews`}>
          {t("actions.show_all")}
        </OutlineButton>
      )}
      {place === "review-page" && (
        <BackBtn onClick={handleBack}>{t("actions.back")}</BackBtn>
      )}
      {open && (
        <LeaveReviewDialog open={open} handleClose={handleCloseReview} />
      )}
      {success && (
        <ErrorDialog
          open={success}
          success="success"
          message={t("texts.sent")}
          handleClose={closeSuccessDialog}
        />
      )}
    </Actions>
  );
};

export default LeaveReview;
