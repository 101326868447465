import React from "react";
import { InputWrapper, InputIconButton, Input } from "./styled";
import visibilityIcon from "../../assets/visibilityIcon.svg";

const PasswordInput = ({
  show,
  handleShow,
  id,
  value,
  onChange,
  className,
}) => {
  return (
    <InputWrapper>
      <Input
        id={id}
        type={show ? "text" : "password"}
        autoComplete="off"
        value={value}
        onChange={onChange}
        className={className}
      />
      <InputIconButton onClick={handleShow} type="button">
        <img src={visibilityIcon} alt="show" />
      </InputIconButton>
    </InputWrapper>
  );
};

export default PasswordInput;
