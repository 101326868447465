import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { pad_x } from "../../shared/styled";

export const spaceBwFlex = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const customTypo = css`
  color: #fff;
  font-weight: 400;
  font-size: 10px;
`;

export const MainHeader = styled.header`
  width: 100%;
  padding: 15px 20px;
  ${pad_x}
  min-height: 105px;
  background-color: #fff;
  ${alignCenter};
  justify-content: space-between;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 10;
  gap: 5px;
  @media (max-width: 1440px) {
    min-height: 60px;
    padding: 15px 10px;
    ${pad_x}
  }
  @media (max-width: 380px) {
    gap: 10px;
  }
`;

export const LeftContent = styled.div`
  ${alignCenter};
  gap: 20px;
  @media (max-width: 420px) {
    gap: 16px;
  }
`;

export const LogoWrapper = styled(Link)`
  text-decoration: none;
  ${alignCenter}
  &.left {
    & img {
      height: 40px;
      display: block;
      margin: 5px 0;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &.center {
    & img {
      height: 30px;
    }
    @media (min-width: 1025px) {
      display: none;
    }
  }
`;

export const IconButton = styled.button`
  ${alignCenter};
  gap: 5px;
  border: none;
  background-color: inherit;
  font-size: 18px;
  color: #373e4b;
  text-transform: uppercase;
  cursor: pointer;
  &.search {
    display: none;
  }
  @media (max-width: 1024px) {
    &.search {
      display: block;
      width: 20px;
      height: 20px;
    }
    & img {
      width: 24px;
      height: 20px;
    }
  }
`;

export const CenterContent = styled.div`
  ${alignCenter};
  gap: 15px;
  min-width: 680px;
  @media (max-width: 1800px) {
    display: none;
  }
`;

export const RightContent = styled.div`
  ${alignCenter};
  gap: 20px;
  @media (max-width: 420px) {
    gap: 16px;
  }
  @media (max-width: 1440px) {
    max-width: 50%;
    width: 100%;
    justify-content: flex-end;
  }
  @media (max-width: 1024px) {
    ${alignCenter};
    gap: 20px;
    width: unset;
    max-width: unset;
  }
`;

export const SearchFieldWrapper = styled.div`
  position: relative;
  & img {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  max-width: 330px;
  width: 100%;
  @media (max-width: 1440px) {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SearchField = styled.input`
  background: #fff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  outline: none;
  padding: 14px 35px 14px 10px;
  width: 100%;
  font-size: 12px;
`;

export const RightContentItem = styled(Link)`
  border: none;
  background-color: inherit;
  ${spaceBwFlex}
  color: #373e4b;
  font-size: 8px;
  text-decoration: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  & p::first-letter {
    text-transform: uppercase;
  }
  & .img-wrapper {
    position: relative;
    & .count {
      position: absolute;
      bottom: 0;
      left: 100%;
      font-size: 12px;
      line-height: 14px;
      color: #373e4b;
    }
    & img {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 1520px) {
    & .icon-name {
      display: none;
    }
  }
`;