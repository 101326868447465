import React from "react";
import DataContainer from "../DataContainer";
import { data } from "./data";
import {List, Title, Table, TablesWrapper} from "../styled";
import {useSelector} from "react-redux";
import {Divider} from "../../delivery/prices/styled";
import dot from "../../../assets/dot.svg";

const Bonuses = () => {
  const lang = useSelector((state) => state.lang[0].val);

  return (<DataContainer as="div">
    <Title>{data?.[lang].title}</Title>
    <List ul>
      {data?.[lang]?.content?.map((item, i) => (
            <li key={i}><img src={dot} alt=""/><p>{item}</p></li>
      ))}
    </List>
    <Divider />
    <Title top>{data?.[lang].tablesTitle}</Title>
    <TablesWrapper>
      <Table>
        <tr>
          <th>{data?.[lang].price}</th>
          <th>{data?.[lang].next}</th>
          <th>{data?.[lang].secondTh}</th>
          <th>{data?.[lang].thirdTh}</th>
        </tr>
        {data?.[lang].firstTable?.map(item=>(
            <tr>
              <td>{item[0]}</td>
              <td>{item[1]}</td>
              <td>{item[2]}</td>
              <td>{item[3]}</td>
            </tr>
        ))}
      </Table>
    </TablesWrapper>
  </DataContainer>);
};

export default Bonuses;
