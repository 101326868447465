import React, {useState} from "react";
import { useSelector } from "react-redux";
import { data } from "./data";
import {Title, List, FormWrapper, NavigationButton, NavigationContainer, NavigationButtonn} from "../styled";
import DataContainer from "../DataContainer";
import {Actions, Button, Form, Input, InputWrapper, Label, RecoverPasswword} from "../../../components/auth/styled";
import {ErrorText} from "../../../shared/styled";
import PasswordInput from "../../../components/auth/PasswordInput";
import {useTranslation} from "react-i18next";
import card from "../../../assets/card.png";
import {useLocation} from "react-router";
import axios from "axios";
import {baseUrl} from "../../../constants";
import {changeState, deleteKey} from "../../../utils/funcs/formHandlings";
import {useLocalStorageGetItem} from "../../../utils/hooks/hooks";
import ErrorDialog from "../../../components/dialogs/ErrorDialog";

const GiftCard = () => {
    const { hash } = useLocation();
    const [emailOrPhone, setEmailOrPhone] = useState()
    const [name, setName] = useState()
    const [money, setMoney] = useState()
    const [modal, setModal] = useState(false)
    const [id, setId] = useState()
    const token = useLocalStorageGetItem("token");
    const lang = useSelector((state) => state.lang[0].val);
    const { t } = useTranslation();
    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = { phone: emailOrPhone, fullName: name, balance : +money };
        console.log('data', data);
        axios
            .post(`${baseUrl}/gifts`, data, {
                headers: {
                    Accept: "application/json",
                    "Accept-Language": lang,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then(()=>{
            setModal(true)
        })
  }

    const handleUse = (e)=>{
        e.preventDefault();
        axios
            .patch(`${baseUrl}/gifts/${id}/use/request`, {
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Accept-Language": lang,
                    "Content-Type": "application/json",
                },
            })
  }

    const handleChange = (e, key, setValue) => {
        changeState(e.target.value, setValue);
        // deleteKey(key, errors);
    };
  return (
    <DataContainer as="div">
        <ErrorDialog handleClose={()=>setModal(false)}
                     message={t("texts.cardOrderMessage")}
                     open={modal}
                     success="success"/>
        <NavigationContainer>

            <NavigationButtonn
                to="/info/company/gift-cards"
                hash={!hash}
            >
                {t('texts.order_card')}
            </NavigationButtonn>
            <NavigationButtonn
                to="/info/company/gift-cards#activate"
                hash={hash}
            >
                {t('texts.activate_card')}
            </NavigationButtonn>
        </NavigationContainer>
        {hash ?
        <>
            <FormWrapper>
                <Form onSubmit={handleUse} autocomplete="off">
                    <input type="hidden" id="password" />
                    <div>
                        <Label htmlFor="name">{"Card ID"}</Label>
                        <InputWrapper>
                            <Input
                                id="name"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                        </InputWrapper>
                        {/*{errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}*/}
                    </div>
                    <Button>{t("actions.activate")}</Button>
                </Form>
            </FormWrapper>
        </> :
        <>
            <Title>{data?.[lang].title}</Title>
            <List>
                {data?.[lang]?.content?.map((item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </List>
            <FormWrapper small>
                <Form onSubmit={handleSubmit} autocomplete="off">
                    <input type="hidden" id="password" />
                    <div>
                        <Label htmlFor="name">{t("texts.name")}</Label>
                        <InputWrapper>
                            <Input
                                id="name"
                                value={name}
                                onChange={(e) => handleChange(e, "email", setName)}
                            />
                        </InputWrapper>
                        {/*{errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}*/}
                    </div>
                    <div>
                        <Label htmlFor="phone">{t("texts.phone")}</Label>
                        <InputWrapper>
                            <Input
                                id="phone"
                                value={emailOrPhone}
                                onChange={(e) => handleChange(e, "email", setEmailOrPhone)}
                            />
                        </InputWrapper>
                        {/*{errors?.password && <ErrorText>{errors.password?.[0]}</ErrorText>}*/}
                    </div>
                    <div>
                        <Label htmlFor="money">{t("texts.money")}</Label>
                        <InputWrapper>
                            <Input
                                id="money"
                                value={money}
                                onChange={(e) => handleChange(e, "email", setMoney)}
                            />
                        </InputWrapper>
                        {/*{errors?.password && <ErrorText>{errors.password?.[0]}</ErrorText>}*/}
                    </div>
                    <Button>{t("actions.to_order")}</Button>
                </Form>
                <img src={card} alt=""/>
            </FormWrapper>
        </>}
    </DataContainer>
  );
};

export default GiftCard;
