import styled from "styled-components";
import breadcrumbsArrow from "../../assets/breadcrumbsArrow.svg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  margin: 20px 0 60px 0;
  text-align: center;
  //@media (max-width: 768px) {
  //  margin: 0;
  //  margin-bottom: 30px;
  //}
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 1024px) {
    gap: 5px;
  }
  max-width: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #373e4b;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1024px) {
    gap: 5px;
  }
  & span {
    text-align: center;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
    width: fit-content;
    &::first-letter {
      text-transform: capitalize;
    }
    &.show-all {
      max-width: fit-content;
    }
    @media (max-width: 1110px) {
      max-width: 180px;
    }
    @media (max-width: 1024px) {
      max-width: 150px;
    }
  }
  &::after {
    content: "";
    height: 100%;
    min-width: 10px;
    min-height: 10px;
    background-image: url(${breadcrumbsArrow});
    background-size: 5px 10px;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:last-child::after {
    display: none;
  }
`;
