import styled, { css } from "styled-components";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;

export const OrdersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  width: 100%;
`;

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px 0;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  &:last-of-type {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const OrderCardsWrapper = styled.div`
  ${props => !props?.single && `display: grid`};
  //grid-template-columns: repeat(5, 1fr);
  ${props => (props?.single && props?.isReversable) && `
  label {
    display: flex;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
  }
  input[type=checkbox] ~ span.selectAll {
    top: 0;
  }
  input[type=checkbox] {
    visibility: hidden;
  }
  input[type=checkbox] ~ span{
    position: absolute;
    top: 40%;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #373e4b;
  }
  input[type=checkbox]:checked ~ span:after  {
    content: "";
  }
  input[type=checkbox] ~ span:after {
    display: block;
    position: absolute;
    left: 6px;
    bottom: 5px;
    width: 6px;
    height: 12px;
    border: solid #373e4b;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  tr {
    width: 100%;
  }
  @media (max-width: 820px) {
    tr {
     flex-wrap: wrap;
    }
    td:first-of-type {
        width: 100%;
        padding-bottom: 0
    }
    .bold {
        font-size: 12px
    }
  }
  `};
  gap: 20px;
  width: 60%;
  margin: 0 auto;

  .reverse {
    margin-top: 40px;
    font-weight: 700;
    font-size: 20px;
  }

  .totalPrice {
    font-size: 20px;
    font-weight: bold;
    text-align: end;
    margin-top: 20px
  }

  .totalPriceWrapper {
    max-width: 250px;
    margin: 15px 0 0 auto;
    text-transform: uppercase;
  }

  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  td a {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1540px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1260px) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const OrderButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin: 0 10px;
  & button {
    border: 1px solid #373e4b;
    border-radius: 5px;
    padding: 14px 22px;
    ${typo};
    font-weight: 700;
    text-align: center;
    &.buy-again {
      background-color: #373e4b;
      color: #fff;
      text-transform: uppercase;
    }
  }
  & .see-details {
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid #373e4b;
    border-radius: 5px;
    padding: 14px 22px;
    ${typo};
    font-weight: 700;
    text-align: center;
    color: #373e4b;
    background-color: #ffffff;
    &.back-to-orders {
      background-color: #373e4b;
      color: #fff;
      min-width: 146px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    & button,
    & a {
      width: 50%;
    }
  }
  @media (max-width: 407px) {
    width: 100%;
    flex-direction: column;
    & button,
    & a {
      width: 100%;
    }
  }
`;

export const OrderCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  //& a {
  //  max-height: 82px;
  //}
  * {
    color: black;
  }
  & .pad {
    padding: 3px 4px;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  & img {
    max-height: 82px;
    max-width: 82px;
    width: auto;
    height: auto;
  }
  & ul {
    width: fit-content;
    list-style-type: none;
  }
  & li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 20px;
    }
    & .mr {
      margin-right: 5px;
      &::after {
        content: ",";
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
  & .bold {
    font-weight: 700;
    display: inline-block;
    margin-right: 5px;
    &::first-letter {
      text-transform: uppercase;
    }
    &::after {
      content: ":";
    }
  }
  @media(max-width: 768px) {
    flex-direction: column;
  }
`;

export const OrderCardDescription = styled.p`
  max-height: 38px;
  ${typo};
  color: #000;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Typo = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-transform: capitalize;
`;

export const SingleOrderDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #d9d9d9;
`;

export const SingleOrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  & .info {
    ${typo}
    color: #373e4b;
    & .value {
      font-weight: 700;
    }
    &.payment-method {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`;

export const OrderInfoContainer = styled.div`
  border-bottom: 1px solid #d9d9d9;
  width: 100%;

  span.message {
    color: red;
    text-align: center;
    display: block;
    margin: 20px 0;
    font-size: 16px;
  }

  & ul {
    ${typo}
    width: fit-content;
    margin: 0 auto;
    list-style-type: none;
  }

  & li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 20px;
    }

    & .mr {
      margin-right: 5px;

      &::after {
        content: ",";
      }

      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
  
  & a {
    color: black;
    span {
      text-decoration: underline;
    }
  }

  & .bold {
    font-weight: 700;
    display: inline-block;
    margin-right: 5px;

    &::first-letter {
      text-transform: uppercase;
    }

    &::after {
      content: ":";
    }
  }
`;
