import React, { useEffect, useState } from "react";
import {
  Background,
  Dialog,
  DialogHeader,
  IconButton,
  Divider,
  Heading,
  Content,
  Typo,
  Form,
  Label,
  Input,
  Textarea,
  TextButton,
  Warning,
} from "./styled";
import { Button } from "../auth/styled";
import closeIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteKey } from "../../utils/funcs/formHandlings";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useGetData, useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { ErrorText } from "../../shared/styled";
import checkIcon from "../../assets/checkIcon.svg";
import { useParams } from "react-router";
import { PatternFormat } from "react-number-format";
import { InputWrapper } from "../auth/styled";
import { Rating } from "@mui/material";

const LeaveReviewDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0].val);
  const vHeight = useSelector((state) => state.appHeight.val);
  const user = useSelector((state) => state.user);
  const { data: addresses } = useGetData("users/addresses", [], token, token);
  const { productId } = useParams();

  const headers = {
    "Accept-Language": lang,
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (addresses?.length) {
      setAddress(addresses?.[0]?.region.name);
    }
  }, [addresses]);

  useEffect(() => {
    if (user) {
      setfullName(user?.fullName || "");
    }
  }, [user]);

  const [fullName, setfullName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [rate, setRate] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (val, setVal, key) => {
    setVal(val);
    deleteKey(key, errors);
  };

  const handleRateChange = (e, val) => {
    setRate(val);
    deleteKey("rate", errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      rate,
      comment: message,
      fullName,
      address,
      phone: phone ? `+374${phone}` : "",
    };
    axios
      .post(`${baseUrl}/products/${productId}/feedbacks`, data, { headers })
      .then(() => {
        handleClose("success");
      })
      .catch((err) => setErrors(err.response.data?.errors));
  };

  return (
    <>
      <Background
        className={`fullwidth ${open && "open"}`}
        onClick={() => handleClose("")}
        vHeight={vHeight}
      />
      <Dialog className={`fullwidth ${open && "open"}`} vHeight={vHeight}>
        <DialogHeader>
          <Heading className="sm">{t("actions.leave_a_review")}</Heading>
          <IconButton onClick={() => handleClose("")}>
            <img src={closeIcon} alt="close leave review modal" />
          </IconButton>
        </DialogHeader>
        <Divider />
        <Content>
          <Form onSubmit={handleSubmit}>
            <div>
              <Label as="span">{t("texts.your_rate")}</Label>
              <div className={errors?.rate ? "rate-error" : ""}>
                <Rating value={rate} onChange={handleRateChange} size="large" />
              </div>
              {errors?.rate && <ErrorText>{errors?.rate?.[0]}</ErrorText>}
            </div>
            <div>
              <Label htmlFor="comment">{t("texts.review")}</Label>
              <Textarea
                id="comment"
                className={errors?.message && "error"}
                autoComplete="off"
                value={message}
                onChange={(e) =>
                  handleChange(e.target.value, setMessage, "message")
                }
              />
              {errors?.message && <ErrorText>{errors?.message?.[0]}</ErrorText>}
            </div>
            <div className="grid">
              <div>
                <Label htmlFor="reviewer-name">{t("texts.name")}</Label>
                <Input
                  id="reviewer-name"
                  className={errors?.fullName && "error"}
                  autoComplete="off"
                  value={fullName}
                  onChange={(e) =>
                    handleChange(e.target.value, setfullName, "fullName")
                  }
                />
                {errors?.fullName && (
                  <ErrorText>{errors?.fullName?.[0]}</ErrorText>
                )}
              </div>
              <div>
                <Label htmlFor="reviewer-city">{t("texts.city")}</Label>
                <Input
                  id="reviewer-city"
                  className={errors?.address && "error"}
                  autoComplete="off"
                  value={address}
                  onChange={(e) =>
                    handleChange(e.target.value, setAddress, "address")
                  }
                />
                {errors?.address && (
                  <ErrorText>{errors?.address?.[0]}</ErrorText>
                )}
              </div>
              <div className="fullwidth-item">
                <Label htmlFor="reviewer-phone">{t("texts.phone")}</Label>
                <InputWrapper>
                  <PatternFormat
                    customInput={Input}
                    placeholder="12 34 56 78"
                    format="########"
                    mask="_"
                    id="reviewer-phone"
                    className={`with-prefix ${errors?.phone && "error"}`}
                    value={phone}
                    onChange={(e) =>
                      handleChange(e.target.value, setPhone, "phone")
                    }
                    type="tell"
                  />
                  <span className="prefix">(+374)</span>
                </InputWrapper>
                {errors?.phone && <ErrorText>{errors?.phone?.[0]}</ErrorText>}
              </div>
            </div>
            <Typo className="cr">{t("texts.terms_of_review")}</Typo>
            <Button>
              <img src={checkIcon} alt="sent" width="18" />
              <span>{t("actions.leave_a_review")}</span>
            </Button>
            <TextButton type="button" onClick={() => handleClose("")}>
              {t("actions.cancel")}
            </TextButton>
          </Form>
        </Content>
      </Dialog>
    </>
  );
};

export default LeaveReviewDialog;
