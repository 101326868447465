import React, { useState } from "react";
import {
  TextButton,
  Form,
  Label,
  Input,
  Button,
  Text,
  BackButton,
} from "./styled";
import {
  Background,
  Dialog,
  DialogHeader,
  IconButton,
  Divider,
} from "../dialogs/styled";
import closeIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../constants";
import { ErrorText } from "../../shared/styled";
import { useSelector } from "react-redux";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";

const Recover = ({ open, handleClose, handleBackToLogIn }) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const token = useLocalStorageGetItem("token");
  const vHeight = useSelector((state) => state.appHeight.val);
  const lang = useSelector((state) => state.lang[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${baseUrl}/auth/reset/request`,
        {
          email,
        },
        {
          headers: {
            "Accept-Language": lang.val,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => handleClose("success"))
      .catch((err) => setErrors(err?.response?.data?.errors));
  };

  const { t } = useTranslation();

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrors({});
  };

  return (
    <>
      <Background
        className={`fullwidth ${open ? "open" : ""}`}
        onClick={handleClose}
        vHeight={vHeight}
      />
      <Dialog className={`fullwidth ${open ? "open" : ""}`} vHeight={vHeight}>
        <DialogHeader>
          <TextButton as="p" style={{ cursor: "default" }}>
            {t("actions.recover_password")}
          </TextButton>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="x" />
          </IconButton>
        </DialogHeader>
        <Divider />
        <Form onSubmit={handleSubmit}>
          <Text className="mb">{t("texts.recover_message")}</Text>
          <div>
            <Label htmlFor="recover-email">{t("texts.email")}</Label>
            <Input
              id="recover-email"
              className={errors?.email && "error"}
              value={email}
              onChange={handleChange}
              type="email"
            />
            {errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}
          </div>
          <div>
            <Button>{t("actions.recover_password")}</Button>
            <BackButton onClick={handleBackToLogIn}>
              {t("actions.back_to_login")}
            </BackButton>
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default Recover;
