import styled, { css } from "styled-components";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

const firstLetterUppercase = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const PersonalPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: baseline;
  }
  @media (max-width: 768px) {
    border: none;
    max-width: 100%;
    width: 100%;
  }
`;

export const FullName = styled.div`
  & .name {
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    color: #373e4b;
    text-transform: capitalize;
    margin-bottom: 12px;
    @media (max-width: 1440px) {
      text-align: left;
    }
  }
  & .referal-code {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #373e4b;
    margin-bottom: 30px;
    ${firstLetterUppercase}
    & .code {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #373e4b;
      margin-right: 7px;
    }
    & img {
      cursor: pointer;
    }
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  ${typo}
  & p {
    text-align: right;
    color: #373e4b;
    &.level {
      font-weight: 700;
    }
    ${firstLetterUppercase}
  }
  & .activate {
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    display: inline-block;
    ${typo}
    ${firstLetterUppercase}
    margin-left: auto;
    &:disabled {
      opacity: 0.7;
      cursor: default;
    }
  }
  @media (max-width: 1440px) {
    & p {
      text-align: left;
    }
    & .activate {
      margin-left: 0;
    }
  }
`;

export const AdditionalFilesFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  & .fullwidth {
    width: 100%;
    & .row {
      @media (max-width: 620px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  & .form-title-container {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    & .form-title {
      ${typo}
      font-weight: 700;
      color: #373e4b;
      ${firstLetterUppercase}
    }
  }
  & .submit-button {
    background: #ffe600;
    border-radius: 5px;
    padding: 12px 32px;
    margin-top: 15px;
    ${typo}
    font-weight: 700;
    color: #373e4b;
    ${firstLetterUppercase}
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  &.file-input-wrapper {
    margin-bottom: 75px;
  }
  & label {
    ${typo}
    color: #000000;
    ${firstLetterUppercase}
  }
  & input,
  .custom-file-input {
    width: 100%;
    padding: 16px 12px;
    background: #ffffff;
    border: 1px solid #373e4b;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
    &.custom-file-input {
      position: absolute;
      top: 28px;
      display: flex;
      align-items: center;
      gap: 12px;
      & .placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #373e4b;
      }
    }
    &.hidden-file-input {
      position: absolute;
      top: 28px;
      opacity: 0;
      z-index: 10;
      cursor: pointer;
      transform: rotateY(180deg);
    }
  }
`;

export const FiltersContainer = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  align-items: center;
  margin: 30px auto;
  gap: 20px;
  &.popup-filters-container {
    margin: 0;
    & button {
      width: 50%;
    }
  }
`;
