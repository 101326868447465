import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../services-section/styled";
import { Form, GridSection } from "./styled";
import { Label, Input, InputWrapper } from "../../checkout/styled";
import { PatternFormat } from "react-number-format";
import { ErrorText } from "../../../shared/styled";
import { Textarea } from "../../shinmag-club/styled";
import { Button } from "../../shinmag-club/styled";
import { deleteKey } from "../../../utils/funcs/formHandlings";
import checkIcon from "../../../assets/checkIcon.svg";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useSelector } from "react-redux";
import { useLocalStorageGetItem } from "../../../utils/hooks/hooks";

const ContactUsSection = () => {
  const { t } = useTranslation();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const lang = useSelector((state) => state.lang[0].val);
  const token = useLocalStorageGetItem("token");

  let headers = {
    "Accept-Language": lang,
  };
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  const handleChange = (val, setVal, key) => {
    setVal(val);
    deleteKey(key, errors);
  };

  const handleUnset = () => {
    setFName("");
    setLName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: `${fName} ${lName}`,
      email,
      phone: `+374${phone?.split(" ").join("")}`,
      message,
    };

    axios
      .post(`${baseUrl}/messages`, data, { headers })
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          handleUnset();
        }, 3000);
      })
      .catch((err) => setErrors(err.response?.data?.errors));
  };

  return (
    <Section className="bckg-brown">
      <h3 className="title">{t("texts.contact_if_interested")}</h3>
      <Form onSubmit={handleSubmit}>
        <GridSection>
          <div>
            <Label htmlFor="contact-name">{t("texts.name")}</Label>
            <Input
              id="contact-name"
              placeholder={t("texts.name")}
              className={`info ${errors?.name ? "error" : ""}`}
              value={fName}
              onChange={(e) => handleChange(e.target.value, setFName, "name")}
            />
            {errors?.name && <ErrorText>{errors.name?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="contact-lastname">{t("texts.lastname")}</Label>
            <Input
              id="contact-lastname"
              placeholder={t("texts.lastname")}
              className={`info ${errors?.name ? "error" : ""}`}
              value={lName}
              onChange={(e) => handleChange(e.target.value, setLName, "name")}
            />
            {errors?.name && <ErrorText>{errors.name?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="contact-email">{t("texts.email")}</Label>
            <Input
              id="contact-email"
              placeholder={t("texts.email")}
              className={`info ${errors?.email ? "error" : ""}`}
              value={email}
              onChange={(e) => handleChange(e.target.value, setEmail, "email")}
            />
            {errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}
          </div>
          <div>
            <Label className="sm-margin" htmlFor="contact-phone">
              {t("texts.phone")}
            </Label>
            <InputWrapper className="phone">
              <PatternFormat
                id="contact-phone"
                className={`info phone ${errors?.phone ? "error" : ""}`}
                customInput={Input}
                type="tell"
                placeholder="12 34 56 78"
                format="## ## ## ##"
                mask="_"
                value={phone}
                onChange={(e) =>
                  handleChange(e.target.value, setPhone, "phone")
                }
              />
            </InputWrapper>
            {errors?.phone && <ErrorText>{errors.phone?.[0]}</ErrorText>}
          </div>
        </GridSection>
        <div>
          <Label htmlFor="message">{t("texts.message")}</Label>
          <Textarea
            id="message"
            placeholder={t("texts.message")}
            className={`${errors?.message ? "error" : ""}`}
            value={message}
            onChange={(e) =>
              handleChange(e.target.value, setMessage, "message")
            }
          />
          {errors?.message && <ErrorText>{errors.message?.[0]}</ErrorText>}
        </div>
        <Button className={`yellow ${success ? "success" : ""}`}>
          <img src={checkIcon} alt="sent" width="18" />
          <span>{t("actions.send")}</span>
        </Button>
      </Form>
    </Section>
  );
};

export default ContactUsSection;
