export const data = {
  hy: [
    {
      title: "",
      content: [
        {
          type: "normal",
          text: `Սույն գաղտնիության քաղաքականությունը կազմվել է «Shinmag»-ի կողմից  և ներկայացնում է անձնական տվյալների պաշտպանության համար սահմանված ընդհանուր դրույթները։ Կայքի բովանդակության և սույն գաղտնիության քաղաքականության համար կիրառելի օրենքը ՀՀ օրենսդրությունն է: Խնդրում ենք ուշադիր կարդալ մեր գաղտնիության քաղաքականությունը՝ հասկանալու համար, թե ինչպես ենք Մենք հավաքում, օգտագործում և պաշտպանում ձեր անձնական տվյալները մեր կայքում:`,
        },
      ],
    },
    {
      title: "1. Ինչպիսի՞ տեղեկատվություն ենք Մենք հավաքում",
      content: [
        {
          type: "normal",
          text: `Մենք հավաքում ենք Ձեր տրամադրած անձնական տվյալները՝ անուն, ազգանուն, հասցե, էլեկտրոնային փոստ և հեռախոսահամար:\nՁեր կողմից հատկացված բանկային քարտի տվյալների պահմանումը և կարգավորումը իրականացվում է այն բանկի կողմից, որը տրամադրել է Ձեզ այդ քարտը:`,
        },
      ],
    },
    {
      title: "2. Ի՞նչ նպատակով ենք Մենք տեղեկատվություն հավաքում",
      content: [
        {
          type: "normal",
          text: `Անձնական տվյալների հավաքումը Մենք իրականացնում ենք պահանջված ապրանքներն ու  ծառայությունները Ձեզ տրամադրելու, ինչպես նաև ըստ անհրաժեշտության ապրանքների և զեղչերի մասին տեղեկատվություն ուղարկելու համար։`,
        },
      ],
    },
    {
      title: "3. Ինչպե՞ս ենք Մենք տեղեկատվություն հավաքում",
      content: [
        {
          type: "text",
          text: `Տեղեկատվությունը Մենք հավաքում ենք անմիջապես Ձեզանից հետևյալ միջոցներով.  `,
        },
        {
          type: "list",
          items: [
            "- երբ դուք գրանցվում եք Shinmag.am կայքում",
            "- երբ առցանց պատվեր եք գրանցում Shinmag.am կայքում",
          ],
        },
      ],
    },
    {
      title: "4. Ինչպե՞ս ենք Մենք պաշտպանում տեղեկատվությունը",
      content: [
        {
          type: "normal",
          text: `Ձեր տվյալների գաղտնիությունն ապահովելու համար մեր կայքը օգտագործում ենք SSL անվտանգության տեխնոլոգիան՝ տվյալների փոխանցումը պաշտպանելու համար։ SSL-ը ծածկագրում է Ձեր բոլոր անձնական տվյալները, այնպես, որ այն հնարավոր չլինի կարդալ։ `,
        },
      ],
    },
    {
      title: "5. Անձնական տվյալները ջնջելու իրավունքը",
      content: [
        {
          type: "normal",
          text: `Օգտատերը ցանկության դեպքում իրավունք ունի պահանջելու հեռացնել իր  անձնական տվյալները: Ջնջման հարցումը ստանալուց և հաստատելուց հետո, տրամադրված բոլոր տվյալները կջնջվեն:`,
        },
      ],
    },
  ],
  ru: [
    {
      title: "",
      content: [
        {
          type: "normal",
          text: `Shinmag разработала эту политику конфиденциальности, чтобы помочь тем, кто обеспокоен тем, как Shinmag использует их «Личные данные» на этом веб-сайте.\n  Пожалуйста, внимательно прочитайте нашу политику конфиденциальности, чтобы понять, как мы собираем, используем и защищаем ваши данные на нашем веб-сайте.`,
        },
      ],
    },
    {
      title: "1. Какую личную информацию мы собираем?",
      content: [
        {
          type: "normal",
          text: `Мы собираем личные данные, включая ваше имя, адрес, адрес электронной почты и номер телефона. \nПредоставленная Вами информация о кредитной карте будет храниться конфиденциально. Однако ответственность и регулирование политики безопасности карты принадлежит банку, который выдал Вам эту карту.`,
        },
      ],
    },
    {
      title: "2. Почему мы собираем Вашу информацию?",
      content: [
        {
          type: "text",
          text: `Мы собираем персональные данные, для того, чтобы предоставить Вам запрошенные продукты и услуги, а также проинформировать о продуктах и скидках по мере необходимости.`,
        },
      ],
    },
    {
      title: "3. Как мы собираем Вашу информацию?",
      content: [
        {
          type: "normal",
          text: `Мы получаем персональные данные непосредственно от Вас, когда Вы предоставляете их нам, регистрируясь на сайте или оформляя заказ на продукцию Shinmag. `,
        },
      ],
    },
    {
      title: "4. Как мы защищаем Вашу информацию?",
      content: [
        {
          type: "normal",
          text: `Для обеспечения высокой конфиденциальности мы внедряем SSL-сертификат на нашем веб-сайте. Эта технология безопасности шифрует все конфиденциальные данные, передаваемые через Интернет. Это означает, что предоставленная Вами информация сохраняется конфиденциально.`,
        },
      ],
    },
    {
      title: "5. Право на удаление персональных данных.",
      content: [
        {
          type: "normal",
          text: `Вы имеете право запросить удаление вашей личной информации в любое время. Как только мы получим и подтвердим ваш запрос, все предоставленные вами данные будут удалены из нашей записи.`,
        },
      ],
    },
  ],
};
