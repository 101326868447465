import styled from "styled-components";

export const Container = styled.div`
  margin-top: 90px;
  margin-bottom: 90px;
  width: 100%;
  background-color: ${props => !props.white && ' #dae0e7'};
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    padding: 50px 40px 30px 40px;
  }
  @media (max-width: 420px) {
    padding: 20px;
    padding-top: 30px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    column-gap: 20%;
    row-gap: 40px;
    display: none;
  }
`;

export const ContentLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #F3F4F5;
  position: absolute;
  left: 0;
  top: calc(50% - 0.5px);
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ length }) => `${100 / length - ((100 / length) * 10) / 100}%`};
  position: relative;
  min-width: 80px;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  @media (max-width: 900px) {
    row-gap: 40px;
    align-items: center;
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #ffe500;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    transform: rotate(-45deg);
    height: 50%;
    aspect-ratio: 1/1;
  }
`;
export const ItemTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  //text-align: center;
  width: 100%;
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;
