import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const pad_x = css``;

export const searchScrollbar = css`
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  ::-webkit-scrollbar-thumb {
    background: #373e4b;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  &.overflow-hidden {
    overflow-y: hidden;
  }
  ${searchScrollbar}
  ::-webkit-scrollbar {
    width: 6px;
  }
`;

export const ContainerFluid = styled.div`
  width: 75%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  margin: 0 auto;
  @media (max-width: 1180px) {
    width: 100%;
  }
  &.overflow-hidden {
    overflow-y: hidden;
  }
  ${searchScrollbar}
  ::-webkit-scrollbar {
    width: 6px;
  }
`;

export const Container = styled.div`
  ${pad_x}
  width: 100%;
  background-color: #f5f5f5;
`;

export const BtnLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  padding: 12px 22px;
  background-color: #ffe600;
  width: max-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  &:hover {
    opacity: 0.9;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
  max-width: fit-content;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TabItem = styled.button`
  border: 1px solid #373e4b;
  background: #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #373e4b;
  text-transform: uppercase;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 100ms;
  min-width: max-content;
  &.active {
    background-color: #373e4b;
    color: #fff;
    cursor: default;
  }
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
  }
`;

export const ErrorText = styled.p`
  font-size: 12px;
  color: #e42829;
  margin-top: 2px;
  &.mb {
    margin-bottom: 20px;
  }
`;
