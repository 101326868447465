import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ProductInfo from "./ProductInfo";
import ProductSlider from "./ProductSlider";
import Descriptions from "./Descriptions";
import ProductsSlider from "../../components/products-slider/ProductsSlider";
import {Container} from "./styled";
import {useParams} from "react-router";
import {useGetData} from "../../utils/hooks/hooks";
import {getBradcrumbs} from "../../utils/funcs/getBreadcrumbs";
import ProductReviews from "../../components/product-reviews";
import {ImgSkeleton, ProductInfoSkeleton} from "./Skeletons";
import SEO from "../../components/seo";

const Product = () => {
    const {productId} = useParams();
    const [nav, setNav] = useState([]);

    const {data: product, isLoading} = useGetData(`products/${productId}`, [
        productId,
    ]);

    useEffect(() => {
        if (product?.breadcrumb) {
            const arr = getBradcrumbs(
                product?.breadcrumb,
                {
                    to: `/products/${productId}`,
                    name: product.name || "product name",
                },
                "singleProduct"
            );
            setNav(arr);
        }
    }, [product?.breadcrumb]);

    return (
        <>
            <Breadcrumbs nav={nav?.length ? nav : []}/>
            {product ? (
                <Container>
                    <ProductSlider
                        product={product}
                        images={
                            product.images?.additional?.length
                                ? product.images?.additional
                                : [product?.images?.main]
                        }
                    />
                    <ProductInfo product={product}/>
                </Container>
            ) : (
                <Container>
                    <ImgSkeleton/>
                    <ProductInfoSkeleton/>
                </Container>
            )}
            {!!product?.feedbacks?.length && <ProductReviews reviews={product?.feedbacks}/>}
            {!!product?.similars?.length && (
                <ProductsSlider title="similar" products={product?.similars}/>
            )}
            {!!product?.relates?.length && (
                <ProductsSlider products={product?.relates}/>
            )}
        </>
    );
};

export default Product;
