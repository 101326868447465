export const about = {
  hy: {
    mainTitle: "ՇԻՆԱՆՅՈՒԹԻ ԱՄԵՆԱՄԵԾ, ՎՍՏԱՀԵԼԻ, ԱՐԱԳ ԵՎ ՀԱՐՄԱՐ OՆԼԱՅՆ ՀԱՐԹԱԿԸ",
    title: "մեր մասին",
    content: [
      {
        type: "text",
        content:
          "Յուրաքանչյուր շինարարական աշխատանք սկսվում է որակյալ շինանյութի ձեռքբերմամբ։ <<Shinmag.am>>- ը երիտասարդ, դինամիկ զարգացող ընկերություն է, որը մուտք է գործել տեղական շուկա՝ գնորդին որակյալ շինանյութ և ծառայություններ մատուցելու համար։",
      },
      {
        type: "text",
        content:
          "Մենք ամբողջությամբ ընդունում ենք մեր աշխատանքի պատասխանատվությունը և մրցակցությունը`",
      },
      {
        type: "list",
        content: [
          "մշտապես բարձրացնելով մեր մասնագիտական որակավորումը,",
          "ձգտելով մաքսիմալ արդյունավետ, ժամանակակից աշխատանքային մեթոդների,",
          "վստահեցնելով, որ մեզ հետ հարմար է և արագ։",
        ],
      },
    ],
  },
  ru: {
    mainTitle:
      "САМАЯ БОЛЬШАЯ, НАДЁЖНАЯ, БЫСТРАЯ И УДОБНАЯ СТРОИТЕЛЬНАЯ ОНЛАЙН ПЛОЩАДКА",
    title: "О нас",
    content: [
      {
        type: "text",
        content: `Каждое строительство начинается со строительных материалов.`,
      },
      {
        type: "text",
        content: `"Shinmag.am" – молодая, динамичная, развивающаяся компания, пришедшая на строительный рынок с тем, чтобы предоставить потребителю качественные строительные товары и услуги.`,
      },
      {
        type: "list",
        content: [
          "Мы полностью принимаем всю ответственность и конкуренцию на растущем строительном рынке.",
          "Стремимся к максимально эффективным и современным рабочим методам.",
          "Уверяем, что с нами удобно и быстро.",
        ],
      },
    ],
  },
};
