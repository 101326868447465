import React from "react";
import { CardGrid } from "../../../components/cards/styled";
import ProductCard from "../../../components/cards/ProductCard";
import { FavoritesContainer } from "./styled";
import EmptyList from "../../../components/empty-list";
import { useSelector } from "react-redux";

const Favorites = () => {
  const favs = useSelector((state) => state?.favs);

  return (
    <FavoritesContainer>
      {favs?.length === 0 ? (
        <EmptyList />
      ) : (
        <CardGrid>
          {favs?.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
        </CardGrid>
      )}
    </FavoritesContainer>
  );
};

export default Favorites;
