import React from "react";
import { Skeleton } from "@mui/material";

const AddressSkeleton = () => {
  return (
    <div>
      {Array.from({ length: 3 }).map((x, i) => (
        <div key={i} style={{ margin: "30px 0" }}>
          {Array.from({ length: 3 }).map((x, i) => (
            <Skeleton
              key={i}
              style={{
                transform: "scale(1)",
                width: "100%",
                margin: "10px 0",
                height: "15px",
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default AddressSkeleton;
