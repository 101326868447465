import React from "react";
import {
  FilesContainer,
  FileInputWrapper,
  FilesList,
  File,
  FileLink,
  IconButtonSm,
  FilesWrapper,
} from "./styled";
import { ErrorText } from "../../shared/styled";
import fileIcon from "../../assets/fileIcon.svg";
import deleteIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";

const Files = ({ files, handleChange, handleDelete, error, fullWidth }) => {
  const { t } = useTranslation();
  return (
    <FilesWrapper className={fullWidth ? "fullwidth" : ""}>
      <FilesContainer className={fullWidth ? "row" : ""}>
        <FileInputWrapper>
          <input
            type="file"
            id="itn-file"
            className={`itn-file`}
            multiple={fullWidth ? false : true}
            onChange={handleChange}
          />
          <label htmlFor="itn-file" className={`itn-file ${error && "error"}`}>
            <img src={fileIcon} alt="file icon" width="10" height="14" />
            <span>{t("actions.choose_file")}</span>
          </label>
        </FileInputWrapper>
        <FilesList>
          {files.map((file) => (
            <File key={file.name}>
              <FileLink target="_blank" href={URL.createObjectURL(file)}>
                <span>{file.name}</span>
              </FileLink>
              <IconButtonSm type="button" onClick={() => handleDelete(file)}>
                <img src={deleteIcon} alt={`delete ${file.name} file`} />
              </IconButtonSm>
            </File>
          ))}
        </FilesList>
      </FilesContainer>
      {error && <ErrorText>{error}</ErrorText>}
    </FilesWrapper>
  );
};

export default Files;
