import React from "react";
import {
  ContactContainer,
  ContactHeading,
  ContactParagraph,
  ContactsContainer,
  IconParagraphWrapper,
  SocialsContainer,
} from "../styled";
import viberIcon from "../../../assets/viber-icon.svg";
import whatsappIcon from "../../../assets/whatsapp-icon.svg";
import linkedinIcon from "../../../assets/linkedin-icon.svg";
import instagramIcon from "../../../assets/instagram-icon.svg";
import facebookIcon from "../../../assets/facebook-icon.svg";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();
  return (
    <ContactsContainer>
      <ContactContainer>
        <ContactHeading>{t("texts.address")}</ContactHeading>
        <ContactParagraph>{t("texts.points_address")}</ContactParagraph>
      </ContactContainer>
      <ContactContainer>
        <ContactHeading>{t("texts.phone")}</ContactHeading>
        <ContactParagraph>+374 12 440 440</ContactParagraph>
        <ContactParagraph>+374 43 540 440</ContactParagraph>
        {/* <IconParagraphWrapper>
          <img src={viberIcon} alt="viber" />
          <ContactParagraph className="viber">
            +374 96 00 00 00
          </ContactParagraph>
        </IconParagraphWrapper>
        <IconParagraphWrapper>
          <img src={whatsappIcon} alt="whatsapp" />
          <ContactParagraph className="whatsapp">
            +374 96 00 00 00
          </ContactParagraph>
        </IconParagraphWrapper> */}
      </ContactContainer>
      <ContactContainer>
        <ContactHeading>{t("texts.email")}</ContactHeading>
        <ContactParagraph>sales@shinmag.am</ContactParagraph>
      </ContactContainer>
      <ContactContainer>
        <ContactHeading>{t("texts.follow_us")}</ContactHeading>
        <SocialsContainer>
          <a
            href="https://instagram.com/shinmag.am?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramIcon} alt="instagram" />
          </a>
          <a
            href=" https://www.facebook.com/shinmag.am?mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebookIcon} alt="facebook" />
          </a>
        </SocialsContainer>
      </ContactContainer>
    </ContactsContainer>
  );
};

export default Contacts;
