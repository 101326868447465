import React from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../services-section/styled";
import { List, Item } from "./styled";
import fenceIcon from "../../../assets/fenceIcon.svg";

const ProcessSection = () => {
  const { t } = useTranslation();
  const data = [
    { id: 1, content: t("texts.prividing_offer") },
    { id: 2, content: t("texts.order_confirmation") },
    { id: 3, content: t("texts.account_withdrawal") },
    { id: 4, content: t("texts.transportation") },
  ];
  return (
    <Section className="smpad">
      <h3 className="title">{t("texts.work_with_shinmag")}</h3>
      <List>
        {data.map((item) => (
          <Item key={item.id}>
            <img src={fenceIcon} alt="" />
            <span className="count">{item.id}</span>
            <p className="content">{item.content}</p>
          </Item>
        ))}
      </List>
    </Section>
  );
};

export default ProcessSection;
