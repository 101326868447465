import React from "react";
import { StyledCount, Title } from "./styled";

const ListStyleType = ({ count, name }) => {
  return (
    <div className="flex">
      <StyledCount>
        <div />
        <span>{count}</span>
      </StyledCount>
      <Title>{name}</Title>
    </div>
  );
};

export default ListStyleType;
