import React from "react";
import { List, Title } from "../styled";
import DataContainer from "../DataContainer";

const Credit = () => {
  return (
    <DataContainer as="div">
      <Title className="title">Ապառիկ տեղում</Title>
      Որոշել եք ձեռք բերել շինանյութ կառուցման ու վերանորոգման աշխատանքներ
      կատարելու համար, սակայն առկա ֆինանսական միջոցները բավարա՞ր չեն:«Shinmag»-ը
      առաջարկում է Ձեզ «Ապառիկ տեղում» վարկավորում սկսած 0% կանխավճարից: Ապառիկի
      պայմաններն են՝
      <List>
        <li>Վարկը տրամադրվում է 21 մինչև 65 տարեկան անձանց</li>
        <li>
          Անհրաժեշտ փաստաթղթերը՝ անձնագիր, սոց. քարտ (իրավաբանական անձինք
          անհրաժեշտության դեպքում ՝ նաև վկայական )
        </li>
        <li>
          Մենք համագործակցում ենք ներքոհիշյալ բանկերի հետ.
          <List>
            <li>Evocabank</li>
            <li>ACBA bank</li>
            <li>InecoBank</li>
          </List>
        </li>
      </List>
    </DataContainer>
  );
};

export default Credit;
