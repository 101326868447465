import React from "react";
import DataContainer from "../DataContainer";
import { Title, List } from "../styled";
import { data } from "./data";
import { useSelector } from "react-redux";
import dot from "../../../assets/dot.svg";

const DeliveryContent = () => {
  const lang = useSelector((state) => state.lang[0].val);

  return (
    <DataContainer as="div">
      {data[lang]?.map((item, i) => (
        <div key={i}>
          <Title className="title">{item.title}</Title>
          {item.content.map((elem, i) => (
            <div key={i} className="title">
              {elem.type !== "list" ? (
                <span>{elem.text}</span>
              ) : (
                <List ul>
                  {elem.items.map((el, i) => (
                    <li key={i}><img src={dot} alt=""/>{el}</li>
                  ))}
                </List>
              )}
            </div>
          ))}
        </div>
      ))}
    </DataContainer>
  );
};

export default DeliveryContent;
