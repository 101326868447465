import styled, { css } from "styled-components";
import checkIcon from "../../assets/checkIcon.svg";
import { Link } from "react-router-dom";

const unstyledBtn = css`
  border: 0;
  background-color: inherit;
  cursor: pointer;
`;

const smTypo = css`
  font-size: 14px;
  line-height: 16px;
  color: #373e4b;
`;

export const TextButton = styled.button`
  ${unstyledBtn}
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #373e4b;
  &.active {
    font-weight: 700;
  }
`;

export const Underline = styled.div`
  position: absolute;
  height: 3px;
  width: ${({ width }) => `${width}px`};
  background-color: #373e4b;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms;
  &.right {
    left: ${({ left }) => `${left}px`};
  }
`;

export const Form = styled.form`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.register {
    gap: 15px;
    @media (max-height: 625px) {
      padding-top: 20px;
    }
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  ${smTypo}
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Input = styled.input`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  padding: 14px 10px;
  outline: 0;
  border: 1px solid #373e4b;
  border-radius: 10px;
  width: 100%;
  &.error {
    border-color: #e42829;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  & .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 16px;
    line-height: 18px;
  }
  & .with-prefix {
    padding-left: 60px;
  }
`;

export const InputIconButton = styled.button`
  ${unstyledBtn}
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const RememberMeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & input {
    display: none;
  }
  & .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #373e4b;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    display: block;
    &::after {
      content: url(${checkIcon});
      position: absolute;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  & input + .checkbox::after {
    visibility: hidden;
  }
  & input:checked + .checkbox::after {
    visibility: visible;
  }
  & span {
    ${smTypo}
  }
`;

export const RecoverPasswword = styled.button`
  ${unstyledBtn}
  ${smTypo}
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  @media (max-width: 420px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  ${unstyledBtn};
  width:100%;
  background-color: #ffe600;
  border-radius: 5px;
  padding: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #373e4b;
  text-transform: uppercase;
  margin-top: 10px;
  &:hover {
    opacity: 0.9;
  }
  position: relative;
  & img {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.success {
    & span {
      opacity: 0;
    }
    & img {
      display: unset;
    }
  }
`;

export const BackButton = styled(Button)`
  background-color: inherit;
`;

export const Text = styled.p`
  ${smTypo};
  &.mb {
    margin-bottom: 10px;
  }
`;

export const BtnLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  background-color: #373e4b;
  color: #fff;
  margin-bottom: 15px;
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  &:hover {
    opacity: 0.9;
  }
  @media (max-height: 625px) {
    margin-bottom: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  row-gap: 20px;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`;
