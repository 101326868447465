import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { searchScrollbar } from "../../shared/styled";

const df = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const typo = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

export const Container = styled.div`
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  max-width: 90%;
  width: 100%;
  max-height: calc(90% - 122px);
  top: 112px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  ${searchScrollbar}
  @media (max-width: 1024px) {
    top: 0;
    left: 0;
    height: 100%;
    max-height: ${({ vHeight }) => `${vHeight}px`};
    max-width: 100%;
    transform: none;
    background-color: #fff;
  }
`;

export const Header = styled.div`
  background-color: #fff;
  padding: 10px;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  ${typo}
  position: relative;
  padding-bottom: 10px;
`;

export const SearchHeader = styled.div`
  ${df}
  padding: 20px;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: #fff;
  @media (max-width: 1024px) {
    padding-bottom: 10px;
    position: unset;
  }
`;

export const CustomLink = styled(Link)`
  ${typo}
  font-weight: 700;
  text-decoration: none;
`;

export const Item = styled.div`
  ${df}
  justify-content: flex-start;
  padding: 10px 20px;
  border-top: 1px solid #d9d9d9;
  & .right-content {
    ${df}
    width: 100%;
    @media (max-width: 620px) {
      flex-direction: column;
      align-items: flex-end;
      & .text {
        max-width: 100%;
      }
    }
    @media (max-width: 480px) {
      align-items: flex-start;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & .text {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    max-width: 270px;
    width: 100%;
    & .name {
      margin-bottom: 10px;
      text-decoration: none;
      color: #000;
      max-height: 44px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
`;

export const Actions = styled.div`
  ${df}
  gap:10px;
  & .counter-wrapper {
    min-width: 120px;
  }

  @media (max-width: 340px) {
    & .counter-wrapper {
      min-width: 100px;
    }
  }
`;

export const Message = styled.p`
  border-top: 1px solid #d9d9d9;
  padding: 40px;
  text-align: center;
  font-size: 18px;
  opacity: 0.8;
`;

export const IconButton = styled.button`
  border: 0;
  background-color: inherit;
  &.custom {
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
  }
  &.small {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

export const Input = styled.input`
  padding: 10px 35px;
  font-size: 16px;
  color: #373e4b;
  background: #fff;
  border: 1px solid #373e4b;
  border-radius: 20px;
  width: 100%;
  &:focus {
    outline: 0.5px solid #373e4b;
  }
`;
