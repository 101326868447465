import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.section`
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0;
  background-color: #dae0e7;
  & img {
    display: none;
  }
  @media (max-width: 1180px) {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0;
    text-align: center;
    background-color: #fff;
    & img {
      display: flex;
      max-height: 50px;
      height: 50px;
    }
  }
`;

export const CustomLink = styled(Link)`
  display: flex;
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
  color: #373e4b;
  padding: 5px;
  &:hover {
    background-color: #ffe600;
  }
  @media (max-width: 1180px) {
    flex-direction: column;
    width: 30%;
    font-size: 14px;
    height: 100px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 45%;
    font-size: 14px;
    height: 100px;
  }
  img.category-image {
    height: 100px;
    margin: 0 auto;
    @media (min-width: 1180px) {
      display: none;
    }
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
