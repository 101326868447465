import React from "react";
import { Background, PopupContainer } from "./styled";
import { useSelector } from "react-redux";

const Modal = ({ children, handleClose }) => {
  const vHeight = useSelector((state) => state.appHeight.val);

  return (
    <>
      <Background vHeight={vHeight} onClick={handleClose} />
      <PopupContainer vHeight={vHeight}>{children}</PopupContainer>
    </>
  );
};

export default Modal;
