import React, { useState } from "react";
import closeIcon from "../../../assets/closeIcon.svg";
import {
  PopupBody,
  PopupDivider,
  PopupFormInput,
  PopupFormInputLabel,
  PopupFormInputWrapper,
  PopupFormSubmitButton,
  PopupFormWrapper,
  PupupHeader,
} from "./styled";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import { useGetData, useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import { deleteKey } from "../../../utils/funcs/formHandlings";
import { Textarea } from "../../../components/dialogs/styled";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useSelector } from "react-redux";
import { ErrorText } from "../../../shared/styled";
import checkIcon from "../../../assets/checkIcon.svg";

const AddAddress = ({ handleClose, toggleReload }) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const { data: regions } = useGetData("regions");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [region, setRegion] = useState(null);
  const [address, setAddress] = useState("");
  const [details, setDetails] = useState("");
  const headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`,
  };

  const handleExpand = () => setExpanded((prev) => !prev);
  const handleUnsetExpand = () => setExpanded(false);

  const handleSelectChange = (option) => {
    setRegion(option);
    deleteKey("regionId", errors);
  };

  const handleChange = (val, setVal, k) => {
    setVal(val);
    deleteKey(k, errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { address, details, regionId: region?.id };
    axios
      .post(`${baseUrl}/users/addresses`, data, { headers })
      .then(() => {
        setSuccess(true);
        setErrors({});
        setTimeout(() => {
          setSuccess(false);
          handleClose();
          toggleReload();
        }, 3000);
      })
      .catch((err) => setErrors(err.response.data?.errors));
  };

  return (
    <Modal handleClose={handleClose}>
      <PupupHeader>
        <p className="heading">{t("texts.new_address")}</p>
        <img onClick={handleClose} src={closeIcon} alt="close" />
      </PupupHeader>
      <PopupDivider></PopupDivider>
      <PopupBody>
        <PopupFormWrapper onSubmit={handleSubmit}>
          <PopupFormInputWrapper>
            <PopupFormInputLabel>{t("texts.district")}</PopupFormInputLabel>
            <CustomSelect
              className={`checkout ${errors?.regionId && "error"}`}
              placeholder={t("texts.district")}
              disabled={!regions}
              expanded={expanded}
              selectedOption={region}
              options={regions}
              handleExpand={handleExpand}
              setValue={setRegion}
              handleSelect={handleSelectChange}
              onClickAway={handleUnsetExpand}
              errorText={errors?.regionId?.[0]}
            />
          </PopupFormInputWrapper>
          <PopupFormInputWrapper>
            <PopupFormInputLabel>
              {t("texts.delivery_detailed_address")}
            </PopupFormInputLabel>
            <PopupFormInput
              className={errors?.address ? "error" : ""}
              value={address}
              onChange={(e) =>
                handleChange(e.target.value, setAddress, "address")
              }
            />
            {errors?.address && <ErrorText>{errors.address?.[0]}</ErrorText>}
          </PopupFormInputWrapper>
          <PopupFormInputWrapper>
            <PopupFormInputLabel>
              {t("texts.delivery_details_more_info")}
            </PopupFormInputLabel>
            <Textarea
              className={`ltypo ${errors?.details ? "error" : ""}`}
              value={details}
              onChange={(e) =>
                handleChange(e.target.value, setDetails, "details")
              }
            />
            {errors?.details && <ErrorText>{errors.details?.[0]}</ErrorText>}
          </PopupFormInputWrapper>
          <PopupFormSubmitButton
            onClick={handleSubmit}
            className={success ? "success" : ""}
          >
            <img src={checkIcon} alt="sent" width="18" />
            <span>{t("actions.save")}</span>
          </PopupFormSubmitButton>
        </PopupFormWrapper>
      </PopupBody>
    </Modal>
  );
};

export default AddAddress;
