import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {Container, SliderWrapper, RightContent, CustomLink} from "./styled";
import adsImg from "../../assets/slider_1.png";
import "../../shared/slider.css";
import {SlickArrowLeft, SlickArrowRight} from "./NextPrevButtons";
import {useGetData} from "../../utils/hooks/hooks";

const LowerSlider = () => {
    const {data} = useGetData(
        `contents?keys[]=secondBanner`,
        [],
    );
    const [secondBanner, setSecondBanner] = useState([])
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 0,
        speed: 1000,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        play: false,
        nextArrow: <SlickArrowRight/>,
        prevArrow: <SlickArrowLeft/>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    useEffect(() => {
        data?.map(item => item.key === "secondBanner" && setSecondBanner(first => [...first, item])
        )
    }, [data])
    return (
        <Container>
            {secondBanner.length ?
                <SliderWrapper>
                    <Slider {...settings}>
                        {secondBanner?.map(item =>
                            <a href={item.link}>
                                <picture key={item.id}>
                                    <img style={{margin: '0 auto'}} src={item?.src} alt=""/>
                                </picture>
                            </a>
                        )}
                    </Slider>
                </SliderWrapper> : ''
            }
        </Container>
    );
};

export default LowerSlider;
