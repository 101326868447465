import React from "react";
import {BaseSection} from "./styled";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import nextArrow from "../../assets/next-arrow.svg";
import prevArrow from "../../assets/prev-arrow.svg"
import {ImageWrapper, Item, ItemTitle} from "../advanatages/styled";

const HomeAdvantages = ({advantages}) => {
    const {t} = useTranslation();

    function SliderNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={nextArrow}
                 alt="Next"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }
    function SliderPrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={prevArrow}
                 alt="Prev"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }

    const settings = {
        initialSlide: 1,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        cssEase: 'linear',
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 20,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
    };

    return (
        <BaseSection className="block">
            <Slider {...settings} className="slider">
                {advantages?.map((advantage, index) => (
                    <advantageImageWrapper className={"slide"} key={advantage.name}>
                        <Item key={index} length={advantages.length}>
                            <ItemTitle>{advantage.name}</ItemTitle>
                            <ImageWrapper>
                                <img src={advantage.icon} alt={advantage.name} loading="lazy"/>
                            </ImageWrapper>
                        </Item>
                    </advantageImageWrapper>
                ))}
            </Slider>
        </BaseSection>
    );
};

export default HomeAdvantages;