import React, { useState, useEffect } from "react";
import { PageTitle, ProductsContainer, ProductsCardGrid } from "./styled";
import ProductCard from "../../components/cards/ProductCard";
import CustomPagination from "../../components/pagination/CustomPagination";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useGetData } from "../../utils/hooks/hooks";
import EmptyList from "../../components/empty-list";
import { getBradcrumbs } from "../../utils/funcs/getBreadcrumbs";
import FiltersHeader from "../../components/filters/FiltersHeader";
import Filters from "../../components/filters/Filters";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../components/cards/SkeletonCard";
import FiltersSkeleton from "../../components/filters/FiltersSkeleton";
import { useSelector } from "react-redux";

const SubCategoryProducts = () => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang[0].val);
  const { subcategoryId, page } = useParams();
  const [currentPage, setCurrentPage] = useState(+page || 1);
  const [pageChanged, setPageChanged] = useState(false);
  const [nav, setNav] = useState([]);
  const navigate = useNavigate();
  const [initFetch, setInitFetch] = useState(true);
  const [priceFilter, setPriceFilter] = useState({});
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [usedFilter, setUsedFilter] = useState("");
  const [colors, setColors] = useState([]);
  const [search, setSearch] = useSearchParams();
  const f = search.get("f");
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandsStr, setSelectedBrandsStr] = useState("");

  const { data: productsData, isLoading } = useGetData(
    `categories/${subcategoryId}/products?perPage=12&page=${currentPage}${
      selectedMinPrice ? `&price[min]=${selectedMinPrice}` : ""
    }${selectedMaxPrice ? `&price[max]=${selectedMaxPrice}` : ""}${f || ""}${
      selectedBrandsStr.length ? `${selectedBrandsStr}` : ""
    }`,
    [pageChanged],
    true
  );
  const products = productsData?.products?.data;
  const meta = productsData?.products?.meta;

  const { data: breadcrumbs } = useGetData(
    `categories/${subcategoryId}/breadcrumbs`,
    [subcategoryId]
  );

  useEffect(() => {
    if (breadcrumbs?.breadcrumb) {
      const arr = getBradcrumbs(breadcrumbs);
      setNav(arr);
    }
  }, [breadcrumbs?.breadcrumb]);

  const [showFilters, setShowFilters] = useState(false);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/sub-category/${subcategoryId}/${page}${window.location.search}`);
    setPageChanged((p) => !p);
  };

  useEffect(() => {
    setCurrentPage(page ?? 1);
    if (window.location.search) {
      navigate(`/sub-category/${subcategoryId}/${page}${window.location.search}`);
    }
    setPageChanged((p) => !p);
  }, [subcategoryId, selectedMinPrice, selectedMaxPrice, f]);

  useEffect(() => {
    if (!initFetch) {
      setInitFetch(true);
      setSelectedFilters([]);
      setPriceFilter({});
      setSelectedMinPrice("");
      setSelectedMaxPrice("");
      setUsedFilter("");
      setSelectedBrands([]);
    }
  }, [lang]);

  useEffect(() => {
    if (productsData) {
      if (initFetch) {
        setColors(productsData?.colors);
        setInitFetch(false);
        const filts = productsData?.filters;
        if (filts) {
          const entries = Object.entries(filts);
          const prices = entries.find(
            (fl) => fl?.[0].toLowerCase() === t("texts.price")
          );
          const brands = entries.find(
            (fl) =>
              fl?.[0].toLowerCase() === "բռենդ" ||
              fl?.[0].toLowerCase() === "бренд"
          );
          const list = entries.filter(
            (fl) =>
              fl?.[0].toLowerCase() !== t("texts.price") &&
              fl?.[0].toLowerCase() !== "բռենդ" &&
              fl?.[0].toLowerCase() !== "бренд"
          );
          setBrands(brands);
          setFilters(list);
          setPriceFilter(prices);
        }
      } else {
        const filts = productsData?.filters;
        if (filts) {
          const entries = Object.entries(filts);
          if (usedFilter.toLowerCase() !== t("texts.price")) {
            const prices = entries.find(
              (fl) => fl?.[0].toLowerCase() === t("texts.price")
            );
            setPriceFilter(prices);
          }
          if (
            usedFilter.toLowerCase() !== "բռենդ" &&
            usedFilter.toLowerCase() !== "бренд"
          ) {
            const brands = entries.find(
              (fl) =>
                fl?.[0].toLowerCase() === "բռենդ" ||
                fl?.[0].toLowerCase() === "бренд"
            );
            setBrands(brands);
          }
          const list = entries.filter(
            (fl) =>
              fl?.[0].toLowerCase() !== t("texts.price") &&
                fl?.[0].toLowerCase() !== "բռենդ" &&
                fl?.[0].toLowerCase() !== "бренд"
          );
          const usedFilterData = filters.find((elem) => elem[0] === usedFilter);
          const newList = list.filter((elem) => elem[0] !== usedFilter);
          if (usedFilter.toLowerCase() === t("texts.price")) {
            setFilters(list);
          } else if (usedFilterData && newList) {
            setFilters([usedFilterData, ...newList]);
          }
          if (usedFilter !== "Գույն" && usedFilter !== "Цвет") {
            setColors(productsData?.colors);
          }
        }
      }
    }
  }, [productsData]);

  useEffect(() => {
    if (priceFilter?.length) {
      const prices = Object.values(priceFilter?.[1]);
      setMinPrice(prices[0]);
      setMaxPrice(prices[1]);
    }
  }, [priceFilter]);

  const handleMinChange = (e) => {
    if (
      priceFilter?.[1] &&
      Object.values(priceFilter[1])[1] &&
      +e.target.value < Object.values(priceFilter[1])[1]
    ) {
      setMinPrice(e.target.value);
      handleChangeUsedFilter(priceFilter?.[0]);
    }
  };
  const handleMaxChange = (e) => {
    if (!(
      priceFilter?.[1] &&
      Object.values(priceFilter[1])[1] &&
      +e.target.value <= Object.values(priceFilter[1])[1]
    )) {
      setMaxPrice(e.target.value);
      handleChangeUsedFilter(priceFilter?.[0]);
    }
  };

  const handleMaxBlur = (e) => {
    if (!e.relatedTarget || e.relatedTarget?.id !== "min-price") {
      if (+maxPrice <= +minPrice || !maxPrice) {
        selectedMaxPrice
          ? setMaxPrice(+selectedMaxPrice)
          : setMaxPrice(Object.values(priceFilter[1])[1]);
      } else {
        setSelectedMaxPrice(+maxPrice);
      }
    }
  };

  const handleMinBlur = (e) => {
    if (!e.relatedTarget || e.relatedTarget.id !== "max-price") {
      if (+maxPrice <= +minPrice || !minPrice) {
        selectedMinPrice
          ? setMinPrice(selectedMinPrice)
          : setMinPrice(Object.values(priceFilter[1])[0]);
      } else {
        setSelectedMinPrice(+minPrice);
      }
    }
  };

  const toggleShowFilters = () => setShowFilters((prev) => !prev);

  const handleChangeUsedFilter = (name) => setUsedFilter(name);

  const handleSelectedFiltersChange = (arr) => setSelectedFilters(arr);

  const handleUnsetFilters = () => {
    setSelectedMaxPrice("");
    setSelectedMinPrice("");
    setSelectedFilters([]);
    setUsedFilter("");
    setSelectedBrands([]);
  };

  const handleBrandSelect = (id) => {
    selectedBrands.includes(id)
      ? setSelectedBrands((p) => p.filter((elem) => elem !== id))
      : setSelectedBrands((p) => [...p, id]);
  };

  useEffect(() => {
    let str = "";
    if (selectedFilters?.length) {
      selectedFilters.forEach((fil) => {
        fil.list.forEach((elem) => (str += `&filters[${fil.name}][]=${encodeURIComponent(elem)}`));
      });
    }
    if (selectedBrands?.length) {
      selectedBrands.forEach((fil) => {
        str += `&brands[]=${encodeURIComponent(fil)}`;
      });
    }
    if (str.length) {
      setSearch({ f: str })
    }
  }, [selectedFilters, selectedBrands]);

  useEffect(() => {
    setCurrentPage(page ?? 1);
    if (window.location.search) {
      navigate(`/sub-category/${subcategoryId}/${page}${window.location.search}`);
    }
    setPageChanged((p) => !p);
    let str = "";
    if (selectedBrands?.length) {
      selectedBrands.forEach((elem) => (str += `&brands[]=${elem}`));
    }
    setSelectedBrandsStr(str);
  }, [selectedBrands]);

  return (
    <>
      <Breadcrumbs nav={nav} />
      <PageTitle>{breadcrumbs?.name}</PageTitle>
      <FiltersHeader
        toggleShowFilters={toggleShowFilters}
        selectedFilters={selectedFilters}
        price={selectedMaxPrice || selectedMinPrice}
        handleSelectedFiltersChange={handleSelectedFiltersChange}
        handleUnsetFilters={handleUnsetFilters}
        selectedBrands={selectedBrands}
      />
      <ProductsContainer>
        {isLoading ? (
          <FiltersSkeleton />
        ) : (
          <Filters
            showFilters={showFilters}
            filters={filters}
            selectedFilters={selectedFilters}
            handleSelectedFiltersChange={handleSelectedFiltersChange}
            priceFilter={priceFilter}
            colors={colors}
            handleChangeUsedFilter={handleChangeUsedFilter}
            handleMaxBlur={handleMaxBlur}
            handleMinBlur={handleMinBlur}
            handleMaxChange={handleMaxChange}
            handleMinChange={handleMinChange}
            min={minPrice}
            max={maxPrice}
            brands={brands}
            selectedBrands={selectedBrands}
            handleBrandSelect={handleBrandSelect}
            handleUnsetFilters={handleUnsetFilters}
          />
        )}
        {isLoading ? (
          <ProductsCardGrid>
            {Array.from({ length: 8 }).map((x, i) => (
              <SkeletonCard key={i} />
            ))}
          </ProductsCardGrid>
        ) : !products?.length ? (
          <EmptyList />
        ) : (
          <ProductsCardGrid>
            {!!products?.length &&
              products.map((product) => (
                <ProductCard key={product?.id} product={product} />
              ))}
          </ProductsCardGrid>
        )}
      </ProductsContainer>
      {meta?.last_page > 1 && (
        <CustomPagination
          page={+currentPage}
          pageCount={meta?.last_page}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default SubCategoryProducts;
