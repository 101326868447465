import styled, { css } from "styled-components";
import errorIcon from "../../assets/errorIcon.svg";

const typo = css`
  font-size: 16px;
  line-height: 18px;
`;

const smTypo = css`
  font-size: 14px;
  line-height: 16px;
`;

const border = css`
  background: #fff;
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

const sectionWidth = css`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const firstLetterUppercase = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Heading = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: #373e4b;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 60px;
`;

export const BorderedText = styled.p`
  ${typo}
  ${border}
  padding: 30px;
  margin: 60px;
  white-space: pre-line;
  b:first-child {
    margin-bottom: 20px;
    display: block;
  }
  a {
    color: #373e4b;
    cursor: pointer;
  }
  ${props=>props.distance && `
  h2 {
  margin: 10px 0;
  };`}
  b:nth-of-type(2) {
    margin-top: 60px;
    display: block;
  }
  ${sectionWidth},
`;

export const Form = styled.form`
  ${border}
  padding: 20px;
  ${sectionWidth}
  margin-top:20px;
`;

export const Label = styled.label`
  ${smTypo}
  color: #373e4b;
  margin-bottom: 10px;
  &.bold {
    ${typo}
    font-weight: 700;
    margin-bottom: 15px;
    color: #000;
  }
  display: block;
  width: fit-content;
  ${firstLetterUppercase}
  cursor: default;
  & span {
    ${typo}
    font-weight: 400;
    color: #373e4b;
    @media (max-width: 620px) {
      display: block;
      margin-top: 5px;
    }
  }
`;

export const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 0;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  &.phone {
    &::before {
      content: "(+374)";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      ${typo}
    }
  }
`;

export const Input = styled.input`
  ${border}
  ${typo}
  width:100%;
  padding: 15px;
  &.phone {
    padding-left: 70px;
  }
  &.error {
    border-color: #e42829;
  }
  &:focus {
    /* outline: 0.1px solid #373e4b; */
    &.error {
      /* outline: 0.1px solid #e42829; */
    }
  }
  &:read-only {
    outline: 0;
  }
`;

export const InputIconButton = styled.button`
  border: 0;
  background-color: inherit;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TextareaWrapper = styled.div`
  margin-bottom: 20px;
`;
export const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  ${border}
  ${typo}
  padding: 15px;
  height: 120px;
  &.error {
    border-color: #e42829;
  }
  &:focus {
    outline: 0.1px solid #373e4b;
    &.error {
      outline: 0.1px solid #e42829;
    }
  }
`;

export const FilesWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FilesContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 620px) {
    flex-direction: column;
  }
`;

export const FileInputWrapper = styled.div`
  & input {
    display: none;
  }
  & label {
    ${border}
    padding:15px;
    display: flex;
    gap: 10px;
    ${smTypo}
    font-weight: 700;
    text-transform: uppercase;
    width: max-content;
    color: #373e4b;
    cursor: pointer;
    &.error {
      border-color: #e42829;
    }
  }
`;

export const FilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 10px;
  align-items: center;
`;

export const File = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

export const FileLink = styled.a`
  ${typo}
  color: #373E4B;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

export const IconButtonSm = styled.button`
  border-radius: 2px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: 1px solid #f0f0f0;
  &:hover {
    background-color: #f0f0f0;
  }
  & img {
    width: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 15px;
  padding: 10px 0;
  flex-wrap: wrap;
`;

export const Button = styled.button`
  ${typo}
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 11.5px 30px;
  color: #fff;
  background-color: #373e4b;
  position: relative;
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  &.success {
    & span {
      opacity: 0;
    }
    & img {
      display: unset;
    }
  }
  &.yellow {
    color: #373e4b;
    background-color: #ffe600;
  }
  &:disabled {
    cursor: default;
    opacity: 0.8;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  color: #e42829;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  &::before {
    content: "";
    background-image: url(${errorIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-width: 20px;
    min-height: 20px;
  }
  &.sm {
    font-size: 14px;
  }
`;
