import React from "react";
import { Checkbox } from "./styled";

const CustomCheckbox = ({
  id,
  text,
  className,
  handleChange,
  checked,
  bckgColor,
}) => {
  return (
    <Checkbox
      className={`${className} ${bckgColor?.includes(" ") ? "def" : ""}`}
      bckgColor={bckgColor}
    >
      <input
        type="checkbox"
        id={id}
        onChange={handleChange}
        checked={checked || false}
      />
      <label htmlFor={id}>
        <div className="box" />
        <span className="first-letter-upp">{text}</span>
      </label>
    </Checkbox>
  );
};

export default CustomCheckbox;
