import React, { useState } from "react";
import { Section, Info, InfoItem, Divider, Button } from "./styled";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../custome-checkbox/CustomCheckbox";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Auth from "../../components/auth/Auth";
import Recover from "../../components/auth/Recover";
import {
  makeHidden,
  makeAuto,
} from "../../features/overflow-body/overflowOfMainSlice";
import {
  makeAuthHidden,
  makeAuthVisible,
} from "../../features/show-auth/showAuthSlice";
import {
  makeForgetPasswordHidden,
  makeForgetPasswordVisible,
} from "../../features/show-forget-password/showForgetPasswordSlice";
import OfferPriceInCart from "../dialogs/OfferPriceInCart";
import { useNavigate } from "react-router";
import ErrorDialog from "../dialogs/ErrorDialog";

const CartSummary = ({
  price,
  delivery,
  points,
  total,
  actionName,
  onClick,
  place,
  discount,
  products,
  isGetPoints,
  toggleGetPonts,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openAuth = useSelector((state) => state.showAuth);
  const openRecoverPassword = useSelector((state) => state.showForgetPassword);
  const token = useLocalStorageGetItem("token");
  const [openOfferPrice, setOpenOfferPrice] = useState(false);
  const [offerPriceSuccess, setOpenOfferPriceSuccess] = useState(false);
  const [openSuccessReg, setOpenSuccessReg] = useState(false);

  const navigate = useNavigate();

  const handleOpenRecoverPassword = () => {
    dispatch(makeAuthHidden());
    dispatch(makeForgetPasswordVisible());
    dispatch(makeHidden());
  };

  const handleBackToLogIn = () => {
    dispatch(makeAuthVisible());
    dispatch(makeForgetPasswordHidden());
  };

  const handleOpenAuth = () => {
    openAuth ? dispatch(makeAuthHidden()) : dispatch(makeAuthVisible());
    dispatch(makeHidden());
  };

  const handleShowOfferPrice = () => {
    setOpenOfferPrice((prev) => !prev);
  };

  return (
    <Section>
      <p className="black">{t("texts.summary")}</p>
      <Info>
        <InfoItem>
          <span>{t("texts.price")}</span>
          <span>{price}</span>
        </InfoItem>
        {discount?.[0] !== "0" && (
          <InfoItem>
            <span>{t("texts.sale")}</span>
            <span>{discount}</span>
          </InfoItem>
        )}
        {place === "checkout" && (
          <InfoItem>
            <span>{t("texts.delivery")}</span>
            <span>{delivery ? delivery + ",00 ֏" : "0,00 ֏"}</span>
          </InfoItem>
        )}
        <InfoItem className="points">
          {!!(place === "checkout" && token && points > 0) && (
            <>
              <div className="flex">
                <span className="first-letter-upp">
                  {t("texts.accumulated_points")}
                </span>
                <span>{points}</span>
              </div>
              <div className="divider" />
              <CustomCheckbox
                id="points-checkbox"
                text={t("actions.get_points")}
                checked={isGetPoints}
                handleChange={toggleGetPonts}
              />
            </>
          )}
        </InfoItem>
        <Divider />
        <InfoItem className="bold">
          <span>{t("texts.sum")}</span>
          <span>
            {place === "checkout" ||
            window.location.pathname.includes("checkout")
              ? `${(delivery ? total + delivery : total) || 0},00 ֏`
              : `${total || 0}`}
          </span>
        </InfoItem>
      </Info>
      <Button onClick={onClick}>{actionName}</Button>
      {place === "cart" && (
        <Button
          className="grey"
          onClick={!token ? handleOpenAuth : handleShowOfferPrice}
        >
          {t("actions.offer_price")}
        </Button>
      )}
      {openOfferPrice && (
        <OfferPriceInCart
          open={openOfferPrice}
          handleClose={(success) => {
            success === "success" && setOpenOfferPriceSuccess(true);
            setOpenOfferPrice(false);
          }}
          products={products}
        />
      )}
      {openAuth && (
        <Auth
          open={openAuth}
          handleClose={(success) => {
            dispatch(makeAuthHidden());
            dispatch(makeAuto());
            success === "success" && setOpenSuccessReg(true);
          }}
          handleOpenRecoverPassword={handleOpenRecoverPassword}
        />
      )}
      {openRecoverPassword && (
        <Recover
          open={openRecoverPassword}
          handleClose={() => {
            dispatch(makeForgetPasswordHidden());
            dispatch(makeAuto());
          }}
          handleBackToLogIn={handleBackToLogIn}
        />
      )}
      {offerPriceSuccess && (
        <ErrorDialog
          open={offerPriceSuccess}
          success="success"
          handleClose={() => {
            navigate("/personal-page/price-offers");
          }}
          message={t("texts.price_offer_message")}
        />
      )}
      {openSuccessReg && (
        <ErrorDialog
          open={openSuccessReg}
          success="success"
          handleClose={() => {
            setOpenSuccessReg(false);
          }}
          message={t("texts.check_your_email")}
        />
      )}
    </Section>
  );
};

export default CartSummary;
