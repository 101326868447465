import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../products-slider/styled";
import Review from "./Review";
import LeaveReview from "./LeaveReview";

const ProductReviews = ({ reviews }) => {
  const { t } = useTranslation();

  return (
    <section>
      <Title>{t("texts.reviews")}</Title>
      {!!reviews?.length && <Review review={reviews[0]} />}
      <LeaveReview place="product-page" />
    </section>
  );
};

export default ProductReviews;
