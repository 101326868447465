import React from "react";
import {
  ListContainer,
  SearchHeader,
  CustomLink,
  Message,
  Container,
  Header,
  IconButton,
  InputWrapper,
  Input,
  Label,
} from "./styled";
import { useTranslation } from "react-i18next";
import SearchItem from "./SearchItem";
import closeIcon from "../../assets/closeIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import SearchItemSkeleton from "./SearchItemSkeleton";

const SearchedList = ({
  onAway,
  handleClose,
  searchedList,
  handleChange,
  handleUnset,
  search,
  isLoading,
  error,
}) => {
  const { t } = useTranslation();
  const vHeight = useSelector((state) => state.appHeight.val);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (!e.target.className.includes("searchfield")) {
          onAway();
        }
      }}
    >
      <Container vHeight={vHeight}>
        <Header>
          <IconButton onClick={handleClose} className="custom">
            <img
              src={closeIcon}
              alt="close search"
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
          <InputWrapper>
            <Input
              id="search-input"
              value={search || ""}
              onChange={handleChange}
            />
            <Label htmlFor="search-input">
              <img
                src={searchIcon}
                alt="search icon"
                style={{ width: "15px", height: "15px" }}
                className="search"
              />
            </Label>
            <IconButton className="small" onClick={handleUnset}>
              <img
                src={closeIcon}
                alt="delete"
                style={{ width: "12px", height: "12px" }}
              />
            </IconButton>
          </InputWrapper>
        </Header>
        <ListContainer>
          {!search || search?.length < 3 ? null : isLoading ? (
            <div className="items">
              {Array.from({ length: 3 }).map((x, i) => (
                <SearchItemSkeleton key={i} />
              ))}
            </div>
          ) : searchedList?.length === 0 ? (
            <Message>{t("texts.nothing_found")}</Message>
          ) : (
            <>
              <SearchHeader>
                <h5>{t("texts.products")}</h5>
                <CustomLink to={`/searched/${search}/1`} onClick={handleClose}>
                  {t("actions.show_all")}
                </CustomLink>
              </SearchHeader>
              <div className="items">
                {searchedList.map((item) => (
                  <SearchItem
                    key={item.id}
                    product={item}
                    handleClose={handleClose}
                  />
                ))}
              </div>
            </>
          )}
        </ListContainer>
      </Container>
    </ClickAwayListener>
  );
};

export default SearchedList;
