import styled, { css } from "styled-components";

export const SmTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  font-size: 14px;
  color: #373e4b;
  border: 1px solid #373e4b;
  border-radius: 10px;
  padding: 20px;
`;

export const DF = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  & .name {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
  & .MuiRating-iconFilled {
    color: #ffe600;
  }
  &.m {
    margin: 5px 0 15px 0;
  }
  & .it {
    font-style: italic;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  &.mt {
    margin-top: 40px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    & button,
    & a {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const BackBtn = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  background-color: #373e4b;
  border: 1px solid #373e4b;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;
