import styled from "styled-components";
import { searchScrollbar } from "../../shared/styled";

export const Options = styled.div`
  max-height: 160px;
  height: auto;
  overflow-y: auto;
  ${searchScrollbar}
  margin-bottom: 10px;
  &.flex {
    display: flex;
    gap: 10px;
    padding: 0 10px;
    margin-bottom: 20px;
    & .input-wrapper {
      max-width: 50%;
      @media (max-width: 768px) {
        max-width: 150px;
      }
      @media (max-width: 480px) {
        max-width: 50%;
      }
    }
  }
`;

export const Input = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  border: 1px solid #373e4b;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 3px;
`;
