import styled from "styled-components";
import { Link } from "react-router-dom";

export const Accordion = styled.div`
  max-height: 28px;
  overflow: hidden;
  transition: max-height 100ms;
  &.expanded {
    max-height: 100%;
  }
`;

export const AccordionHeader = styled.button`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
  &.expanded {
    img {
      transform: rotate(180deg);
    }
  }
`;

export const AccordionContent = styled.ul``;

export const Li = styled.li`
  padding: 3px 0;
`;

export const CustomLink = styled(Link)`
  color: #373e4b;
  text-decoration: none;
  display: inline-block;
  &::first-letter {
    text-transform: capitalize;
  }
`;
