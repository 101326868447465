import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 40%;
  min-width: fit-content;
  padding: 10px;
  border: none;
  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
  & .button-text {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.125em;
    color: #373e4b;
  }
  & .button-icon {
    width: 14%;
  }
  @media (max-width: 420px) {
    padding: 6px;
    & .button-icon {
      width: 16px;
    }
  }
`;

export const CardButton = styled(StyledButton)`
  background-color: #ffe600;
  border-radius: 5px;
  padding: 10px;
  & p::first-letter {
    text-transform: capitalize;
  }
  @media (max-width: 1440px) {
    & .in-slider-text {
      display: none;
    }
    & .in-slider-img {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    & p {
      display: none;
    }
    & .button-icon {
      width: 20px;
    }
  }
  @media (max-width: 768px) {
    & p {
      display: unset;
    }
    & .button-icon {
      width: 20px;
    }
  }
  @media (max-width: 620px) {
    & p {
      display: none;
    }
  }
  @media (max-width: 440px) {
    & p {
      display: unset;
    }
  }
  @media (max-width: 340px) {
    & p {
      display: none;
    }
  }
  position: relative;
  & .success-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  &.success {
    & .btn {
      visibility: hidden;
    }
    & .success-icon {
      display: unset;
    }
  }
`;

export const FilterButton = styled.button`
  padding: 10px 20px;
  min-width: max-content;
  border: 1px solid #373e4b;
  border-radius: 10px;
  background-color: #ffffff;
  color: #373e4b;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &.active {
    background-color: #373e4b;
    color: #ffffff;
  }
  @media (max-width: 1440px) {
    padding: 10px;
    font-size: 0.875rem;
    line-height: 1rem;
    &.personal-data,
    &.addresses {
      display: block;
    }
    &.settings {
      display: none;
    }
  }
`;
