import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useGetData } from "../../utils/hooks/hooks";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { getBradcrumbs } from "../../utils/funcs/getBreadcrumbs";
import { Heading } from "../shinmag-club/styled";
import Review from "../../components/product-reviews/Review";
import { Reviews } from "./styled";
import CustomPagination from "../../components/pagination/CustomPagination";
import ReviewSkeleton from "./ReviewSkeleton";
import EmptyList from "../../components/empty-list";
import LeaveReview from "../../components/product-reviews/LeaveReview";

const ProductReviews = () => {
  const { productId } = useParams();
  const [nav, setNav] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: product } = useGetData(`products/${productId}`, [productId]);
  const { data: list, isLoading } = useGetData(
    `products/${productId}/feedbacks`,
    [productId]
  );

  useEffect(() => {
    if (product?.breadcrumb) {
      let arr = getBradcrumbs(
        product?.breadcrumb,
        {
          to: `/products/${productId}`,
          name: product.name || "product name",
        },
        "singleProduct"
      );
      arr = [
        ...arr,
        { to: `/products/${productId}/reviews`, name: t("texts.reviews") },
      ];
      setNav(arr);
    }
  }, [product?.breadcrumb]);

  const handlePageChange = () => {};

  const handleBack = () => navigate(-1);

  return (
    <div>
      <Breadcrumbs nav={nav} />
      <Heading>{t("texts.reviews")}</Heading>
      <Reviews>
        {isLoading ? (
          Array.from({ length: 5 }).map((x, i) => <ReviewSkeleton key={i} />)
        ) : !list?.length ? (
          <EmptyList />
        ) : (
          list?.map((review, i) => <Review review={review} key={i} />)
        )}
      </Reviews>
      <LeaveReview place="review-page" handleBack={handleBack} />
      {/* <CustomPagination
        page={1}
        pageCount={1}
        handlePageChange={handlePageChange}
      /> */}
      {/* )} */}
    </div>
  );
};

export default ProductReviews;
