import React from "react";
import UpperSlider from "../../components/home-upper-slider/UpperSlider";
import SubscribeForm from "../../components/home-subscribe-panel/SubscribeForm";
import { Section, SectionTitle } from "./styled";

import Advantages from "../../components/advanatages/Advantages";
import BestBrands from "../../components/best-brands/BestBrands";
import HomeNews from "../../components/home-news/HomeNews";
import HomeProducts from "../../components/home-products/HomeProducts";
import HomeCategories from "../../components/home-categories";
import HomeServices from "../../components/home-services/HomeServices";
import { useTranslation } from "react-i18next";
import { useGetData } from "../../utils/hooks/hooks";
import BrandSkeleton from "../../components/best-brands/BrandSkeleton";
import HomeDiscounts from "../../components/home-discounts/HomeDiscounts";
import LowerSlider from "../../components/home-lower-slider/LowerSlider";

const Home = () => {
  const { t } = useTranslation();
  const { data: brands, isLoading } = useGetData("brandes");
  const { data: articles, isLoading: articlesLoading } = useGetData("articles?page=1&perPage=4");

    return (
    <>
      <HomeCategories />
      <UpperSlider />
      <Section>
        <HomeServices />
      </Section>
      <Advantages />
      <Section>
        <HomeDiscounts />
      </Section>
      <Section>
        <HomeProducts />
      </Section>
      <LowerSlider />
      <Section>
        <SubscribeForm />
      </Section>
        {!!articles?.length &&
            <Section>
                <SectionTitle>{t("texts.news")}</SectionTitle>
                <HomeNews articles={articles} isLoading={articlesLoading}/>
            </Section>
        }
      <Section>
        {isLoading ? (
          <BrandSkeleton />
        ) : !!brands?.data?.length ? (

          <BestBrands brands={brands} />
        ) : null}
      </Section>
    </>
  );
};

export default Home;
