import React, { useState, useEffect } from "react";
import { PageTitle, ProductsCardGrid } from "../categories/styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router";
import FiltersHeader from "../../components/filters/FiltersHeader";
import { ProductsContainer } from "../categories/styled";
import ProductCard from "../../components/cards/ProductCard";
import CustomPagination from "../../components/pagination/CustomPagination";
import Filters from "../../components/filters/Filters";
import { useGetData } from "../../utils/hooks/hooks";
import EmptyList from "../../components/empty-list";
import { useSearchParams } from "react-router-dom";
import SkeletonCard from "../../components/cards/SkeletonCard";
import FiltersSkeleton from "../../components/filters/FiltersSkeleton";
import { useSelector } from "react-redux";

const Searched = () => {
  const [showFilters, setShowFilters] = useState(false);
  const { t } = useTranslation();
  const { key, page } = useParams();
  const lang = useSelector((state) => state.lang[0].val);
  console.log('page', page);
  const [currentPage, setCurrentPage] = useState(+page || 1);
  console.log('currentPage', currentPage);
  const [pageChanged, setPageChanged] = useState(false);
  const nav = [{ to: `searched/${key}`, name: t("texts.found_products") }];
  const [categoriesFilter, setCategoriesFilter] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesStr, setSelectedCategoriesStr] = useState("");
  const [usedFilter, setUsedFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState({});
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [search, setSearch] = useSearchParams();
  const f = search.get("f");
  const [initFetch, setInitFetch] = useState(true);
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandsStr, setSelectedBrandsStr] = useState("");

  const { data, isLoading } = useGetData(
    `search/${key}?perPage=12&page=${currentPage}${
      selectedMinPrice ? `&price[min]=${selectedMinPrice}` : ""
    }${selectedMaxPrice ? `&price[max]=${selectedMaxPrice}` : ""}${f || ""}${
      selectedCategoriesStr ? `${selectedCategoriesStr}` : ""
    }${selectedBrandsStr.length ? `${selectedBrandsStr}` : ""}`,
    [key, pageChanged]
  );

   const list = data?.products?.data?.data;
   const meta = data?.products?.data?.meta;

   const navigate = useNavigate();

   useEffect(()=>{
     console.log('pagepage', page);
     setCurrentPage(page || 1)
   },[page])

   useEffect(() => {
     if (!initFetch) {
       setInitFetch(true);
       setSelectedFilters([]);
       setSelectedCategories([]);
       setCategoriesFilter({});
       setPriceFilter({});
       setSelectedMinPrice("");
       setSelectedMaxPrice("");
       setUsedFilter("");
       setSelectedBrands([]);
     }
   }, [lang]);

   useEffect(() => {
     if (data) {
       if (initFetch) {
         setInitFetch(false);
         setColors(data?.colors);
         const filts = data?.products?.filters;
         if (filts) {
           const entries = Object.entries(filts);
           const prices = entries.find(
             (fl) => fl?.[0].toLowerCase() === t("texts.price")
           );
           const brands = entries.find(
             (fl) =>
               fl?.[0].toLowerCase() === "բռենդ" ||
               fl?.[0].toLowerCase() === "бренд"
           );
           const categories = entries.find(
             (fl) => fl?.[0].toLowerCase() === t("texts.category")
           );
           const list = entries.filter(
             (fl) =>
               fl?.[0].toLowerCase() !== t("texts.price") &&
               fl?.[0].toLowerCase() !== t("texts.category") &&
               fl?.[0].toLowerCase() === "բռենդ" &&
               fl?.[0].toLowerCase() === "бренд"
           );
           setFilters(list);
           setPriceFilter(prices);
           setCategoriesFilter(categories);
           setBrands(brands);
         }
       } else {
         const filts = data?.products?.filters;
         if (filts) {
           const entries = Object.entries(filts);
           if (usedFilter.toLowerCase() !== t("texts.price")) {
             const prices = entries.find(
               (fl) => fl?.[0].toLowerCase() === t("texts.price")
             );
             setPriceFilter(prices);
           }
           if (
             usedFilter.toLowerCase() !== "բռենդ" &&
             usedFilter.toLowerCase() !== "бренд"
           ) {
             const brands = entries.find(
               (fl) =>
                 fl?.[0].toLowerCase() === "բռենդ" ||
                 fl?.[0].toLowerCase() === "бренд"
             );
             setBrands(brands);
           }
           if (usedFilter.toLowerCase() !== t("texts.category")) {
             const categories = entries.find(
               (fl) => fl?.[0].toLowerCase() === t("texts.category")
             );
             setCategoriesFilter(categories);
           }
           const list = entries.filter(
             (fl) =>
               fl?.[0].toLowerCase() !== t("texts.price") &&
               fl?.[0].toLowerCase() !== t("texts.category") &&
               fl?.[0].toLowerCase() === "բռենդ" &&
               fl?.[0].toLowerCase() === "бренд"
           );
           const usedFilterData = filters.find((elem) => elem[0] === usedFilter);
           const newList = list.filter((elem) => elem[0] !== usedFilter);
           if (
             usedFilter.toLowerCase() === t("texts.price") ||
             usedFilter.toLowerCase() === t("texts.category")
           ) {
             setFilters(list);
           } else if (usedFilterData && newList) {
             setFilters([usedFilterData, ...newList]);
           }
           if (usedFilter !== "Գույն" && usedFilter !== "Цвет") {
             setColors(data?.colors);
           }
         }
       }
     }
   }, [data]);

  useEffect(() => {
    if (priceFilter?.length) {
      const prices = Object.values(priceFilter?.[1]);
      setMinPrice(prices[0]);
      setMaxPrice(prices[1]);
    }
  }, [priceFilter]);

  useEffect(() => {
    let str = "";
    if (selectedFilters?.length) {
      selectedFilters.forEach((fil) => {
        fil.list.forEach((elem) => (str += `&filters[${fil.name}][]=${elem}`));
      });
    }
    str ? setSearch({ f: str }) : setSearch({});
  }, [selectedFilters]);

  const handleMinChange = (e) => {
    if (
      priceFilter?.[1] &&
      Object.values(priceFilter[1])[1] &&
      +e.target.value < Object.values(priceFilter[1])[1]
    ) {
      setMinPrice(e.target.value);
      handleChangeUsedFilter(priceFilter?.[0]);
    }
  };
  const handleMaxChange = (e) => {
    if (
      priceFilter?.[1] &&
      Object.values(priceFilter[1])[1] &&
      +e.target.value <= Object.values(priceFilter[1])[1]
    ) {
      setMaxPrice(e.target.value);
      handleChangeUsedFilter(priceFilter?.[0]);
    }
  };

  const handleMaxBlur = (e) => {
    if (!e.relatedTarget || e.relatedTarget?.id !== "min-price") {
      if (+maxPrice <= +minPrice || !maxPrice) {
        selectedMaxPrice
          ? setMaxPrice(+selectedMaxPrice)
          : setMaxPrice(Object.values(priceFilter[1])[1]);
      } else {
        setSelectedMaxPrice(+maxPrice);
      }
    }
  };

  const handleMinBlur = (e) => {
    if (!e.relatedTarget || e.relatedTarget.id !== "max-price") {
      if (+maxPrice <= +minPrice || !minPrice) {
        selectedMinPrice
          ? setMinPrice(selectedMinPrice)
          : setMinPrice(Object.values(priceFilter[1])[0]);
      } else {
        setSelectedMinPrice(+minPrice);
      }
    }
  };

  const handleChangeUsedFilter = (name) => setUsedFilter(name);


    const toggleShowFilters = () => setShowFilters((prev) => !prev);


    const handleSelectedFiltersChange = (arr) => setSelectedFilters(arr);

   const handleBrandSelect = (id) => {
     selectedBrands.includes(id)
       ? setSelectedBrands((p) => p.filter((elem) => elem !== id))
       : setSelectedBrands((p) => [...p, id]);
   };

   const handleCategorySelect = (id) => {
     selectedCategories.includes(id)
       ? setSelectedCategories((p) => p.filter((elem) => elem !== id))
       : setSelectedCategories((p) => [...p, id]);
   };

    const handleUnsetFilters = () => {
      setSelectedMaxPrice("");
      setSelectedMinPrice("");
      setSelectedCategories([]);
      setSelectedFilters([]);
      setSelectedBrands([]);
    };

    const handlePageChange = (e, page) => {
      setCurrentPage(page);
      navigate(`/searched/${key}/${page}${window.location.search}`);
      setPageChanged((p) => !p);
    };

    useEffect(() => {
      // setCurrentPage(1);
      navigate(`/searched/${key}/${page}${window.location.search}`);
      setPageChanged((p) => !p);
    }, [selectedMinPrice, selectedMaxPrice, f]);

  useEffect(() => {
    // setCurrentPage(1);
    navigate(`/searched/${key}/1${window.location.search}`);
    setPageChanged((p) => !p);
    let str = "";
    if (selectedCategories?.length) {
      selectedCategories.forEach((elem) => (str += `&categories[]=${elem}`));
    }
    setSelectedCategoriesStr(str);
  }, [selectedCategories]);

  useEffect(() => {
    // setCurrentPage(1);
    navigate(`/searched/${key}/${page}${window.location.search}`);
    setPageChanged((p) => !p);
    let str = "";
    if (selectedBrands?.length) {
      selectedBrands.forEach((elem) => (str += `&brands[]=${elem}`));
    }
    setSelectedBrandsStr(str);
  }, [selectedBrands]);

  useEffect(() => {
    const elem = document.getElementById("wrapper");
    elem.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <Breadcrumbs nav={nav} />
      <PageTitle>{t("texts.found_products")}</PageTitle>
      <FiltersHeader
        toggleShowFilters={toggleShowFilters}
        selectedFilters={selectedFilters}
        selectedCategories={selectedCategories}
        selectedBrands={selectedBrands}
        price={selectedMinPrice || selectedMaxPrice}
        handleSelectedFiltersChange={handleSelectedFiltersChange}
        handleUnsetFilters={handleUnsetFilters}
      />
      <ProductsContainer>
        {isLoading ? (
          <FiltersSkeleton />
        ) : (
          <Filters
            showFilters={showFilters}
            filters={filters}
            selectedFilters={selectedFilters}
            handleSelectedFiltersChange={handleSelectedFiltersChange}
            priceFilter={priceFilter}
            page={currentPage}
            categoriesFilter={categoriesFilter}
            handleCategorySelect={handleCategorySelect}
            selectedCategories={selectedCategories}
            colors={colors}
            handleChangeUsedFilter={handleChangeUsedFilter}
            handleMaxBlur={handleMaxBlur}
            handleMinBlur={handleMinBlur}
            handleMaxChange={handleMaxChange}
            handleMinChange={handleMinChange}
            min={minPrice}
            max={maxPrice}
            brands={brands}
            selectedBrands={selectedBrands}
            handleBrandSelect={handleBrandSelect}
            handleUnsetFilters={handleUnsetFilters}
            // disabled={!list?.length}
          />
        )}
        {isLoading ? (
          <ProductsCardGrid>
            {Array.from({ length: 8 }).map((x, i) => (
              <SkeletonCard key={i} />
            ))}
          </ProductsCardGrid>
        ) : !list?.length ? (
          <EmptyList />
        ) : (
          <ProductsCardGrid>
            {list.map((product) => (
              <ProductCard key={product?.id} product={product} />
            ))}
          </ProductsCardGrid>
        )}
      </ProductsContainer>
      {console.log('currentPage',currentPage)}
      {meta?.last_page > 1 && (
        <CustomPagination
          page={+currentPage}
          pageCount={meta?.last_page}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Searched;
