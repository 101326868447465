import React, {useState, useEffect} from "react";
import {
    MainHeader,
    LogoWrapper,
    IconButton,
    LeftContent,
    CenterContent,
    RightContent,
    SearchFieldWrapper,
    SearchField,
} from "./styled";
import logo from "../../assets/logo.svg";
import menuIcon from "../../assets/menuIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import closeIcon from "../../assets/closeIcon.svg";
import loginIcon from "../../assets/loginIcon.svg";
import favouriteIcon from "../../assets/favouriteIcon.svg";
import cartIcon from "../../assets/cartIcon.svg";
import Menu from "../menu/Menu";
import Auth from "../auth/Auth";
import Recover from "../auth/Recover";
import {
    makeHidden,
    makeAuto,
} from "../../features/overflow-body/overflowOfMainSlice";
import {
    makeAuthHidden,
    makeAuthVisible,
} from "../../features/show-auth/showAuthSlice";
import {
    makeForgetPasswordHidden,
    makeForgetPasswordVisible,
} from "../../features/show-forget-password/showForgetPasswordSlice";
import {useDispatch, useSelector} from "react-redux";
import MainServices from "../main-services/MainServices";
import {useTranslation} from "react-i18next";
import {useGetData, useLocalStorageGetItem} from "../../utils/hooks/hooks";
import LoggedInPopover from "../loggedin-popover";
import RightContentItem from "./RightContentItem";
import SearchedList from "../searched-list/SearchedList";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {baseUrl} from "../../constants";
import {getCategories} from "../../features/categories/categoriesSlice";
import {userAdded} from "../../features/user/userSlice";
import {favsAdded} from "../../features/favs/favsSlice";
import ErrorDialog from "../dialogs/ErrorDialog";
import {Button} from "../auth/styled";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const dispatch = useDispatch();
    const openAuth = useSelector((state) => state.showAuth);
    const openRecoverPassword = useSelector((state) => state.showForgetPassword);
    const lang = useSelector((state) => state.lang[0].val);
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const token = useLocalStorageGetItem("token");

    const [showPopover, setShowPopover] = useState(false);

    const [openSearchbar, setOpenSearchbar] = useState(false);

    const [openRegSuccess, setOpenRegSuccess] = useState(false);

    const closeRegSuccess = () => setOpenRegSuccess(false);

    const handleCloseAuth = (success) => {
        if (success === "success") {
            setOpenRegSuccess(true);
        }
        dispatch(makeAuthHidden());
        dispatch(makeAuto());
    };

    const handleCloseRecover = (success) => {
        if (success === "success") {
            setOpenRegSuccess(true);
        }
        dispatch(makeForgetPasswordHidden());
        dispatch(makeAuto());
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search");
    const [showSearchedList, setShowSearchedList] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const [searchError, setSearchError] = useState("");

    const handleSearchChange = (e) => {
        setSearchParams({search: e.target.value});
    };
    const handleUnsetSearch = () => {
        setSearchParams({});
    };

    useEffect(() => {
        setSearchError("");
        if (search && search.length > 2) {
            if (cancelToken) {
                cancelToken.cancel("Request canceled due to new search query");
            }
            const newCancelToken = axios.CancelToken.source();
            setCancelToken(newCancelToken);

            setIsSearchLoading(true);

            const getSearchData = () => {
                axios
                    .get(`${baseUrl}/search/${search}?perPage=5`, {
                        headers: {
                            "Accept-Language": lang,
                            Authorization: `Bearer ${token}`,
                        },
                        cancelToken: newCancelToken.token,
                    })
                    .then((res) => {
                        setSearchedList(res.data?.data?.products?.data?.data);
                        setIsSearchLoading(false);
                    })
                    .catch(() => setSearchError(t("texts.unexpected_error")));
            };

            const debounceTimeout = setTimeout(() => {
                getSearchData();
            }, 500);

            return () => clearTimeout(debounceTimeout);
        } else {
            setSearchedList([]);
        }
    }, [search]);

    const countToggle = useSelector((state) => state.cart.countToggle);

    const {data: cart} = useGetData("carts", [countToggle, token], true, token);
    const cartLength = cart?.count;

    const {data: user} = useGetData(
        "products/favorites",
        [token],
        token,
        token
    );

    const [searchedList, setSearchedList] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    useEffect(() => {
        if (user) {
            dispatch(userAdded(user));
            dispatch(favsAdded(user?.favorites || []));
        }
    }, [user]);

    useEffect(() => {
        if (search) {
            setShowSearchedList(true);
            dispatch(makeHidden());
        } else if (!openSearchbar) {
            setShowSearchedList(false);
            dispatch(makeAuto());
        }
    }, [search]);

    const {t} = useTranslation();

    const handleOpenSearchbar = () => {
        dispatch(makeHidden());
        setOpenSearchbar(true);
    };

    const handleCloseSearchbar = () => {
        dispatch(makeAuto());
        setOpenSearchbar(false);
        setShowSearchedList(false);
        setSearchParams({});
    };

    const handleOpenRecoverPassword = () => {
        dispatch(makeAuthHidden());
        dispatch(makeForgetPasswordVisible());
        dispatch(makeHidden());
    };

    const handleBackToLogIn = () => {
        dispatch(makeAuthVisible());
        dispatch(makeForgetPasswordHidden());
    };

    const handleOpenAuth = () => {
        openAuth ? dispatch(makeAuthHidden()) : dispatch(makeAuthVisible());
        dispatch(makeHidden());
    };

    const handleLogOut = () => {
        setShowPopover(false);
        dispatch(makeAuto());
        pathname.includes("personal-page") && navigate("/");
    };

    const handleBlurLogin = (e) => {
        if (!e?.relatedTarget?.className.includes("personal-settings")) {
            setShowPopover(false);
            dispatch(makeAuto());
        }
    };

    const handleLoginIconClick = () => {
        if (!token) {
            handleOpenAuth();
        } else {
            !showPopover ? dispatch(makeHidden()) : dispatch(makeAuto());
            setShowPopover((prev) => !prev);
        }
    };

    useEffect(() => {
        axios
            .get(`${baseUrl}/categories?with-subcategories=true&perPage=30`, {
                headers: {
                    "Accept-Language": lang,
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data.data)
            .then((data) => {
                dispatch(getCategories(data));
            });
    }, [lang]);

    return (
        <>
            <MainHeader>
                <LeftContent>
                    <LogoWrapper to="/" className="left">
                        <img src={logo} alt=""/>
                    </LogoWrapper>
                    <IconButton
                        onClick={() => {
                            !showMenu ? dispatch(makeHidden()) : dispatch(makeAuto());
                            setShowMenu((prev) => !prev);
                        }}
                    >
                        <img src={menuIcon} alt=""/>
                    </IconButton>
                    <IconButton className="search" onClick={handleOpenSearchbar}>
                        <img src={searchIcon} alt=""/>
                    </IconButton>
                </LeftContent>
                <CenterContent>
                    <MainServices/>
                </CenterContent>
                <LogoWrapper to="/" className="center">
                    <img src={logo} alt=""/>
                </LogoWrapper>
                <RightContent>
                    <SearchFieldWrapper>
                        <SearchField
                            value={search || ""}
                            onChange={handleSearchChange}
                            className="searchfield"
                        />
                        {search ? (
                            <IconButton onClick={handleUnsetSearch}>
                                <img src={closeIcon} alt=""/>
                            </IconButton>
                        ) : (
                            <img src={searchIcon} alt=""/>
                        )}
                    </SearchFieldWrapper>
                    {token ? (
                        <RightContentItem
                            to="/personal-page/favorites"
                            icon={favouriteIcon}
                            name={t(`texts.favourites`)}
                        />
                    ) : (
                        <RightContentItem
                            as="button"
                            onClick={handleOpenAuth}
                            icon={favouriteIcon}
                            name={t(`texts.favourites`)}
                        />
                    )}
                    <RightContentItem
                        to={cartLength ? "/cart" : "#"}
                        icon={cartIcon}
                        name={t(`texts.cart`)}
                        count={cartLength || ""}
                    />
                    <RightContentItem
                        as="button"
                        name={token ? user?.firstName : t("texts.login")}
                        icon={loginIcon}
                        onClick={handleLoginIconClick}
                        onBlur={handleBlurLogin}
                    />
                </RightContent>
            </MainHeader>
            {showMenu && (
                <Menu
                    show={showMenu}
                    handleClose={() => {
                        setShowMenu(false);
                        dispatch(makeAuto());
                    }}
                />
            )}
            {openAuth && (
                <Auth
                    open={openAuth}
                    handleClose={handleCloseAuth}
                    handleOpenRecoverPassword={handleOpenRecoverPassword}
                />
            )}
            {openRecoverPassword && (
                <Recover
                    open={openRecoverPassword}
                    handleClose={handleCloseRecover}
                    handleBackToLogIn={handleBackToLogIn}
                />
            )}
            {showPopover && <LoggedInPopover handleClose={handleLogOut}/>}
            {(showSearchedList || openSearchbar) && (
                <SearchedList
                    handleClose={handleCloseSearchbar}
                    handleChange={handleSearchChange}
                    handleUnset={handleUnsetSearch}
                    search={search}
                    searchedList={searchedList}
                    isLoading={isSearchLoading}
                    error={searchError}
                    onAway={() => {
                        setSearchParams({});
                    }}
                />
            )}
            {openRegSuccess && (
                <>
                    <ErrorDialog
                        open={openRegSuccess}
                        success="success"
                        message={t("texts.check_your_email")}
                        handleClose={closeRegSuccess}
                        isCounter={true}
                    />
                </>
            )}
        </>
    );
};

export default Header;
