import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  padding: 5px 0;
  & .slick-slider {
    z-index: 90;
    width: 100%;
    overflow: hidden;
    display: flex;
    & .slick-slide {
      max-width: calc(25% - 10px);
      margin: 1px;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 10px;
      }
      & div {
        width: 100%;
      }
      @media (max-width: 1180px) {
        max-width: calc(100% / 3 - 10px);
      }
      @media (max-width: 768px) {
        max-width: calc(50% - 10px);
      }
      @media (max-width: 500px) {
        max-width: 100%;
      }
    }
    & .slick-track {
      width: 100%;
      display: flex;
    }
    & .slick-list {
      width: calc(100% - 80px);
      margin: 0 auto;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    & .slick-arrow {
      background-color: inherit;
      min-width: 40px;
      height: 20px;
      margin: auto 0;
      &.slick-prev {
        transform: rotate(90deg);
      }
      &.slick-next {
        transform: rotate(-90deg);
      }
      &.slick-prev + .slick-list {
        width: 100%;
      }
    }
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #373e4b;
  margin-bottom: 40px;
  &::first-letter {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
