import styled from "styled-components";

export const GiftCardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
`;

export const PublicId = styled.span`
  bottom: 50px;
  position: absolute;
  font-size: 30px;
  ${props => props.balance ? 'right: 50px' : 'left: 50px;'};
  @media (max-width: 768px) {
    bottom: 30px;
    ${props => props.balance ? 'right: 20px' : 'left: 20px;'};
  }
`;
export const GiftCardWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  margin-bottom: 50px;
  img {
    width: 100%;
  }
  span:not(${PublicId}) {
    position: absolute;
    bottom: 100px;
    left: 50px;
  }
  span.balance:not(${PublicId}){
    right: 50px;
    left: unset;
  }
  @media (max-width: 768px) {
    span {
      font-size: 14px;
    }
    span:not(${PublicId}) {
      bottom: 60px;
      left: 20px;
    }
    span.balance:not(${PublicId}){
      right: 20px;
      left: unset;
    }
  }
`;


