import React, {useState, useEffect} from "react";
import {Container, PageHeading, Section} from "../cart/styled";
import {
    Form,
    List,
    ListItem,
    ListHeader,
    Button,
    ListContent,
    GridContainer,
    Label,
    Inputs,
    DeliveryMessage,
} from "./styled";
import CartSummary from "../../components/cart-summary/CartSummary";
import CustomSelect from "../../components/custom-select/CustomSelect";
import CustomRadio from "../../components/custom-radio/CustomRadio";
import {useTranslation} from "react-i18next";
import PayerInfo from "./PayerInfo";
import CustomInput from "./CustomInput";
import ListStyleType from "./ListStyleType";
import DatesList from "../../components/dates-list/DatesList";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import CustomCheckbox from "../../components/custome-checkbox/CustomCheckbox";
import {useGetHoursIntervals} from "./getHours";
import {useSelector, useDispatch} from "react-redux";
import {changeCartLength} from "../../features/cart/cartSlice";
import {useNavigate, useLocation, useParams} from "react-router";
import {useGetData, useLocalStorageGetItem} from "../../utils/hooks/hooks";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import axios from "axios";
import {baseUrl} from "../../constants";
import {deleteKey} from "../../utils/funcs/formHandlings";
import {ErrorText} from "../../shared/styled";
import moment from "moment";
import AdditionalServices from "./AdditionalServices";
import {useGetDates} from "./getDate";
import Progress from "../../components/progress-bar";
import Switch from '@mui/material/Switch';
import AddressRadioContent from "../../components/custom-radio/AddressRadioContent";
import PaymentRadioContent from "../../components/custom-radio/PaymentRadioContent";
import {DeliveryOption, Radio} from "../../components/custom-radio/styled";
import {Link} from "react-router-dom";
import infoIcon from "../../assets/infoIcon.svg";
import GiftCardRadio from "../../components/custom-radio/GiftCardRadio";

const Checkout = () => {
    const {t} = useTranslation();
    const {state} = useLocation();
    const customerTypes = [
        {id: 1, name: t("texts.physical_person")},
        {id: 2, name: t("texts.juridical_person")},
    ];
    const token = useLocalStorageGetItem("token");
    const lang = useSelector((state) => state.lang[0]);
    const {orderId} = useParams();
    const ip = localStorage.getItem('ip')
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [customerType, setCustomerType] = useState(customerTypes[0]);
    const [customerExpanded, setCustomerExpanded] = useState(false);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [tin, setTin] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [purchaseType, setPurchaseType] = useState(null);
    const [giftCard, setGiftCard] = useState();
    const [isSelectAddressFromList, setIsSelectAddressFromList] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [address, setAddress] = useState("");
    const [city, setCity] = useState(null);
    const [additional, setAdditional] = useState("");
    const [cityExpanded, setCityExpanded] = useState(false);
    const [saveAddress, setSaveAddress] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState("");
    const [selectedHours, setSelectedHours] = useState("");
    const [hoursExpanded, setHoursExpanded] = useState(false);
    const [paymentType, setPaymentType] = useState({});
    const [today, setToday] = useState(false);
    const [int, setInt] = useState(4);
    const hours = useGetHoursIntervals(int, today);
    const dates = useGetDates();
    const [paymentError, setPaymentError] = useState(false);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [showCards, setShowCards] = useState(false);
    const [switcher, setSwitcher] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [customerSuccess, setCustomerSuccess] = useState(false);
    const [processSuccess, setProcessSuccess] = useState(false);
    const [priceDetails, setPriceDetails] = useState(null);
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const [isGetPoints, setIsGetPoints] = useState(false);
    const dispatch = useDispatch();
    const label = {inputProps: {'aria-label': 'Switch demo'}};

    const {data: order, error} = useGetData(`orders/${orderId}`, [], orderId, token, token);

    const {data: partner} = useGetData(
        `partners/users`,
        [user],
        !!user?.isPartner,
        token
    );

    const {data: addresses} = useGetData("users/addresses", [], token, token);

    const {data: extraOptions} = useGetData("orders/extra-options");

    let headers = {
        "Accept-Language": lang.val,
    };

    if (token) {
        headers = {...headers, Authorization: `Bearer ${token}`};
    }

    const nav = [
        {
            to: state === "checkout" ? "/personal-page/price-offers" : "/cart",
            name:
                state === "checkout" ? t("texts.my_offered_prices") : t("texts.cart"),
        },
        {to: "/checkout", name: t("texts.payment")},
    ];

    const handleChangeSwitch = () => {
        setShowCards(!showCards)
    }

    useEffect(() => {
        if (user && order && !order?.customer) {
            const {firstName, lastName, phone, email} = user;
            firstName && setName(firstName);
            lastName && setLastName(lastName);
            email && setEmail(email);
            phone && setPhone(phone?.slice(4));
            for (let key in user) {
                if (errors?.[key]) {
                    deleteKey(key, errors);
                }
            }
        }
    }, [user, order]);

    useEffect(() => {
        if (partner && order && !order.customer?.referralCode) {
            setReferralCode(`${partner.referralCode}`);
        }
    }, [partner, order]);

    useEffect(() => {
        if (order) {
            setPaymentType(order?.paymentMethods?.[0]);
            setPurchaseType(order?.orderTypes?.[0]);
            // setGiftCard(order?.giftCard?.[0]);
            if (order?.extraOptions?.length) {
                setSelectedExtras(order?.extraOptions.map((elem) => elem.id));
            }
            if (order?.customer) {
                const {email, firstName, lastName, phone, type, referralCode} =
                    order.customer;
                firstName && setName(firstName);
                lastName && setLastName(lastName);
                email && setEmail(email);
                phone && setPhone(phone?.slice(4));
                referralCode && setReferralCode(referralCode);
                type &&
                setCustomerType(
                    customerTypes.find((elem) => elem.name === type.toLowerCase())
                );
                for (let key in user) {
                    if (errors?.[key]) {
                        deleteKey(key, errors);
                    }
                }
            }
            if (order?.date) {
                const selectedDt = dates.find(
                    (item) => moment(item).format("YYYY-MM-DD") === order?.date
                ) ?? moment().format("YYYY-MM-DD");
                if (selectedDt) {
                    setDeliveryDate(selectedDt);
                }
            }
        }
    }, [order]);

    useEffect(() => {
        if (
            purchaseType &&
            ((isSelectAddressFromList && selectedAddress?.id) || city?.id)
        ) {
            let data = {orderTypeId: purchaseType.id};
            if (isSelectAddressFromList && selectedAddress?.id) {
                data.addressId = selectedAddress.id;
            } else {
                data.regionId = city.id;
            }
            axios
                .post(`${baseUrl}/orders/${orderId}/check/details${ip ? `?ip=${ip}` : ''}`, data, {headers})
                .then((res) => setPriceDetails(res.data.data));
        }
    }, [purchaseType, selectedAddress, city]);

    useEffect(() => {
        purchaseType?.id === 3 ? setInt(2) : setInt(4);
        setSelectedHours("");
    }, [purchaseType]);

    useEffect(() => {
        setCustomerType(customerTypes[0]);
    }, [lang]);

    useEffect(() => {
        if (addresses?.length) {
            let selected;
            if (order?.address) {
                selected = addresses?.find((elem) => elem.id === order.address?.id);
            } else {
                selected = addresses?.find((ad) => !!ad.isDefault);
                if (!selected) {
                    selected = addresses[0];
                }
            }
            if (selected) {
                setIsSelectAddressFromList(true);
                setSelectedAddress(selected);
            } else {
                setSelectedAddress(addresses?.[0]);
            }
        } else {
            setIsSelectAddressFromList(false);
            if (order?.address) {
                const {region, address, details} = order.address;
                setCity(region);
                setAddress(address);
                setAdditional(details);
            }
        }
    }, [addresses, order]);

    const handleChange = (val, setVal, key) => {
        if (!(key === "tin" && val?.length > 8)) {
            setVal(val);
            deleteKey(key, errors);
            const custKeys = [
                "fullName",
                "referralCode",
                "email",
                "type",
                "phone",
                "lastname",
                "gift"
            ];
            customerSuccess && custKeys.includes(key) && setCustomerSuccess(false);
            processSuccess && setProcessSuccess(false);
        }
    };

    const toggleShowAddresses = () => {
        setCity(null);
        setIsSelectAddressFromList((p) => !p);
    };

    const handleSaveAddressToggle = () => setSaveAddress((prev) => !prev);

    const handleDeliveryDateChange = (date) => {
        date?.name === t("texts.today") ? setToday(true) : setToday(false);
        (date?.name === t("texts.today") ||
            deliveryDate?.name === t("texts.today")) &&
        setSelectedHours("");
        setDeliveryDate(date);
        deleteKey("date", errors);
    };

    const handleCloseErrorDialog = () => navigate("/cart");

    const handleClosePaymentErrorDialog = () => setPaymentError(false);

    const handleCloseSuccessDialog = () => {
        setSuccess(false);
        token ? navigate("/personal-page/orders/1") : navigate("/");
    };

    const toggleGetPonts = () => setIsGetPoints((p) => !p);
    const windowReference = window

    function openPaymentWindow(paymentUrl) {
        console.log(11, windowReference);
        windowReference.open(paymentUrl, "_blank");
        console.log('windowReference', paymentUrl, windowReference);
        if (windowReference) {
            windowReference.focus();
        }
    }

    const handleSubmit = async (e) => {
        console.log('ds', giftCard)
        e.preventDefault();
        // if (orderSuccess) {
        //   return;
        // }
        let custS = false;
        let procS = false;
        let customer = {orderId};
        let customerInfo = {email, tin, referralCode};
        for (let info in customerInfo) {
            if (customerInfo[info]) {
                customer[info] = customerInfo[info];
            }
        }
        if (name || lastName) {
            customer.fullName = `${name} ${lastName}`;
        }
        if (phone) {
            customer.phone = `+374${phone.split(" ").join("")}`;
        }
        if (customerType) {
            customer.type =
                customerType?.name === t("texts.physical_person")
                    ? "individual"
                    : "entity";
        }


        let processData = {
            extraOptions: selectedExtras,
        };
        if (token && isSelectAddressFromList) {
            processData.addressId = selectedAddress?.id;
        } else {
            const processInfo = {address, additional};
            for (let info in processInfo) {
                if (processInfo[info]) {
                    processData[info] = processInfo[info];
                }
            }
            if (city?.id) {
                processData.regionId = city.id;
            }
        }
        if (giftCard) {
            processData.gift = giftCard
        }

        if (purchaseType?.id) {
            processData.orderTypeId = purchaseType.id;
        }
        if (deliveryDate) {
            processData.date = moment(deliveryDate).format("YYYY-MM-DD");
        }
        if (selectedHours) {
            processData.start = selectedHours?.start;
            processData.end = selectedHours?.end;
        } else {
            processData.start = moment().format('HH:mm');
            processData.end = moment().format('HH:mm');
        }
        if (!customerSuccess) {
            await axios
                .post(`${baseUrl}/customers${ip ? `?ip=${ip}` : ''}`, customer, {headers})
                .then(() => {
                    setCustomerSuccess(true);
                    custS = true;
                    setErrors({});
                })
                .catch((err) => {
                    setErrors(err.response?.data?.errors);
                });
        }
        if ((customerSuccess || custS) && !processSuccess) {
            await axios
                .put(`${baseUrl}/orders/${orderId}/process${ip ? `?ip=${ip}` : ''}`, processData, {
                    headers,
                })
                .then(() => {
                    setProcessSuccess(true);
                    procS = true;
                    setErrors({});
                })
                .catch((err) => {
                    console.log('err', err);
                    setErrors(err.response?.data?.errors);
                });
        }
        if ((customerSuccess || custS) && (processSuccess || procS)) {
            setShowProgress(true);
            await axios
                .post(
                    `${baseUrl}/orders/${orderId}/pay/${paymentType?.slug}${ip ? `?ip=${ip}` : ''}`,
                    {},
                    {headers}
                )
                .then((res) => {
                    setShowProgress(false);
                    console.log('res', res);
                    const respData = res.data.data;
                    console.log('respData', respData);
                    if (respData.redirectUrl) {
                        setOrderSuccess(true);
                        window.location.href = respData.redirectUrl;
                        // openPaymentWindow(respData.redirectUrl);
                    } else {
                        setSuccess(true);
                        axios.delete(`${baseUrl}/carts${ip ? `?ip=${ip}` : ''}`, {headers}).then((res) => {
                            dispatch(changeCartLength());
                        });
                    }
                })
                .then(() => {
                    if (saveAddress) {
                        const data = {address, details: additional, regionId: city?.id};
                        axios.post(`${baseUrl}/users/addresses`, data, {headers});
                    }
                    if (isGetPoints && partner?.accumulatedPoints) {
                        axios.post(
                            `${baseUrl}/partners/bonuses/request`,
                            {
                                points: partner?.accumulatedPoints,
                            },
                            {headers}
                        );
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        setShowProgress(false);
                        setPaymentError(true);
                    }
                });
        }
    };

    const handleSelectExtra = (id) => {
        selectedExtras?.includes(id)
            ? setSelectedExtras((prev) => prev.filter((el) => el !== id))
            : setSelectedExtras((pr) => [...pr, id]);
    };

    return (
        <Container>
            <Breadcrumbs nav={nav}/>
            <PageHeading>{t("texts.payment")}</PageHeading>
            <Section>
                <Form onSubmit={handleSubmit}>
                    <List>
                        <ListItem>
                            <ListHeader>
                                <ListStyleType count={1} name={t("texts.payer_data")}/>
                                <Inputs>
                                    <div>
                                        <Label as="p">{t("texts.payer_type")}</Label>
                                        <CustomSelect
                                            className={`checkout ${errors?.type && "error"}`}
                                            placeholder={t("texts.physical_person")}
                                            disabled={!customerTypes}
                                            expanded={customerExpanded}
                                            selectedOption={customerType}
                                            options={customerTypes}
                                            handleExpand={() =>
                                                handleChange(
                                                    !customerExpanded,
                                                    setCustomerExpanded,
                                                    "type"
                                                )
                                            }
                                            setValue={setCustomerType}
                                            handleSelect={handleChange}
                                            onClickAway={() =>
                                                handleChange(false, setCustomerExpanded)
                                            }
                                            errorText={errors?.type?.[0]}
                                        />
                                    </div>
                                    <PayerInfo
                                        customerType={customerType}
                                        info={{
                                            name,
                                            lastName,
                                            phone,
                                            email,
                                            tin,
                                            referralCode,
                                            setName,
                                            setLastName,
                                            setPhone,
                                            setEmail,
                                            setTin,
                                            setReferralCode,
                                        }}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Inputs>
                            </ListHeader>
                        </ListItem>
                        {
                            <ListItem>
                                <ListHeader>
                                    <ListStyleType count={2} name={t("texts.payment_type")}/>
                                    <CustomRadio
                                        payments={true}
                                        options={order?.paymentMethods}
                                        selectedOption={paymentType}
                                        setValue={setPaymentType}
                                        onChange={handleChange}
                                    />
                                    {order?.giftCards?.length ?
                                        <div>
                                            <Switch onChange={handleChangeSwitch}/>
                                            {t("texts.use_gift_card")}
                                        </div> : null
                                    }

                                </ListHeader>
                                {showCards &&
                                    <Container className="payment">
                                        <GiftCardRadio
                                            name="gift"
                                            options={order?.giftCards}
                                            giftCard={giftCard}
                                            setGiftCard={setGiftCard}
                                            onChange={handleChange}
                                        />
                                        {errors['gift.use'] && (
                                            <ErrorText className="mb">
                                                {errors['gift.use'][0]}
                                            </ErrorText>
                                        )}
                                    </Container>
                                }
                            </ListItem>
                        }
                        <ListItem>
                            <ListHeader>
                                <ListStyleType count={3} name={t("texts.purchase_type")}/>
                            </ListHeader>
                            {purchaseType?.id === 3 && (
                                <DeliveryMessage>
                                    {t("texts.express_delivery_duration_message")}
                                </DeliveryMessage>
                            )}
                            <ListContent>
                                {isSelectAddressFromList && !!addresses?.length ? (
                                    <>
                                        <CustomRadio
                                            name="addresses"
                                            addresses={true}
                                            options={addresses}
                                            selectedOption={selectedAddress}
                                            setValue={setSelectedAddress}
                                            onChange={handleChange}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <GridContainer>
                                            <div>
                                                <Label htmlFor="city">{t("texts.district")}</Label>
                                                <CustomSelect
                                                    className={`city ${errors?.regionId && "error"}`}
                                                    placeholder={t("texts.district")}
                                                    disabled={!order?.regions}
                                                    expanded={cityExpanded}
                                                    selectedOption={city}
                                                    options={order?.regions}
                                                    handleExpand={() =>
                                                        handleChange(
                                                            !cityExpanded,
                                                            setCityExpanded,
                                                            "regionId"
                                                        )
                                                    }
                                                    setValue={setCity}
                                                    handleSelect={handleChange}
                                                    onClickAway={() =>
                                                        handleChange(false, setCityExpanded, "regionId")
                                                    }
                                                    errorText={errors?.regionId?.[0]}
                                                />
                                            </div>
                                        </GridContainer>
                                        <div>
                                            <CustomInput
                                                id="full-address"
                                                labelName={t("texts.delivery_detailed_address")}
                                                inputClassName={`${errors?.address && "error"}`}
                                                inputKey="address"
                                                value={address}
                                                setValue={setAddress}
                                                handleChange={handleChange}
                                            />
                                            {errors?.address && (
                                                <ErrorText className="mb">
                                                    {errors?.address?.[0]}
                                                </ErrorText>
                                            )}
                                        </div>
                                        <div>
                                            <CustomInput
                                                id="full-address-add-info"
                                                labelName={t("texts.delivery_details_more_info")}
                                                inputClassName={`${errors?.additional && "error"}`}
                                                inputKey="additional"
                                                value={additional}
                                                setValue={setAdditional}
                                                handleChange={handleChange}
                                            />
                                            {errors?.additional && (
                                                <ErrorText className="mb">
                                                    {errors?.additional?.[0]}
                                                </ErrorText>
                                            )}
                                        </div>
                                        {token && (
                                            <CustomCheckbox
                                                className={`checkout ${
                                                    token && !addresses?.length ? "mb" : ""
                                                }`}
                                                id="save-address"
                                                text={t("actions.save_address")}
                                                handleChange={handleSaveAddressToggle}
                                                checked={saveAddress}
                                            />
                                        )}
                                    </>
                                )}
                                <CustomRadio
                                    name="purchase-types"
                                    options={!priceDetails ? order?.orderTypes : priceDetails.orderTypes}
                                    selectedOption={purchaseType}
                                    setValue={setPurchaseType}
                                    onChange={handleChange}
                                    to={"/delivery-and-dumping/standard"}
                                />
                                {token && !!addresses?.length && (
                                    <Button
                                        type="button"
                                        className="address bold"
                                        onClick={toggleShowAddresses}
                                    >
                                        {isSelectAddressFromList
                                            ? t("texts.new_address")
                                            : t("actions.choose_saved_address")}
                                    </Button>
                                )}
                                { purchaseType?.isFlexible
                                    ? <>
                                        <Label as="p">
                                            {t("texts.delivery_day")}
                                            {errors?.date && (
                                                <ErrorText as="span"> {errors?.date?.[0]}</ErrorText>
                                            )}
                                        </Label>
                                        <DatesList
                                            deliveryDate={deliveryDate}
                                            handleChange={handleDeliveryDateChange}
                                        />
                                        <Label as="p">{t("texts.delivery_hours")}</Label>
                                        <CustomSelect
                                            className={`hours ${errors?.start && "error"}`}
                                            hours={true}
                                            placeholder={
                                                hours?.[0]
                                                    ? `${hours?.[0]?.start}-${hours?.[0]?.end}`
                                                    : "08:00-10:00"
                                            }
                                            disabled={!deliveryDate}
                                            expanded={hoursExpanded}
                                            selectedOption={selectedHours}
                                            options={hours}
                                            handleExpand={() =>
                                                handleChange(!hoursExpanded, setHoursExpanded)
                                            }
                                            setValue={setSelectedHours}
                                            handleSelect={handleChange}
                                            onClickAway={() => handleChange(false, setHoursExpanded)}
                                            errorText={errors?.start?.[0]}
                                        />
                                    </>
                                    : null
                                }
                            </ListContent>
                        </ListItem>
                        {!!extraOptions?.length && (
                            <ListItem>
                                <ListHeader>
                                    <ListStyleType
                                        count={4}
                                        name={t("texts.additional_services")}
                                    />
                                </ListHeader>
                                <AdditionalServices
                                    extraOptions={extraOptions}
                                    selectedExtras={selectedExtras}
                                    handleSelectExtra={handleSelectExtra}
                                />
                            </ListItem>
                        )}
                    </List>
                </Form>
                <section className="summary-section">
                    <CartSummary
                        place="checkout"
                        price={order?.subtotal}
                        delivery={priceDetails?.deliveryPrice}
                        points={partner?.accumulatedPoints}
                        total={
                            +order?.total
                                ?.slice(0, -5)
                                .split(/\s(.+)/)
                                .join("")
                        }
                        discount={order?.discount}
                        actionName={t("actions.to_order")}
                        onClick={handleSubmit}
                        isGetPoints={isGetPoints}
                        toggleGetPonts={toggleGetPonts}
                    />
                </section>
            </Section>
            {error && (
                <ErrorDialog open={error} handleClose={handleCloseErrorDialog}/>
            )}
            {paymentError && (
                <ErrorDialog
                    open={paymentError}
                    handleClose={handleClosePaymentErrorDialog}
                    message={t("texts.order_failed")}
                />
            )}
            {success && (
                <ErrorDialog
                    open={success}
                    success="success"
                    message={t("texts.order_succeeded")}
                    handleClose={handleCloseSuccessDialog}
                />
            )}
            {showProgress && (
                <Progress
                    open={showProgress}
                    handleClose={() => setShowProgress(false)}
                />
            )}
        </Container>
    );
};

export default Checkout;
