import React, { useState } from "react";
import closeIcon from "../../../assets/closeIcon.svg";
import {
  PopupBody,
  PopupDivider,
  PopupFormInputLabel,
  PopupFormInputWrapper,
  PopupFormSubmitButton,
  PopupFormWrapper,
  PupupHeader,
  ErrorText,
} from "./styled";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import { useSelector } from "react-redux";
import checkIcon from "../../../assets/checkIcon.svg";
import { useNavigate } from "react-router";

const RemoveAccount = ({ handleClose }) => {
  const { t } = useTranslation();
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0]);
  const headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`,
  };
  const [error, setError] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete(`${baseUrl}/users`, { headers })
      .then(() => {
        setSucceeded(true);
        setTimeout(() => {
          setSucceeded(false);
          localStorage.removeItem("token");
          navigate("/");
          handleClose();
        }, 3000);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setTimeout(() => setError(""), 3000);
      });
  };

  return (
    <Modal handleClose={handleClose}>
      <PupupHeader>
        <p className="heading">{t("actions.delete_page")}</p>
        <img onClick={handleClose} src={closeIcon} alt="close" />
      </PupupHeader>
      <PopupDivider></PopupDivider>
      <PopupBody>
        <PopupFormWrapper onSubmit={handleSubmit}>
          <PopupFormInputWrapper>
            <p className="remove-account-heading">
              {t("texts.delete_account_question")}
            </p>
            <PopupFormInputLabel className="center">
              {t("texts.delete_account_warning")}
            </PopupFormInputLabel>
          </PopupFormInputWrapper>
          {error && <ErrorText>{error}</ErrorText>}
          <PopupFormSubmitButton className={succeeded && "success"}>
            <img src={checkIcon} alt="sent" width="18" />
            <span>{t("actions.delete_page")}</span>
          </PopupFormSubmitButton>
          <PopupFormSubmitButton
            type="button"
            className="remove-address-button remove-account-button"
            onClick={handleClose}
          >
            {t("actions.cancel")}
          </PopupFormSubmitButton>
        </PopupFormWrapper>
      </PopupBody>
    </Modal>
  );
};

export default RemoveAccount;
