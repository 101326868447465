import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexCenter, alignCenter } from "./styled";

export const Container = styled.div`
  display: flex;
  gap: 40px;
  padding-top: 20px;
`;

export const SubCategoryCardsContainer = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1240px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SubCategoryCardContainer = styled(Link)`
  text-decoration: none;
  ${alignCenter}
  flex-direction: column;
  row-gap: 15px;
  overflow: hidden;
`;

export const SubCategoryCardImageContainer = styled.div`
  border: 1px solid #373e4b;
  border-radius: 10px;
  ${flexCenter}
  background-color: #fff;
  aspect-ratio: 1/1;
  width: 100%;
  overflow: hidden;
  & img {
    width: 100%;
  }
`;

export const SubCategoryCardTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  text-align: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-height: 36px;
  overflow: hidden !important;
`;
