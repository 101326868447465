import styled from "styled-components";

export const NewsSingleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  & .news-img {
    margin-bottom: 30px;
    max-width: 100%;
    max-height: ${({ vHeight }) => `${vHeight}px`};
    width: auto;
    height: auto;
    object-fit: contain;
  }
  & .MuiSkeleton-root {
    transform: scale(1, 1);
    &.img {
      margin: 0 auto;
      width: 100%;
      height: ${({ vHeight }) => `${vHeight * 0.7}px`};
    }
    &.title {
      width: 200px;
      height: 24px;
      margin: 30px 0;
    }
    &.text {
      width: 100%;
      height: 18px;
      margin: 5px 0;
    }
    &.half {
      width: 40%;
    }
  }
`;

export const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const ArticleHeading = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;

export const ArticleParagraph = styled.div`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  gap: 20px;
  line-height: 18px;
  color: rgb(0, 0, 0);
  word-break: break-word;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  float: left;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
`;

export const Date = styled(ArticleParagraph)`
  margin-left: auto;
`;
