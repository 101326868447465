import React, { useState } from "react";
import {
  Actions,
  BuyButton,
  Divider,
  Field,
  FieldLabel,
  FieldsWrapper,
  FieldWrapper,
  Heading,
  OfferContainer,
  OffersContainer,
  PriceContainer,
  PriceQuantityWrapper,
  ProductContainer,
  ProductDescription,
  ProductWrapper,
} from "./styled";
import { useTranslation } from "react-i18next";
import { useGetData, useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import EmptyList from "../../../components/empty-list";
import OfferSkeleton from "./OfferSkeleton";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useSelector } from "react-redux";
import ErrorDialog from "../../../components/dialogs/ErrorDialog";
import { useNavigate } from "react-router";
import CustomPagination from "../../../components/pagination/CustomPagination";

const Offers = () => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang[0].val);
  const token = useLocalStorageGetItem("token");
  const [currentPage, setCurrentPage] = useState(
    +window.location.hash.slice(1) || 1
  );
  const [declineErr, setDeclineErr] = useState(false);
  const [orderErr, setOrderErr] = useState(false);
  const [reload, setReload] = useState(false);
  const {
    data: offers,
    isLoading,
    meta,
  } = useGetData(
    `products/price-requests?perPage=5&page=${currentPage}`,
    [reload, currentPage],
    token,
    token
  );

  const headers = {
    "Accept-Language": lang,
    Authorization: `Bearer ${token}`,
  };

  const navigate = useNavigate();

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    window.location.hash = page;
  };

  const handleCloseDeclineErrorDialog = () => setDeclineErr(false);
  const handleCloseOrderErrorDialog = () => setOrderErr(false);

  const handleDecline = (id) => {
    axios
      .put(`${baseUrl}/products/price-requests/${id}/decline`, {}, { headers })
      .then(() => setReload((r) => !r))
      .catch(() => setDeclineErr(true));
  };

  const createOrder = (reqData) => {
    const ip = localStorage.getItem('ip')
    axios
      .post(`${baseUrl}/orders${ip ?`?ip=${ip}`:''}`, reqData, { headers })
      .then((res) => res.data.data)
      .then((data) => {
        navigate(`/checkout/${data.id}`);
      })
      .catch(() => setOrderErr(true));
  };

  const handleBuy = async (offer) => {
    const data = {
      items: [{ id: offer.product.id, count: +offer.requestCount }],
    };
    if (offer.counterRequestPrice && !offer.isApproved) {
      axios
        .put(
          `${baseUrl}/products/price-requests/${offer.id}/approve`,
          {},
          { headers }
        )
        .then(() => {
          createOrder(data);
        })
        .catch(() => setDeclineErr(true));
    } else {
      createOrder(data);
    }
  };

  return (
    <OffersContainer>
      {isLoading ? (
        <OfferSkeleton />
      ) : !offers?.length ? (
        <EmptyList />
      ) : (
        offers?.map((offer) => (
          <React.Fragment key={offer.id}>
            <Divider />
            <OfferContainer>
              <ProductContainer>
                <Heading className="left">{t("texts.product")}</Heading>
                <ProductWrapper>
                  <img
                    src={offer?.product?.images?.main?.src}
                    alt="img"
                    loading="lazy"
                  />
                  <ProductDescription>
                    <p className="description">{offer?.product?.name}</p>
                    <p className="price">{offer?.product?.price}</p>
                  </ProductDescription>
                </ProductWrapper>
              </ProductContainer>
              <PriceContainer>
                <Heading className="right">
                  <p>{t("texts.my_offered_price")}</p>
                  <p className="status">
                    {t("texts.status")}:{" "}
                    <b>
                      {!offer.isApproved &&
                      !offer.isDeclined &&
                      !offer?.counterRequestPrice
                        ? t("texts.pending_status")
                        : offer.isDeclined
                        ? t("texts.offer_rejected")
                        : offer?.counterRequestPrice && !offer?.isApproved
                        ? t("texts.counter_offer")
                        : offer?.isApproved
                        ? t("texts.offer_accepted")
                        : ""}
                    </b>
                  </p>
                </Heading>
                <FieldsWrapper>
                  <PriceQuantityWrapper>
                    <FieldWrapper>
                      <FieldLabel>{t("texts.price")}</FieldLabel>
                      <Field>{offer?.requestPrice}</Field>
                    </FieldWrapper>
                    <FieldWrapper>
                      <FieldLabel>{t("texts.amount")}</FieldLabel>
                      <Field>{offer?.requestCount}</Field>
                    </FieldWrapper>
                  </PriceQuantityWrapper>
                  {offer?.message && (
                    <FieldWrapper className="pd">
                      <FieldLabel>{t("texts.comment")}</FieldLabel>
                      <Field className="comment">{offer?.message}</Field>
                    </FieldWrapper>
                  )}
                  {(offer?.counterRequestCount ||
                    offer?.counterRequestPrice ||
                    offer?.counterMessage) && (
                    <>
                      <Heading className="right">
                        <p>{t("texts.shinmag_offered_price")}</p>
                      </Heading>
                      <PriceQuantityWrapper>
                        {offer?.counterRequestPrice && (
                          <FieldWrapper>
                            <FieldLabel>{t("texts.price")}</FieldLabel>
                            <Field>{offer?.counterRequestPrice}</Field>
                          </FieldWrapper>
                        )}
                        {offer?.counterRequestCount && (
                          <FieldWrapper>
                            <FieldLabel>{t("texts.amount")}</FieldLabel>
                            <Field>{offer?.counterRequestCount}</Field>
                          </FieldWrapper>
                        )}
                      </PriceQuantityWrapper>
                      {offer?.counterMessage && (
                        <FieldWrapper className="pd">
                          <FieldLabel>{t("texts.comment")}</FieldLabel>
                          <Field className="comment">
                            {offer?.counterMessage}
                          </Field>
                        </FieldWrapper>
                      )}
                    </>
                  )}
                </FieldsWrapper>
              </PriceContainer>
            </OfferContainer>
            {!offer?.isDeclined && (
              <Actions>
                <BuyButton
                  disabled={
                    (offer?.isApproved || offer?.counterRequestPrice) &&
                    !offer?.isDeclined
                      ? false
                      : true
                  }
                  onClick={() => handleBuy(offer)}
                >
                  {t("actions.buy")}
                </BuyButton>
                {offer?.counterRequestPrice && !offer.isApproved && (
                  <BuyButton
                    className="outlined"
                    onClick={() => handleDecline(offer.id)}
                  >
                    {t("actions.decline")}
                  </BuyButton>
                )}
              </Actions>
            )}
          </React.Fragment>
        ))
      )}
      {!isLoading && meta?.last_page > 1 && (
        <CustomPagination
          page={+currentPage}
          pageCount={meta?.last_page}
          handlePageChange={handlePageChange}
        />
      )}
      {declineErr && (
        <ErrorDialog
          open={declineErr}
          handleClose={handleCloseDeclineErrorDialog}
          message={t("texts.unexpected_error")}
        />
      )}
      {orderErr && (
        <ErrorDialog
          open={orderErr}
          handleClose={handleCloseOrderErrorDialog}
          message={t("texts.unexpected_error")}
        />
      )}
    </OffersContainer>
  );
};

export default Offers;
