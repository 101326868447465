const service = "/info/services";
const company = "/info/company";
const help = "/info/help";

export const generalInfo = [
  {
    id: 1,
    name: "company",
    items: [
      {
        id: 1,
        name: "about_us",
        path: `${company}/about`,
      },
      {
        id: 2,
        name: "contacts",
        path: `${company}/contacts`,
      },
      {
        id: 3,
        name: "guarantee",
        path: `${company}/guarantee`,
      },
      {
        id: 4,
        name: "gift_cards",
        path: `${company}/gift-cards`,
      },
      {
        id: 5,
        name: "loyal_costumer",
        path: `${company}/bonuses`,
      },
    ],
  },
  {
    id: 2,
    name: "services",
    items: [


      {
        id: 3,
        name: "delivery_and_dumping",
        path: `/delivery-and-dumping/standard`,
      },
      // {
      //   id: 4,
      //   name: "credit",
      //   path: `${service}/credit`,
      // },
      {
        id: 4,
        name: "for_business",
        path: `/for-business`,
      },
    ],
  },
  {
    id: 3,
    name: "help",
    items: [
      {
        id: 1,
        name: "terms",
        path: `${help}/terms`,
      },
      {
        id: 2,
        name: "privacy_policy",
        path: `${help}/privacy-policy`,
      },
      {
        id: 1,
        name: "exchange",
        path: `${help}/exchange`,
      },
      {
        id: 2,
        name: "refund",
        path: `${help}/refund`,
      },
    ],
  },
];
