import React from "react";
import { Label, Input, InputWrapper } from "./styled";
import CustomInput from "./CustomInput";
import { PatternFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { ErrorText } from "../../shared/styled";

const PayerInfo = ({ customerType, info, handleChange, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <CustomInput
          labelClassName="sm-margin"
          inputClassName={`info ${errors?.fullName && "error"}`}
          labelName={t("texts.name")}
          id="customer-name"
          inputKey="fullName"
          value={info?.name}
          setValue={info?.setName}
          handleChange={handleChange}
        />
        {errors?.fullName && <ErrorText>{errors?.fullName?.[0]}</ErrorText>}
      </div>
      <div>
        <CustomInput
          labelClassName="sm-margin"
          inputClassName={`info ${errors?.fullName && "error"}`}
          labelName={t("texts.lastname")}
          id="customer-lastname"
          inputKey="lastname"
          value={info?.lastName}
          setValue={info?.setLastName}
          handleChange={handleChange}
        />
        {errors?.fullName && <ErrorText>{errors?.fullName?.[0]}</ErrorText>}
      </div>
      <div>
        <CustomInput
          labelClassName="sm-margin"
          inputClassName={`info ${errors?.email && "error"}`}
          labelName={t("texts.email")}
          id="customer-email"
          inputKey="email"
          value={info?.email}
          setValue={info?.setEmail}
          handleChange={handleChange}
        />
        {errors?.email && <ErrorText>{errors?.email?.[0]}</ErrorText>}
      </div>
      <div>
        <Label className="sm-margin" htmlFor="customer-phone">
          {t("texts.phone")}
        </Label>
        <InputWrapper className="phone">
          <PatternFormat
            id="customer-phone"
            className={`info phone ${errors?.phone && "error"}`}
            customInput={Input}
            type="tell"
            placeholder="12 34 56 78"
            format="## ## ## ##"
            mask="_"
            value={info.phone}
            onChange={(e) =>
              handleChange(e.target.value, info?.setPhone, "phone")
            }
          />
        </InputWrapper>
        {errors?.phone && <ErrorText>{errors?.phone?.[0]}</ErrorText>}
      </div>
      {customerType?.name === t("texts.juridical_person") && (
        <div>
          <CustomInput
            labelClassName="sm-margin"
            inputClassName={`info ${errors?.tin && "error"}`}
            labelName={t("texts.tin")}
            id="customer-type"
            inputKey="tin"
            value={info?.tin}
            setValue={info?.setTin}
            handleChange={handleChange}
          />
          {errors?.tin && <ErrorText>{errors?.tin?.[0]}</ErrorText>}
        </div>
      )}
    </>
  );
};

export default PayerInfo;
