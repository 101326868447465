import styled, { css } from "styled-components";
import expandIcon from "../../assets/expandIconMenu.svg";
import { searchScrollbar } from "../../shared/styled";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 112px;
  left: 20px;
  bottom: 20px;
  padding-top: 0;
  width: calc(100% - 40px);
  height: ${({ vHeight }) => `${vHeight - 122}px`};
  display: flex;

  @media (max-width: 1024px) {
    top: 92px;
    left: 10px;
    width: calc(100% - 20px);
    height: ${({ vHeight }) => `${vHeight - 92}px`};
  }
  @media (max-width: 768px) {
    inset: 0;
    width: 100%;
    height: ${({ vHeight }) => `${vHeight}px`};
    flex-direction: column;
  }
`;

export const MenuHeader = styled.div`
  padding: 20px 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  ${alignCenter}
  justify-content: space-between;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const IconButton = styled.button`
  border: 0;
  background-color: inherit;
  ${alignCenter}
  gap:7px;
  & span {
    display: inline-block;
    font-size: 10px;
    line-height: 11px;
    color: #373e4b;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const MainMenu = styled.ul`
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 200;
  max-width: 361px;
  width: 100%;
  height: fit-content;
  max-height: ${({ vHeight }) => `${vHeight - 122}px`};
  overflow-y: auto;
  @media (max-width: 1025px) {
    max-height: ${({ vHeight }) => `${vHeight - 92}px`};
  }
  @media (max-width: 768px) {
    height: 100%;
    max-height: ${({ vHeight }) => `${vHeight}px`};
    max-width: 100%;
    &.hidden {
      overflow-y: hidden;
    }
  }
  ${searchScrollbar}
  & .desc-expanded {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const MenuItem = styled.li`
  list-style-type: none;
  width: 100%;
  padding: 15px;
  ${alignCenter}
  gap: 15px;
  cursor: pointer;
  ${typo};
  & a {
    ${typo};
    text-decoration: none;
  }
  @media (hover: hover) {
    &:hover {
      background-color: #ffe500;
      & .icon {
        border: 1px solid #373e4b;
      }
    }
  }
  & .icon {
    min-width: 35px;
    height: 35px;
    & img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  & .name {
    width: 100%;
    word-wrap: wrap;
    &::first-letter {
      text-transform: capitalize;
    }
    &.main {
      ${alignCenter}
      justify-content: space-between;
      gap: 5px;
      &::after {
        content: url(${expandIcon});
      }
    }
  }
  &.expanded .name::after {
    transform: rotate(-90deg);
  }
  @media (max-width: 768px) {
    padding: 15px 10px;
    & .name::after {
      transform: rotate(-90deg);
    }
  }
`;

export const MenuFooter = styled.div`
  @media (min-width: 1800px) {
    display: none;
  }
  margin-top: 10px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 10px;
`;

export const AllServices = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const SubMenuMobileWrapper = styled.div`
  position: absolute;
  z-index: 210;
  top: 60px;
  width: 100%;
  height: ${({ vHeight }) => `${vHeight - 60}px`};
  overflow-y: auto;
  ${searchScrollbar}
  background-color: #fff;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const BackButton = styled.button`
  ${typo}
  font-weight: 700;
  background-color: #fff;
  border: 0;
  text-transform: uppercase;
  &::before {
    content: url(${expandIcon});
    display: inline-block;
    transform: rotate(90deg);
    width: 15px;
    margin-right: 10px;
  }
`;

export const SubMenuActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  & a {
    ${typo}
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const SubMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  & a {
    ${typo}
    font-size: 18px;
    text-decoration: none;
    padding: 5px;
    &.bold {
      font-weight: 700;
    }
  }
`;
