import React from "react";
import { Skeleton } from "@mui/material";
import { CardContainer } from "./styled";

const SkeletonCard = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Skeleton
        style={{ transform: "scale(1)", width: "100%", height: "200px" }}
      />
      <Skeleton style={{ transform: "scale(1)", width: "70%" }} />
      <Skeleton style={{ transform: "scale(1)", marginBottom: "30px" }} />
    </div>
  );
};

export default SkeletonCard;
