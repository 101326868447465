import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const ServiceLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  ${alignCenter};
  gap: 15px;
  & h1 {
    font-weight: 400;
    font-size: 12px;
    color: #373e4b;
    white-space: nowrap;
  }
  @media (max-width: 1800px) {
    flex-direction: row-reverse;
  }
`;

export const IconWrapper = styled.div`
  ${alignCenter};
  justify-content: center;
  width: ${props => props.small ? '20px' : '30px'};
  height: ${props => props.small ? '20px' : '30px'};
  background-color: #ffe500;
  border-radius: 5px;
  transform: rotate(45deg);
  & img {
    transform: rotate(-45deg);
    width: 15px;
    height: 15px;
  }
  & .MuiSvgIcon-root {
    transform: rotate(-45deg);
    width: 18px;
    height: 18px;
    & path {
      fill: #373e4b;
    }
  }
  /* @media (max-width: 1440px) {
    width: 30px;
    height: 30px;
    & img {
      width: 15px;
      height: 15px;
    }
  } */
`;

export const IconWrapperSvg = styled.div`
  ${alignCenter}
  justify-content: center;
  max-width: 37.65px;
  max-height: 37.65px;
`;
