import React, {useEffect, useRef, useState} from "react";
import {
  CardContainer,
  ImageContainer,
  DescriptionContainer,
  PriceContainer,
  CounterAddButtonContainer,
  CustomLink,
  IconButton, Badges as Container,
} from "./styled";
import { ProductCardButton } from "../shared/button/Button";
import Counter from "../counter-basket/CounterBasket";
import cartIcon from "../../assets/cartIcon.svg";
import favouriteIcon from "../../assets/favouriteIcon.svg";
import favouriteIconActive from "../../assets/favouriteIconActive.svg";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeCartLength } from "../../features/cart/cartSlice";
import axios from "axios";
import { baseUrl } from "../../constants";
import ErrorDialog from "../dialogs/ErrorDialog";
import { favsAdded } from "../../features/favs/favsSlice";
import Badges from "./Badges";

const ProductCard = ({ product, slider, handleError }) => {
  const unit = product?.unit;
  const step = +product?.unit?.step || 1;
  const [count, setCount] = useState(step);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const lang = useSelector((state) => state.lang[0]);
  const favs = useSelector((state) => state?.favs);
  const fav = favs?.find((item) => item?.id === product?.id);
  const token = useLocalStorageGetItem("token");
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(()=>{
    ref.current && (ref.current.innerHTML = product?.description)
  },[ref?.current, t, product])

  const handleFavouriteClick = () => {
    if (!fav) {
      axios
        .post(
          `${baseUrl}/products/favorites`,
          {
            products: [product?.id],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res?.data?.data?.favorites)
        .then((data) => {
          dispatch(favsAdded(data));
        });
    } else {
      axios
        .delete(`${baseUrl}/products/favorites/${product?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch(favsAdded(favs.filter((f) => f.id !== fav.id)))
        );
    }
  };

  const fixNum = (init) => {
    return Number.isInteger(init)
      ? parseFloat(init).toFixed(0)
      : parseFloat(init).toFixed(2);
  };

  const fixCount = () => {
    return parseFloat((Math.ceil(count / step) * step).toFixed(2));
  };

  const handleChange = (e) => {
    setCount(e.target.value);
  };

  const handleBlur = () => {
    if (!count) {
      setCount(step);
    } else if(product?.unit?.type === "int"){
      const num = fixCount();
      setCount(num);
    }
  };

  const handleCountChangeByBtn = (type) => {
    let init = type === "dec" ? +count - step : +count + step;
    const num = fixNum(init);
    setCount(num);
  };

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const ip = localStorage.getItem('ip');
    if (!ip && !token){
      const newIp = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))
      localStorage.setItem("ip", newIp)
    }
    const fixedCount = fixCount();
    const data = { id: product?.id, count: +count };
    let headers = {
      "Accept-Language": lang.val,
    };
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    axios
      .post(`${baseUrl}/carts/add${ip ?`?ip=${ip}`:''}`, data, { headers })
      .then(() => {
        setIsSucceeded(true);
        dispatch(changeCartLength());
        setTimeout(() => {
          setIsSucceeded(false);
          setCount(step);
        }, 3000);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ===
          "validation.cart.count.available.false"
            ? t("texts.unavailable_count")
            : t("texts.unexpected_error");
        if (handleError) {
          handleError(message);
        } else {
          setError(true);
          setErrorMessage(message);
        }
        setCount(step);
      });
  };

  return (
    <>
      <CardContainer className={slider && "slider"}>
        <Badges product={product} />
        {token && (
          <IconButton onClick={handleFavouriteClick}>
            <img src={fav ? favouriteIconActive : favouriteIcon} alt="fav" />
          </IconButton>
        )}
        <CustomLink to={`/products/${product?.id}`}>
          <ImageContainer>
            <img
              src={product?.images?.main?.src}
              alt="product"
              loading="lazy"
            />
          </ImageContainer>
        </CustomLink>
        <DescriptionContainer className={slider ? "in-slide" : ""}>
          <p className="bold">{product?.name || "product name"}</p>
          {!slider && <p ref={ref} className="desc">{product?.description}</p>}
        </DescriptionContainer>
        <PriceContainer>
          <span className={product?.discountedPrice?.[0] !== "0" ? "old" : ""}>
            {product?.price} / {product?.unit?.name}
          </span>
          {product?.discountedPrice?.[0] !== "0" && (
            <span className="discounted">{product?.discountedPrice}</span>
          )}
        </PriceContainer>
        <CounterAddButtonContainer>
          <Counter
            className="sm"
            count={count}
            handleChange={handleChange}
            handleBlur={handleBlur}
            unit={unit}
            step={step}
            handleCountChange={handleCountChangeByBtn}
          />
          <ProductCardButton
            buttonText={t("actions.add")}
            icon={cartIcon}
            slider={slider}
            handleClick={handleAddToCart}
            isSucceeded={isSucceeded}
          />
        </CounterAddButtonContainer>
      </CardContainer>
      {!slider && error && (
        <ErrorDialog
          slider={slider}
          open={error}
          handleClose={() => {
            setError(false);
          }}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default ProductCard;
