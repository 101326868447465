import React, { useReducer } from "react";
import {
  MenuWrapper,
  MainMenu,
  MenuItem,
  MenuHeader,
  IconButton,
  MenuFooter,
  Section,
  AllServices,
} from "./styled";
import closeIcon from "../../assets/closeIcon.svg";
import loginIcon from "../../assets/loginIcon.svg";
import SubMenu from "../sub-menu/SubMenu";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Divider } from "../../shared/styled";
import MainServices from "../main-services/MainServices";
import ServicesInfo from "./ServicesInfo";
import SubMenuMobile from "./SubMenuMobile";
import { makeAuthVisible } from "../../features/show-auth/showAuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { useNavigate } from "react-router";
import { IconWrapper } from "../main-services/styled";

const expandReducer = (state, action) => {
  const pl = action.payload;
  switch (action.type) {
    case "expandItem":
      return { expanded: true, category: pl };
    case "hoverItem": {
      return { expanded: false, category: pl };
    }
    case "unset":
      return { expanded: false, category: null };
    default:
      return state;
  }
};

const Menu = ({ handleClose }) => {
  const { t } = useTranslation();
  const categories = useSelector((state) => state.categories);
  const vHeight = useSelector((state) => state.appHeight.val);
  const user = useSelector((state) => state?.user);
  const token = useLocalStorageGetItem("token");
  const navigate = useNavigate();

  const [expand, handleDispatch] = useReducer(expandReducer, {
    expanded: false,
    category: null,
  });

  const dispatch = useDispatch();

  const handleExpand = (e, category) => {
    e.stopPropagation();
    expand?.category?.id === category.id && expand.expanded
      ? handleDispatch({ type: "unset" })
      : handleDispatch({ type: "expandItem", payload: category });
  };

  const handleMouseOver = (category) => {
    !expand.expanded &&
      expand?.category?.id !== category.id &&
      handleDispatch({ type: "hoverItem", payload: category });
  };

  const handleMouseLeave = (category) => {
    !expand.expanded &&
      expand?.category?.id === category.id &&
      handleDispatch({ type: "unset" });
  };

  const unsetExpand = (e) => {
    e.stopPropagation();
    handleDispatch({ type: "unset" });
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    handleDispatch({ type: "unset" });
    handleClose();
  };

  const handleOpenAuth = (e) => {
    handleCloseMenu(e);
    dispatch(makeAuthVisible());
  };

  const handleNavigateToPersonalPage = (e) => {
    navigate("/personal-page/personal_data");
    handleCloseMenu(e);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuWrapper onClick={handleClose} vHeight={vHeight}>
          <MainMenu
            className={`main-menu ${expand?.category?.id && "hidden"}`}
            onClick={(e) => e.stopPropagation()}
            vHeight={vHeight}
          >
            <MenuHeader>
              <IconButton onClick={handleCloseMenu}>
                <img src={closeIcon} alt="close" width="20" height="20" />
              </IconButton>
              {!token ? (
                <IconButton onClick={handleOpenAuth}>
                  <span>{t("texts.login")}</span>
                  <img src={loginIcon} alt="login" width="16" height="20" />
                </IconButton>
              ) : (
                <IconButton onClick={handleNavigateToPersonalPage}>
                  <span>{user?.firstame}</span>
                  <img src={loginIcon} alt="login" width="16" height="20" />
                </IconButton>
              )}
            </MenuHeader>
            {categories.map((category) => (
              <MenuItem
                key={category}
                className={`${
                  expand?.category?.id === category?.id && expand.expanded
                    ? "expanded"
                    : ""
                } ${expand?.category?.id ? "desc-expanded" : ""}`}
                onClick={(e) => handleExpand(e, category)}
                onMouseOver={() => {
                  if (window.innerWidth < 769) {
                    return;
                  }
                  handleMouseOver(category);
                }}
                onMouseLeave={(e) => {
                  const className = e.relatedTarget.className;
                  if (
                    window.innerWidth < 769 ||
                    className?.includes("main-menu") ||
                    className?.includes("sub-menu")
                  ) {
                    return;
                  }
                  handleMouseLeave(category);
                }}
              >
                <IconWrapper className="icon">
                  <img src={category?.image} alt="" />
                </IconWrapper>
                <p className="name main">{category?.name}</p>
              </MenuItem>
            ))}
            <MenuFooter className={expand?.category?.id ? "desc-expanded" : ""}>
              <Divider />
              <Section>
                <MainServices menu={true} handleCloseMenu={handleCloseMenu} />
              </Section>
              <AllServices>
                <Divider />
                <Section>
                  <ServicesInfo handleCloseMenu={handleCloseMenu} />
                </Section>
              </AllServices>
            </MenuFooter>
          </MainMenu>
          {expand?.category?.id && (
            <SubMenu
              handleMouseLeave={handleMouseLeave}
              category={expand.category}
            />
          )}
          {expand?.category?.id && (
            <SubMenuMobile
              unsetExpand={unsetExpand}
              category={expand.category}
              handleClose={handleCloseMenu}
            />
          )}
        </MenuWrapper>
      </ClickAwayListener>
    </>
  );
};

export default Menu;
