import styled from "styled-components";
import backgroundImage from "../../assets/subscriptionBackground.jpg";

export const SubscribeFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 335px;
  padding: 70px 0;
  margin-bottom: 60px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  & .color-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #373e4bb3;
  }
  & .title {
    max-width: 90%;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 37px;
    color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
  }
  & .sub-title {
    max-width: 90%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 40px;
    position: relative;
    z-index: 10;
  }
  @media (max-width: 768px) {
    min-height: 225px;
    padding: 50px 0;
    margin-bottom: 50px;
    & .title {
      font-size: 1.125rem;
      line-height: 21px;
      margin-bottom: 10px;
    }
    & .sub-title {
      font-size: 0.875rem;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }
`;

export const InputContainer = styled.form`
  display: flex;
  align-items: center;
  min-width: 1024px;
  position: relative;
  z-index: 10;
  background: #ffffff;
  border: 1px solid #ffe500;
  border-radius: 5px;

  & .subscribe-input {
    width: 82%;
    padding: 30px 15px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 21px;
    color: #373e4b;
    border: none;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #abb1bd;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 21px;
    }
  }

  & .subscribe-submit {
    padding: 33px 50px;
    width: 18%;
    height: 100%;
    background-color: #ffe500;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    & .button-text {
      font-weight: 700;
      font-size: 1rem;
      line-height: 18px;
      color: #373e4b;
      text-transform: uppercase;
    }

    & .button-icon {
      display: none;
    }

    & .error {
      border: 1px solid #e42829;
    }
  }

  & .message {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    color: #fff;
    font-weight: 700;
  }

  & .error-text {
    display: flex;
    align-items: center;
    gap: 5px;

    &::before {
      content: "!";
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e42829;
      border-radius: 50%;
      position: relative;
    }
  }

  @media (max-width: 1440px) {
    min-width: 784px;
    & .subscribe-input {
      padding: 30px 11px;
    }

    & .subscribe-submit {
      padding: 33px 28px;
    }
  }
  @media (max-width: 992px) {
    min-width: 550px;
    & .subscribe-input {
      width: 90%;
      padding: 16px 10px;

      &::placeholder {
        font-size: 0.75rem;
        line-height: 14px;
      }
    }

    & .subscribe-submit {
      width: 10%;
      padding: 16px;

      & .button-text {
        display: none;
      }

      & .button-icon {
        display: inline;
        text-align: center;
        max-height: 20px;
      }
    }
  }
  @media (max-width: 688px) {
    min-width: 95%;
    & .subscribe-input {
      width: 95%;
      padding: 16px 10px;
    }

    & .subscribe-submit {
      width: 10%;
      padding: 16px;
      min-width: 58px;

      & .button-text {
        display: none;
      }

      & .button-icon {
        display: inline;
        text-align: center;
        max-height: 20px;
      }
    }
  }
`;
