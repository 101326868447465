import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PageHeading} from "../cart/styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import {LowerSection, Nav, NavItem, UpperSection} from "./styled";
import {Outlet} from "react-router";
import DeliveryDetails from "../../components/delivery/DeliveryDetails";
import UpDetails from "../../components/up/UpDetails";
import {FiltersContainer} from "../../components/product-filters/styled";
import DeliveryContent from "../company/delivery";
import Dumping from "../company/dumping";
import {FilterButton} from "../../components/shared/button/styled";

const Delivery = () => {
    const {t} = useTranslation();
    const nav = [
        {to: "/delivery-and-dumping"},
    ];
    const terms = [t("texts.terms_of_service"), t("texts.terms_of_dumping")];
    const [active, setActive] = useState(terms[0]);

    return (
        <>
            <Breadcrumbs nav={nav} className="show-all"/>
            <PageHeading>{t("texts.delivery_and_dumping")}</PageHeading>
            <UpperSection>
                <DeliveryDetails/>
                <Outlet/>
                <UpDetails/>
            </UpperSection>
            <LowerSection>
                <FiltersContainer className="filters">
                    {terms.map((term, i) => (
                        <FilterButton
                            onClick={() => setActive(term)}
                            key={i}
                            className={term === active ? "active" : ""}
                        >
                            {term}
                        </FilterButton>
                    ))}
                </FiltersContainer>
                {active === terms[0] ? <DeliveryContent /> : <Dumping />}
            </LowerSection>
        </>
    );
};

export default Delivery;
