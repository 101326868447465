import React, { useState, useEffect } from "react";
import {
  FullName,
  PersonalPageContainer,
  UserDetailsContainer,
  UserInfoContainer,
} from "./styled";
import AdditionalFilesForm from "./AdditionalFilesForm";
import { FilterButton } from "../../components/shared/button/styled";
import { FiltersContainer } from "./styled";
import copyIcon from "../../assets/copy-icon.svg";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ActivateBonuses from "./popups/ActivateBonuses";
import { useSelector } from "react-redux";
import { useLocalStorageGetItem, useGetData } from "../../utils/hooks/hooks";
import { useNavigate } from "react-router-dom";
import OrderTextSkeleton from "./orders/skeletons/OrderTextSkeleton";

const PersonalPage = () => {
  const [openActivateBonusDialog, setOpenActivateBonusDialog] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const token = useLocalStorageGetItem("token");
  const [reload, setReload] = useState(false);
  console.log('userInfo', userInfo);
  const { data: partner, isLoading: isPartnerLoading } = useGetData(
    `partners/users`,
    [userInfo, reload],
    !!userInfo?.isPartner,
    token
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const links = [
    { to: "personal-data", name: "personal_data" },
    { to: "addresses/1", name: "my_addresses" },
    { to: "orders/1", name: "orders", active: "order" },
    { to: "favorites", name: "favourites" },
    { to: "price-offers", name: "my_offered_prices" },
    { to: "gift-cards", name: "gift_cards" },
    // { to: "points", name: "points" },
  ];
  const { pathname } = useLocation();

  const nav = [
    { to: "/personal-page/personal-data", name: t("texts.personal_page") },
  ];

  useEffect(() => {
    !token && navigate("/");
  }, []);

  const toggleReload = () => setReload((r) => !r);

  return (
    <PersonalPageContainer>
      <Breadcrumbs nav={nav} />
      <UserInfoContainer>
        <FullName>
          <p className="name">{userInfo?.fullName}</p>
        </FullName>
        {!!userInfo?.isPartner &&
          (isPartnerLoading ? (
            <OrderTextSkeleton fl={true} />
          ) : (
            <UserDetailsContainer>
              <p className="level">{`${t("texts.status")}: ${
                partner?.status
              }`}</p>
              <p className="level">{`${t("texts.shinmag_club")}: ${t(
                "texts.level"
              )} ${partner?.level}`}</p>
              <p>{`${t("texts.profession")}: ${partner?.profession}`}</p>
              <p>{`${t("texts.field")}: ${partner?.sphere}`}</p>
              {!!partner?.company && (
                <p>{`${t("texts.company")}: ${partner?.company}`}</p>
              )}
              {!!partner?.tin && <p>{`${t("texts.tin")}: ${partner?.tin}`}</p>}
              {!!partner?.accumulatedPoints && (
                <p>{`${t("texts.accumulated_points")}: ${
                  partner?.accumulatedPoints
                }`}</p>
              )}
              {partner?.accumulatedPoints?.[0] !== "0" && (
                <button
                  onClick={() => setOpenActivateBonusDialog(true)}
                  className="activate"
                  type="button"
                >
                  {t("actions.cash")}
                </button>
              )}
            </UserDetailsContainer>
          ))}
      </UserInfoContainer>
      {partner?.statusCode === "certificateRequested" && (
        <AdditionalFilesForm toggleReload={toggleReload} />
      )}
      <FiltersContainer>
        {links.map((link) => (
          <FilterButton
            as={Link}
            to={`/personal-page/${link?.to}`}
            key={link?.to}
            className={`${
              pathname.includes(link.to) || pathname.includes(link?.active)
                ? "active"
                : ""
            } personal-data`}
          >
            {t(`texts.${link?.name}`)}
          </FilterButton>
        ))}
      </FiltersContainer>
      <Outlet />
      {openActivateBonusDialog && (
        <ActivateBonuses
          open={openActivateBonusDialog}
          point={partner?.accumulatedPoints}
          handleClose={() => setOpenActivateBonusDialog(false)}
        />
      )}
    </PersonalPageContainer>
  );
};

export default PersonalPage;
