import React, {Suspense, useEffect} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from "react-redux";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </HelmetProvider>
);
