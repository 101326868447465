import axios from "axios";
import { baseUrl } from "../../constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useLocalStorageGetItem = (name) => {
  return localStorage.getItem(name);
};

export const useGetData = (endpoint, dep = [], condition = true) => {
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  let headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`
  };
  const ip = localStorage.getItem("ip");

  // if (token) {
  //   headers = { ...headers, Authorization: `Bearer ${token}` };
  // }

  useEffect(() => {
    if (condition) {
      axios
        .get(`${baseUrl}/${endpoint}${(endpoint?.includes('?') && ip) ? `&ip=${ip}` : ip ? `?ip=${ip}` :''}`, {
          headers,
        })
        .then((res) => res.data)
        .then((data) => {
          setData(data?.data);
          setMeta(data?.meta);
        })
        .catch((err) => setError(err.response))
        .finally(() => setIsLoading(false));
    }
    setIsLoading(true);
  }, [...dep, lang]);
  return { data, error, isLoading, meta };
};
