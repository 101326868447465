export const socialMediaHeaderItems = [
  {
    id: 1,
    name: "Facebook",
    icon: "social-media/fb.svg",
    link: "https://www.facebook.com/shinmag.am/",
  },{
    id: 2,
    name: "Instagram",
    icon: "social-media/inst.svg",
    link: "https://www.instagram.com/shinmag.am/",
  },{
    id: 3,
    name: "Linkedin",
    icon: "social-media/linkedin.svg",
    link: null,
  },{
    id: 4,
    name: "Telegram",
    icon: "social-media/telegram.svg",
    link: null,
  },{
    id: 5,
    name: "Youtube",
    icon: "social-media/youtube.svg",
    link: "https://www.youtube.com/channel/UCGMCUgJQg-Vgigj-qGJ7ZRw",
  },
];
