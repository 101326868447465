import styled from "styled-components";

export const Checkbox = styled.div`
  padding: 8px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #373e4b;
  &.checkout {
    padding: 0;
    margin-top: -10px;
    &.mb {
      margin-bottom: 20px;
    }
  }
  & input {
    display: none;
  }
  & label {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    cursor: pointer;
    & .box {
      min-width: 16px;
      height: 16px;
      border: 1px solid #373e4b;
      position: relative;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: inherit;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  & input:checked ~ label .box::after {
    background-color: #373e4b;
  }
  & .first-letter-upp {
    display: inline-block;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &.colored {
    &.def {
      & .box::after {
        background-color: #373e4b;
      }
    }
    & label {
      & .box {
        border: 0;
        &::after {
          background-color: ${({ bckgColor }) =>
            bckgColor ? `${bckgColor} !important` : ""};
        }
      }
    }
    & input:checked ~ label .box {
      border: 1px solid #000;
    }
  }
`;
