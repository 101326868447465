import React from "react";
import DataContainer from "../DataContainer";
import { List, Title } from "../styled";
import { terms } from "./data";

const Terms = () => {
  return (
    <DataContainer as="div">
      <List>
        {terms?.data?.map((el) => (
          <li key={el.title} className="df">
            <Title as="div" className="title">
              {el.title}
            </Title>
            {el?.content}
            {el?.highlighted && (
              <span className="highlighted">{el?.highlighted}</span>
            )}
          </li>
        ))}
      </List>
      <>
        <Title>{terms?.requisites?.title}</Title>
        {terms?.requisites?.content?.map((re) => (
          <span key={re} className="bold">
            {re}
          </span>
        ))}
      </>
    </DataContainer>
  );
};

export default Terms;
