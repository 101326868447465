import React, { useState, useEffect } from "react";
import {
  Form,
  Label,
  FlexContainer,
  Input,
  InputWrapper,
  Textarea,
  Button,
  Actions,
  TextareaWrapper,
  ErrorMessage,
} from "./styled";
import CustomSelect from "../../components/custom-select/CustomSelect";
import CustomRadio from "../../components/custom-radio/CustomRadio";
import { PatternFormat } from "react-number-format";
import PasswordInput from "./PasswordInput";
import Files from "./Files";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../constants";
import { deleteKey, changeState } from "../../utils/funcs/formHandlings";
import { ErrorText } from "../../shared/styled";
import { useLocalStorageGetItem, useGetData } from "../../utils/hooks/hooks";
import checkIcon from "../../assets/checkIcon.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ErrorDialog from "../../components/dialogs/ErrorDialog";

const BecomeAMember = () => {
  const { t } = useTranslation();
  const memberTypes = [
    { id: 1, name: t("texts.physical_person") },
    { id: 2, name: t("texts.juridical_person") },
  ];
  const user = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang[0].val);
  const [profExpanded, setProfExpanded] = useState(false);
  const [profession, setProfession] = useState(null);
  const [memberType, setMemberType] = useState(memberTypes[0]);
  const [fieldExpanded, setFieldExpanded] = useState(false);
  const [field, setField] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tin, setTin] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [about, setAbout] = useState("");
  const [company, setCompany] = useState("");
  const [succeeded, setSucceeded] = useState(false);

  const token = useLocalStorageGetItem("token");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { data: profOptions } = useGetData("professions");
  const { data: fields } = useGetData("spheres");

  const handleUnset = () => {
    setProfession(null);
    setMemberType(memberTypes[0]);
    setField(null);
    setPassword("");
    setPasswordConfirmation("");
    setShowPassword(false);
    setShowPasswordConfirm(false);
    setFiles([]);
    setAbout("");
    setErrors({});
    setErrorMessage("");
    if (!token) {
      setName("");
      setTin("");
      setPhone("");
      setEmail("");
      setCompany("");
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.fullName) {
        let fulln = user?.fullName.split(" ");
        let n = fulln[0];
        let l = fulln.slice(1).join(" ");
        setName(n);
        setLastName(l);
      }
      user?.phone?.slice(4) && setPhone(user?.phone?.slice(4));
      user?.email && setEmail(user?.email);
    }
  }, [user]);

  const handleChange = (e, key, setValue) => {
      changeState(e.target.value, setValue);
      deleteKey(key, errors);
      setErrorMessage("");
  };

  const handleExpand = (setExpand) => {
    setExpand((prev) => !prev);
  };

  const makeFocused = (className, setExpand) => {
    document.querySelector(className).focus();
    handleExpand(setExpand);
  };

  const handleProfSelect = (option) => {
    setProfession(option);
    deleteKey("profession", errors);
    setErrorMessage("");
  };

  const handleFieldSelect = (option) => {
    setField(option);
    deleteKey("sphere", errors);
    setErrorMessage("");
  };

  const handleMemberTypeChange = (option) => {
    setMemberType(option);
  };

  const handleChangeFiles = (e) => {
    setFiles(Array.from(e.target.files));
    deleteKey("certificate", errors);
    setErrorMessage("");
  };

  const handleDeleteFile = (file) => {
    setFiles(files.filter((item) => item.name !== file.name));
  };

  const handleRequest = (data) => {
    const url = token ? "partners/users" : "partners";
    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Accept-Language": lang,
      Authorization: `Bearer ${token}`,
    };
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    axios
      .post(`${baseUrl}/${url}`, data, {
        headers,
      })
      .then((res) => {
        localStorage.setItem("token", res.data?.data?.token);
        setSucceeded(true);
        // setTimeout(() => {
        //   setSucceeded(false);
        //   handleUnset();
        //   navigate("/personal-page/personal-data");
        // }, 3000);
      })
      .catch((err) => {
        +err.response.status === 409
          ? setErrorMessage(err?.response?.data?.message)
          : setErrors(err?.response?.data?.errors);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      profession: profession?.id,
      sphere: field?.id,
      about,
    };
    console.log('phone', phone);
    if (!token) {
      data = {
        ...data,
        email,
        phone: `+374${phone?.split(" ").join("")}`,
        password,
        passwordConfirmation,
        fullName: `${name} ${lastName}`,
      };
    }
    if (memberType?.name === t("texts.juridical_person")) {
      data = {
        ...data,
        company,
        tin,
      };
    }
    const formData = new FormData();
    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }
    if (files.length) {
      for (let file of files) {
        formData.append("certificate", file, file.name);
      }
    }
    handleRequest(formData);
  };

  const handleCloseSuccessDialog = () => {
    setSucceeded(false);
    handleUnset();
    navigate("/personal-page/personal-data");
  };

  return (
    <>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Label
          as="p"
          className="bold"
          onClick={() => makeFocused(".profession", setProfExpanded)}
        >
          {t("texts.your_profession")}
        </Label>
        <CustomSelect
          className={`profession ${errors?.profession && "error"}`}
          errorText={errors?.profession?.[0] || ""}
          placeholder={t("texts.your_profession")}
          expanded={profExpanded}
          selectedOption={profession}
          options={profOptions}
          handleExpand={() => handleExpand(setProfExpanded)}
          handleSelect={handleProfSelect}
          onClickAway={() => setProfExpanded(false)}
        />
        <Label htmlFor="member-types" className="bold">
          {t("texts.form_of_activity")}
        </Label>
        <CustomRadio
          name="member-types"
          options={memberTypes}
          selectedOption={memberType}
          onChange={handleMemberTypeChange}
          shinmagClub={true}
        />
        <FlexContainer>
          {memberType?.id === memberTypes[1]?.id && (
            <>
              <div>
                <Label htmlFor="tin">{t("texts.tin")}</Label>
                <Input
                  id="tin"
                  className={`${errors?.tin && "error"}`}
                  placeholder={t("texts.tin")}
                  value={tin}
                  onChange={(e) => handleChange(e, "tin", setTin)}
                />
                {errors?.tin && <ErrorText>{errors?.tin?.[0]}</ErrorText>}
              </div>
              <div>
                <Label htmlFor="company">{t("texts.company")}</Label>
                <Input
                  id="company"
                  className={`${errors?.company && "error"}`}
                  placeholder={t("texts.company")}
                  value={company}
                  onChange={(e) => handleChange(e, "company", setCompany)}
                />
                {errors?.company && (
                  <ErrorText>{errors?.company?.[0]}</ErrorText>
                )}
              </div>
            </>
          )}
          <div>
            <Label htmlFor="member-name">{t("texts.name")}</Label>
            <Input
              id="member-name"
              placeholder={t("texts.name")}
              className={`${!token && errors?.fullName && "error"}`}
              readOnly={!!token}
              value={name}
              onChange={(e) => handleChange(e, "fullName", setName)}
            />
            {errors?.fullName && <ErrorText>{errors?.fullName?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="member-lastname">{t("texts.lastname")}</Label>
            <Input
              id="member-lastname"
              placeholder={t("texts.lastname")}
              className={`${!token && errors?.fullName && "error"}`}
              readOnly={!!token}
              value={lastName}
              onChange={(e) => handleChange(e, "fullName", setLastName)}
            />
            {errors?.fullName && <ErrorText>{errors?.fullName?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="member-phone">{t("texts.phone")}</Label>
            <InputWrapper className="phone">
              <PatternFormat
                customInput={Input}
                className={`phone ${errors?.phone && "error"}`}
                id="member-phone"
                readOnly={!!token}
                type="tell"
                placeholder="12 34 56 78"
                format="## ## ## ##"
                mask="_"
                value={phone}
                onChange={(e) => handleChange(e, "phone", setPhone)}
              />
            </InputWrapper>
            {errors?.phone && <ErrorText>{errors?.phone?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="member-email">{t("texts.email")}</Label>
            <Input
              type="email"
              placeholder="info@shinmag.am"
              readOnly={!!token}
              className={`${errors?.email && "error"}`}
              value={email}
              onChange={(e) => handleChange(e, "email", setEmail)}
            />
            {errors?.email && <ErrorText>{errors?.email?.[0]}</ErrorText>}
          </div>
          <div>
            <Label
              htmlFor="work-field"
              onClick={() => makeFocused(".work-field", setFieldExpanded)}
            >
              {t("texts.work_field")}
            </Label>
            <CustomSelect
              className={`work-field ${errors?.sphere && "error"}`}
              placeholder={t("texts.work_field")}
              expanded={fieldExpanded}
              selectedOption={field}
              options={fields}
              handleExpand={() => handleExpand(setFieldExpanded)}
              handleSelect={handleFieldSelect}
              onClickAway={() => setFieldExpanded(false)}
              errorText={errors?.sphere?.[0] || ""}
            />
          </div>
          {!token && (
            <>
              <div>
                <Label htmlFor="member-password">{t("texts.password")}</Label>
                <PasswordInput
                  id="member-password"
                  className={`${errors?.password && "error"}`}
                  show={showPassword}
                  handleShow={() => setShowPassword(!showPassword)}
                  value={password}
                  onChange={(e) => handleChange(e, "password", setPassword)}
                />
                {errors?.password && (
                  <ErrorText>{errors?.password?.[0]}</ErrorText>
                )}
              </div>
              <div>
                <Label htmlFor="member-password-confirm">
                  {t("texts.confirm_password")}
                </Label>
                <PasswordInput
                  id="member-password-confirm"
                  className={`${errors?.passwordConfirmation && "error"}`}
                  show={showPasswordConfirm}
                  handleShow={() =>
                    setShowPasswordConfirm(!showPasswordConfirm)
                  }
                  value={passwordConfirmation}
                  onChange={(e) =>
                    handleChange(
                      e,
                      "passwordConfirmation",
                      setPasswordConfirmation
                    )
                  }
                />
                {errors?.passwordConfirmation && (
                  <ErrorText>{errors?.passwordConfirmation?.[0]}</ErrorText>
                )}
              </div>
            </>
          )}
        </FlexContainer>
        <Label htmlFor="member-about" className="bold">
          {t("texts.about_you")}
        </Label>
        <TextareaWrapper>
          <Textarea
            id="member-about"
            className={`${errors?.about && "error"}`}
            value={about}
            onChange={(e) => handleChange(e, "about", setAbout)}
          />
          {errors?.about && <ErrorText>{errors?.about?.[0]}</ErrorText>}
        </TextareaWrapper>
        <Label htmlFor="tin-file" className="bold">
          {t("actions.upload_file")}{" "}
          {memberType?.id === memberTypes[1]?.id && (
            <span>{`(${t("texts.state_registry_certificate")})`}</span>
          )}
        </Label>
        <Files
          files={files}
          handleChange={handleChangeFiles}
          handleDelete={handleDeleteFile}
          error={errors?.certificate}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Actions>
          <Button className={`yellow ${succeeded && "success"}`}>
            <img src={checkIcon} alt="sent" width="18" />
            <span>{t("actions.send")}</span>
          </Button>
          <Button type="button" onClick={handleUnset}>
            {t("actions.cancel")}
          </Button>
        </Actions>
      </Form>
      {succeeded && (
        <ErrorDialog
          open={succeeded}
          success="success"
          message={t("texts.member_request_sent")}
          handleClose={handleCloseSuccessDialog}
        />
      )}
    </>
  );
};

export default BecomeAMember;
