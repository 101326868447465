import React from "react";
import {
  SubMenuMobileWrapper,
  SubMenuItem,
  BackButton,
  SubMenuActions,
} from "./styled";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EmptyList from "../empty-list";
import { useSelector } from "react-redux";

const SubMenuMobile = ({ unsetExpand, category, handleClose }) => {
  const { t } = useTranslation();
  const { subcategories } = category;
  const vHeight = useSelector((state) => state.appHeight.val);

  return (
    <SubMenuMobileWrapper vHeight={vHeight}>
      <SubMenuActions>
        <BackButton onClick={unsetExpand}>{t("actions.back")}</BackButton>
        <Link
          to={
            category?.subcategoriesCount
              ? `/subcategories/${category.id}`
              : `/sub-category/${category.id}/1`
          }
        >
          {t("actions.show_all")}
        </Link>
      </SubMenuActions>
      {subcategories?.length ? (
        subcategories?.map((subcategory) => (
          <SubMenuItem key={subcategory.id}>
            <Link
              to={
                subcategory?.subcategoriesCount
                  ? `/subcategories/${subcategory.id}`
                  : `/sub-category/${subcategory.id}/1`
              }
              onClick={handleClose}
              className="bold"
            >
              {subcategory.name}
            </Link>
            {!!subcategory?.subcategories?.length &&
              subcategory?.subcategories.map((sub) => (
                <Link
                  key={sub?.id}
                  to={
                    sub?.subcategoriesCount
                      ? `/subcategories/${sub.id}`
                      : `/sub-category/${sub.id}/1`
                  }
                >
                  {sub.name || "empty sub 2"}
                </Link>
              ))}
          </SubMenuItem>
        ))
      ) : (
        <EmptyList />
      )}
    </SubMenuMobileWrapper>
  );
};

export default SubMenuMobile;
