import styled from "styled-components";

export const List = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.div`
  position: relative;
  & img {
    width: 300px;
    display: block;
    margin: 0 auto;
  }
  & .count {
    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    font-weight: 700;
  }
  & .content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    background-color: #ffe500;
    bottom: 23px;
    min-width: 300px;
    min-height: 62px;
    border: 1px solid #373e4b;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: 1440px) {
    & img {
      width: 400px;
    }
    & .count {
      top: 65px;
    }
    & .content {
      min-width: 400px;
      min-height: 88px;
      bottom: 36px;
    }
  }
  @media (max-width: 1180px) {
    & img {
      width: 280px;
    }
    & .count {
      top: 42px;
    }
    & .content {
      bottom: 23px;
      min-width: 280px;
      min-height: 62px;
    }
  }
`;
