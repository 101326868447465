import styled, { css } from "styled-components";

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const leftSideWidth = css`
  width: 100%;
  max-width: 360px;
  @media (max-width: 1440px) {
    max-width: 300px;
  }
  @media (max-width: 1240px) {
    max-width: 270px;
  }
  @media (max-width: 1024px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const customBorder = css`
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;
  color: #373e4b;
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FiltersSidebar = styled.div`
  ${leftSideWidth}
  word-break: break-word;
  & .filters-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    & .MuiPaper-root {
      ${customBorder}
      box-shadow: none;
      & .MuiButtonBase-root {
        padding: 15px 10px;
        & .MuiAccordionSummary-content {
          margin: 0;
          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: #373e4b;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
    &.show {
      display: unset;
    }
  }
`;

export const ProductsCardGrid = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 1640px) {
    & .button-text {
      display: none;
    }
    & .button-icon {
      width: 20px;
    }
  }
  @media (max-width: 1110px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    gap: 5px;
  }
  @media (max-width: 440px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SelectWrapper = styled.div`
  min-width: 200px;
`;
