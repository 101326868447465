import styled, { css } from "styled-components";

const bigTypo = css`
  font-weight: 700;
  color: #373e4b;
  font-size: 59px;
  @media (max-width: 2000px) {
    font-size: 37px;
  }
  @media (max-width: 1440px) {
    font-size: 31px;
  }
  @media (max-width: 1110px) {
    font-size: 24px;
  }
`;

const smallTypo = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;
const mediumTypo = css`
  font-size: 32px;
  line-height: 28px;
  color: #373e4b;
  @media (max-width: 2000px) {
    font-size: 22px;
  }
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  @media (max-width: 1110px) {
    font-size: 18px;
  }
`;

const columnFlex = css`
  display: flex;
  flex-direction: column;
`;

const rowFlex = css`
  display: flex;
  flex-direction: row;
`;

const alignCenter = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.section`
  display: flex;
  gap: 113px;
  @media (max-width: 1250px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  padding: 15px;
  max-width: 50%;
  width: 100%;
  ${columnFlex};
  gap: 30px;
  @media (max-width: 1440px) {
    max-width: 60%;
    gap: 30px;
  }
  @media (max-width: 1250px) {
    max-width: 100%;
    gap: 20px;
  }
`;

export const ColumnFlex = styled.div`
  ${columnFlex};
  gap: 20px;
  @media (max-width: 1440px) {
    gap: 10px;
  }
  .bonusWrapper {
    position: relative;
    margin-bottom: 40px;
  }
  .hidden {
    display: none;
  }
  &.collapsed {
    visibility: collapse;
    display: none;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
  }
`;
export const RowFlex = styled.div`
  ${rowFlex};
  gap: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 1440px) {
    gap: 10px;
  }
  &.bonusWrapper {
    position: relative;
    margin-bottom: 40px;
  }
  &.collapsed {
    visibility: collapse;
    display: none;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
  }
  &.spaceBetween {
    justify-content: space-between;
    cursor: pointer;
  }
`;
export const DeliveryRowFlex = styled.div`
  ${rowFlex};
  gap: 15px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 1440px) {
    gap: 5px;
  }
  .bonusWrapper {
    position: relative;
    margin-bottom: 40px;
  }
`;

export const InfoBlock = styled.div`
  width: 100%;
  gap: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-transform: capitalize;
  width: 100%;
  box-shadow: 3px 0 19px 0 #00005340;
  padding: 25px;
  @media (max-width: 1440px) {
    gap: 10px;
    & img {
      height: 20px;
      width: 20px;
    }
  }

  .bonusWrapper {
    position: relative;
    margin-bottom: 40px;
  }
`;

export const HeadingText = styled.h5`
  font-size: 32px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  &.uppercase {
    text-transform: uppercase;
  }
  &.pointer {
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
  }
`;
export const DescriptionText = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  @media (max-width: 2000px) {
    font-size: 15px;
  }
`;

export const BigText = styled.p`
  ${bigTypo}
  &.uppercase {
    text-transform: uppercase;
  }
  & .old {
    text-decoration: line-through;
    text-decoration-color: #e42829;
    color: #737475;
    margin-right: 5px;
  }
`;

const ellipsisOverflow = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  //-webkit-line-clamp: 3;
  text-overflow: ellipsis;
  //max-height: 54px;
  //overflow: hidden;
`;

export const InfoText = styled.span`
    font-weight: 700 ;
`;

export const SmallText = styled.p`
  ${mediumTypo};
  &::first-letter {
    text-transform: capitalize;
  }
  text-decoration: none;
  &.bold {
    font-weight: 700;
  }
  &.light {
    font-weight: 400;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.not-expanded {
    ${ellipsisOverflow};
  }
  .noWrap:first-child {
    white-space: nowrap;
    z-index: 1;
  }
  .noWrap:last-child {
    white-space: break-spaces;
    text-align: end;
    z-index: 1;
  }
  > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    //max-width: 80%;
    min-height: 27px;
    margin-bottom: 10px;
  }
  p.noWrap {
    background: #f5f5f5;
    padding: 0 10px;
  }
  &.blue{
    color: white;
  }
  & .dotted-border {
    width: 100%;
    margin-top: 9px;
    position: absolute;
    border-top: 1px dotted #373e4b;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: -1px;
      bottom: -1px;
      //border-bottom: 2px dotted #fff;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  & .MuiRating-iconFilled {
    color: #ffe600;
  }
  @media (max-width: 480px) {
    &.cl {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    & .hid {
      display: none;
    }
  }
`;

export const Actions = styled.div`
  ${alignCenter}
  align-items: center;
  gap: 15px;
  & .btns {
    ${alignCenter}
    align-items: stretch;
    gap: 15px;
    height: 65px;
  }
  @media (max-width: 1110px), (min-width: 769px) {
    & span {
      display: none;
    }
  }
  @media (max-width: 480px) {
    flex-wrap: wrap;
    & .btns {
      width: 100%;
    }
    &.inputs {
      flex-wrap: nowrap;
    }
  }
`;

export const FilledButton = styled.button`
  opacity: ${props => props.disabled && `.8`};
  border: 1px solid #ffe600;
  background-color: #ffe600;
  gap: 10px;
  ${smallTypo}
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px;
  cursor: pointer;
  height: 100%;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
  &.grey {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    @media (max-width: 680px) {
      width: 100%;
      justify-content: center;
      margin: 5px 0;
    }
  }
  &.blue {
    background-color: #373e4b;
    color: #fff;
    border: none;
    @media (max-width: 680px) {
      width: 100%;
      justify-content: center;
      margin: 5px 0;
    }
  }
  &.search {
    @media (max-width: 768px) {
      & span {
        display: none;
      }
    }
  }
  position: relative;
  & .success-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  & div {
    ${alignCenter}
    gap: 10px;
  }
  &.success {
    & div {
      visibility: hidden;
    }
    & .success-icon {
      display: unset;
    }
  }
`;

export const OutlineButton = styled(FilledButton)`
  background-color: inherit;
  border: 1px solid #373e4b;
  text-decoration: none;
  @media (max-width: 480px) {
    & span {
      display: none;
    }
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  height: 18px;
  ${smallTypo}
  text-transform: uppercase;
  @media (max-width: 1200px) {
    font-size: 10px;
  }
`;

export const DeliveryItem = styled.span`
  ${smallTypo}
  border: 1px solid #373e4b;
  text-transform: capitalize;
  padding: 5px;
  height: 42px;
  width: 130px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  font-size: 15px;
  @media (max-width: 1200px) {
    font-size: 10px;
    width: 100%;
  }
`;

export const Line = styled.p`
  height: 2px;
  width: 25%;
  background-color: black;
  margin: 0 auto;
`;

export const Input = styled.input`
  outline: 0;
  ${smallTypo}
  padding: 10px;
  background-color: #fff;
  border: 1px solid #373e4b;
  border-radius: 5px;
  height: 67px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
  width: 100%;
`;

export const ExpandBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: fit-content;
  gap: 5px;
  & img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    margin-bottom: 2px;
  }
  &.expanded {
    & img {
      transform: rotate(0);
    }
  }
`;

export const CodeAndRating = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #373e4b;
`;
