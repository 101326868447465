import styled, { css } from "styled-components";

const typo = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  font-weight: 700;
`;

export const OffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 748px;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: #d9d9d9;
`;

export const OfferContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-right: 1px solid #373e4b;
  gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    align-items: center;
    border-bottom: 2px solid #373e4b;
  }
`;

export const Heading = styled.h2`
  ${typo}
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  background-color: #f0f0f0;
  text-transform: uppercase;
  &.left {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  & .status {
    font-weight: 400;
    margin-top: 5px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  gap: 10px;
  & img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 166px;
  ${typo}
  & .description {
    font-weight: 400;
  }

  @media (max-width: 480px) {
    max-width: unset;
    padding-bottom: 20px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0;
`;

export const PriceQuantityWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &.pd {
    padding: 0 10px;
  }
`;

export const FieldLabel = styled.p`
  ${typo}
  font-weight: 400;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Field = styled.div`
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #373e4b;
  border-radius: 10px;
  ${typo}
  font-weight: 400;
  width: 100%;
  &.comment {
    height: 50px;
    overflow-y: scroll;
    word-break: break-word;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const BuyButton = styled.button`
  padding: 12px 30px;
  background-color: #373e4b;
  border-radius: 5px;
  ${typo}
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #373e4b;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  &.outlined {
    background-color: transparent;
    color: #373e4b;
  }
`;
