import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: ${({ vHeight }) => `${vHeight}px`};
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 160;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 170;
  max-height: 95%;
  @media (max-width: 768px) {
    min-width: 100%;
    border-radius: 0;
    height: 100%;
    min-height: 100%;
  }
`;

export const PupupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 30px;
  &.save-data {
    padding: 20px 30px;
  }
  & .heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #373e4b;
  }
  & img {
    cursor: pointer;
  }
  &.save-data {
    justify-content: end;
  }
  @media (max-width: 768px) {
    padding: 20px;
    & .heading {
      font-size: 18px;
      line-height: 20px;
    }
  }
`;

export const PopupDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #373e4b;
`;

export const PopupBody = styled.div`
  padding: 30px;
  height: 100%;
  overflow-y: auto;
  &.save-data-body {
    padding: 0 110px 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  ::-webkit-scrollbar-thumb {
    background: #373e4b;
    border-radius: 5px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
  & textarea {
    resize: none;
  }
`;

export const PopupFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PopupFormInputWrapper = styled.div`
  & .remove-account-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #373e4b;
    text-align: center;
  }
  & .save-data-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
  }
  & .success-icon {
    margin-inline: auto;
    margin-bottom: 35px;
  }
  & .error-message {
    color: red;
    font-size: 0.825rem;
    padding: 0;
  }
  & .phone-wrapper {
    position: relative;
    & .prefix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      font-size: 16px;
      line-height: 18px;
    }
    & .with-prefix {
      padding-left: 60px;
    }
  }
  @media (max-width: 768px) {
    & .textarea {
      max-width: unset;
    }
  }
`;

export const PopupFormInputLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  display: inline-block;
  margin-bottom: 10px;
  &::first-letter {
    text-transform: capitalize;
  }
  &.center {
    text-align: center;
    width: 100%;
  }
  &.mt {
    margin-top: 20px;
  }
`;

export const PopupFormInput = styled.input`
  width: 100%;
  padding: 0 10px;
  min-height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  background: #ffffff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  &:focus-visible {
    outline: 0.1px solid #373e4b;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error {
    border-color: #e42829;
    outline-color: #e42829;
  }
`;

export const PopupFormSubmitButton = styled.button`
  background: #ffe600;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #373e4b;
  margin-top: 10px;
  position: relative;
  text-transform: uppercase;
  &.remove-address-button {
    background-color: transparent;
    text-decoration: underline;
    margin-top: 0;
  }
  &.remove-account-button {
    padding: 0;
    margin: 0;
  }
  &.save-data {
    min-width: 340px;
  }
  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  &.success {
    & span {
      opacity: 0;
    }
    & img {
      display: unset;
    }
  }
  &.fit {
    width: fit-content;
    padding-inline: 20px;
  }
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
`;

export const ErrorText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  &::before {
    content: "!";
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e42829;
    padding: 5px;
    border: 1px solid #e42829;
    border-radius: 50%;
    position: relative;
  }
`;
