import React from "react";
import { CardButton, NewsCardButton } from "./styled";
import { useNavigate } from "react-router";
import checkIcon from "../../../assets/checkIcon.svg";

export const ProductCardButton = ({
  buttonText,
  icon,
  slider,
  handleClick,
  isSucceeded,
}) => {
  return (
    <>
      <CardButton
        disabled={isSucceeded ? true : false}
        onClick={handleClick}
        className={isSucceeded ? "success" : ""}
      >
        <p className={`btn button-text ${slider && "in-slider-text"}`}>
          {buttonText}
        </p>
        {icon && (
          <img
            className={`btn button-icon ${slider && "in-slider-img"}`}
            src={icon}
            alt="icon"
          />
        )}
        <img className="success-icon" src={checkIcon} alt="sent" width="18" />
      </CardButton>
    </>
  );
};
