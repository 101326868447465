import React from "react";
import { Wrapper, TitleLink, SubcategoryList, SubLink } from "./styled";
import EmptyList from "../empty-list";

const SubMenu = ({ handleMouseLeave, category }) => {
  const { subcategories } = category;

  return (
    <Wrapper
      className="sub-menu"
      onMouseLeave={() => handleMouseLeave(category.id)}
    >
      <TitleLink
        to={
          category?.subcategoriesCount
            ? `/subcategories/${category.id}`
            : `/sub-category/${category.id}/1`
        }
      >
        {category.name}
      </TitleLink>
      {subcategories?.length > 1 ? (
        <SubcategoryList>
          {subcategories?.map((subcategory) => (
            <React.Fragment key={subcategory}>
              <SubLink
                className={`bold ${
                  !subcategory?.subcategories?.length ? "mtn" : ""
                }`}
                to={
                  subcategory?.subcategoriesCount
                    ? `/subcategories/${subcategory.id}`
                    : `/sub-category/${subcategory.id}/1`
                }
              >
                {subcategory.name || "empty sub 1"}
              </SubLink>
              {!!subcategory?.subcategories?.length &&
                subcategory?.subcategories.map((sub) => (
                  <SubLink
                    key={sub}
                    to={
                      sub?.subcategoriesCount
                        ? `/subcategories/${sub.id}`
                        : `/sub-category/${sub.id}/1`
                    }
                  >
                    {sub.name || "empty sub 2"}
                  </SubLink>
                ))}
            </React.Fragment>
          ))}
        </SubcategoryList>
      ) : (
        <EmptyList />
      )}
    </Wrapper>
  );
};

export default SubMenu;
