import styled from "styled-components";

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 10px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.a`
  aspect-ratio: 1/1;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 100%;
    height: auto;
  }
`;
