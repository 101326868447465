import React, {useEffect, useState} from "react";
import { Background, Dialog, DialogHeader, Heading, Content } from "./styled";
import { FilledButton } from "../../pages/product/styled";
import errorIcon from "../../assets/errorIcon.svg";
import successIcon from "../../assets/checkIconRounded.svg";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import {Button} from "../auth/styled";

const ErrorDialog = ({ open, handleClose, message, success ,isCounter, handleCloseModal}) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <>
      {createPortal(
        <>
          <Background className={open && "open"} onClick={success === "none" ? handleCloseModal : handleClose} />
          <Dialog className={`error ${open && "open"}`}>
            <DialogHeader className="error">
              {success !== "none" &&
                  <img
                      src={success ? successIcon : errorIcon}
                      alt={success ? "success" : "error"}
                      width="50"
                      height="50"
                  />
              }
              <Heading>{message || t("texts.unexpected_error")}</Heading>
            </DialogHeader>
            <Content flex>
              {success === "none" ?
              <>
                <FilledButton className="centered" onClick={handleClose}>
                  {t("texts.yes")}
                </FilledButton>
                <FilledButton className="centered" onClick={handleCloseModal}>
                  {t("texts.no")}
                </FilledButton>
              </> :
                  <FilledButton className="centered" onClick={handleClose}>
                    {t("texts.got_it")}
                  </FilledButton>}

            </Content>
              {isCounter &&
                  <Content center>
                      {counter != 0 && <p>00:{counter}</p>}
                      {<FilledButton className="centered" disabled={counter != 0}>{t("texts.resend")}</FilledButton>}
                  </Content>}
          </Dialog>
        </>,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ErrorDialog;
