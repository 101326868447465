import React, {useRef, useState, useEffect} from "react";
import Slider from "react-slick";
import {MainSlider, SliderContainer, SmallSlider} from "./Slider.styled";
import {useSelector} from "react-redux";
import Badges from "../../components/cards/Badges";
import {SlickArrowLeft, SlickArrowRight} from "./arrows";
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ProductSlider = ({images, product}) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [mainImg, setMainImg] = useState(images[0]);
    const vHeight = useSelector((state) => state.appHeight.val);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSlideIndex, setModalSlideIndex] = useState(0);
    const [carousel1Index, setCarousel1Index] = useState(0);
    const [carousel2Index, setCarousel2Index] = useState(0);

    const handleCarousel1Change = (index) => {
        setCarousel1Index(index);
        setCarousel2Index(index);
    };

    const handleCarousel2Change = (index) => {
        setCarousel2Index(index);
        setCarousel1Index(index);
    };

    useEffect(() => {
        setMainImg(images[0]);
    }, [images]);

    const resultRef = useRef(null);

    const openModal = (index) => {
        console.log('index', index);
        setModalSlideIndex(index);
        setModalOpen(true);
    };

    const closeModal = (e) => {
        console.log("element", e.target);
        setModalOpen(false);
    };
    const settings = {
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setMainImg(images[current]),
    };

    const slideTwoSettings = {
        slidesToShow: 5,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: true,
        nextArrow: <SlickArrowRight/>,
        prevArrow: <SlickArrowLeft/>,
        infinite: false,
    };


    return (
        <SliderContainer>
            <div ref={resultRef} className="result"/>

            <Carousel showStatus={false}
                      swipeable={true}
                      onChange={handleCarousel1Change}
                      selectedItem={carousel1Index}
                      showThumbs={false}>
                {images.map((img, index) =>
                    <div onClick={() => openModal(index)}>
                        <img src={img?.src} alt="Image 1"/>
                    </div>
                )}
            </Carousel>
            <SmallSlider
                vHeight={vHeight}
            >

                <Carousel centerMode={true} onChange={handleCarousel2Change} selectedItem={carousel2Index}>
                    {images.map((img, i) => (
                        <div key={img?.src || i}>
                            <img className="small-img" src={img?.src} alt=""/>
                            <div className="small-nav"/>
                        </div>
                    ))}
                </Carousel>
            </SmallSlider>

            {modalOpen && (
                <div className="modal">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <Carousel
                        showThumbs={false}
                        selectedItem={modalSlideIndex}
                        showStatus={false}
                        infiniteLoop={true}
                    >
                        {images.map((img, index) =>
                            <div>
                                <img src={img?.src} alt="Image 1"/>
                            </div>
                        )}
                    </Carousel>
                </div>
            )}
        </SliderContainer>
    );
};

export default ProductSlider;