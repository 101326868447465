import React, { useState, useEffect } from "react";
import { FiltersSidebar } from "./styled";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "./ExpandMoreIcon";
import { Options } from "./accordion.styled";
import CustomCheckbox from "../../components/custome-checkbox/CustomCheckbox";
import { Label } from "../dialogs/styled";
import { useTranslation } from "react-i18next";
import PriceInput from "./PriceInput";
import { ClickAwayListener } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "../../pages/cart/styled";
import deleteIcon from "../../assets/deleteIcon.svg";

const Filters = ({
  showFilters,
  filters,
  selectedFilters,
  handleSelectedFiltersChange,
  priceFilter,
  page,
  categoriesFilter,
  handleCategorySelect,
  selectedCategories,
  colors,
  handleChangeUsedFilter,
  handleMaxChange,
  handleMinChange,
  handleMaxBlur,
  handleMinBlur,
  min,
  max,
  brands,
  selectedBrands,
  handleBrandSelect,
  handleUnsetFilters,
}) => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const filts = search.get("f");
  const [expanded, setExpanded] = useState("");
  useEffect(() => {
    setExpanded("");
  }, [page]);

  const handleExpand = (val) => {
    expanded === val ? setExpanded("") : setExpanded(val);
  };

  const handleChange = (name, val) => {
    const filter = selectedFilters.find((item) => item.name === name);
    let filters = selectedFilters.filter((item) => item.name !== name);
    let newFilter;
    if (filter) {
      if (filter.list.includes(val)) {
        if (filter.list.length > 1) {
          newFilter = {
            ...filter,
            list: filter.list.filter((elem) => elem !== val),
          };
        }
      } else {
        newFilter = { ...filter, list: [...filter.list, val] };
      }
    } else {
      newFilter = { name, list: [val] };
    }
    if (newFilter) {
      handleSelectedFiltersChange([...filters, newFilter]);
    } else {
      handleSelectedFiltersChange(filters);
    }
    handleChangeUsedFilter(name);
  };

  return (
    <ClickAwayListener onClickAway={() => setExpanded(null)}>
      <FiltersSidebar className={showFilters && "show"}>
        <div className="filters-container">
          {categoriesFilter?.[0] &&
            !!Object.entries(categoriesFilter?.[1])?.length && (
              <Accordion
                expanded={expanded === categoriesFilter?.[0]}
                onChange={() => handleExpand(categoriesFilter?.[0])}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {categoriesFilter?.[0]}
                </AccordionSummary>
                <AccordionDetails>
                  <Options>
                    {Object.entries(categoriesFilter?.[1])?.map((option) => (
                      <CustomCheckbox
                        key={option?.[0]}
                        id={option?.[0]}
                        text={option?.[1]}
                        className={``}
                        handleChange={() => {
                          handleCategorySelect(option?.[0]);
                          handleChangeUsedFilter(categoriesFilter?.[0]);
                        }}
                        checked={selectedCategories.includes(option?.[0])}
                      />
                    ))}
                  </Options>
                </AccordionDetails>
              </Accordion>
            )}
          {brands?.[0] && !!Object.entries(brands?.[1])?.length && (
            <Accordion
              expanded={expanded === brands?.[0]}
              onChange={() => handleExpand(brands?.[0])}
              key={'brands'}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {brands?.[0]}
              </AccordionSummary>
              <AccordionDetails
                  key={'brand-details'}
              >
                <Options>
                  {Object.entries(brands?.[1])?.map((option) => (
                    <CustomCheckbox
                      key={option?.[0]}
                      id={option?.[0]}
                      text={option?.[1]}
                      className={``}
                      handleChange={() => {
                        handleBrandSelect(option?.[1]);
                        handleChangeUsedFilter(brands?.[0]);
                      }}
                      checked={selectedBrands.includes(option?.[1])}
                    />
                  ))}
                </Options>
              </AccordionDetails>
            </Accordion>
          )}
          {!!filters?.length &&
            filters.map((f, i) => (
              <Accordion
                expanded={expanded === f?.[0]}
                onChange={() => handleExpand(f?.[0])}
                key={i}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {f?.[0]}
                </AccordionSummary>
                <AccordionDetails
                    key={`filters-${i}`}
                >
                  <Options>
                    {!!f?.[1]?.length &&
                      f?.[1].map((option, i) => (
                          <>
                        <CustomCheckbox
                          key={i}
                          id={option}
                          text={option}
                          className={
                            f?.[0] === "Գույն" || f?.[0] === "Цвет"
                              ? "colored"
                              : ""
                          }
                          bckgColor={colors?.[option]?.toLowerCase()}
                          handleChange={() => handleChange(f?.[0], option)}
                          checked={filts?.includes(option) || filts?.includes(encodeURIComponent(option)) }
                        />
                          </>
                      ))}
                  </Options>
                </AccordionDetails>
              </Accordion>
            ))}
          {priceFilter?.[0] && (
            <Accordion
              expanded={expanded === priceFilter?.[0]}
              onChange={() => handleExpand(priceFilter?.[0])}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {priceFilter?.[0]}
              </AccordionSummary>
              <AccordionDetails>
                <Options className="flex">
                  <div className="input-wrapper">
                    <Label htmlFor="min-price">{t("texts.min")}</Label>
                    <PriceInput
                      id="min-price"
                      value={min}
                      onChange={handleMinChange}
                      onBlur={handleMinBlur}
                    />
                  </div>
                  <div className="input-wrapper">
                    <Label htmlFor="max-price">{t("texts.max")}</Label>
                    <PriceInput
                      id="max-price"
                      value={max}
                      onChange={handleMaxChange}
                      onBlur={handleMaxBlur}
                    />
                  </div>
                </Options>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
        <IconButton
          className="with-text fright"
          style={{ margin: "15px auto 0 0" }}
          onClick={handleUnsetFilters}
        >
          <span> {t("actions.clear_filters")}</span>
        </IconButton>
      </FiltersSidebar>
    </ClickAwayListener>
  );
};

export default Filters;
