import React from "react";
import { ServiceLink, IconWrapperSvg } from "./styled";
import shinIcon from "../../assets/shinIcon.svg";
import loyalCostumer from "../../assets/loyal_costumer.svg";
import deliveryIcon from "../../assets/deliveryIcon.svg";
import forBussines from "../../assets/forBussines.svg";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import forBusiness from "../../pages/for-business";

export const services = [
  {
    id: 1,
    name: "loyal_costumer",
    route: "/info/company/bonuses",
    icon: loyalCostumer,
    type: "a",
  },
  {
    id: 2,
    name: "shinmag_club",
    route: "/shinmag-club",
    icon: shinIcon,
    type: "a",
  },
  {
    id: 3,
    name: "delivery_and_dumping",
    route: "/delivery-and-dumping",
    icon: deliveryIcon,
    type: "a",
  },
  {
    id: 4,
    name: "for_business",
    route: "/for-business",
    icon: forBussines,
    type: "a",
  },
];

const MainServices = ({ handleCloseMenu }) => {
  const { t } = useTranslation();

  return (
    <>
      {services.map((item) =>
        item.type === "div" ? (
          <ServiceLink key={item.id} as="div">
            <h1>{item.name}</h1>
            <IconWrapperSvg>
              <img src={item.icon} alt="" />
            </IconWrapperSvg>
          </ServiceLink>
        ) : (
          <ServiceLink key={item.id} to={item.route} onClick={handleCloseMenu}>
            <h1>{t(`texts.${item.name}`)}</h1>
            <IconWrapperSvg>
                <img src={item.icon} alt="" />
            </IconWrapperSvg>
          </ServiceLink>
        )
      )}
    </>
  );
};

export default MainServices;
