import React from "react";
import NewsCard from "../../components/cards/NewsCard";
import { CardGrid } from "../../components/cards/styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useGetData } from "../../utils/hooks/hooks";
import CustomPagination from "../../components/pagination/CustomPagination";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import { PageTitle } from "../categories/styled";
import SkeletonCard from "../../components/cards/SkeletonCard";
import EmptyList from "../../components/empty-list";

const NewsList = () => {
  const { t } = useTranslation();
  const { page } = useParams();
  const nav = [{ to: "/news/1", name: t("texts.news") }];
  const {
    data: newsList,
    isLoading,
    meta,
  } = useGetData(`articles?page=${page}&perPage=12`, [page]);

  const navigate = useNavigate();

  const handlePageChange = (e, page) => {
    navigate(`/news/${page}`);
  };

  return (
    <>
      <Breadcrumbs nav={nav} />
      <PageTitle style={{ marginBottom: "30px" }}>{t("texts.news")}</PageTitle>
      {isLoading && !newsList?.length ? (
        <CardGrid>
          {Array.from({ length: 4 }).map((x, i) => (
            <SkeletonCard key={i} />
          ))}
        </CardGrid>
      ) : !newsList?.length ? (
        <EmptyList />
      ) : (
        <CardGrid>
          {newsList?.map((news) => (
            <NewsCard
              key={news?.id || news?.uuid}
              id={news?.id || news?.uuid}
              imgSrc={news?.images?.main?.src}
              newsTitle={news?.title}
              newsDate={moment(news?.created).format("DD-MM-YYYY")}
              newsDescription={news?.content}
            />
          ))}
        </CardGrid>
      )}
      {meta?.last_page > 1 && (
        <CustomPagination
          page={+page}
          pageCount={meta?.last_page}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default NewsList;
