import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { searchScrollbar } from "../../shared/styled";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 50%;
  height: fit-content;
  max-height: calc(100vh - 122px);
  overflow-y: auto;
  padding: 15px 30px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  @media (max-width: 1024px) {
    max-height: calc(100vh - 92px);
  }
  @media (max-width: 768px) {
    display: none;
  }
  ${searchScrollbar}
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #373e4b;
`;

export const SubcategoryList = styled.div`
  margin-top: 20px;
  display: block;
  columns: 4;
  @media (max-width: 1440px) {
    columns: 3;
  }
  @media (max-width: 1110px) {
    columns: 2;
  }
  @media (max-width: 880px) {
    columns: 1;
  }
`;

export const SubLink = styled(Link)`
  ${typo};
  text-decoration: none;
  max-width: 100%;
  width: fit-content;
  break-inside: avoid-column;
  display: block;
  &::first-letter {
    text-transform: uppercase;
  }
  margin: 5px;
  padding: 3px 5px;
  &:hover {
    background-color: #ffe500;
  }
  &.bold {
    font-weight: 700;
    padding: 5px;
    margin-top: 10px;
    &.mtn {
      margin-top: 0;
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;
