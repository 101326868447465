import styled, { css } from "styled-components";

const typo = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

const smTypo = css`
  font-size: 14px;
  line-height: 16px;
`;

const border = css`
  background: #fff;
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

const dispFlex = css`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
`;

const firstLetterUppercase = css`
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: calc(100% - 20px - 358px);
  @media (max-width: 1440px) {
    max-width: calc(100% - 20px - 280px);
  }
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const List = styled.ol`
  list-style-type: none;
`;

export const ListItem = styled.li`
  ${border}
  padding: 30px 20px;
  margin-bottom: 10px;
`;

export const ListHeader = styled.div`
  ${dispFlex}
  & .flex {
    ${dispFlex}
    gap:10px;
    flex-wrap: nowrap;
  }
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 1790px) {
    width: 100%;
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledCount = styled.div`
  position: relative;
  min-width: 30px;
  min-height: 30px;
  margin: 5px;
  & span {
    ${typo}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffe600;
    border-radius: 5px;
    transform: rotate(45deg);
  }
`;

export const Title = styled.h3`
  ${typo}
  ${firstLetterUppercase}
  min-width: 260px;
  @media (max-width: 768px) {
    min-width: unset;
  }
`;

export const Button = styled.button`
  ${typo}
  color:#373e4b;
  background-color: #fff;
  text-transform: uppercase;
  border: 1px solid #373e4b;
  border-radius: 5px;
  &.bold {
    color: #fff;
    background: #373e4b;
  }
  &.address {
    margin: 20px 0;
    padding: 11.5px 27px;
  }
`;

export const ListContent = styled.div`
  margin-top: 20px;
  &.info {
    margin-top: 10px;
    & p {
      ${smTypo}
      margin-bottom: 20px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    gap: 0;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Label = styled.label`
  ${smTypo};
  ${firstLetterUppercase}
  display: block;
  margin-bottom: 10px;
  &.sm-margin {
    margin-bottom: 10px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  &.phone {
    &::before {
      content: "(+374)";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      ${smTypo}
    }
  }
`;

export const Input = styled.input`
  ${smTypo}
  padding: 15px 10px;
  margin-bottom: 20px;
  width: 100%;
  ${border}
  &:focus {
    outline: 0.1px solid #373e4b;
  }
  &.info {
    margin-bottom: 0;
  }
  &.phone {
    padding-left: 60px;
  }
  &.error {
    border-color: #e42829;
    margin-bottom: 0;
    &:focus {
      outline-color: #e42829;
    }
  }
`;

export const DeliveryMessage = styled.p`
  ${typo}
  font-weight: 400;
  background-color: #d9d9d9;
  margin-top: 10px;
  padding: 5px;
  width: fit-content;
`;
