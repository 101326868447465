import React, { useState } from "react";
import {
  Form,
  Label,
  Input,
  RememberMeWrapper,
  Button,
  InputWrapper,
  RecoverPasswword,
  Actions,
} from "./styled";
import PasswordInput from "./PasswordInput";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "../../constants";
import { deleteKey, changeState } from "../../utils/funcs/formHandlings";
import { ErrorText } from "../../shared/styled";
import { useSelector } from "react-redux";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";

const Login = ({ handleOpenRecoverPassword, handleClose }) => {
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e, key, setValue) => {
    changeState(e.target.value, setValue);
    deleteKey(key, errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email: emailOrPhone, password };
    axios
      .post(`${baseUrl}/auth/login/user`, data, {
        headers: {
          Accept: "application/json",
          "Accept-Language": lang.val,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.setItem("token", res?.data?.data?.token);
        handleClose();
      })
      .catch((err) => {
        setErrors(err?.response?.data?.errors);
      });
  };

  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} autocomplete="off">
      <input type="hidden" id="password" />
      <div>
        <Label htmlFor="login-email">{t("texts.email")}</Label>
        <InputWrapper>
          <Input
            id="login-email"
            type="email"
            className={errors?.email && "error"}
            value={emailOrPhone}
            onChange={(e) => handleChange(e, "email", setEmailOrPhone)}
          />
        </InputWrapper>
        {errors?.email && <ErrorText>{errors.email?.[0]}</ErrorText>}
      </div>
      <div>
        <Label htmlFor="login-password">{t("texts.password")}</Label>
        <PasswordInput
          show={showPassword}
          handleShow={() => setShowPassword(!showPassword)}
          id="login-password"
          className={errors?.password && "error"}
          value={password}
          onChange={(e) => handleChange(e, "password", setPassword)}
        />
        {errors?.password && <ErrorText>{errors.password?.[0]}</ErrorText>}
      </div>
      <Actions>
        {/* <RememberMeWrapper>
          <input id="remember" type="checkbox" />
          <label htmlFor="remember" className="checkbox" />
          <span>{t("actions.remember_me")}</span>
        </RememberMeWrapper> */}
        <RecoverPasswword
          type="button"
          onClick={() => handleOpenRecoverPassword("recovery")}
        >
          {t("actions.recover_password")}
        </RecoverPasswword>
      </Actions>
      <Button>{t("actions.login")}</Button>
    </Form>
  );
};

export default Login;
