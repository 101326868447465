import { CircularProgress } from "@mui/material";
import React from "react";
import { createPortal } from "react-dom";
import { Background } from "../dialogs/styled";
import { ProgressBarWrapper } from "./styled";

const Progress = ({ open, handleClose }) => {
  return createPortal(
    <>
      <Background className={open ? "open" : ""} onClick={handleClose} />
      <ProgressBarWrapper className={open ? "open" : ""}>
        <CircularProgress size={80} />
      </ProgressBarWrapper>
    </>,
    document.getElementById("modal-root")
  );
};

export default Progress;
