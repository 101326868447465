import React from "react";
import { Label, Input } from "./styled";

const CustomInput = ({
  labelClassName,
  inputClassName,
  id,
  labelName,
  value,
  setValue,
  handleChange,
  inputKey,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.value, setValue, inputKey);
  };

  return (
    <>
      <Label className={labelClassName || ""} htmlFor={id}>
        {labelName}
      </Label>
      <Input
        id={id}
        className={inputClassName || ""}
        placeholder={labelName}
        value={value}
        onChange={handleInputChange}
      />
    </>
  );
};

export default CustomInput;
