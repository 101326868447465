import styled from "styled-components";
import { Button } from "../../pages/checkout/styled";

export const CustomButton = styled(Button)`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  & .date {
    font-weight: 400;
  }
  &:hover {
    opacity: 0.9;
  }
`;

export const Dates = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(9, 1fr);
  margin-bottom: 20px;
  @media (max-width: 1840px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 1190px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 720px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
