import React from "react";
import { ListContent } from "./styled";
import CustomCheckbox from "../../components/custome-checkbox/CustomCheckbox";
import { useTranslation } from "react-i18next";

const AdditionalServices = ({
  extraOptions,
  selectedExtras,
  handleSelectExtra,
}) => {
  const { t } = useTranslation();

  return (
    <ListContent className="info">
      <p>{t("texts.additional_services_price")}</p>
      {extraOptions?.map((option) => (
        <CustomCheckbox
          key={option.id}
          checked={selectedExtras.includes(option.id)}
          text={option.name}
          id={option.name}
          handleChange={() => handleSelectExtra(option.id)}
        />
      ))}
    </ListContent>
  );
};

export default AdditionalServices;
