import React, {useState} from "react";
import {
    UpperHeaderWrapper,
    UpperHeaderContent,
    ExpandButtonWrapper,
    ExpandButton,
    MenuList,
    CustomLink,
    MenuBtn, SocialMediaLinks,
} from "./UpperHeader.styled";
import {generalInfo} from "../../shared/generalInfo";
import {useSelector, useDispatch} from "react-redux";
import {changeLang} from "../../features/lang/langSlice";
import {useTranslation} from "react-i18next";
import {socialMediaHeaderItems} from "./socialMediaHeaderItems";

const UpperHeader = () => {
    const [expanded, setExpanded] = useState(false);
    const [langExpanded, setLangExpanded] = useState(false);

    const [lang, langs] = useSelector((state) => state.lang);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (val) => {
        dispatch(changeLang(val));
        i18n.changeLanguage(val);
        setLangExpanded(false);
    };

    return (
        <UpperHeaderWrapper>
            <UpperHeaderContent className="left">
                <p>+374 12 440 440, +374 43 540 440</p>
            </UpperHeaderContent>
            <UpperHeaderContent>
                <SocialMediaLinks>
                    {socialMediaHeaderItems.filter((item) => item.link).map((item) => (
                        <CustomLink
                            key={item.id}
                            to={item.link}
                        >
                            <img src={item.icon} alt={item.name} width="15" height="17"/>
                        </CustomLink>
                    ))}
                </SocialMediaLinks>

                <div>
                    <p>{t("texts.deliveryHeaderMessage")}</p>

                    <ExpandButtonWrapper
                        onBlur={(e) => {
                            if (!e.relatedTarget?.className.includes("expandable")) {
                                setExpanded(null);
                            }
                        }}
                    >
                        <ExpandButton
                            onClick={() => setLangExpanded((prev) => !prev)}
                            onBlur={(e) => {
                                if (!e.relatedTarget?.className.includes("expandable")) {
                                    setLangExpanded(false);
                                }
                            }}
                        >
                            <img src={lang?.img} alt={lang?.name} width="14" height="14"/>
                        </ExpandButton>
                        <MenuList expanded={langExpanded} className="lang expandable">
                            {langs.map((lang) => (
                                <MenuBtn
                                    key={lang.name}
                                    className="expandable"
                                    onClick={() => handleChangeLanguage(lang.val)}
                                >
                                    <img src={lang.img} alt={lang.name} width="14" height="14"/>
                                </MenuBtn>
                            ))}
                        </MenuList>
                    </ExpandButtonWrapper>
                </div>
            </UpperHeaderContent>
        </UpperHeaderWrapper>
    );
};

export default UpperHeader;
