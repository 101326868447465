export const data = {
  hy: [
    {
      title: "Ծառայությունների մատուցման կանոններ.",
      content: [
        {
          type: "normal",
          text: `Առաքման ծառայությունը (բացառությամբ մոպեդային) չի ներառում բեռնաթափում (մեքենայից բեռնաթափում), ապրանքների բարձրացումը եւ տեղափոխումը առանձին ծառայություններ են։`,
        },
        {
          type: "list",
          items: [
            `Առաքումն իրականացվում է ապրանքը ստանալու պատրաստակամությունը հաստատելուց հետո։ Առաքման օրը խնդրում ենք կապի մեջ մնալ պատվերի մեջ նշված հեռախոսահամարով։`,
            `Գնալով հասցե՝ վարորդը անպայման կզանգահարի ձեզ, եթե դուք ընտրել եք առաքման ցանկացած տեսակ, բացառությամբ  «Էքսպրես»-ից։ <<ՇԻՆՄԱԳԸ>> երաշխավորում է ժամանակին առաքում ընտրված միջակայքում`,
            `Եթե ընտրել եք  «Էքսպրես» առաքումը, ապա մեքենան հասցե կժամանի նույնիսկ առանց հեռախոսով պատվերի նախնական հաստատման։`,
            `Կարող եք վերադասավորել առաքման ժամանակը կամ հարմարեցնել պատվերը՝ կապվելով 012440440 հեռախոսահամարին:`,
            `Եթե մուտքը դեպի բեռնաթափման վայր դժվարանցանելի է , ապա մեքենան կկանգնի դրանից ամենամոտ հեռավորության վրա՝ չխախտելով ճանապարհային երթեւեկության կանոնները եւ բացառելով տրանսպորտին վնաս հասցնելու հնարավորությունը։ Մինչեւ 1,8 տոննա ներառյալ մեքենան բեռնաթափելու համար հատկացվում է 30 րոպե, 1,8 տոննայից ավել քաշը՝ 60 րոպե։`,
            `Առաքման համար կարող եք նախապես վճարել ցանկացած հարմար եղանակով (կայքում, հեռախոսով), ինչպես նաեւ տեղում կանխիկ կամ բանկային քարտով՝ մինչեւ բեռնաթափման մեկնարկը։`,
            `Ապրանքների որակի կամ քանակի հետ կապված հարցեր ունենալու դեպքում կարող եք դիմել ընկերության ներկայացուցչին տեղում կամ Հաճախորդների սպասարկման ծառայություն 012440440 հեռախոսահամարով։ Վաճառքի կտրոնի վրա նշված է նաեւ հեռախոսահամարը։`,
          ],
        },
      ],
    },
    {
      title: "Ծառայության արժեքը.",
      content: [
        {
          type: "list",
          items: [
            `Ծառայության արժեքը կախված է գնված ապրանքի քաշից, ծավալից եւ չափսերից, ինչպես նաեւ ընտրված առաքման տեսակից: Առաջարկում ենք 0,7-ից 15 տոննա քաշով  բեռնատարներ Ծառայության արժեքը հաշվարկելիս հաշվի են առնվում հասցեների եւ բեռնաթափման կետերի քանակը։`,
            `Ապրանքների բեռնաթափման, տեղափոխման եւ բարձրացման ծառայությունները վճարվում են առանձին։`,
          ],
        },
      ],
    },
    {
      title: "Առանձնահատկություններ.",
      content: [
        {
          type: "normal",
          text: `«Մոպեդով առաքման » պատվերի ընդհանուր քաշը չի կարող գերազանցել 10 կգ-ը, իսկ ընդհանուր չափսերը՝ 110x45x30 սմ։Բեռնաբարձման արժեքը այս դեպքում ներառված է առաքման արժեքիմեջ։`,
        },
      ],
    },
  ],
  ru: [
    {
      title: "Правила оказания услуги",
      content: [
        {
          type: "normal",
          text: `В услугу доставки (кроме курьерской) не входит разгрузка (выгрузка из автомобиля), подъем и пронос товара — это отдельные услуги.`,
        },
        {
          type: "list",
          items: [
            `Доставка осуществляется после того, как Вы подтвердите готовность получить товар. В день доставки просим оставаться на связи по указанному в заказе номеру телефона.`,
            `Отправляясь на адрес, водитель обязательно Вам позвонит, если вы выбрали любой вид доставки, кроме  «Экспресс»,которая  гарантирует своевременную доставку в выбранном интервале.`,
            `Если Вы выбрали доставку  «Экспресс», машина прибудет на адрес даже без предварительного подтверждения заказа по телефону.`,
            `Вы можете перенести время доставки или скорректировать заказ, обратившись в Контакт-центр по номеру 012440440.`,
            `Если подъезд к месту разгрузки затруднен, то машина остановится на максимально близком от него расстоянии, не нарушая ПДД и исключая вероятность повреждения транспорта. Для разгрузки автомобиля весом до 1,8 тонн включительно отводится 30 минут, весом свыше 1,8 тонн – 60 минут.`,
            `Оплатить доставку можно заранее любым удобным способом (на сайте, по телефону), а также на месте наличными или банковской картой до начала момента разгрузки.`,
            `Если возникли вопросы по качеству или количеству товара, Вы можете обратиться к представителю компании на месте или в Службу поддержки клиентов по телефону 012440440. Номер телефона также указан в товарном чеке.`,
          ],
        },
      ],
    },
    {
      title: "Стоимость услуги:",
      content: [
        {
          type: "list",
          items: [
            `Стоимость услуги зависит от веса, объемов и габаритов приобретенного товара, а также выбранного вида доставки.  Мы предлагаем машины грузоподъемностью от 0,7 до 15 тонн . При расчете стоимости услуги учитывается количество адресов и точек разгрузки`,
            `Услуги по разгрузке товара, его проносу и подъему оплачиваются отдельно`,
          ],
        },
      ],
    },
    {
      title: "Особенности:",
      content: [
        {
          type: "normal",
          text: `Суммарный вес заказа «Курьерской доставки» не может превышать 10 кг, а габаритные размеры — 110х45х30 см. Стоимость подъема в данном случае включена в стоимость доставки.`,
        },
      ],
    },
  ],
};
