import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import styled from "styled-components";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useSelector } from "react-redux";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    color: #373e4b;
    text-align: center;
    text-transform: capitalize;
  }
`;

const Verification = () => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang[0]);
  const { token } = useParams();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const initToken = useLocalStorageGetItem("initToken");
  useEffect(() => {
    let headers = {
      "Accept-Language": lang.val,
    };
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${initToken}` };
    }

    axios
      .post(
        `${baseUrl}/auth/verification/verify`,
        {
          token,
        },
        { headers }
      )
      .then(() => {
        localStorage.removeItem("initToken");
        localStorage.setItem("token", initToken);
        navigate("/");
      })
      .catch((err) => setError(true));
  }, []);

  const handleCloseErrorDialog = () => {
    setError(false);
    navigate("/");
  };

  return (
    <>
      <Container>
        <h1>{t("actions.verifying")}...</h1>
      </Container>
      {error && (
        <ErrorDialog open={error} handleClose={handleCloseErrorDialog} />
      )}
    </>
  );
};

export default Verification;
