import styled from "styled-components";

export const SliderContainer = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  & .result {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    z-index: 104;
    left: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    display: none;
  }
  @media (max-width: 1440px) {
    max-width: 40%;
    min-width: 400px;
  }
  @media (max-width: 1250px) {
    max-width: 100%;
    min-width: unset;
    & .result {
      left: auto;
      top: calc(100% / 1.3);
      transform: scale(50%);
    }
  }
`;

export const MainSlider = styled.div`
  max-width: calc(100% - 100px);
  width: 100%;
  max-height: ${({ vHeight }) => `${vHeight}px`};
  & .slick-slider {
    width: 100%;
    & .img-wrapper {
      position: relative;
      max-width: ${({ vHeight }) => `${vHeight * 0.8}px`};
      max-height: ${({ vHeight }) => `${vHeight * 0.8}px`};
      margin: 0 auto;
    }
    & img {
      margin: 0 auto;
      display: block !important;
      width: 100%;
      object-fit: contain;
      height: auto;
      border: 1px solid #373e4b;
      border-radius: 5px;
    }
    & .lens {
      position: absolute;
      width: 170px;
      height: 170px;
      z-index: 105;
      border: 1px solid #d4d4d4;
    }
  }
  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

export const SmallSlider = styled.div`
  max-width: 588px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .carousel-slider {
    display: none;
  }
  .thumbs {
    display: flex;
    justify-content: center;
  }
  .thumb {
    height: 80px;
    cursor: pointer;
  }
  .thumb img {
    object-fit: cover;
    height: 100%;
  }
  & .slick-slider,
  & .slick-list {
    width: 100%;
  }
  &.show {
    & .slick-slider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }
  & .slick-track {
    overflow: hidden;
    max-height: 92px;
    display: flex!important;
  }
  & .slick-slide {
    width: 90px !important;
    margin: 0 5px;
    & .small-nav {
      display: none;
    }
    & img {
      width: 90px;
      aspect-ratio: 1/1;
      border: 1px solid #373e4b;
      border-radius: 5px;
      margin: 1px;
    }
  }
  @media (max-width: 1440px) {
    max-width: 325px;
    & .slick-track {
      max-height: 52px;
    }
    & .slick-slide {
      width: 50px !important;
      margin: 0 2.5px;
      & img {
        width: 50px;
      }
    }
  }
  @media (max-width: 480px) {
    max-width: 275px;
  }
  &.hide {
    & .slick-arrow {
      display: none !important;
    }
  }
  & .slick-arrow {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    & img {
      border: 0;
      width: 20px;
    }
    &.slick-prev {
      transform: rotate(90deg);
    }
    &.slick-next {
      transform: rotate(-90deg);
    }
    @media (max-width: 1440px) {
      padding: 5px;
      & img {
        width: 10px;
      }
    }
    @media (max-width: 480px) {
      display: none !important;
    }
  }
`;
