import styled, { css } from "styled-components";

const typo = css`
  font-weight: 700;
  font-size: 27px;
  line-height: 18px;
`;

export const Counter = styled.div`
  max-width: 120px;
  width: 25%;
  height: 100%;
  font-size: 48px;
  display: flex;
`;

export const Button = styled.button`
  background-color: #373e4b;
  color: #fff;
  max-width: 40px;
  min-width: 30px;
  width: 100%;
  aspect-ratio: 1/1;
  ${typo}
  &:hover {
    opacity: 0.9;
  }
`;

export const Input = styled.input`
  border: 1.8px solid #373e4b;
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 0;
  outline: 0;
  max-height: 67px;
  &:focus {
    border: 2px solid #373e4b;
  }
  ${typo}
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
