import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const mainTypo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
`;

const rowPadding = css`
  padding: 20px 0;
  padding-left: 20px;
  &:first-child {
    padding-left: 0;
  }
  .count {
    width: 10%;
  }
`;

const underlinedTypo = css`
  text-decoration: underline;
  text-underline-offset: 3px;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Container = styled.div`
  &.giftCard {
    display: flex;
    gap: 20px
  }
`;

export const PageHeading = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;
  color: #373e4b;
  margin-bottom: 30px;
`;

export const Section = styled.section`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  & .table-section {
    width: 100%;
    @media (max-width: 768px) {
      & table {
        display: none;
      }
    }
  }
  & .summary-section {
    max-width: 358px;
    width: 100%;
    @media (max-width: 1440px) {
      max-width: 280px;
    }
    @media (min-width: 1025px) {
      position: sticky;
      top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    & .summary-section {
      max-width: 100%;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const Trow = styled.tr`
  border-bottom: 1px solid #d9d9d9;
`;

export const Thead = styled.thead``;

export const Theader = styled.th`
  ${mainTypo}
  text-align: start;
  color: #373e4b;
  ${rowPadding}
  padding-top: 0;
`;

export const Tbody = styled.tbody``;

export const Tdata = styled.td`
  ${rowPadding}
  vertical-align: top;
  &.bold {
    font-weight: 700;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.amount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
    min-width: 90px;
  }
  &.price {
    display: flex;
    flex-direction: column;
    & .old {
      text-decoration: line-through;
      text-decoration-color: #e42829;
      color: #737475;
    }
  }
  &:first-child {
    width: 60%;
  }
  &:last-child {
    text-align: end;
  }
  @media(min-width: 768px){
    &:last-child {
      width: 20%;
    }
  }
`;

export const ProductInfo = styled(Link)`
  display: flex;
  gap: 15px;
  max-width: 500px;
  text-decoration: none;
  color: #000;
  & img {
    width: 140px;
    height: 140px;
  }
  & h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden !important;
    max-height: 57px;
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 1440px) {
    max-width: 400px;
    & img {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 1180px) {
    max-width: 300px;
    & img {
      width: 70px;
      height: 70px;
    }
  }
`;

export const IconButton = styled.button`
  background-color: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  &.with-text {
    margin-top: 20px;
  }
  & span {
    ${underlinedTypo}
  }
  &.fright {
    @media (max-width: 768px) {
      margin-left: auto;
    }
    @media (max-width: 480px) {
      margin-right: auto;
    }
  }
`;

export const TextButton = styled.button`
  ${underlinedTypo}
  width: 100%;
  text-align: right;
  background-color: inherit;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    text-align: center;
    width: max-content;
    display: block;
    margin: 0 auto 20px auto;
  }
`;

export const CartItems = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
  width: 100%;
  & .item {
    border-top: 1px solid #d9d9d9;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
    & .bold {
      font-weight: 700;
    }
    & .price {
      display: flex;
      gap: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
      & .old {
        text-decoration: line-through;
        text-decoration-color: #e42829;
        color: #737475;
      }
    }
    & .display-flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  & a {
    text-decoration: none;
    color: #000;
  }
  & .column-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  & h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden !important;
    max-height: 70px;
    font-size: 16px;
    font-weight: 400;
  }
  & img {
    width: 100px;
    height: 100px;
  }
`;
