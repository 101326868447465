import React from "react";
import { Address } from "./styled";

const AddressRadioContent = ({ address }) => {
  return (
    <Address>
      {Object.entries(address)
        .filter((elem) => elem[0] !== "id")
        .map((entry) => (
          <li key={entry[0]}>
            {entry[0] === "region" ? entry[1]?.name : entry[1]}
          </li>
        ))}
    </Address>
  );
};

export default AddressRadioContent;
