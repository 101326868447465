import styled, { css } from "styled-components";

const typo = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const typoL = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;

const firstLetterUppercase = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Form = styled.form`
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &.bt {
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
  }
  & .edit-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 26px;
    @media (min-width: 581px) {
      &.fullw {
        grid-column: 1/3;
      }
    }
    @media (max-width: 580px) {
      justify-content: center;
      margin-top: 0;
    }
  }
`;

export const EditBtn = styled.button`
  ${typo}
  background-color: transparent;
  text-decoration: underline;
  text-transform: capitalize;
`;

export const RemoveAccountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 40px;
  & p {
    ${typo}
    ${firstLetterUppercase}
    color: #373e4b;
  }
  & button {
    ${typoL}
    font-weight: 700;
    text-align: center;
    color: #373e4b;
    padding: 11px 46px;
    background: #ffffff;
    border: 1px solid #373e4b;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    max-width: 100%;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const AddressesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 700px;
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  padding: 20px;
  width: 100%;
  border: 1px solid #000000;
  border-radius: 10px;
  & .set-default-container {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    & .radio-outer {
      min-width: 20px;
      min-height: 20px;
      border: 1px solid #373e4b;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    & .radio-inner {
      width: 12px;
      height: 12px;
      background-color: #373e4b;
      border-radius: 35px;
      visibility: hidden;
      &.selected {
        visibility: visible;
      }
    }
  }
  & .btn {
    ${typo}
    color: #373e4b;
    background-color: transparent;
  }
  & .edit {
    text-decoration-line: underline;
  }
  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  & button::first-letter {
    text-transform: uppercase;
  }
  @media (max-width: 480px) {
    flex-direction: row-reverse;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

export const CurrentAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AddNewAddressButton = styled.div`
  background-color: #373e4b;
  border-radius: 5px;
  padding: 12px 28px;
  ${typoL}
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
  cursor: pointer;
`;
