import React, { useState } from "react";
import closeIcon from "../../../assets/closeIcon.svg";
import {
  PopupBody,
  PopupDivider,
  // PopupFormInput,
  PopupFormInputLabel,
  PopupFormInputWrapper,
  PopupFormSubmitButton,
  PopupFormWrapper,
  PupupHeader,
} from "./styled";
// import { FiltersContainer } from "../styled";
// import { FilterButton } from "../../../components/shared/button/styled";
import { useTranslation } from "react-i18next";
// import { PatternFormat } from "react-number-format";
// import { Textarea } from "../../../components/dialogs/styled";
import Modal from "./Modal";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useSelector } from "react-redux";
import { useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import checkIcon from "../../../assets/checkIcon.svg";
import { ErrorText } from "./styled";
import {Input} from "../../product/styled";

const ActivateBonuses = ({ handleClose, point }) => {
  const { t } = useTranslation();
  // const [cashSelected, setCashSelected] = useState(true);
  // const [creditSelected, setCreditSelected] = useState(false);

  const [error, setError] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const [points, setPoints] = useState('');

  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0]);
  const headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    if (succeeded) {
      return;
    }
    axios
      .post(
        `${baseUrl}/partners/bonuses/request`,
        {
          points: Number(points),
        },
        { headers }
      )
      .then(() => {
        setSucceeded(true);
        setTimeout(() => {
          setSucceeded(false);
          handleClose();
          window.location.reload()
        }, 3000);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };


  return (
    <Modal handleClose={handleClose}>
      <PupupHeader>
        <p className="heading">{t("actions.activate")}</p>
        <img onClick={handleClose} src={closeIcon} alt="close" />
      </PupupHeader>
      <PopupDivider></PopupDivider>
      <PopupBody>
        <PopupFormWrapper onSubmit={handleSubmit}>
          <PopupFormInputWrapper>
            <PopupFormInputLabel>
              {t("texts.moneyCount")}
            </PopupFormInputLabel>
            <Input name={"points"} placeholder={point} value={points} onChange={(e)=>setPoints(e.target.value)}/>
          </PopupFormInputWrapper>
          {/* <PopupFormInputWrapper>
            <PopupFormInputLabel>
              {t("actions.choose_how_to_get_points")}
            </PopupFormInputLabel>
            <FiltersContainer className="popup-filters-container">
              <FilterButton
                type="button"
                onClick={() => {
                  setCashSelected(true);
                  setCreditSelected(false);
                }}
                className={cashSelected && "active"}
              >
                {t("texts.cash")}
              </FilterButton>
              <FilterButton
                type="button"
                onClick={() => {
                  setCashSelected(false);
                  setCreditSelected(true);
                }}
                className={creditSelected && "active"}
              >
                {t("texts.card")}
              </FilterButton>
            </FiltersContainer>
          </PopupFormInputWrapper> */}
          <PopupFormInputWrapper>
            <PopupFormInputLabel as="p">
              {t("actions.go_to_address")}
            </PopupFormInputLabel>
            <div>
              <b>{t("texts.points_address")}</b>
            </div>
          </PopupFormInputWrapper>
          {/* {cashSelected ? (
          ) : (
            <PopupFormInputWrapper>
              <PopupFormInputLabel>{t("texts.name")}</PopupFormInputLabel>
              <PopupFormInput />
              <PopupFormInputLabel className="mt">
                {t("actions.fill_in_card_numbers")}
              </PopupFormInputLabel>
              <PatternFormat
                customInput={PopupFormInput}
                placeholder="0000 0000 0000 0000"
                format="#### #### #### ####"
                mask="_"
              />
            </PopupFormInputWrapper>
          )} */}
          {error && <ErrorText>{error}</ErrorText>}
          <PopupFormSubmitButton className={succeeded && "success"}>
            <img src={checkIcon} alt="sent" width="18" />
            <span>{t("actions.confirm")}</span>
          </PopupFormSubmitButton>
        </PopupFormWrapper>
      </PopupBody>
    </Modal>
  );
};

export default ActivateBonuses;
