import React, {useState, useEffect} from "react";
import {Trow, Tdata, ProductInfo, IconButton} from "./styled";
import CounterBasket from "../../components/counter-basket/CounterBasket";

const CartItem = ({
                      total,
                      product,
                      initCount,
                      deleteIcon,
                      handleDelete,
                      handleCountChange,
                      reload,
                  }) => {
    const unit = product?.unit;
    const step = +product?.unit?.step || 1;
    const [count, setCount] = useState(+initCount);

    useEffect(() => {
        setCount(+initCount);
    }, [initCount, reload]);

    const fixNum = (init) => {
        return Number.isInteger(init)
            ? parseFloat(init).toFixed(0)
            : parseFloat(init).toFixed(2);
    };

    const fixCount = () => {
        return parseFloat((Math.ceil(count / step) * step).toFixed(2));
    };

    const handleBlur = () => {
        if (!count) {
            setCount(initCount);
        } else if (product?.unit?.type === "int") {
            const num = +fixCount();
            setCount(num);
            handleCountChange(num, product.id);
        } else {
            handleCountChange(+count, product.id);
        }
    };

    const handleCountChangeByBtn = (type) => {
        let init = type === "dec" ? +count - step : +count + step;
        const num = +fixNum(init);
        handleCountChange(num, product.id);
    };

    return (
        <Trow>
            <Tdata>
                <ProductInfo to={`/products/${product.id}`}>
                    <img
                        width="140"
                        height="140"
                        src={product?.images?.main?.src}
                        alt="product"
                    />
                    <h5>{product.name || "product name"}</h5>
                </ProductInfo>
            </Tdata>
            <Tdata className="bold price">
        <span className={product?.discountedPrice?.[0] !== "0" ? "old" : ""}>
          {product?.price}
        </span>
                {product?.discountedPrice?.[0] !== "0" && (
                    <span>{product?.discountedPrice}</span>
                )}
            </Tdata>
            <Tdata className="count">
                <CounterBasket
                    count={count}
                    handleBlur={handleBlur}
                    handleChange={(e) => setCount(e.target.value)}
                    unit={unit}
                    step={step}
                    handleCountChange={handleCountChangeByBtn}
                />
            </Tdata>
            <Tdata className="bold">
                <span>{total}</span>
            </Tdata>
            <Tdata>
                <IconButton onClick={() => handleDelete(product.id)}>
                    <img src={deleteIcon} alt="x" width="20" height="20"/>
                </IconButton>
            </Tdata>
        </Trow>
    );
};

export default CartItem;
