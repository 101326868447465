import React from "react";
import { Label, Input } from "./styled";
import { useTranslation } from "react-i18next";

const OfferPriceInCartInputs = ({ productId, price, count, handleChange }) => {
  const { t } = useTranslation();
  return (
    <div className="grid">
      <div>
        <Label htmlFor="my_offer" className="fl">
          <span className="up">{t("texts.my_offered_price")}</span>
          <span>{`(${t("texts.as_total")})`}</span>
        </Label>
        <Input
          id="my_offer"
          //   className={errors?.["items.0.price"] && "error"}
          autoComplete="off"
          type="number"
          onKeyDown={(e) => {
            if (
              (!e.target.value.length && e.key === ".") ||
              (+e.target.value === 0 && e.key === "0") ||
              e.key === "e" ||
              e.key === "-" ||
              e.key === "+"
            ) {
              e.preventDefault();
            }
          }}
          value={price}
          onChange={(e) => handleChange(productId, "price", e.target.value)}
        />
        {/* {errors?.["items.0.price"] && (
          <ErrorText>{errors?.["items.0.price"]?.[0]}</ErrorText>
        )} */}
      </div>
      <div>
        <Label htmlFor="amount" className="fl">
          <span className="up">{t("texts.the_amount")}</span>
        </Label>
        <Input
          id="amount"
          //   className={errors?.["items.0.count"] && "error"}
          autoComplete="off"
          type="number"
          onKeyDown={(e) => {
            if (
              (!e.target.value.length && e.key === ".") ||
              (+e.target.value === 0 && e.key === "0") ||
              e.key === "e" ||
              e.key === "-" ||
              e.key === "+"
            ) {
              e.preventDefault();
            }
          }}
          value={count}
          onChange={(e) => handleChange(productId, "count", e.target.value)}
        />
        {/* {errors?.["items.0.count"] && (
          <ErrorText>{errors?.["items.0.count"]?.[0]}</ErrorText>
        )} */}
      </div>
    </div>
  );
};

export default OfferPriceInCartInputs;
