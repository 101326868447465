import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const typo = css`
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

export const TableSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  padding: 40px;
  gap: 23px;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1400px) {
    flex-direction: row;
    align-items: flex-end;
  }
  @media (max-width: 1245px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const RowSection = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  align-items: center;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const DeliveryKey = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 230px;
  flex-wrap: wrap;
`;
export const DeliveryItemRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-items: center;
  width: 100%;
  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: flex-end;
  }
  @media (max-width: 1245px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const DeliveryItem = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 5px;
  padding: 7px 11px;
  max-width: 250px;
  min-height: 80px;
  border: 1px solid #373E4B40;
  width: 100%;
  @media (max-width: 1245px) {
    max-width: 100%;
  }
`;
export const DeliveryKeyIcon = styled.img`
  max-height: 42px;
  max-width: 42px;
`;
export const DeliveryKeyName = styled.h3`
  
`;
export const DeliveryItemText = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
`;

export const DeliveryItemValue = styled.h3`
  
`;
export const DeliveryItemDescription = styled.h4`
  
`;
export const DeliveryItemIcon = styled.img`
  max-height: 42px;
  max-width: 42px;
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: black;
`;
