import styled, { css } from "styled-components";

const typo = css`
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #373e4b;
  @media (max-width: 1440px), (min-width: 1025px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Section = styled.section`
  background-color: #fff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  padding: 30px 20px;
  ${typo}
  & .black {
    color: #000;
  }
  width: 100%;
  @media (max-width: 1440px) {
    padding: 20px 10px;
  }
  @media (max-width: 1024px) {
    border: none;
  }
`;

export const Info = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1440px) {
    margin: 15px 0;
    gap: 10px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.bold {
    font-weight: 700;
  }
  &.points {
    text-transform: lowercase;
    flex-wrap: wrap;
    display: block;
    background-color: #d9d9d9;
    & .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
    }
    & .first-letter-upp {
      display: inline-block;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    & .divider {
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
    & .checkbox {
      padding: 8px 10px;
      & input {
        display: none;
      }
      & label {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        & .box {
          width: 16px;
          height: 16px;
          border: 1px solid #373e4b;
          position: relative;
        }
      }
      & input:checked ~ label .box::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #373e4b;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
`;

export const Button = styled.button`
  width: 100%;
  padding: 11.5px;
  ${typo}
  font-weight: bold;
  background-color: #ffe600;
  border-radius: 5px;
  &.grey {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    margin-top: 10px;
  }
  &:hover {
    opacity: 0.9;
  }
`;
