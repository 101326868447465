import React, {useEffect, useRef, useState} from "react";
import {
    ProductInfoContainer,
    BigText,
    ColumnFlex,
    SmallText,
    Actions,
    FilledButton,
    OutlineButton,
    ExpandBtn,
    Info, RowFlex, InfoText, InfoBlock, HeadingText, DescriptionText, Label, DeliveryItem, Line, DeliveryRowFlex,
} from "./styled";
import CounterBasket from "../../components/counter-basket/CounterBasket";
import cartIcon from "../../assets/cartIcon.svg";
import favouriteIcon from "../../assets/favouriteIcon.svg";
import favouriteIconActive from "../../assets/favouriteIconActive.svg";
import {useTranslation} from "react-i18next";
import OfferPriceDialog from "../../components/dialogs/OfferPriceDialog";
import Auth from "../../components/auth/Auth";
import Recover from "../../components/auth/Recover";
import {
    makeHidden,
    makeAuto,
} from "../../features/overflow-body/overflowOfMainSlice";
import {
    makeAuthHidden,
    makeAuthVisible,
} from "../../features/show-auth/showAuthSlice";
import {
    makeForgetPasswordHidden,
    makeForgetPasswordVisible,
} from "../../features/show-forget-password/showForgetPasswordSlice";
import {useDispatch, useSelector} from "react-redux";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";
import {changeCartLength} from "../../features/cart/cartSlice";
import axios from "axios";
import {baseUrl} from "../../constants";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import checkIcon from "../../assets/checkIcon.svg";
import {favsAdded} from "../../features/favs/favsSlice";
import expandIcon from "../../assets/descriptionExpandIcon.svg";
import {Rating} from "@mui/material";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {ProductInfoSkeleton} from "./Skeletons";
import {Content} from "./Descriptions.styled";
import {Badges as Container} from "../../components/cards/styled";
import deliveryTruck from "../../assets/delivery/deliveryTruck.svg";
import downArrow from "../../assets/collapse/down-arrow.svg";
import upArrow from "../../assets/collapse/up-arrow.svg";

const ProductInfo = ({product}) => {
    const {t} = useTranslation();
    const unit = product?.unit;
    const step = +product?.unit?.step || 1;
    const [count, setCount] = useState(step);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [openOfferPrice, setOpenOfferPrice] = useState(false);
    const [collapseDesc, setCollapseDesc] = useState(true);
    const [collapseCharacteristics, setCollapseCharacteristics] = useState(true);
    const [expandDesc, setExpandDesc] = useState(false);
    const [expandChar, setExpandChar] = useState(false);
    const [offerSuccess, setOfferSuccess] = useState(false);
    const [openSuccessReg, setOpenSuccessReg] = useState(false);

    const dispatch = useDispatch();
    const openAuth = useSelector((state) => state.showAuth);
    const openRecoverPassword = useSelector((state) => state.showForgetPassword);
    const token = useLocalStorageGetItem("token");
    const lang = useSelector((state) => state.lang[0]);
    const favs = useSelector((state) => state.favs);
    const fav = favs?.find((item) => item?.id === product?.id);
    const ref = useRef(null)

    const navigate = useNavigate();

    useEffect(() => {
        ref.current && (ref.current.innerHTML = product?.description)
    }, [ref?.current, t, product])

    const toggleExpand = () => setExpandDesc((ex) => !ex);
    const toggleCollapseDescription = () => setCollapseDesc((ex) => !ex);
    const toggleCollapseCharacteristics = () => setCollapseCharacteristics((ex) => !ex);
    const deliveryItems = [
        {
            name: t('delivery.type.title'),
            background: '#FFE500',
            items: [
                {
                    title: t('delivery.type.types.express.title'),
                },
                {
                    title: t('delivery.type.types.standard.title'),
                },
                {
                    title: t('delivery.type.types.courier.description'),
                },
                {
                    title: t('delivery.type.types.delivery.description'),
                },
            ],
        }, {
            name: t('delivery.location.title'),
            items: [
                {
                    title: t('delivery.location.types.city.description'),
                },
                {
                    title: t('delivery.location.types.city.description'),
                },
                {
                    title: t('delivery.location.types.city.description'),
                },
                {
                    title: t('delivery.location.types.delivery.description'),
                },
            ],
        }, {
            name: t('delivery.time.title'),
            items: [
                {
                    title: t('delivery.time.types.estimatedHours.description', {hours: '2-4'})
                },
                {
                    title: t('delivery.time.types.estimatedDays.title', {days: '1-2'})
                },
                {
                    title: t('delivery.time.types.estimatedHours.description', {hours: '2-4'})
                },
                {
                    title: t('delivery.time.types.estimatedDays.title', {days: '1-3'})
                },
            ],
        }, {
            name: t('delivery.price.title'),
            items: [
                {
                    title: t('delivery.price.types.exceptions.description', {price: 2000})
                },
                {
                    title: t('delivery.price.types.free.title')
                },
                {
                    title: t('delivery.price.types.deliveryPrice.title', {price: 1500}),
                },
                {
                    title: t('delivery.price.types.deliveryPrice.title', {price: 700}),
                },
            ],
        }, {
            name: t('delivery.notes.title'),
            items: [
                {
                    title: null
                },
                {
                    title: t('delivery.notes.types.exceptions.description')
                },
                {
                    title: t('delivery.notes.types.weightKg.description', {weight: 10})
                },
                {
                    title: t('delivery.notes.types.weightKg.description', {weight: 2})
                },
            ],
        },
    ];

    const upItems = [
        {
            name: null,
            border: 0,
            items: [
                {
                    title: t('up.type.types.weight.title'),
                },
                {
                    title: t('up.type.types.price.title'),
                },
            ],
        }, {
            name: t('up.onePack.title'),
            items: [
                {
                    title: t('up.onePack.types.weight.title'),
                },
                {
                    title: t('up.onePack.types.floor.title'),
                },
            ],
        }, {
            name: t('up.gips.title'),
            items: [
                {
                    title: t('up.gips.types.weight.title'),
                },
                {
                    title: t('up.gips.types.floor.title'),
                },
            ],
        }, {
            name: t('up.profile.title'),
            items: [
                {
                    title: t('up.profile.types.weight.title'),
                },
                {
                    title: t('up.profile.types.floor.title'),
                },
            ],
        }
    ];

    const handleOpenRecoverPassword = () => {
        dispatch(makeAuthHidden());
        dispatch(makeForgetPasswordVisible());
        dispatch(makeHidden());
    };

    const handleBackToLogIn = () => {
        dispatch(makeAuthVisible());
        dispatch(makeForgetPasswordHidden());
    };

    const handleOpenAuth = () => {
        openAuth ? dispatch(makeAuthHidden()) : dispatch(makeAuthVisible());
        dispatch(makeHidden());
    };

    const fixNum = (init) => {
        return Number.isInteger(init)
            ? parseFloat(init).toFixed(0)
            : parseFloat(init).toFixed(2);
    };

    const fixCount = () => {
        return parseFloat((Math.ceil(count / step) * step).toFixed(2));
    };

    const handleChange = (e) => {
        setCount(e.target.value);
    };

    const handleBlur = () => {
        if (!count) {
            setCount(step);
        } else if (product?.unit?.type === "int") {
            const num = +fixCount();
            setCount(num);
        }
    };

    const handleCountChangeByBtn = (type) => {
        let init = type === "dec" ? +count - step : +count + step;
        const num = fixNum(init);
        setCount(num);
    };

    const handleAddToCart = () => {
        const ip = localStorage.getItem('ip');
        if (!ip && !token) {
            const newIp = (Math.floor(Math.random() * 255) + 1) + "." + (Math.floor(Math.random() * 255)) + "." + (Math.floor(Math.random() * 255)) + "." + (Math.floor(Math.random() * 255))
            localStorage.setItem("ip", newIp)
        }
        const data = {id: product?.id, count: +count};
        let headers = {
            "Accept-Language": lang.val,
        };
        if (token) {
            headers = {...headers, Authorization: `Bearer ${token}`};
        }
        axios
            .post(`${baseUrl}/carts/add${ip ? `?ip=${ip}` : ''}`, data, {headers})
            .then(() => {
                setIsSucceeded(true);
                dispatch(changeCartLength());
                setTimeout(() => {
                    setIsSucceeded(false);
                    setCount(step);
                }, 3000);
            })
            .catch((err) => {
                err.response?.data?.message === "validation.cart.count.available.false"
                    ? setErrorMessage(t("texts.unavailable_count"))
                    : setErrorMessage(t("texts.unexpected_error"));
                setError(true);
                setCount(step);
            });
    };

    const handleFavouriteClick = () => {
        if (!fav) {
            axios
                .post(
                    `${baseUrl}/products/favorites`,
                    {
                        products: [product?.id],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => res?.data?.data?.favorites)
                .then((data) => {
                    dispatch(favsAdded(data));
                });
        } else {
            axios
                .delete(`${baseUrl}/products/favorites/${product?.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) =>
                    dispatch(favsAdded(favs.filter((f) => f.id !== fav.id)))
                );
        }
    };

    const handleShowOfferPrice = () => setOpenOfferPrice((prev) => !prev);

    const handleCloseOfferDialog = (status) => {
        console.log(status, status === "success");
        if (status === "success") {
            setOfferSuccess(true);
        }
        setOpenOfferPrice(false);
    };

    return (
        <>
            <ProductInfoContainer>
                <ColumnFlex>
                    <BigText as="h1" className="uppercase">
                        {product?.name}
                    </BigText>
                    <Rating
                        size="large"
                        onChange={() => {
                        }}
                        value={product?.rating || null}
                        readOnly
                    />
                    <RowFlex>
                        <Info className="cl">
                            <Info>
                                <SmallText as="span" className="light">{t("texts.product_code")}/<InfoText
                                    className="bold">{product?.code}</InfoText></SmallText>
                            </Info>
                        </Info>
                        <Info>
                            <SmallText as="span" className="light" as={Link} to={`/best-brands/${product?.brand}/1`}>
                                {t("texts.brand")}/<InfoText>{product?.brand}</InfoText>
                            </SmallText>
                        </Info>
                    </RowFlex>
                    <div className={product?.bonusPercent ? 'bonusWrapper' : 'hidden'} style={{position: "relative"}}>
                        <Container single>
                            {!!product?.bonusPercent &&
                                <span className="saleBonus">-{product?.bonusPercent}% {t("texts.sale")}</span> }
                        </Container>
                    </div>
                    <div className={product?.bonus ? 'bonusWrapper' : 'hidden'} style={{position: "relative"}}>
                        <Container single>
                            {!!product?.bonus &&
                                <span className="bonus">{t('texts.accumulatingPoints')} - {product?.bonus}</span>}
                        </Container>
                    </div>
                </ColumnFlex>
                <ColumnFlex>
                    <BigText>
                        <span className={product?.discountedPrice?.[0] !== "0" ? "old" : ""}
                        > {product?.price} / {product?.unit?.name} </span>
                        {product?.discountedPrice?.[0] !== "0" && (
                            <span>{product?.discountedPrice} / {product?.unit?.name}</span>
                        )}
                    </BigText>
                    <Actions>
                        <RowFlex>
                            <div className="btns">
                                <RowFlex className="notFullWidth">
                                    <CounterBasket
                                        count={count}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        unit={unit}
                                        step={step}
                                        handleCountChange={handleCountChangeByBtn}
                                    />
                                </RowFlex>
                                <RowFlex className="notFullWidth">
                                    <FilledButton
                                        onClick={handleAddToCart}
                                        className={isSucceeded ? "success" : ""}
                                    >
                                        <div>
                                            <span>{t("actions.add")}</span>
                                            <img src={cartIcon} alt=""/>
                                        </div>
                                        <img
                                            className="success-icon"
                                            src={checkIcon}
                                            alt="sent"
                                            width="18"
                                        />
                                    </FilledButton>
                                    <OutlineButton
                                        onClick={!token ? handleOpenAuth : handleFavouriteClick}
                                    >
                                        <span>{t("texts.favourite")}</span>
                                        <img src={fav ? favouriteIconActive : favouriteIcon} alt=""/>
                                    </OutlineButton>
                                </RowFlex>
                            </div>
                            <RowFlex className="notFullWidth">
                                <FilledButton
                                    className="blue"
                                    onClick={!token ? handleOpenAuth : handleShowOfferPrice}
                                >
                                    <SmallText className={'blue'}>{t("actions.offer_price")}</SmallText>
                                </FilledButton>
                            </RowFlex>
                        </RowFlex>
                    </Actions>
                </ColumnFlex>

                <ColumnFlex>
                    <RowFlex>
                        <ColumnFlex>
                            <RowFlex>
                                <Label><img src={deliveryTruck} style={{height: '15px'}}/> {t('texts.delivery')}</Label>
                            </RowFlex>
                            <DeliveryRowFlex>
                                {deliveryItems.map((item) =>
                                    <ColumnFlex>
                                        <Label>{item.name}</Label>
                                        <ColumnFlex>
                                            {item.items.map((deliveryItem) =>
                                                <DeliveryItem style={{backgroundColor: item.background}}>
                                                    {(deliveryItem.title ?? <Line/>)}
                                                </DeliveryItem>
                                            )}
                                        </ColumnFlex>
                                    </ColumnFlex>
                                )}
                            </DeliveryRowFlex>
                        </ColumnFlex>
                    </RowFlex>
                </ColumnFlex>
                <ColumnFlex>
                    <RowFlex>
                        <ColumnFlex>
                            <RowFlex>
                                <Label>{t('texts.dumping')}</Label>
                            </RowFlex>
                            <DeliveryRowFlex>
                                {upItems.map((item) =>
                                    <ColumnFlex>
                                        <Label>{item.name}</Label>
                                        <ColumnFlex>
                                            {item.items.map((deliveryItem) =>
                                                <DeliveryItem>
                                                    {(deliveryItem.title ?? <Line/>)}
                                                </DeliveryItem>
                                            )}
                                        </ColumnFlex>
                                    </ColumnFlex>
                                )}
                            </DeliveryRowFlex>
                        </ColumnFlex>
                    </RowFlex>
                </ColumnFlex>

                <ColumnFlex>
                    {product?.description ? (
                        <InfoBlock>
                            <ColumnFlex>
                                <RowFlex onClick={() => toggleCollapseDescription()} className="spaceBetween">
                                    <HeadingText className="uppercase bold pointer">
                                        {t("texts.description")}
                                    </HeadingText>
                                    {collapseDesc ? <img src={downArrow}/> : <img src={upArrow}/>}
                                </RowFlex>
                                <RowFlex className={collapseDesc ? 'collapsed' : ''}>
                                    <DescriptionText ref={ref} className={expandDesc ? "" : "not-expanded"}>
                                        {product?.description}
                                    </DescriptionText>
                                </RowFlex>
                            </ColumnFlex>
                        </InfoBlock>
                    ) : null}

                    {product?.characteristics?.length ? (
                        <InfoBlock>
                            <ColumnFlex>
                                <RowFlex onClick={() => toggleCollapseCharacteristics()} className="spaceBetween">
                                    <HeadingText className="uppercase bold pointer">
                                        {t("texts.characteristic")}
                                    </HeadingText>
                                    {collapseCharacteristics ? <img src={downArrow}/> : <img src={upArrow}/>}
                                </RowFlex>
                                <ColumnFlex className={collapseCharacteristics ? 'collapsed' : ''}>
                                    {product?.characteristics?.map(item =>
                                        <DescriptionText><b>{item?.name}</b> {item?.value}</DescriptionText>
                                    )}
                                </ColumnFlex>
                            </ColumnFlex>
                        </InfoBlock>
                    ) : null}
                </ColumnFlex>

            </ProductInfoContainer>
            {openOfferPrice && (
                <OfferPriceDialog
                    open={OfferPriceDialog}
                    productId={product?.id}
                    handleClose={handleCloseOfferDialog}
                />
            )}
            {openAuth && (
                <Auth
                    open={openAuth}
                    handleClose={(success) => {
                        // if (success === "success") {
                        //   setOpenSuccessReg(true);
                        // }
                        dispatch(makeAuthHidden());
                        dispatch(makeAuto());
                    }}
                    handleOpenRecoverPassword={handleOpenRecoverPassword}
                />
            )}
            {openRecoverPassword && (
                <Recover
                    open={openRecoverPassword}
                    handleClose={() => {
                        dispatch(makeForgetPasswordHidden());
                        dispatch(makeAuto());
                    }}
                    handleBackToLogIn={handleBackToLogIn}
                />
            )}
            {error && (
                <ErrorDialog
                    open={error}
                    handleClose={() => {
                        setError(false);
                    }}
                    message={errorMessage}
                />
            )}
            {openSuccessReg && (
                <ErrorDialog
                    open={openSuccessReg}
                    success="success"
                    handleClose={() => {
                        setOpenSuccessReg(false);
                    }}
                    message={t("texts.check_your_email")}
                />
            )}
            {offerSuccess && (
                <ErrorDialog
                    open={offerSuccess}
                    success="success"
                    handleClose={() => {
                        navigate("/personal-page/price-offers");
                    }}
                    message={t("texts.price_offer_message")}
                />
            )}
        </>
    );
};

export default ProductInfo;
