import React, { useEffect, useState } from "react";
import {Routes, Route, useNavigate} from "react-router";
import Home from "./pages/home/Home";
import SubCategories from "./pages/categories/SubCategories";
import Product from "./pages/product/Product";
import Cart from "./pages/cart/Cart";
import ShinmugClub from "./pages/shinmag-club/ShinmugClub";
import Checkout from "./pages/checkout/Checkout";
import Header from "./components/header";
import Footer from "./components/footer/Footer";
import {Container, ContainerFluid, Wrapper} from "./shared/styled";
import SubCategoryProducts from "./pages/categories/SubCategoryProducts";
import Company from "./pages/company/Company";
import Services from "./pages/company/Services";
import { useSelector, useDispatch } from "react-redux";
import PersonalPage from "./pages/personal-page/PersonalPage";
import SingleOrder from "./pages/personal-page/orders/SingleOrder";
import NewsList from "./pages/news/NewsList";
import NewsSingle from "./pages/news/NewsSingle";
import BestBrands from "./pages/best-brands/BestBrands";
import NotFound from "./pages/not-found";
import Verification from "./pages/verification";
import Searched from "./pages/searched";
import ScrollToTop from "./ScrollToTop";
import { addAppHeight } from "./features/appHeight/appHeightSlice";
import Offers from "./pages/personal-page/offers/Offers";
import OrdersList from "./pages/personal-page/orders/OrdersList";
import Favorites from "./pages/personal-page/favorites/Favorites";
import EditData from "./pages/personal-page/settings/EditData";
import EditAddresses from "./pages/personal-page/settings/EditAddresses";
import ProductReviews from "./pages/product-reviews";
import ResetPassword from "./pages/reset-password";
import Credit from "./pages/company/credit";
import Refund from "./pages/company/refund";
import Exchange from "./pages/company/exchange";
import GiftCards from "./pages/personal-page/gift-cards/GiftCards";
import GiftCard from "./pages/company/gift-card";
import Bonuses from "./pages/company/bonuses";
import Terms from "./pages/company/terms";
import Contacts from "./pages/company/contacts";
import About from "./pages/company/about";
import { useTranslation } from "react-i18next";
import { changeLang } from "./features/lang/langSlice";
import Guarantee from "./pages/company/guarantee";
import CompanyNavigation from "./pages/company/CompanyNavigation";
import Help from "./pages/company/Help";
import ForBusiness from "./pages/for-business";
import Delivery from "./pages/delivery";
import Courier from "./pages/delivery/prices/Courier";
import Express from "./pages/delivery/prices/Express";
import Standard from "./pages/delivery/prices/Standard";
import OrderSucceeded from "./pages/succeeded-order";
import Points from "./pages/personal-page/points";
import PrivacyPolicy from "./pages/company/privacy-policy";
import SEO from "./components/seo";
import ReturnedItems from "./pages/personal-page/orders/ReturnedItems";
import ActivateGift from "./pages/activate/ActivateGift";
import {LiveChatWidget} from "@livechat/widget-react";
import {liveChat} from "./constants";
import {changeState, deleteKey} from "./utils/funcs/formHandlings";

const App = () => {
  const isHiddenOverlow = useSelector((state) => state.overflowOfMain);
  const [vHeight, setVHeight] = useState(0);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const [name, setName] = useState("");

  useEffect(() => {
    const height = window.innerHeight;
    setVHeight(height);
    dispatch(addAppHeight(height));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "en") {
      i18n.changeLanguage("hy");
      dispatch(changeLang("hy"));
    }
  }, []);

  useEffect(() => {
    if (user) {
      changeState(user.fullName, setName)
    }
  }, [user]);

  function handleNewEvent(event) {
    console.log('LiveChatWidget.onNewEvent', event)
  }

  return (
    <Wrapper
      id="wrapper"
      className={isHiddenOverlow && "overflow-hidden"}
      vheight={vHeight}
    >
   {/*   <SEO
          title={"product?.name"}
          description={"product?.description"}
          image={"product?.images?.main?.src"}
          // image={SharedLogo}
      />*/}
      <ContainerFluid>
        <Header />
        <Container>
          <ScrollToTop />
          <LiveChatWidget
              license={liveChat.license}
              visibility={liveChat.visibility}
              onNewEvent={handleNewEvent}
              customerName={name}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/:categoryId/:subcategoriesId"
              element={<SubCategories />}
            />
            <Route
              path="/sub-category/:subcategoryId/:page"
              element={<SubCategoryProducts />}
            />
            <Route path="/products/:productId" element={<Product />} />
            <Route
              path="/products/:productId/reviews"
              element={<ProductReviews />}
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/shinmag-club" element={<ShinmugClub />} />
            <Route path="/personal-page" element={<PersonalPage />}>
              <Route path="price-offers" element={<Offers />} />
              <Route path="gift-cards" element={<GiftCards />} />
              <Route path="orders/:page" element={<OrdersList />} />
              <Route path="order/:id" element={<SingleOrder />} />
              <Route path="order/returned/:id" element={<ReturnedItems />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="addresses/:page" element={<EditAddresses />} />
              <Route path="personal-data" element={<EditData />} />
              <Route path="points" element={<Points />} />
            </Route>
            <Route path="/checkout/:orderId" element={<Checkout />} />
            <Route path="info" element={<Company />}>
              <Route path="company" element={<CompanyNavigation />}>
                <Route path="bonuses" element={<Bonuses />} />
                <Route path="about" element={<About />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="gift-cards" element={<GiftCard />} />
                <Route path="guarantee" element={<Guarantee />} />
              </Route>
              <Route path="services" element={<Services />}>
                <Route path="credit" element={<Credit />} />
              </Route>
              <Route path="help" element={<Help />}>
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="exchange" element={<Exchange />} />
                <Route path="refund" element={<Refund />} />
              </Route>
            </Route>
            <Route path="/activate/gift/:id" element={<ActivateGift/>}/>
            <Route path="/news/:page" element={<NewsList />} />
            <Route path="/news/single/:id" element={<NewsSingle />} />
            <Route path="/best-brands/:name/:page" element={<BestBrands />} />
            <Route path="/searched/:key/:page" element={<Searched />} />
            <Route path="/verification/:token" element={<Verification />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/for-business" element={<ForBusiness />} />
            <Route path="/delivery-and-dumping" element={<Delivery />}/>
            <Route path="/order-succeeded" element={<OrderSucceeded />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </ContainerFluid>
    </Wrapper>
  );
};

export default App;
