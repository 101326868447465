import styled from "styled-components";

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  max-width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1440px) {
    column-gap: 10px;
  }
`;
