import serviceIcon from "../../../assets/serviceIcon.svg";
import moneyIcon from "../../../assets/moneyIcon.svg";
import supportIcon from "../../../assets/supportIcon.svg";
import prosIcon from "../../../assets/prosIcon.svg";

export const services = {
  hy: [
    {
      icon: serviceIcon,
      title: "ՍՊԱՍԱՐԿՈՒՄ",
      content: [
        "Պրոֆեսիոնալ սպասարկում և պատասխանատու վերաբերմունք",
        "Ամենամեծ օնլայն հարթակը/` ավելի քան 10,000 անուն ապրանքատեսակ",
        "Որակավորված վարպետ-մասնագետների ծառայություն",
        "Դռնից դուռ առաքում",
      ],
    },
    {
      icon: moneyIcon,
      title: "ՄԱՏՉԵԼԻ ԳՆԵՐ",
      content: [
        "Մրցունակ գներ",
        "Ճկուն հետվճարային համակարգ",
        "Գնային առաջարկ կատարելու հնարավորություն",
        "ԱԱՀ-ի հաշվանցման հնարավորություն",
        "Հաճախորդների հետ համագործակցություն առանց միջնորդների",
        "Չպլանավորված ծախսերի կրճատում",
      ],
    },
    {
      icon: supportIcon,
      title: "ՄՈՏԵՑՈՒՄ",
      content: [
        "Հաշվի են առնվում հաճախորդի բոլոր պահանջմունքները և ցանկությունները",
        "Պատվերների ընդհանուր պատմության էջի առկայություն",
        "Առանց աշխատատեղից դուրս գալու ընտրություն կատարելու հնարավորություն",
        "Անհատական մոտեցում և ուշադրություն աշխատանքի ցանկացած փուլում",
        "Ժամանակի և ռեսուրսների խնայում",
      ],
    },
    {
      icon: prosIcon,
      title: "SHINMAG-Ի ՀԵՏ ԱՌԱՎԵԼՈՒԹՅՈՒՆՆԵՐԸ ՇԱՏ ԵՆ",
      content: [
        "Ճկուն վճարային համակարգ",
        "Ընդհանուր փաթեթներ և առաջարկներ",
        "Կորպորատիվ հաճախորդ",
        "Ճկուն հետվճարային համակարգ և ԱԱՀ –ի հաշվանցման հնարավորություն",
        "Անձնական էջ գնման պատմությամբ",
        "Անհատական կորպորատիվ առաջարկ",
        "Փորձառու և վստահելի վարպետներ",
      ],
    },
  ],
  ru: [
    {
      icon: serviceIcon,
      title: "ОБСЛУЖИВАНИЕ",
      content: [
        "Профессиональная консультация и ответственное отношение",
        "Самая больная онлайн платформа - более 10 000 наименований продукции",
        "Предоставление услуг квалифицированных мастеров",
        "Доставка от двери до двери",
      ],
    },
    {
      icon: moneyIcon,
      title: "ДОСТУПНЫЕ ЦЕНЫ",
      content: [
        "Конкурентные цены",
        "Гибкая система рассрочки",
        "Возможность предложения своей цены на конкретный товар",
        "Возможность вычета НДС",
        "Сотрудничество с клиентами без посредников",
        "Сокращение незапланированных расходов",
      ],
    },
    {
      icon: supportIcon,
      title: "ПОДХОД",
      content: [
        "Учитываем все желания и потребности клиента",
        "Отслеживание истории заказов в личном кабинете",
        "Возможность сделать свой выбор, не покидая рабочего места",
        "Сопровождение и внимание на любом этапе",
        "Экономия времени и ресурсов",
      ],
    },
    {
      icon: prosIcon,
      title: "МНОЖЕСТВО ПРЕИМУЩЕСТВ С SHINMAG",
      content: [
        "Гибкая система оплаты",
        "Общие пакеты и предложения",
        "Корпоративный клиент",
        "Гибкая система постоплаты и возможность вычета НДС",
        "Личная страница с историей покупок",
        "Индивидуальное корпоративное предложение",
        "Опытные и надежные мастера",
      ],
    },
  ],
};
