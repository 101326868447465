import React from "react";
import { InfoContainer, InfoParagraph } from "./styled";

const DataContainer = ({ children, as }) => {
  return (
    <InfoContainer>
      <InfoParagraph as={as}>{children}</InfoParagraph>
    </InfoContainer>
  );
};

export default DataContainer;
