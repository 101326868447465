import React, { useState, useEffect } from "react";
import CounterBasket from "../../components/counter-basket/CounterBasket";
import { ItemInfo, IconButton } from "./styled";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CartItemMobile = ({
  product,
  initCount,
  deleteIcon,
  handleDelete,
  handleCountChange,
  reload,
}) => {
  const { t } = useTranslation();
  const unit = product?.unit;
  const step = +product?.unit?.step || 1;
  const [count, setCount] = useState(+initCount);

  useEffect(() => {
    setCount(+initCount);
  }, [initCount, reload]);

  const fixNum = (init) => {
    return Number.isInteger(init)
      ? parseFloat(init).toFixed(0)
      : parseFloat(init).toFixed(2);
  };

  const fixCount = () => {
    return parseFloat((Math.ceil(count / step) * step).toFixed(2));
  };

  const handleBlur = () => {
    if (!count) {
      setCount(initCount);
    } else if(product?.unit?.type === "int"){
      const num = +fixCount();
      handleCountChange(num, product.id);
    } else {
      handleCountChange(+count, product.id);
    }
  };

  const handleCountChangeByBtn = (type) => {
    let init = type === "dec" ? +count - step : +count + step;
    const num = +fixNum(init);
    handleCountChange(num, product.id);
  };

  return (
    <div className="item">
      <ItemInfo>
        {!!product?.images?.main?.src && (
          <Link to="/products/prod">
            <img
              width="100"
              height="100"
              src={product?.images?.main?.src}
              alt="product"
              className="main-img"
            />
          </Link>
        )}
        <div className="column-flex">
          <Link to="/products/prod">
            <h5>{product.name || "product name"}</h5>
          </Link>
          <div className="bold price">
            <span
              className={product?.discountedPrice?.[0] !== "0" ? "old" : ""}
            >
              {product?.price}
            </span>
            {product?.discountedPrice?.[0] !== "0" && (
              <span>{product?.discountedPrice}</span>
            )}
            <span>
              {!product.count?.[0] !== "0"
                ? t("texts.product_available")
                : t("texts.product_not_available")}
            </span>
          </div>
        </div>
      </ItemInfo>
      <div className="display-flex">
        <IconButton onClick={() => handleDelete(product.id)}>
          <img src={deleteIcon} alt="x" width="20" height="20" />
          <span>{t("actions.remove")}</span>
        </IconButton>
        <CounterBasket
          count={count}
          handleBlur={handleBlur}
          handleChange={(e) => setCount(e.target.value)}
          unit={unit}
          step={step}
          handleCountChange={handleCountChangeByBtn}
        />
      </div>
    </div>
  );
};

export default CartItemMobile;
