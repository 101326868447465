import styled, { css } from "styled-components";
import expandIcon from "../../assets/expandIconMenu.svg";
import { searchScrollbar } from "../../shared/styled";

const typo = css`
  font-size: 16px;
  line-height: 18px;
`;

const border = css`
  background: #fff;
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

const firstLetterUppercase = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Container = styled.div`
  margin-bottom: 20px;
  &.product-page,
  &.work-field,
  &.checkout {
    margin-bottom: 0;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  &.work-field,
  &.checkout {
    margin-bottom: 0;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.hours {
    max-width: 220px;
  }
`;

export const SelectButton = styled.button`
  ${typo}
  ${border}
  color:#000;
  padding: 14px 15px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: left;
  ${firstLetterUppercase}
  &::after {
    content: url(${expandIcon});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
  &.expanded {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
    border-radius: 10px 10px 0 0;
  }
  & .placeholder {
    color: #8e8e8e;
  }
  & span {
    display: inline-block;
    overflow: hidden;
    height: 18px;
    max-width: calc(100% - 20px);
  }
  &.error {
    border-color: #e42829;
  }
  &.checkout {
    min-width: 220px;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const Options = styled.div`
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  ${border}
  border-radius: 0 0 10px 10px;
  border-top: none;
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  & ul {
    list-style-type: none;
    max-height: 200px;
    overflow-y: auto;
    ${searchScrollbar}
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

export const Option = styled.li`
  & button {
    padding: 5px 15px;
    margin: 5px 0;
    width: 100%;
    display: block;
    ${typo}
    color:#373e4b;
    text-align: left;
    background-color: inherit;
    ${firstLetterUppercase}
  }
`;
