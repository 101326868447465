import React, {useState} from "react";
import OrderCard from "./OrderCard";
import {
    OrderButtonsWrapper,
    OrderCardsWrapper,
    OrderContainer,
} from "./styled";
import {OrdersListContainer} from "./styled";
import {useTranslation} from "react-i18next";
import {useGetData, useLocalStorageGetItem} from "../../../utils/hooks/hooks";
import {useParams, useNavigate, Link} from "react-router-dom";
import EmptyList from "../../../components/empty-list";
import CustomPagination from "../../../components/pagination/CustomPagination";
import OrderCardSkeleton from "./skeletons/OrderCardSkeleton";

const OrdersList = () => {
    const {t} = useTranslation();
    const token = useLocalStorageGetItem("token");
    const navigate = useNavigate();
    const {page} = useParams();
    const [currentPage, setCurrentPage] = useState(page);
    const {
        data: orders,
        meta,
        isLoading,
    } = useGetData(
        `users/orders?perPage=10&page=${currentPage}`,
        [page],
        true,
        token
    );

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
        navigate(`/personal-page/orders/${page}`);
    };

    return (
        <>
            <OrdersListContainer>
                {isLoading ? (
                    <OrderContainer>
                        <OrderCardsWrapper>
                            {Array.from({length: 6}).map((x, i) => (
                                <OrderCardSkeleton key={i}/>
                            ))}
                        </OrderCardsWrapper>
                    </OrderContainer>
                ) : !!orders?.length ? (
                    orders?.map((order) => (
                        <OrderContainer key={order.id}>
                            <OrderCard key={order.id} product={order}/>
                        </OrderContainer>
                    ))
                ) : (
                    <EmptyList/>
                )}
            </OrdersListContainer>
            {!isLoading && meta?.last_page > 1 && (
                <CustomPagination
                    page={+currentPage}
                    pageCount={meta?.last_page}
                    handlePageChange={handlePageChange}
                />
            )}
        </>
    );
};

export default OrdersList;
