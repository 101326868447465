import React from "react";
import {
  ArticleContainer,
  ArticleHeading,
  ArticleParagraph,
  Date,
  NewsSingleContainer,
} from "./styled";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetData } from "../../utils/hooks/hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import EmptyList from "../../components/empty-list";
import { PageTitle } from "../categories/styled";

const NewsSingle = () => {
  const { t } = useTranslation();
  const vHeight = useSelector((state) => state.appHeight.val);
  const { id } = useParams();
  const { data: news, isLoading } = useGetData(`articles/${id}`);

  const nav = [
    { to: `/news/1`, name: t("texts.news") },
    { to: `/news/single/${id}`, name: t("texts.article") },
  ];

  return (
    <NewsSingleContainer vHeight={vHeight}>
      <Breadcrumbs nav={nav} />
      <PageTitle style={{ marginBottom: "30px" }}>{news?.title || ""}</PageTitle>
      {isLoading && !news ? (
        <>
          <Skeleton className="img" />
          <Skeleton className="title" />
          <Skeleton className="text" />
          <Skeleton className="text" />
          <Skeleton className="text half" />
        </>
      ) : !news ? (
        <EmptyList />
      ) : (
        <>
          <img className="news-img" src={news?.images?.main?.src} alt="news" />
          <ArticleContainer>
            <ArticleParagraph dangerouslySetInnerHTML={{ __html: news?.content }}/>
            <Date>{moment(news?.created).format("DD-MM-YYYY")}</Date>
          </ArticleContainer>
        </>
      )}
    </NewsSingleContainer>
  );
};

export default NewsSingle;
