import React, { useState } from "react";
import { generalInfo } from "../../shared/generalInfo";
import {
  Accordion,
  AccordionHeader,
  AccordionContent,
  Li,
  CustomLink,
} from "./ServicesInfo.styled";
import expandIcon from "../../assets/expandIconMenu.svg";
import { useTranslation } from "react-i18next";

const ServicesInfo = ({ handleCloseMenu }) => {
  const [expanded, setExpanded] = useState(null);
  const { t } = useTranslation();

  return (
    <>
      {generalInfo.map((item) => (
        <Accordion key={item.id} className={expanded === item.id && "expanded"}>
          <AccordionHeader
            className={expanded === item.id && "expanded"}
            onClick={() =>
              expanded === item.id ? setExpanded(null) : setExpanded(item.id)
            }
          >
            <span>{t(`texts.${item.name}`)}</span>
            <img src={expandIcon} alt="expand" width="8" height="5" />
          </AccordionHeader>
          <AccordionContent>
            {item.items.map((elem) => (
              <Li key={elem.id} onClick={handleCloseMenu}>
                <CustomLink to={elem.path}>
                  {t(`texts.${elem.name}`)}
                </CustomLink>
              </Li>
            ))}
          </AccordionContent>
        </Accordion>
      ))}
    </>
  );
};

export default ServicesInfo;
