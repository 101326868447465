import React from "react";
import DataContainer from "../DataContainer";
import { data } from "./data";
import { Title } from "../styled";

const Exchange = () => {
  return (
    <DataContainer as="div">
      <Title className="title">{data?.title}</Title>
      {data?.content}
      {data?.highlighted && (
        <span className="highlighted">{data?.highlighted}</span>
      )}
    </DataContainer>
  );
};

export default Exchange;
