import React from "react";
import {OrderButtonsWrapper, OrderCardContainer} from "./styled";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment/moment";

const OrderCard = ({product}) => {
    const {t} = useTranslation();

    return (
        <OrderCardContainer>
            <ul>
                <li>
                    <span className="bold">{t("texts.order_number")}</span>
                    <span>{product?.id}</span>
                </li>
                <li>
                    <span className="bold">{t("texts.order_status")}</span>
                    <span>{product?.status}</span>
                </li>
                <li>
                    <span className="bold">{t("texts.payment_type")}</span>
                    <span>{product?.method}</span>
                </li>
                <li>
                    <span className="bold">{t("texts.address")}</span>
                    <span>{product?.address?.address}</span>
                </li>
                {product?.address?.details && (
                    <li>
                  <span className="bold">
                    {t("texts.delivery_details_more_info")}
                  </span>
                        <span>{product?.address?.details}</span>
                    </li>
                )}
                <li>
                    <span className="bold">{t("texts.delivery_day")}</span>
                    <span>{`${
                        product?.date || ""
                    } `}</span>
                    <span>{product?.start}-{product?.end}</span>
                </li>
                <li>
                    <span className="bold">{t("texts.price")}</span>
                    <span>{product?.totalWithDelivery}</span>
                </li>
                {!!product?.extraOptions?.length && (
                    <li>
                        <span className="bold">{t("texts.additional_services")}</span>
                        {product?.extraOptions.map((option) => (
                            <span key={option.id} className="mr">
                      {option.name}
                    </span>
                        ))}
                    </li>
                )}
                {!!product?.reversesCount && (
                    <li>
                        <span className="bold">Վերադարձված ապրանքների քանակը</span>
                        <span className="mr">
                             <Link
                                 className="see-details"
                                 to={`/personal-page/order/returned/${product.id}`}
                             >
                        {product?.reversesCount}
                    </Link>
                        </span>
                    </li>
                )}
            </ul>
            <div>
                <OrderButtonsWrapper>
                    <Link
                        className="see-details"
                        to={`/personal-page/order/${product.id}`}
                    >
                        {t("texts.details")}
                    </Link>
                </OrderButtonsWrapper>

            </div>
        </OrderCardContainer>
    );
};

export default OrderCard;
