import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Container, Nav, NavButton, Divider, Typo, List} from "../../pages/delivery/prices/styled";
import clockTwo from '../../assets/delivery/clock-two.svg';
import truck from '../../assets/delivery/truck.svg';
import location from '../../assets/delivery/location.svg';
import moped from '../../assets/delivery/moped.svg';
import moneyBag from '../../assets/delivery/money-bag.svg';
import packageIcon from '../../assets/delivery/package.svg';
import bag from '../../assets/delivery/bag.svg';
import {
    DeliveryItem, DeliveryItemDescription,
    DeliveryItemIcon, DeliveryItemRow, DeliveryItemText,
    DeliveryItemValue,
    DeliveryKey, DeliveryKeyIcon,
    DeliveryKeyName, Line,
    RowSection,
    TableSection
} from "./styled";
import {Outlet} from "react-router";

const UpDetails = () => {
    const {t} = useTranslation();
    const lang = useSelector((state) => state.lang[0].val);
    const upItems = [
        {
            name: null,
            border: 0,
            items: [
                {
                    title: t('up.type.types.weight.title'),
                },
                {
                    title: t('up.type.types.price.title'),
                },
            ],
        }, {
            name: t('up.onePack.title'),
            icon: bag,
            items: [
                {
                    title: t('up.onePack.types.weight.title'),
                },
                {
                    title: t('up.onePack.types.floor.title'),
                },
            ],
        },{
            name: t('up.gips.title'),
            items: [
                {
                    title: t('up.gips.types.weight.title'),
                },
                {
                    title: t('up.gips.types.floor.title'),
                },
            ],
        },{
            name: t('up.profile.title'),
            items: [
                {
                    title: t('up.profile.types.weight.title'),
                },
                {
                    title: t('up.profile.types.floor.title'),
                },
            ],
        }
    ];

    return (
        <Container>
            <TableSection>
                {upItems.map((item) => (
                    <RowSection>
                        <DeliveryKey>
                            <DeliveryKeyIcon src={item.icon}></DeliveryKeyIcon>
                            <DeliveryKeyName>{item.name}</DeliveryKeyName>
                        </DeliveryKey>
                        <DeliveryItemRow>
                            {item.items.map((deliveryItem) => (
                                <DeliveryItem style={{ border: item.border }}>
                                    <DeliveryItemText>
                                        <DeliveryItemValue>{deliveryItem.title}</DeliveryItemValue>
                                        <DeliveryItemDescription>{deliveryItem.description}</DeliveryItemDescription>
                                    </DeliveryItemText>
                                    <DeliveryItemIcon src={deliveryItem.icon}/>
                                </DeliveryItem>
                            ))}
                        </DeliveryItemRow>
                    </RowSection>
                ))}
            </TableSection>
        </Container>
    );
};

export default UpDetails;
