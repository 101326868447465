import { configureStore } from "@reduxjs/toolkit";
import overflowOfMainReducer from "./features/overflow-body/overflowOfMainSlice";
import showAuthSliceReducer from "./features/show-auth/showAuthSlice";
import showForgetPasswordSliceReducer from "./features/show-forget-password/showForgetPasswordSlice";
import langSliceReducer from "./features/lang/langSlice";
import categoriesSliceReducer from "./features/categories/categoriesSlice";
import cartSliceReducer from "./features/cart/cartSlice";
import userSliceReducer from "./features/user/userSlice";
import favsSliceReducer from "./features/favs/favsSlice";
import revsSliceReducer from "./features/reversable/revSlice";
import appHeightSliceReducer from "./features/appHeight/appHeightSlice";

export const store = configureStore({
  reducer: {
    overflowOfMain: overflowOfMainReducer,
    showAuth: showAuthSliceReducer,
    showForgetPassword: showForgetPasswordSliceReducer,
    lang: langSliceReducer,
    categories: categoriesSliceReducer,
    cart: cartSliceReducer,
    user: userSliceReducer,
    favs: favsSliceReducer,
    revs: revsSliceReducer,
    appHeight: appHeightSliceReducer,
  },
});
