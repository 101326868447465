export const data = {
  hy: [
    {
      title: "Մեր մասին ",
      main: true,
      content: [
        {
          type: "normal",
          text: `Յուրաքանչյուր շինարարական աշխատանք սկսվում է որակյալ շինանյութի ձեռքբերմամբ։ ‘’Shinmag’’- ընկերությունը երիտասարդ, դինամիկ զարգացող ընկերություն է, որը մուտք է գործել տեղական շուկա՝  գնորդին որակյալ շինանյութեր և ծառայություններ մատուցելու համար։  ‘’Shinmag’’-ը երիտասարդ  թիմ է ՝ մասնագիտական ամբիցիաներով և  բարձր պոտենցիալով, որը ձեզ կօգնի շինանյութ գնելու  հարցում ։  `,
        },
        {
          type: "normal",
          text: `Մենք ամբողջությամբ ընդունում ենք մեր աշխատանքի պատասխանատվությունը և մրցակցությունը օրեցօր աճող հայկական  շուկայում՝  մշտապես բարձրացնելով մեր մասնագիտական որակավորումն ՝ ձգտելով մաքսիմալ արդյունավետ և ժամանակակից աշխատանքային մեթոդների ։ `,
        },
        {
          type: "normal",
          text: `Մենք ունենք մի շարք առավելություններ, որոնք մեզ առանձնացնում են շուկայում որպես պատասխանատու, ժամանակակից և վստահելի հարթակ։ Վերլուծելով մեր հաճախորդների կարիքները ՝ ձևավորել ենք ստանդարտներ յուրաքանչյուրի համար։ Հաճախորդները ընտրում են մեզ լայն տեսականու, անհատական մոտեցման և ուշադրության համար աշխատանքի ցանկացած փուլում, մշտապես թարմացվող տեսականու, ցածր գների և լավ սպասարկման  համար ։`,
        },
      ],
    },
    {
      title: "Առավելությունները",
      main: true,
      content: [
        {
          type: "normal",
          text: `Մենք ամբողջությամբ ընդունում ենք մեր աշխատանքի պատասխանատվությունը  և մրցակցությունը օրեցօր աճող հայկական  շուկայում՝  մշտապես բարձրացնելով մեր մասնագիտական որակավորումն ՝  ձգտելով մաքսիմալ արդյունավետ և ժամանակակից աշխատանքային մեթոդների ։ `,
        },
        {
          type: "normal",
          text: `Մենք ունենք մի շարք առավելություններ, որոնք մեզ առանձնացնում են շուկայում որպես պատասխանատու, ժամանակակից և վստահելի հարթակ։ Վերլուծելով մեր հաճախորդների կարիքները ՝ ձևավորել ենք ստանդարտներ յուրաքանչյուրի  համար։ Հաճախորդները ընտրում են մեզ լայն տեսականու, անհատական մոտեցման և աշխատանքի նկարմամբ ուշադրության համար ցանկացած փուլում, մշտապես թարմացվող տեսականու, ցածր գների և լավ սպասարկման  համար։   Մեր մասնագետների հետ աշխատելն հարմարավետ  է և հաճելի՝ մենք կօգնենք ընտրություն կատարելու հարցում  նաև ապահովելով  հաճախորդին անհատական խորհրդատվությամբ ։`,
        },
      ],
    },
    {
      title: "Էքսպրես առաքում",
      content: [
        {
          type: "normal",
          text: `Էքսպրես առաքումը իրականացվում է նշված հասցեով մինչև 1 ժամվա ընթացքում։`,
        },
      ],
    },
    {
      title: "Սեփական պահեստներ",
      content: [
        {
          type: "normal",
          text: `Սեփական պահեստների շնորհիվ մենք ապահովում ենք ճիշտ և համապատասխան պայմաններում ապրանքատեսականիների պահեստավորումը, իսկ կայքում ներկայացված տեսականին միշտ առկա է։`,
        },
      ],
    },
    {
      title: "Որակավորված, հմուտ և կոկիկ  վարորդներ և բանվորներ",
      content: [
        {
          type: "normal",
          text: `Մեր բանվորները և վարորդները  հմուտ և բարձր մասնագիտական որակներ ունեցող մասնագետներ են։ Նրանք օպերատիվ կերպով կառաքեն Ձեր պատվերը, ՝  իրականացնելով իրենց աշխատանքը արագ և հմտորեն ՝ տնտեսելով ձեր ժամանակը և գումարը ։ `,
        },
      ],
    },
    {
      title: "Առավել մատչելի գներ",
      content: [
        {
          type: "normal",
          text: `Վստահաբար ձեզ կզարմացնեն մեր ապրանքատեսակների գները՝  վերլուծելով դրանք դուք  կհասկանաք, որ Shinmag-ում ի տարբերություն առկա խանութների ապրանքները ձեռք կբերեք համեմատաբար մատչելի գներով, քանի որ մեզ մոտ չկա անհրաժեշտություն խանութ վարձակալելու և դրանից բխող  ծախսերը հոգալու։`,
        },
      ],
    },
    {
      title: "Խորհրդատու-մասնագետի այց",
      content: [
        {
          type: "normal",
          text: `Մեր մասնագետները կկատարեն մասնագիտական խորհրդատվություն՝ օգնելով գույնի, նյութի, չափաքանակի  ընտրության հարցերում։ Նույնիսկ կախված ընտրվող ապրանքատեսակից, կարող եք ունենալ հնարավորություն կատարել ընտրություն հատուկ ձեզ համար բերված նմուշների տեսականուց։`,
        },
      ],
    },
  ],
  ru: [
    {
      title: "О нас",
      main: true,
      content: [
        {
          type: "normal",
          text: `Каждое строительство начинается с покупки качественных материалов. Строительная Компания  Shinmag молодая, динамично развивающаяся компания на строительном рынке пришедшая на строительный рынок с тем, чтобы предоставить потребителю качественные строительные товары и услуги. `,
        },
        {
          type: "normal",
          text: `Строительная база Shinmag — это молодая команда с профессиональными амбициями, высоким потенциалом которая поможет Вам благоустроить свое жизненное пространство: построить дом мечты, начать ремонт в квартире –специализирующийся на продаже строительных материалов для внутренней и внешней отделки дома или квартиры. `,
        },
        {
          type: "normal",
          text: `Мы понимаем всю ответственность работы на растущем армянском строительном рынке, поэтому постоянно повышаем свой профессиональный уровень, стремимся к наибольшему качеству услуг и предлагаем максимально эффективные методы работы. У нас есть ряд выгодных преимуществ, выделяющих нас на рынке как ответственную, современную и надёжную платформу. Анализируя потребности наших покупателей мы сформировали стандарты оказываемого сервиса для каждого, без исключения, клиента. Клиенты выбирают ‘’Shinmag’’ за широкий ассортимент, низкие цены, профессиональную помощь на каждом этапе и внимание, которое оказывает компания каждому клиенту в процесс покупки.`,
        },
      ],
    },
    {
      title: "Преимущества",
      main: true,
      content: [
        {
          type: "normal",
          text: `Мы понимаем всю ответственность работы на растущем армянском    строительном рынке, поэтому постоянно повышаем свой профессиональный уровень, стремимся к наибольшему качеству услуг и предлагаем максимально эффективные методы работы. У нас есть ряд выгодных преимуществ, выделяющих нас на рынке как ответственную, современную и надёжную компанию. Анализируя потребности наших покупателей мы сформировали стандарты оказываемого сервиса для каждого, без исключения, клиента. Работать с нашими специалистами удобно и комфортно: мы помогаем с  выбором, предлагаем готовые решения, обеспечиваем индивидуальный подход и сопровождаем в затруднительных ситуациях.
`,
        },
      ],
    },
    {
      title: "Экспрес доставка ",
      content: [
        {
          type: "normal",
          text: `Это своевременная доставка стройматериалов любого назначения, веса и объема по указанному адресу в минимально короткий срок.`,
        },
      ],
    },
    {
      title: "Время работы 24/7",
      content: [
        {
          type: "normal",
          text: `Это возможность в любое время пользоваться нашими услугами: круглосуточно без перерывов, как правило, каждый день. `,
        },
      ],
    },
    {
      title:
        "С HYPERLINK https://wallsgroup.ru/nashi-preimushhestva обственные склады",
      content: [
        {
          type: "normal",
          text: `Собственный склад. Мы обеспечиваем правильное хранение всех товаров, а представленные на сайте позиции всегда имеются в наличии`,
        },
      ],
    },
    {
      title:
        "Квалифицированные опытные и опрятные сотрудники (водители и грузчики)",
      content: [
        {
          type: "normal",
          text: `Грузчики и (водители нашей компании– это опытные и высококвалифицированные опрятные, работники. Они оперативно погрузят, выгрузят, отсортируют и организуют Выполняя работу профессионально и быстро, они максимально сэкономят Ваши деньги и время.`,
        },
      ],
    },
    {
      title: "Доставка товаров день в день",
      content: [
        {
          type: "normal",
          text: `Каждый уважающий себя строительный магазин предлагает своим клиентам доставку товаров. Это позволит вам сэкономить время и не нужно будет решать вопрос транспортировки тяжёлого или габаритного груза. Перевозка осуществляется на собственных грузовых машинах, которые отличаются типом кузова, грузоподъемностью, чистотой. Достоинств такой перевозки стройматериалов очень много: высокая скорость, маневренность ТС для проезда по непростым участкам дороги, быстрая разгрузка и погрузка товара.`,
        },
      ],
    },
    {
      title: "Цены",
      content: [
        {
          type: "normal",
          text: `Вас приятно порадуют цены, ведь именно в нашем магазине Вы сможете приобрести стройматериалы дешево в отличие от многих магазинах.  Проанализировав цены магазинов, вы придете к выводу, что дешевле всего в нашем интернет-магазине, так как здесь нет необходимости содержать магазин с дорогой арендой.`,
        },
      ],
    },
    {
      title: "Бесплатная расширенная консультация. ",
      content: [
        {
          type: "normal",
          text: `Наши специалисты помогут подобрать наиболее подходящие варианты и                                 цветовые решения для ваших строительных работ.`,
        },
      ],
    },
  ],
};
