import React, { useState, useEffect } from "react";
import {
  EditBtn,
  Form,
  GridContainer,
  RemoveAccountContainer,
  SubmitContainer,
} from "./styled";
import { Label, Input, InputWrapper } from "../../../components/auth/styled";
import EditPassword from "../popups/EditPassword";
import RemoveAccount from "../popups/RemoveAccount";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import { deleteKey, changeState } from "../../../utils/funcs/formHandlings";
import checkIcon from "../../../assets/checkIcon.svg";
import { userAdded } from "../../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorText } from "../../../shared/styled";
import { PatternFormat } from "react-number-format";
import { Button } from "../../shinmag-club/styled";

const EditData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  const [openEditPassword, setOpenEditPassword] = useState(false);
  const [openRemoveAccount, setOpenRemoveAccount] = useState(false);
  const [errors, setErrors] = useState({});
  const [succeeded, setSucceeded] = useState(false);
  const [unset, setUnset] = useState(false);

  const token = useLocalStorageGetItem("token") || "";
  const userInfo = useSelector((state) => state.user);
  const lang = useSelector((state) => state.lang[0]);

  const toggleEditable = () => setIsEditable((prev) => !prev);

  const handleUnset = () => {
    setUnset((u) => !u);
    setIsEditable(false);
  };

  useEffect(() => {
    if (userInfo) {
      const { firstName, lastName, phone, email } = userInfo;
      firstName && setName(firstName);
      lastName && setLastName(lastName);
      phone && setPhone(phone?.slice(4));
      email && setEmail(email);
      setErrors({});
    }
  }, [userInfo, unset]);

  const handleChange = (e, key, setValue) => {
    changeState(e.target.value, setValue);
    deleteKey(key, errors);
  };

  const handleClose = (setVal) => {
    setVal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {};
    if (userInfo?.email !== email) {
      data.email = email;
    }
    if (userInfo.fullName !== `${name} ${lastName}`) {
      data.name = `${name} ${lastName}`;
    }
    if (userInfo?.phone !== `+374${phone}`) {
      data.phone = `+374${phone}`;
    }
    axios
      .put(`${baseUrl}/users/settings`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang.val,
        },
      })
      .then((res) => {
        setSucceeded(true);
        dispatch(userAdded(res?.data?.data));
        setTimeout(() => {
          setSucceeded(false);
          setIsEditable(false);
        }, 3000);
      })
      .catch((err) => {
        setErrors(err.response?.data?.errors);
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <GridContainer>
          <div>
            <Label htmlFor="changing-name">{t("texts.name")}</Label>
            <Input
              id="changing-name"
              autoComplete="off"
              readOnly={!isEditable}
              onChange={(e) => handleChange(e, "name", setName)}
              value={name}
              className={`${errors?.name && "error"}`}
            />
            {errors?.name && <ErrorText>{errors?.name?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="changing-lastname">{t("texts.lastname")}</Label>
            <Input
              id="changing-lastname"
              autoComplete="off"
              readOnly={!isEditable}
              onChange={(e) => handleChange(e, "name", setLastName)}
              value={lastName}
              className={`${errors?.name && "error"}`}
            />
            {errors?.name && <ErrorText>{errors?.name?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="changing-phone">{t("texts.phone")}</Label>
            <InputWrapper>
              <PatternFormat
                id="changing-phone"
                readOnly={!isEditable}
                autoComplete="off"
                customInput={Input}
                placeholder="12 34 56 78"
                format="########"
                mask="_"
                className={`with-prefix ${errors?.phone && "error"}`}
                value={phone}
                onChange={(e) => handleChange(e, "phone", setPhone)}
                type="tell"
              />
              <span className="prefix">(+374)</span>
            </InputWrapper>
            {errors?.phone && <ErrorText>{errors?.name?.[0]}</ErrorText>}
          </div>
          <div>
            <Label htmlFor="changing-email">{t("texts.email")}</Label>
            <Input
              id="changing-email"
              autoComplete="off"
              readOnly={!isEditable}
              type="email"
              onChange={(e) => handleChange(e, "email", setEmail)}
              value={email}
              className={`${errors?.email && "error"}`}
            />
            {errors?.email && <ErrorText>{errors?.email[0]}</ErrorText>}
          </div>
          {!isEditable && (
            <div className="edit-btn-wrapper fullw">
              <EditBtn type="button" onClick={toggleEditable}>
                {t("actions.edit")}
              </EditBtn>
            </div>
          )}
        </GridContainer>
        {isEditable && (
          <SubmitContainer>
            <Button
              className={`yellow ${succeeded && "success"}`}
              disabled={
                userInfo?.fullName !== name ||
                userInfo?.email !== email ||
                userInfo?.phone?.slice(4) !== phone
                  ? false
                  : true
              }
            >
              <img src={checkIcon} alt="sent" width="18" />
              <span>{t("actions.save")}</span>
            </Button>
            <Button type="button" onClick={handleUnset}>
              {t("actions.cancel")}
            </Button>
          </SubmitContainer>
        )}
        <GridContainer className="bt">
          <div>
            <Label as="span">{t("texts.password")}</Label>
            <Input type="password" readOnly value="11111111" />
          </div>
          <div className="edit-btn-wrapper">
            <EditBtn type="button" onClick={() => setOpenEditPassword(true)}>
              {t("actions.edit")}
            </EditBtn>
          </div>
        </GridContainer>
        <RemoveAccountContainer>
          <p>{t("actions.delete_page")}</p>
          <button
            type="button"
            onClick={() => {
              setOpenRemoveAccount(true);
            }}
          >
            {t("actions.remove")}
          </button>
        </RemoveAccountContainer>
      </Form>
      {openEditPassword && (
        <EditPassword handleClose={() => handleClose(setOpenEditPassword)} />
      )}
      {openRemoveAccount && (
        <RemoveAccount handleClose={() => handleClose(setOpenRemoveAccount)} />
      )}
    </>
  );
};

export default EditData;
