import React, { useState } from "react";
import {
  Actions,
  AddNewAddressButton,
  AddressContainer,
  AddressesContainer,
  CurrentAddressContainer,
} from "./styled";
import EditAddress from "../popups/EditAddress";
import AddAddress from "../popups/AddAddress";
import { useTranslation } from "react-i18next";
import { useGetData, useLocalStorageGetItem } from "../../../utils/hooks/hooks";
import EmptyList from "../../../components/empty-list";
import axios from "axios";
import { baseUrl } from "../../../constants";
import { useSelector } from "react-redux";
import AddressSkeleton from "./AddressSkeleton";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useNavigate, useParams } from "react-router";

const EditAddresses = () => {
  const { t } = useTranslation();
  const { page } = useParams();
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const [currentPage, setCurrentPage] = useState(page);
  const [reload, setReload] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openAddAddress, setOpenAddAddress] = useState(false);

  const navigate = useNavigate();

  const {
    data: addresses,
    isLoading,
    meta,
  } = useGetData(
    `users/addresses?perPage=10&page=${currentPage}`,
    [reload, currentPage],
    true,
    token
  );

  const headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`,
  };

  const handleEditAddressClose = () => {
    setOpenEditAddress(false);
    setSelectedAddress(null);
    setCurrentPage(1);
    navigate(`/personal-page/addresses/1`);
  };

  const handleAddAddressClose = () => {
    setOpenAddAddress(false);
  };

  const handleOpenEditAddress = (address) => {
    setOpenEditAddress(true);
    setSelectedAddress(address);
  };

  const toggleReload = () => setReload((r) => !r);

  const handleMakeDefault = (id) => {
    axios
      .put(`${baseUrl}/users/addresses/${id}/set-default`, {}, { headers })
      .then(() => setReload((r) => !r));
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    navigate(`/personal-page/addresses/${page}`);
  };

  return (
    <>
      <AddressesContainer
        className={`${(openEditAddress || openAddAddress) && "popup-opened"}`}
      >
        {isLoading ? (
          <AddressSkeleton />
        ) : !!addresses?.length ? (
          addresses.map((address) => (
            <AddressContainer key={address.id}>
              <CurrentAddressContainer>
                <p>{address?.region?.name || "region"}</p>
                <p>{address?.address}</p>
                <p>{address.details}</p>
              </CurrentAddressContainer>
              <Actions>
                <button
                  className="set-default-container btn"
                  onClick={() => handleMakeDefault(address.id)}
                >
                  {t("texts.main_address")}
                  <div className="radio-outer">
                    <div
                      className={`radio-inner ${
                        address?.isDefault ? "selected" : ""
                      }`}
                    />
                  </div>
                </button>
                <button
                  onClick={() => handleOpenEditAddress(address)}
                  className="edit btn"
                >
                  {t("actions.edit")}
                </button>
              </Actions>
            </AddressContainer>
          ))
        ) : (
          <EmptyList />
        )}
        {!isLoading && meta?.last_page > 1 && (
          <CustomPagination
            page={+currentPage}
            pageCount={meta?.last_page}
            handlePageChange={handlePageChange}
          />
        )}
      </AddressesContainer>
      <AddNewAddressButton onClick={() => setOpenAddAddress(true)}>
        {t("texts.new_address")}
      </AddNewAddressButton>
      {openEditAddress ? (
        <EditAddress
          handleClose={handleEditAddressClose}
          selectedAddress={selectedAddress}
          toggleReload={toggleReload}
        />
      ) : null}
      {openAddAddress ? (
        <AddAddress
          handleClose={handleAddAddressClose}
          toggleReload={toggleReload}
        />
      ) : null}
    </>
  );
};

export default EditAddresses;
