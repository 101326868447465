export const data = {
  hy: {
    title: `SHINMAG ՆՎԵՐ ՔԱՐՏ`,
    content: [
      `Սա հիանալի նվեր կլինի բոլորի համար, ովքեր կառուցում, պլանավորում կամ վերանորոգում են սեփական բնակարանը տունը կամ գրասենյակը`,
      `Նվեր քարտը կարող է օգտագործվել միայն shinmag.am կայքից ապրանքներ գնելու համար.`,
      `Քարտը գործում է 1 (մեկ) տարի կայքի անձնական էջում  ակտիվացնելու օրվանից: Այս ժամկետը լրանալուց հետո Նվեր Քարտը դառնում է անվավեր, քարտի դրական մնացորդը չեղարկվում է.`,
      `Քարտը փոխանակման եւ վերադարձի ենթակա չէ, դրա դիմաց վճարված միջոցները չեն վերադարձվում։ Քարտը կորցնելու կամ գողանալու դեպքում դրա հաշվեկշռում առկա միջոցները չեն վերականգնվում.`,
      `Եթե խանութի տեսականուց ընտրված ապրանքի արժեքը Նվեր Քարտի անվանական արժեքից պակաս է, ապա նվեր քարտում մնացորդ գումարը կարելի է օգտագործել հետագա գնումների ժամանակ`,
      `Եթե տեսկանուց ընտրված ապրանքի արժեքը գերազանցում է նվեր քարտի վրա, առկա գումարին ապա գերազանցող մասը վճարվում է քարտապանի կողմից`,
      `կարող եք ձեռք բերել 10.000-ից մինչեւ 2.000.000 ՀՀ դրամ անվանական արժեքներով նվեր քարտ`,
    ],
  },
  ru: {
    title: `ПОДАРОЧНАЯ КАРТА SHINMAG`,
    content: [
      `Это будет отличный универсальный подарок для тех, кто строит, планирует или делает ремонт!`,
      `Подарочная карта может быть использована только для приобретения товаров на сайте shinmag.am;`,
      `Карта действительна в течении 1 (одного) года с момента активации в личном кабинете сайта shinmag.am .По истечению данного срока Подарочная карта становится недействительной, положительный баланс по карте аннулируется;`,
      `Карта обмену и возврату не подлежит, уплаченные за нее денежные средства не возвращаются. При утере или краже карты, денежные средства, находящиеся на ее балансе, не восстанавливаются;`,
      `В случае, если стоимость выбранного товара из ассортимента магазина менее номинала Подарочной карты, остаток денежных средств остается на карте для дальнейших покупок;`,
      `В случае, если стоимость выбранного товара из ассортимента магазина больше номинала Подарочной карты, то разница выплачивается предьявителем карты;`,
      `Приобрести подарочную карту можно номиналом от 10000-2000000 драмов на нашем сайте.`,
    ],
  },
};
