import React, { useState } from "react";
import { AdditionalFilesFormWrapper } from "./styled";
import infoIcon from "../../assets/info-icon.svg";
import { useTranslation } from "react-i18next";
import Files from "../shinmag-club/Files";
import { deleteKey } from "../../utils/funcs/formHandlings";
import { TextareaWrapper, Textarea } from "../shinmag-club/styled";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useSelector } from "react-redux";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import checkIcon from "../../assets/checkIcon.svg";
import { PopupFormSubmitButton } from "./popups/styled";
import { ErrorText } from "../../shared/styled";

const AdditionalFilesForm = ({ toggleReload }) => {
  const { t } = useTranslation();
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0]);
  const [files, setFiles] = useState([]);
  const [about, setAbout] = useState("");
  const [errors, setErrors] = useState({});
  const [succeeded, setSucceeded] = useState(false);

  const headers = {
    "Accept-Language": lang.val,
    Authorization: `Bearer ${token}`,
  };

  const handleChangeFiles = (e) => {
    setFiles(Array.from(e.target.files));
    deleteKey("certificate", errors);
  };

  const handleDeleteFile = (file) => {
    setFiles([]);
  };

  const handleAboutChange = (e) => {
    setAbout(e.target.value);
    deleteKey("about", errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    about && formData.append("about", about);
    if (files.length) {
      formData.append("certificate", files[0], files[0].name);
    }
    axios
      .post(`${baseUrl}/partners/certificate`, formData, { headers })
      .then(() => {
        setSucceeded(true);
        setTimeout(() => {
          setSucceeded(false);
          toggleReload();
          setFiles([]);
          setAbout("");
        }, 3000);
      })
      .catch((err) => setErrors(err?.response?.data?.errors));
  };

  return (
    <AdditionalFilesFormWrapper onSubmit={handleSubmit}>
      <div className="form-title-container">
        <img src={infoIcon} alt="info" />
        <p className="form-title">
          {t("texts.please_fill_in_following_fields")}
        </p>
      </div>
      <Files
        files={files}
        handleChange={handleChangeFiles}
        handleDelete={handleDeleteFile}
        error={errors?.certificate}
        fullWidth={true}
      />
      <TextareaWrapper className="fullwidth">
        <Textarea
          id="member-about"
          className={`${errors?.about && "error"}`}
          value={about}
          onChange={handleAboutChange}
        />
        {errors?.about && <ErrorText>{errors?.about?.[0]}</ErrorText>}
      </TextareaWrapper>
      <PopupFormSubmitButton
        className={`fit ${succeeded && "success"}`}
        disabled={!files.length}
      >
        <img src={checkIcon} alt="sent" width="18" />
        <span>{t("actions.send")}</span>
      </PopupFormSubmitButton>
    </AdditionalFilesFormWrapper>
  );
};

export default AdditionalFilesForm;
