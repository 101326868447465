import React from "react";
import { services } from "./data";
import { useSelector } from "react-redux";
import { Section, Card } from "./styled";

const ProsSection = () => {
  const lang = useSelector((state) => state.lang[0].val);
  const data = services[lang];
  return (
    <Section>
      {data.map((item, i) => (
        <Card key={i}>
          <div className="left-side">
            <img src={item?.icon} alt="service" />
          </div>
          <div className="right-side">
            <p className="title">{item?.title}</p>
            <ul>
              {item?.content?.map((elem, i) => (
                <li key={i}>{elem}</li>
              ))}
            </ul>
          </div>
        </Card>
      ))}
    </Section>
  );
};

export default ProsSection;
