import React from 'react';
import {Helmet} from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const SEO = ({ title, description, image }) => {
    console.log(321);
    // console.log("title, description, image", title, description, image);
    const { t } = useTranslation();

    return (
        <Helmet>
            <title>{title || "Shinmag"}</title>
            <meta property="og:image" content={image || "og:image"} data-react-helmet="true" />
            <meta name="image" content={image || "image"} data-react-helmet="true"/>
            <meta name="title" content={title || "title"} data-react-helmet="true"/>
            <meta property="og:title" content={title || "og:title"} data-react-helmet="true"/>
            <meta name="description" content={description || "description"} data-react-helmet="true"/>
            <meta property="og:description" content={description || "og:description"} data-react-helmet="true" />
        </Helmet>
    );
};

export default SEO;