import React, {useEffect, useState} from "react";
import {BaseSection, ImageWrapper, ServiceImageWrapper, SliderWrapper} from "./styled";
import Slider from "react-slick";
import {baseUrl} from "../../constants";
import {useTranslation} from "react-i18next";
import nextArrow from "../../assets/next-arrow.svg";
import prevArrow from "../../assets/prev-arrow.svg";
import {useGetData} from "../../utils/hooks/hooks";

const HomeServices = ({}) => {
    const {t} = useTranslation();
    const [services, setServices] = useState([]);

    function SliderNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={nextArrow}
                 alt="Next"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }

    function SliderPrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <img src={prevArrow}
                 alt="Prev"
                 className={className}
                 style={{...style, display: "block", cursor: "pointer"}}
                 onClick={onClick}
            />
        );
    }

    const {data} = useGetData(`contents?keys[]=services`, []);

    const settings = {
        initialSlide: 1,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        cssEase: 'linear',
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 20,
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    useEffect(() => {
        setServices(data)
    }, [data])

    return (
        <BaseSection className="block">
            {services?.length ? <h1>{t('texts.our_services')}</h1> : null}
            <Slider {...settings} className="slider">
                {services?.map((service, index) => (
                    <ServiceImageWrapper className={"slide"} key={service.name} to={service.link}  target="_blank">
                        {service?.src ? (
                            <img style={{height: 'max-content'}} src={service?.src} alt={service?.name}/>
                        ) : (
                            <h5>{service?.name}</h5>
                        )}
                        <h2>{service?.description}</h2>
                    </ServiceImageWrapper>
                ))}
            </Slider>
        </BaseSection>
    );
};

export default HomeServices;