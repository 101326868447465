import React, { useState } from "react";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { Tab, TabItem } from "../../shared/styled";
import { Heading, BorderedText } from "./styled";
import { text, tableData } from "./texts";
import BecomeAMember from "./BecomeAMember";
import { useTranslation } from "react-i18next";
import {List, Table, Title} from "../company/styled";
import dot from "../../assets/dot.svg";

const ShinmugClub = () => {
  const { t } = useTranslation();
  const tabItems = [
    { id: 1, name: t("texts.about_club") },
    { id: 2, name: t("texts.how_to_join") },
    { id: 3, name: t("texts.point_accumulation") },
  ];
  const [active, setActive] = useState(1);

  const nav = [{ to: "/shinmag-club", name: t("texts.shinmag_club") }];

  return (
    <>
      <Breadcrumbs nav={nav} />
      <Heading>{t("texts.shinmag_club")}</Heading>
      <Tab>
        {tabItems.map((item) => (
          <TabItem
            key={item.id}
            onClick={() => setActive(item.id)}
            className={active === item?.id && "active"}
          >
            {item.name}
          </TabItem>
        ))}
      </Tab>
      {active === tabItems[0]?.id && <BorderedText distance>
          {t("about.heading")}
      <Title>{t("about.getMoney")}</Title>
          <List ul><li><img src={dot} alt=""/><p>{t("about.getMoneyText")}</p></li></List>
          <Title>{t("about.shareKnowledge")}</Title>
          <List ul><li><img src={dot} alt=""/><p>{t("about.shareKnowledgeText")}</p></li></List>
          <Title>{t("about.getOrders")}</Title>
          <List ul><li><img src={dot} alt=""/><p>{t("about.getOrdersText")}</p></li></List>
          <Title>{t("about.soon")}</Title>
      </BorderedText>}
      {active === tabItems[2]?.id && (
        <BorderedText>
            <b>{t("about.boldText")}</b><br/>{t("about.accumulationPoint_first")} <a href={'#'} onClick={()=>setActive(2)}>https://shinmag.am/shinmag-club</a> {t("about.accumulationPoint_second")}<br/>
            {/*<Table large>
                <tr>
                    <th>{tableData[0][0]}</th>
                    <th>{tableData[0][1]}</th>
                </tr>
                {tableData?.map((item,i)=>(
                    i>0 &&
                    <tr>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                    </tr>
                ))}
            </Table>*/}
        </BorderedText>
      )}
      {active === tabItems[1]?.id && (
        <>
          <BorderedText>{t("about.join")}</BorderedText>
          <BecomeAMember />
        </>
      )}
    </>
  );
};

export default ShinmugClub;
