import React from "react";
import { Container, CustomLink, Button } from "./styled";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/material";

const LoggedInPopover = ({ handleClose }) => {
  const { t } = useTranslation();

  const list = [
    {
      id: 1,
      name: t("texts.personal_data"),
      to: "/personal-data",
    },
    {
      id: 2,
      name: t("texts.my_addresses"),
      to: "/addresses/1",
    },
    { id: 3, name: t("texts.orders"), to: "/orders/1" },
    { id: 4, name: t("texts.favourites"), to: "/favorites" },
    { id: 5, name: t("texts.my_offered_prices"), to: "/price-offers" },
    { id: 6, name: t("texts.shinmag_club"), to: "/shinmag-club" },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Container className="personal-settings">
        {list.map((item) => (
          <CustomLink
            to={
              item.to.includes("shinmag")
                ? `${item.to}`
                : `/personal-page${item.to}`
            }
            key={item.id}
            className="personal-settings"
            onClick={handleClose}
          >
            {item.name}
          </CustomLink>
        ))}
        <Button className="personal-settings" onClick={handleLogout}>
          {t("actions.logout")}
        </Button>
      </Container>
    </ClickAwayListener>
  );
};

export default LoggedInPopover;
