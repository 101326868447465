import styled from "styled-components";
import expandIcon from "../../assets/expandIcon.svg";
import { Link } from "react-router-dom";
import { spaceBwFlex, alignCenter, customTypo } from "./styled";
import { pad_x } from "../../shared/styled";

export const UpperHeaderWrapper = styled.div`
  ${spaceBwFlex};
  padding:9px 20px;
  ${pad_x}
  width: 100%;
  background-color: #373e4b;
  @media (max-width: 1440px) {
    padding: 9px 10px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const UpperHeaderContent = styled.div`
  ${alignCenter};
  display: flex;
  -webkit-box-align: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
  p{
    font-size: 14px;
    color: white;
    margin: 0 5px;
    white-space: nowrap;
  }
  & > div {
    display: flex;
    justify-content: end;
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    & > p {
      font-size: 15px;
      margin: 0;
      text-align: end;
    }
    margin-left: auto;
  }
`;

export const ExpandButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  ${customTypo};
`;

export const ExpandButton = styled.button`
  background-color: inherit;
  border: none;
  position: relative;
  ${alignCenter};
  gap: 5px;
  ${customTypo};
  &:after {
    content: "";
    background-image: url(${expandIcon});
    background-size: 5px 3px;
    width: 5px;
    height: 3px;
    display: inline-block;
    transform: ${({ expanded }) => expanded && "rotate(180deg)"};
  }
`;

export const MenuList = styled.div`
  position: absolute;
  background-color: #373e4b;
  left: -4px;
  top: 20px;
  padding: 4px;
  display: ${({ expanded }) => (expanded ? "flex" : "none")};
  flex-direction: column;
  gap: 8px;
  z-index: 11;
  width: max-content;
  &.lang {
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
  }
`;

export const SocialMediaLinks = styled.div`
  ${customTypo};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  text-transform: lowercase;
  gap: 15px;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CustomLink = styled(Link)`
  ${customTypo};
  text-decoration: none;
  display: inline-block;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const MenuBtn = styled.button`
  border: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
`;
