import React from "react";
import {
    OrderButtonsWrapper,
    OrderCardsWrapper,
    OrderContainer,
} from "./styled";
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router";
import {useLocalStorageGetItem, useGetData} from "../../../utils/hooks/hooks";
import ReturnedItemCard from "./ReturnedItemCard";

const ReturnedItems = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const token = useLocalStorageGetItem("token");
    const {data: reverses} = useGetData(
        `orders/${id}/reverse`,
        [id],
        id,
        token
    );

    return (
        <OrderContainer>
            <OrderCardsWrapper single>
             <div>
                 {reverses?.map((item, index)=>
                  <>
                  {reverses?.length > 1 && <p className={"reverse"}>ՎԵՐԱԴԱՐՁ {index+1}</p>}
                  {item?.items?.map(product=>
                      <ReturnedItemCard
                          total={item?.total}
                          itemsCount={item?.itemsCount}
                          key={product?.product?.id}
                          product={product}
                      />)}
                      </>
                 )}
             </div>
            </OrderCardsWrapper>
            <OrderButtonsWrapper>
                <button
                    onClick={() => {
                        navigate(-1);
                    }}
                    className="see-details back-to-orders"
                >
                    {t("actions.back")}
                </button>
            </OrderButtonsWrapper>
        </OrderContainer>
    );
};

export default ReturnedItems;
