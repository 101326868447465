import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  padding: 40px 0 60px;
  & .MuiPagination-root {
    margin-left: auto;
  }
  .MuiPagination-ul {
    li {
      button {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #373e4b;
        border: 1px solid #373e4b;
        border-radius: 5px;
        background-color: transparent;
        &.Mui-selected {
          background-color: #373e4b !important;
          color: #fff;
        }
      }
    }
    @media (max-width: 480px) {
      & button {
        min-width: 28px;
        height: 28px;
      }
      & .MuiPaginationItem-root {
        margin: 0 2px;
      }
      & .MuiPaginationItem-previousNext {
        border: 0;
        min-width: 20px;
        height: 20px;
      }
      & .MuiPaginationItem-ellipsis {
        margin: 0;
      }
    }
  }
`;
