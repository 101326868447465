import React, {useEffect, useState} from "react";
import {
    OrderButtonsWrapper,
    OrderCardsWrapper,
    OrderContainer,
    OrderInfoContainer,
} from "./styled";
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router";
import {useLocalStorageGetItem, useGetData} from "../../../utils/hooks/hooks";
import moment from "moment";
import OrderCardSkeleton from "./skeletons/OrderCardSkeleton";
import OrderTextSkeleton from "./skeletons/OrderTextSkeleton";
import SingleOrderCard from "./SingleOrderCard";
import deleteIcon from "../../../assets/deleteIcon.svg";
import axios from "axios";
import {baseUrl} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import ErrorDialog from "../../../components/dialogs/ErrorDialog";
import {revsAdded} from "../../../features/reversable/revSlice";
import {Link} from "react-router-dom";
import {InfoItem, Section, Info} from "../../../components/cart-summary/styled";

const SingleOrder = () => {
    const revs = useSelector((state) => state?.revs);
    const {t} = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false)
    const [questionModal, setQuestionModal] = useState(false)
    const [isReversable, setIsReversable] = useState(false)
    const navigate = useNavigate();
    const token = useLocalStorageGetItem("token");
    const lang = useSelector((state) => state.lang[0]);
    const initToken = useLocalStorageGetItem("initToken");
    const {data: order, isLoading} = useGetData(
        `orders/${id}`,
        [id],
        id,
        token
    );
    const handleClose = () => {
        setModal(false);
        navigate(-1)
    }
    const cancel = () => {
        let headers = {
            "Accept-Language": lang.val,
        };
        if (token) {
            headers = {...headers, Authorization: `Bearer ${initToken}`};
        }

        axios
            .patch(
                `${baseUrl}/orders/${order?.id}/cancel`,
                {
                    token,
                },
                {headers}
            )
            .then(() => {
                setQuestionModal(false)
                setModal(true)
            })
    }

    const reverse = () => {
        let result = revs.filter(o1 => checkboxes.filter(checkbox => checkbox.checked).some(o2 => o1.id === o2.id));
        let headers = {
            "Accept-Language": lang.val,
        };
        if (token) {
            headers = {...headers, Authorization: `Bearer ${initToken}`};
        }

        axios
            .post(`${baseUrl}/orders/${order?.id}/reverse`, {"items": result}, {headers})
            .then(() => {
                setQuestionModal(false)
                setModal(true)
                dispatch(revsAdded([]))
            })
    }
    const handleCountChange = (count, id) => {
        let found = false;
        let updatedArray = revs.map(function (item) {
            if (item.id === id) {
                found = true;
                return Object.assign({}, item, {count: count});
            }
            return item;
        });

        if (!found) {
            updatedArray.push({id, count});
        }
        dispatch(revsAdded(updatedArray))
    }
    const [selectAll, setSelectAll] = useState(true);
    const [checkboxes, setCheckboxes] = useState([]);

    const handleSelectAllChange = () => {
        const updatedCheckboxes = checkboxes.map(checkbox => ({
            ...checkbox,
            checked: !selectAll,
        }));
        setCheckboxes(updatedCheckboxes);
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        let test = order?.items?.map(item =>
            ({...item, checked: true})
        )
        setCheckboxes(test)
        dispatch(revsAdded(test?.map(item => ({id: item.id, count: +item.count}))))
    }, [order])

    const handleCheckboxChange = checkboxId => {
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.product.id === checkboxId
                ? {...checkbox, checked: !checkbox.checked}
                : checkbox
        );
        setCheckboxes(updatedCheckboxes);
        const allChecked = updatedCheckboxes.every(checkbox => checkbox.checked);
        setSelectAll(allChecked);
    };
    return (
        <>
            <OrderContainer>
                <ErrorDialog handleCloseModal={() => setQuestionModal(false)} handleClose={cancel}
                             message={t("texts.sure")} open={questionModal} success="none"/>
                <ErrorDialog handleClose={handleClose}
                             message={isReversable ? t("texts.order_is_reversed") : t("texts.order_is_canceled")}
                             open={modal}
                             success="success"/>
                <OrderInfoContainer>
                    {isLoading ? (
                        <OrderTextSkeleton/>
                    ) : (
                        <ul>
                            <li>
                                <span className="bold">{t("texts.order_status")}</span>
                                <span>{order?.status}</span>
                            </li>
                            <li>
                                <span className="bold">{t("texts.payment_type")}</span>
                                <span>{order?.method}</span>
                            </li>
                            <li>
                                <span className="bold">{t("texts.address")}</span>
                                <span>{order?.address?.address}</span>
                            </li>
                            {order?.address?.details && (
                                <li>
                  <span className="bold">
                    {t("texts.delivery_details_more_info")}
                  </span>
                                    <span>{order?.address?.details}</span>
                                </li>
                            )}
                            <li>
                                <span className="bold">{t("texts.delivery_day")}</span>
                                <span>{`${
                                    order?.date
                                        ? moment(order?.date).format("DD-MM-YYYY") + ", "
                                        : ""
                                } ${order?.start}-${order?.end}`}</span>
                            </li>
                            <li>
                                <span className="bold">{t("texts.price")}</span>
                                <span>{order?.totalWithDelivery}</span>
                            </li>
                            {!!order?.extraOptions?.length && (
                                <li>
                                    <span className="bold">{t("texts.additional_services")}</span>
                                    {order?.extraOptions.map((option) => (
                                        <span key={option.id} className="mr">
                      {option.name}
                    </span>
                                    ))}
                                </li>
                            )}
                            {!!order?.reverses && (
                                <Link
                                    className="see-details"
                                    to={`/personal-page/order/returned/${order.id}`}
                                >
                                <li>
                                    <span className="bold">Վերադարձված ապրանքների քանակը</span>
                                    <span className="mr">

                                {order.reverses.length}

                            </span>
                                 </li>
                                </Link>
                                )}
                            </ul>
                    )}
                    {isReversable &&
                        <span className="message">Նշեք ապրանքի այն քանակը, որը ցանկանում եք հետ վերադարձնել</span>}
                </OrderInfoContainer>

                <OrderCardsWrapper isReversable={isReversable} single>
                    {isLoading
                        ? Array.from({length: 3}).map((x, i) => (
                            <OrderCardSkeleton key={i}/>
                        ))
                        : <>
                            <div>
                                {isReversable &&
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                        />
                                        <span className={"selectAll"}/>
                                        Ընտրել բոլորը
                                    </label>}
                                {checkboxes?.map(product => (
                                    <label>
                                        {isReversable &&
                                            <>
                                                <input
                                                    type="checkbox"
                                                    checked={product.checked}
                                                    onChange={() => handleCheckboxChange(product?.product?.id)}
                                                />
                                                <span/>
                                            </>}
                                        <SingleOrderCard
                                            checked={product.checked}
                                            isReversable={isReversable}
                                            total={product?.total}
                                            key={product?.product?.id}
                                            initCount={product?.count}
                                            product={product}
                                            deleteIcon={deleteIcon}
                                            handleCountChange={handleCountChange}
                                        />
                                    </label>
                                ))}
                            </div>
                                <Info className="totalPriceWrapper">
                                    <InfoItem>
                                        <span>{t("texts.price")}</span>
                                        <span>{order?.subtotal}</span>
                                    </InfoItem>
                                    {order?.discount?.[0] !== "0" && (
                                        <InfoItem>
                                            <span>{t("texts.sale")}</span>
                                            <span>{order?.discount}</span>
                                        </InfoItem>
                                    )}
                                    <p className="totalPrice">{order?.total}</p>

                                </Info>
                        </>}
                </OrderCardsWrapper>
                {isReversable ?
                    <div style={{display: 'flex'}}>
                        <OrderButtonsWrapper>
                            <button
                                className="see-details back-to-orders"
                                onClick={() => setIsReversable(false)}
                            >
                                {t("actions.back")}
                            </button>
                        </OrderButtonsWrapper>
                        <OrderButtonsWrapper>
                            <button
                                className="see-details"
                                onClick={() => reverse()}
                            >
                                {t("actions.revers")}
                            </button>
                        </OrderButtonsWrapper>
                    </div>
                    :
                    <div style={{display: 'flex'}}>
                        <OrderButtonsWrapper>
                            <button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                className="see-details back-to-orders"
                            >
                                {t("actions.back")}
                            </button>
                        </OrderButtonsWrapper>
                        {order?.isCancelable &&
                            <OrderButtonsWrapper>
                                <button
                                    className="see-details"
                                    onClick={() => setQuestionModal(true)}
                                >
                                    {t("actions.cancel")}
                                </button>
                            </OrderButtonsWrapper>
                        }
                        {order?.isReversable &&
                            <OrderButtonsWrapper>
                                <button
                                    className="see-details"
                                    onClick={() => setIsReversable(true)}
                                >
                                    {t("actions.revers")}
                                </button>
                            </OrderButtonsWrapper>
                        }
                    </div>
                }

            </OrderContainer>
        </>
    );
};

export default SingleOrder;
