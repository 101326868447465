import React from "react";
import { Skeleton } from "@mui/material";

const OrderTextSkeleton = ({ fl }) => {
  return (
    <div
      style={{ margin: fl ? "0" : "0 auto", maxWidth: "70%", width: "100%" }}
    >
      {Array.from({ length: 5 }).map((x, i) => (
        <Skeleton
          key={i}
          style={{ transform: "scale(1)", marginBottom: "10px" }}
        />
      ))}
    </div>
  );
};

export default OrderTextSkeleton;
