import styled, { css } from "styled-components";
import { searchScrollbar } from "../../shared/styled";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

const smTypo = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #373e4b;
`;

const styledInput = css`
  ${typo}
  border: 1px solid #373e4b;
  border-radius: 10px;
  width: 100%;
  padding: 15px 10px;
  outline: 0;
  &:focus-visible {
    outline: 0.1px solid #373e4b;
  }
`;

export const Background = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  opacity: 50%;
  background-color: #000;
  z-index: 100;
  display: none;
  &.open {
    display: unset;
  }
  &.fullwidth {
    max-height: ${({ vHeight }) => `${vHeight}px`};
    height: 100%;
  }
`;

export const Dialog = styled.div`
  position: absolute;
  z-index: 101;
  width: 100%;
  display: none;
  background-color: #fff;
  &.open {
    display: block;
  }
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 420px;
  max-height: 95%;
  overflow-y: auto;
  ${searchScrollbar}
  &.error {
    padding: 10px 0;
    max-width: 400px;
  }
  &.mw {
    max-width: 700px;
  }
  @media (max-width: 768px) {
    &.fullwidth {
      top: 0;
      left: 0;
      transform: translate(0);
      max-width: 100%;
      max-height: ${({ vHeight }) => `${vHeight}px`};
      height: 100%;
    }
  }
  @media (max-width: 420px) {
    &.error {
      max-width: calc(100% - 20px);
    }
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px;
  &.error {
    flex-direction: column;
    & h5 {
      text-align: center;
    }
  }
  & button {
    width: fit-content !important;
  }
`;

export const Heading = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #373e4b;
  &.sm {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const IconButton = styled.button`
  border: 0;
  background-color: inherit;
  cursor: pointer;
  margin-left: auto;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #373e4b;
  position: relative;
`;

export const Content = styled.div`
  ${props=>props.flex && `
  display: flex;
  gap: 5px;`}
  ${props=>props.center && `text-align: center;
  p {margin-bottom:10px}`};
  padding: 20px;
  & .centered {
    min-width: 50%;
    margin: 0 auto;
    justify-content: center;
  }
`;

export const Typo = styled.p`
  ${typo}
  &.error {
    font-weight: 700;
    text-align: center;
    padding: 30px 0;
  }
  &.err {
    padding: 0;
    color: #e42829;
  }
  &.cr {
    @media (min-width: 769px) {
      text-align: center;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & .grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 481px) {
      & .fullwidth-item {
        grid-column: 1/3;
      }
    }
  }
  & .MuiRating-iconFilled {
    color: #ffe600;
  }
  & .rate-error {
    width: fit-content;
    border-radius: 10px;
    padding: 15px 10px;
    border: 1px solid #e42829;
  }
`;

export const Input = styled.input`
  ${styledInput}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &.error {
    border-color: #e42829;
    outline-color: #e42829;
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  ${styledInput}
  height: 84px;
  ::-webkit-scrollbar {
    display: none;
  }
  &.ltypo {
    ${typo}
    color: #000;
  }
  &.error {
    border-color: #e42829;
    outline-color: #e42829;
  }
`;

export const Label = styled.label`
  ${smTypo}
  display: block;
  margin-bottom: 10px;
  &::first-letter {
    text-transform: capitalize;
  }
  &.fl {
    min-height: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    column-gap: 2px;
    & .up {
      display: block;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    @media (max-width: 480px) {
      min-height: unset;
    }
  }
`;

export const TextButton = styled.button`
  ${typo}
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  &:hover {
    opacity: 0.9;
  }
  margin-bottom: 10px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  & .info {
    max-width: 250px;
    width: 100%;
    & img {
      width: 60px;
      height: 60px;
      border: 1px solid #373e4b;
      border-radius: 5px;
    }
    & h5 {
      font-size: 14px;
      line-height: 16px;
      max-height: 48px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
    & .grid {
      width: 100%;
    }
    & .info {
      max-width: 300px;
    }
  }
`;
