export const data = {
    hy: {
        title: `SHINMAG ԿՈՒՏԱԿԱՅԻՆ ԶԵՂՉԵՐԻ ՀԱՄԱԿԱՐԳ`,
        content: [
            `Որքան շատ գնումներ եք կատարում, այնքան ավելի մեծ զեղչեր եք ստանում`,
            `Անչափ գնահատելով իր հաճախորդներին՝ Shinmag.am-ը մշակել է կուտակային զեղչերի հատուկ համակարգ `,
            `Մեր օնլայն խանութում պատմություն է պահպանվում կատարված յուրաքանչյուր գնման վերաբերյալ, ինչն ազդում է հետագայում գնումներ կատարելիս ստացված զեղչի չափի վրա:`,
            `Կուտակային զեղչերի հատուկ համակարգի պայմանները:`,
            `Կուտակային զեղչը գործում է միայն կայքում  գրանցված օգտատերերի համար:`,
            `Կուտակային զեղչը հաշվարկվում է Ձեր կողմից կատարված բոլոր այն պատվերների հանրագումարի հիման վրա, որոնք ստացել են «Առաքված» կարգավիճակը: Չեղարկված կամ վճարման/առաքման կարգավիճակում գտնվող պատվերները հաշվի չեն առնվում:`,
            `Կուտակային համակարգը չի տարածվում ակցիաների շրջանակներում հատուկ գներով առաջարկվող, ինչպես նաև իջեցված գներով ապրանքների վրա։ Այնուա նայնիվ, նման ապրանքների գինը հաշվի է առնվում գնումների ընդհանուր պատմության մեջ, որն ուղղակիորեն ազդում է Ձեր կուտակային զեղչի չափի վրա:`],
        tablesTitle: "Ֆիզիկական անձ հանդիսացող բոլոր հաճախորդների համար գործում է կուտակային զեղչերի համակարգ՝",
        or: "ԿԱՄ",
        price: "ԳՆՈՒՄՆԵՐԻ ԱՐԺԵՔ /ՀՀ ԴՐԱՄ/",
        next: "ՀԱՋՈՐԴ ԳՆՈՒՄՆԵՐ",
        firstTable: [
            ['0 - 1.000.000', '3% զեղչ', '-', '-'],
            ['1.000.000-3.000.000', '5% զեղչ', 'անվճար', '-'],
            ['3.000.000-ից ավել', '10% զեղչ', 'անվճար', 'անվճար']
        ],
        secondTh: "ԱՌԱՔՈՒՄ",
        thirdTh: "ԲԱՆՎՈՐԻ ԾԱՌԱՅՈՒԹՅՈՒՆ",
    },
    ru: {
        title: `НАКОПИТЕЛЬНАЯ СИСТЕМА СКИДОК SHINMAG`,
        content: [
            `В услугу доставки (кроме мопеда) не входит разгрузка (выгрузка из автомобиля), подъем и транспортировка грузов являются отдельными услугами.`,
            `Доставка осуществляется после подтверждения готовности к получению товара. В день доставки, пожалуйста, свяжитесь с телефоном, указанным в заказе.`,
            `Если вы выбрали «Экспресс» доставку, машина прибудет по адресу даже без предварительного подтверждения заказа по телефону.`,
            `Вы можете изменить время доставки или настроить заказ, связавшись с 012440440`,
            `Если подъезд к месту разгрузки затруднен, то автомобиль остановится на максимально близком расстоянии от него, не нарушая ПДД и исключая возможность повреждения транспорта. На разгрузку автомобиля массой до 1,8 тонны отводится 30 минут, более 1,8 тонны – 60 минут.`,
            `Оплатить доставку можно заранее любым удобным способом (на сайте, по телефону), а также на месте наличными или банковской картой, до начала разгрузки.`,
            `Если у вас есть вопросы, связанные с качеством или количеством продукции, вы можете обратиться к представителю компании на месте или в Службу поддержки клиентов по телефону 012440440. Номер телефона также указан в товарном чеке.`
        ],
        tablesTitle: "Для всех клиентов - физических лиц действует система накопительных скидок:",
        or: "ИЛИ",
        price: "СТОИМОСТЬ ПОКУПКИ",
        next: "СЛЕДУЮЩИЕ ПОКУПКИ",
        firstTable: [
            ['0 - 1.000.000', '3% скидка', '-', '-'],
            ['1.000.000-3.000.000', '5% скидка', 'бесплатно', '-'],
            ['3.000.000-ից ավել', '10% скидка', 'бесплатно', 'бесплатно']
        ],
        secondTh: "ДОСТАВКА",
        thirdTh: "УСЛУГИ ГРУЗЧИКА",
    },
};
