import React from "react";
import { Skeleton } from "@mui/material";

const OrderCardSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <Skeleton
        style={{ transform: "scale(1)", width: "80px", height: "80px" }}
      />
      <div style={{ minWidth: "120px", padding: "4px" }}>
        <Skeleton
          style={{ transform: "scale(1)", Width: "100%", marginBottom: "5px" }}
        />
        <Skeleton style={{ transform: "scale(1)", width: "70%" }} />
      </div>
    </div>
  );
};

export default OrderCardSkeleton;
