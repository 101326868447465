import styled from "styled-components";

export const Section = styled.section`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 115px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;
export const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #373e4b;
`;
