import { t } from "i18next";
import React from "react";
import { LinkBtn } from "../../components/cards/styled";
import { Wrapper } from "./styled";

const NotFound = () => {
  return (
    <Wrapper>
      <h1>{t("texts.page_not_found")}</h1>
      <LinkBtn to="/">{t("texts.main")}</LinkBtn>
    </Wrapper>
  );
};

export default NotFound;
