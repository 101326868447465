import styled, { css } from "styled-components";

export const lTypo = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #48374b;
  @media (max-width: 1440px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const upCase = css`
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;
