import React from "react";
import {
  SelectButton,
  SelectWrapper,
  Options,
  Option,
  Container,
} from "./styled";
import { ErrorText } from "../../shared/styled";
import { ClickAwayListener } from "@mui/material";

const CustomSelect = ({
  placeholder,
  selectedOption,
  expanded,
  options,
  handleExpand,
  handleSelect,
  setValue,
  className,
  hours,
  onClickAway,
  errorText,
  productPage,
  disabled,
}) => {
  const handleSelectAndClose = (option) => {
    handleSelect(option, setValue, "start");
    handleExpand();
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Container className={`${productPage && "product-page"} ${className}`}>
        <SelectWrapper className={className}>
          <SelectButton
            disabled={disabled}
            type="button"
            className={`${className} ${expanded && "expanded"}`}
            onClick={handleExpand}
          >
            {(hours && !selectedOption.start) ||
            (!hours && !selectedOption?.name) ? (
              <span className="placeholder">{placeholder}</span>
            ) : hours ? (
              `${selectedOption.start}-${selectedOption.end}`
            ) : (
              <span>{selectedOption?.name}</span>
            )}
          </SelectButton>
          {expanded && (
            <Options>
              <ul>
                {options?.map((option) => (
                  <Option key={option?.id}>
                    <button
                      type="button"
                      onClick={() => handleSelectAndClose(option)}
                    >
                      {hours
                        ? `${option?.start}-${option?.end}`
                        : option?.name || "option"}
                    </button>
                  </Option>
                ))}
              </ul>
            </Options>
          )}
        </SelectWrapper>
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </Container>
    </ClickAwayListener>
  );
};

export default CustomSelect;
