import React from "react";
import { SectionNavLink, SectionNavigationContainer } from "./styled";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { generalInfo } from "../../shared/generalInfo";

const Services = () => {
  const { t } = useTranslation();
  const links = generalInfo[1].items.filter(
    (elem) =>
      elem.name !== "delivery_and_dumping" && elem.name !== "for_business"
  );

  return (
    <>
      <SectionNavigationContainer>
        {links.map((link) => (
          <SectionNavLink to={link.path} key={link.id}>
            {t(`texts.${link.name}`)}
          </SectionNavLink>
        ))}
      </SectionNavigationContainer>
      <Outlet />
    </>
  );
};

export default Services;
