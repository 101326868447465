import styled from "styled-components";
import { lTypo, upCase } from "../styled";

export const DFSection = styled.section`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  background: #ffe500;
  border-radius: 10px;
  padding: 30px 60px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #373e4b;
  & img {
    width: 300px;
    height: 300px;
  }
  & .up {
    ${upCase}
  }
  & .right-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  & .mainTitle {
    ${lTypo}
  }
  & ul {
    margin-left: 20px;
  }
  @media (max-width: 1440px) {
    padding: 20px 30px;
    font-size: 18px;
    line-height: 20px;
    & .right-content {
      gap: 20px;
    }
  }
  @media (max-width: 1110px) {
    & img {
      width: 200px;
      height: 200px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    & img {
      width: 300px;
      height: 300px;
      align-self: center;
    }
    & .right-content {
      gap: 15px;
    }
  }
  @media (max-width: 480px) {
    & img {
      width: 100%;
      max-width: 200px;
      height: auto;
    }
    & .right-content {
      gap: 10px;
    }
  }
`;
