import React from "react";
import { PaymentType } from "./styled";

const PaymentRadioContent = ({ type }) => {
  return (
    type?.available && (
      <PaymentType>
        <span>{type?.name}</span>
        <img src={type?.icon} alt={`${type?.icon}`} height="20" />
      </PaymentType>
    )
  );
};

export default PaymentRadioContent;
