import moment from "moment";
import { useTranslation } from "react-i18next";

const getToday = () => {
  const today = new Date();
  const date = moment(today).format("DD-MM-YYYY");
  const time = moment(today).format("HH:mm");
  return [today, date, time];
};

export const useGetDates = () => {
  const { t } = useTranslation();
  const week = [
    { id: 1, name: t("texts.monday") },
    { id: 2, name: t("texts.tuesday") },
    { id: 3, name: t("texts.wednesday") },
    { id: 4, name: t("texts.thursday") },
    { id: 5, name: t("texts.friday") },
    { id: 6, name: t("texts.saturday") },
    { id: 0, name: t("texts.sunday") },
  ];

  const [today, date, time] = getToday();

  const firstDays = [];
  if (time < "18:00") {
    firstDays.push({ id: 1, name: t("texts.today"), date });
  }
  firstDays.push({
    id: 2,
    name: t("texts.tomorrow"),
    date: moment(today).add(1, "days").format("DD-MM-YYYY"),
  });

  const index = week.indexOf(
    week.find((item) => item.id === moment(today).add(2, "days").weekday())
  );

  const customWeek = [...week.slice(index), ...week.slice(0, index)];

  for (let i = 0, id = 3; i < customWeek.length; i++, id++) {
    const obj = {
      id,
      name: customWeek[i].name,
      date: moment(today)
        .add(i + 2, "days")
        .format("DD-MM-YYYY"),
    };
    firstDays.push(obj);
  }

  const lastDate = moment(today).add(9, "days");
  const lastDay = lastDate.weekday();
  const lastDayName = week.find((item) => item.id === lastDay)?.name;

  return [
    ...firstDays,
    { id: 10, name: lastDayName, date: lastDate.format("DD-MM-YYYY") },
  ];
};
