import styled from "styled-components";
import { lTypo, upCase } from "../styled";

export const Section = styled.section`
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  padding: 40px;
  & .title {
    ${lTypo}
    ${upCase}
    color:#000;
    text-align: center;
    margin-bottom: 40px;
  }
  &.bckg-brown {
    background-color: #d9d9d9;
    box-shadow: none;
  }
  @media (max-width: 480px) {
    padding: 40px 20px;
    &.smpad {
      padding: 40px 10px;
    }
  }
`;

export const ServiceSection = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  & .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #ffe500;
    border-radius: 50%;
    & img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
  & p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    ${upCase}
    text-align: center;
  }
`;
