import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  color: #373e4b;
  & h1 {
    &::first-letter {
      text-transform: uppercase;
    }
  }
  & .fl {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 420px) {
    & a {
      width: 100%;
      text-align: center;
    }
  }
`;
