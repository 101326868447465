import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const EmptyMessage = styled.h5`
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  font-weight: 700;
  color: #737475;
  width: 100%;
`;

const EmptyList = () => {
  const { t } = useTranslation();
  return <EmptyMessage>{t("texts.nothing_found")}</EmptyMessage>;
};

export default EmptyList;
