import React from "react";
import {Badges as Container} from "./styled";
import {useTranslation} from "react-i18next";

const Badges = ({product}) => {
    const {t} = useTranslation();
    return (
        <Container>
            {!!
                product?.bonusPercent && (
                <span className="saleBonus">-{product?.bonusPercent}% {t("texts.sale")}</span>
            )}
            {!!product?.isPopular && (
                <span className="popular">{t("texts.best_selling")}</span>
            )}
            {!!product?.isNew && <span className="new">{t("texts.new")}</span>}
            {!product?.discount.startsWith("0") && (
                <span className="discounted">{t("texts.sale")}</span>
            )}
            {!!product?.bonus && <span className="bonus">{t('texts.accumulatingPoints')} - {product?.bonus}</span>}
        </Container>
    );
};

export default Badges;
