import { createSlice } from "@reduxjs/toolkit";

const appHeightSlice = createSlice({
  name: "appHeight",
  initialState: {
    val: 0,
  },
  reducers: {
    addAppHeight: (state, action) => {
      return { val: action.payload };
    },
  },
});

export const { addAppHeight } = appHeightSlice.actions;

export default appHeightSlice.reducer;
