import { useState, useEffect } from "react";
import moment from "moment";

const addHours = (start, int) => {
  return moment(start).add(int, "hours");
};

export const useGetHoursIntervals = (int, today) => {
  const [hours, setHours] = useState([]);
  const format = "HH:mm";

  useEffect(() => {
    let arr = [];
    const date = new Date();
    let start = today ? moment(date) : moment("08:00", format);
    const finish = moment("18:00", format);
    let id = 1;
    while (start < finish) {
      const end = addHours(start, int);
      arr = [
        ...arr,
        {
          id,
          start: moment(start).format(format),
          end: moment(end).format(format),
        },
      ];
      start = end;
      id++;
    }
    setHours(arr);
  }, [int, today]);
  return hours;
};
