import React, { useState, useEffect } from "react";
import {
  Background,
  Dialog,
  DialogHeader,
  IconButton,
  Divider,
  Heading,
  Content,
  Typo,
  Form,
  Label,
  Input,
  Textarea,
  TextButton,
  ProductWrapper,
} from "./styled";
import { Button } from "../auth/styled";
import closeIcon from "../../assets/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteKey } from "../../utils/funcs/formHandlings";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useLocalStorageGetItem } from "../../utils/hooks/hooks";
import { ErrorText } from "../../shared/styled";
import checkIcon from "../../assets/checkIcon.svg";
import { useNavigate } from "react-router";
import { createPortal } from "react-dom";
import { ProductInfo } from "../../pages/cart/styled";
import OfferPriceInCartInputs from "./OfferPriceInCartInputs";

const OfferPriceInCart = ({ handleClose, open, products }) => {
  const { t } = useTranslation();
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0].val);
  const vHeight = useSelector((state) => state.appHeight.val);

  const headers = {
    "Accept-Language": lang,
    Authorization: `Bearer ${token}`,
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    let arr = [];
    products.forEach((elem) => {
      const obj = {
        productId: elem.product.id,
        img: elem.product?.images?.main?.src,
        name: elem.product.name,
        count: elem.count,
        price:
          elem.total
            ?.slice(0, -5)
            .split(/\s(.+)/)
            .join("") || "",
      };
      arr = [...arr, obj];
    });
    setItems(arr);
  }, [products]);

  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleChange = (productId, name, value) => {
    let arr = [];
    items.forEach((elem) => {
      if (elem.productId === productId) {
        let obj = {
          ...elem,
          [name]: value,
        };
        arr = [...arr, obj];
      } else {
        arr = [...arr, elem];
      }
    });
    setItems(arr);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = [];
    items.forEach((item) => {
      const prod = products.find((p) => p.product.id === item.productId);
      if (
        prod &&
        +prod.product.price
          ?.slice(0, -5)
          .split(/\s(.+)/)
          .join("") !== +item.price
      ) {
        data = [
          ...data,
          {
            productId: item.productId,
            price: +item.price,
            count: +item.count,
            message: "",
          },
        ];
      }
    });
    axios
      .post(`${baseUrl}/products/price-requests`, { items: data }, { headers })
      .then(() => {
        setSuccess(true);
        setErrMessage("");
        handleClose("success");
      })
      .catch((err) => {
        setErrMessage(err.response.data.message);
      });
  };

  return createPortal(
    <>
      <Background
        className={`fullwidth ${open && "open"}`}
        onClick={handleClose}
        vHeight={vHeight}
      />
      <Dialog className={`fullwidth mw ${open && "open"}`} vHeight={vHeight}>
        <DialogHeader>
          <Heading>{t("actions.offer_price")}</Heading>
          <IconButton onClick={handleClose}>
            <img src={closeIcon} alt="close offer price modal" />
          </IconButton>
        </DialogHeader>
        <Divider />
        <Content>
          <Form onSubmit={handleSubmit}>
            {items?.map((product) => (
              <ProductWrapper key={product.productId}>
                <ProductInfo as="div" className="info">
                  <img
                    width="140"
                    height="140"
                    src={product.img}
                    alt="product"
                  />
                  <h5>{product.name}</h5>
                </ProductInfo>
                <OfferPriceInCartInputs
                  price={product.price}
                  count={product.count}
                  productId={product.productId}
                  handleChange={handleChange}
                />
              </ProductWrapper>
            ))}
            {errMessage && <Typo className="err">{errMessage}</Typo>}
            <Button>
              <img src={checkIcon} alt="sent" width="18" />
              <span>{t("actions.offer_price")}</span>
            </Button>
            <TextButton type="button" onClick={handleClose}>
              {t("actions.cancel")}
            </TextButton>
          </Form>
        </Content>
      </Dialog>
    </>,
    document.getElementById("modal-root")
  );
};

export default OfferPriceInCart;
