import React from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 360px;
  @media (max-width: 1440px) {
    max-width: 300px;
  }
  @media (max-width: 1240px) {
    max-width: 270px;
  }
  @media (max-width: 1024px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const FiltersSkeleton = () => {
  return (
    <Wrapper>
      {Array.from({ length: 3 }).map((x, i) => (
        <Skeleton
          key={i}
          style={{
            transform: "scale(1)",
            width: "100%",
            height: "30px",
            marginBottom: "20px",
          }}
        />
      ))}
    </Wrapper>
  );
};

export default FiltersSkeleton;
