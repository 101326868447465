import React, {useState} from "react";
import {Container, DeliveryOption, Radio} from "./styled";
import AddressRadioContent from "./AddressRadioContent";
import PaymentRadioContent from "./PaymentRadioContent";
import {Link} from "react-router-dom";
import infoIcon from "../../assets/infoIcon.svg";
import {useTranslation} from "react-i18next";

const GiftCardRadio = ({
                           options,
                           value,
                           setGiftCard,
                           giftCard
                       }) => {
    const { t } = useTranslation();

    const onChange = (card, balance) => {
        setGiftCard({id: card?.publicId, use: +balance})
        // if(giftCard?.find(item=> item.id === card?.publicId)){
        //     giftCard?.map(same => (
        //         (same.id === card?.publicId) && (same["use"] = +balance)
        //     ))
        // }
        // else {
        //     setGiftCard([...giftCard, {id: card?.publicId, use: +balance}])
        // }
    }
    return(
        <Container
            className={`giftCard`}
           /* className={`${payments || addresses ? "payment" : ""} ${
                to ? "purchase" : ""
            }`}*/
        >
            {options?.map((option) => (
                <Radio
                    style={{minHeight: "100px"}}
                    className={"payment"}
                    key={option?.publicId}
                    type="button"
                    disabledColor={giftCard?.id !== option?.publicId}
                    onClick={() => onChange(option, option?.balance)}
                >
                    <div className="checkbox">
                        <div
                            className={
                                giftCard?.id === option?.publicId
                                    ? "checked"
                                    : ""
                            }
                        />
                    </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px"}}>
                            <span>{t("texts.card_number")} - {option?.publicId}</span>
                            <span>{t("texts.card_balance")} - {option?.balance} {t("texts.dram")}</span>
                            <span>{t("texts.use_balance")} ֊ <input disabled={giftCard?.id !== option?.publicId}
                                                               defaultValue={option?.balance}
                                                               onChange={(e)=>onChange(option, e.target.value)} type="text"/></span>
                        </div>
                </Radio>
            ))}
        </Container>
    );
};

export default GiftCardRadio