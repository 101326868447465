import { createSlice } from "@reduxjs/toolkit";

const initialState = { countToggle: false };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    changeCartLength: (state) => {
      return { countToggle: !state.countToggle };
    },
  },
});

export const { changeCartLength } = cartSlice.actions;
export default cartSlice.reducer;
