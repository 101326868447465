import React, { useState } from "react";
import { InputContainer, SubscribeFormContainer } from "./styled";
import { useTranslation } from "react-i18next";
import submitIcon from "../../assets/subscribeInputSubmitIcon.webp";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useSelector } from "react-redux";
import {useLocalStorageGetItem} from "../../utils/hooks/hooks";

const SubscribeForm = () => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang[0]);
  const token = useLocalStorageGetItem("token");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const unsetMessage = () => {
    setError("");
    setSuccess("");
    setEmail("");
  };

  const onInputChange = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
    // unsetMessage();
  };

  const onInputSubmit = (e) => {
    e.preventDefault();
    if (email) {
      axios
        .post(
          `${baseUrl}/auth/subscription`,
          {
            email,
          },
          {
            headers: {
              "Accept-Language": lang.val,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSuccess(res?.data?.message);
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
        })
        .finally(setTimeout(() => unsetMessage(), 5000));
    }
  };

  return (
    <SubscribeFormContainer>
      <p className="title">{t("actions.subscribe_to_our_news")}</p>
      <p className="sub-title">{t("actions.be_informed")}</p>
      <span className="color-overlay"></span>
      <InputContainer>
        <input
          type="email"
          className={`subscribe-input ${error && "error"}`}
          placeholder="info@shinmag.am"
          value={email}
          onChange={onInputChange}
        />
        <button onClick={onInputSubmit} className="subscribe-submit">
          <span className="button-text">{t("actions.send")}</span>
          <img className="button-icon" src={submitIcon} alt="submit" />
        </button>
        {error && <p className="message error-text">{error}</p>}
        {success && <p className="message">{success}</p>}
      </InputContainer>
    </SubscribeFormContainer>
  );
};

export default SubscribeForm;
