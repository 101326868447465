export const getBradcrumbs = (breadcrumbs, last, product) => {
  const arr = [];
  if (product) {
    const crumbs = breadcrumbs?.pop();
    for (let item of breadcrumbs) {
      arr.push({
        to: `/subcategories/${item.id || item.uuid}`,
        name: item.name,
      });
    }
    if (crumbs) {
      arr.push({
        to: `/sub-category/${crumbs.id || crumbs.uuid}/1`,
        name: crumbs.name,
      });
    }
  } else {
    for (let item of breadcrumbs?.breadcrumb) {
      if (!item.is_subcategory || breadcrumbs?.categoryParentId) {
        arr.push({
          to: `/subcategories/${item.id || item.uuid}`,
          name: item.name,
        });
      } else {
        arr.push({
          to: `/sub-category/${item.id || item.uuid}/1`,
          name: item.name,
        });
      }
    }
  }
  last && arr.push(last);
  return arr;
};
