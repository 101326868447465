import {Skeleton} from "@mui/material";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {ProductInfoContainer} from "./styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 30px;
  @media (max-width: 1110px) {
    gap: 20px;
  }

  & .MuiSkeleton-root {
    transform: scale(1, 1);

    &.name {
      width: 100%;
      height: 30px;
    }

    &.brand {
      width: 200px;
      height: 18px;
    }

    &.code {
      width: 300px;
      height: 18px;
    }

    &.price {
      width: 200px;
      height: 24px;
    }

    &.fullw {
      width: 100%;
      height: 40px;
    }

    &.text {
      width: 100%;
      height: 18px;
      margin: 3px 0;
    }
  }
`;

const ImgWrapper = styled.div`
  width: 50%;
  min-height: 100%;
  @media (max-width: 768px) {
    min-height: ${({vHeight}) => `${vHeight * 0.7}px`};
    & .MuiSkeleton-root {
      max-width: ${({vHeight}) => `${vHeight * 0.7}px`};
      max-height: ${({vHeight}) => `${vHeight * 0.7}px`};
    }
  }
  @media (max-width: 480px) {
    min-height: ${({vHeight}) => `${vHeight * 0.8}px`};
    & .MuiSkeleton-root {
      max-width: ${({vHeight}) => `${vHeight * 0.8}px`};
      max-height: ${({vHeight}) => `${vHeight * 0.8}px`};
    }
  }
`;

export const ProductInfoSkeleton = () => {
    return (
        <Wrapper>
            <Skeleton className="name"/>
            <Skeleton className="brand"/>
            <Skeleton className="code"/>
            <Skeleton className="price"/>
            <Skeleton className="fullw"/>
            <Skeleton className="brand"/>
            <div>
                <Skeleton className="text"/>
                <Skeleton className="text"/>
                <Skeleton className="text"/>
            </div>
        </Wrapper>
    );
};

export const ImgSkeleton = () => {
    const vHeight = useSelector((state) => state.appHeight.val);
    return (
        <ImgWrapper vHeight={vHeight}>
            <Skeleton
                style={{
                    height: "100%",
                    transform: "scale(1,1)",
                }}
            />
        </ImgWrapper>
    );
};
