import React from "react";
import DataContainer from "../DataContainer";
import { Title, List } from "../styled";
import { data } from "./data";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import dot from "../../../assets/dot.svg";

const Dumping = () => {
  const lang = useSelector((state) => state.lang[0].val);
  const { t } = useTranslation();

  return (
    <DataContainer as="div">
      <div className="title">
        <span>{t("terms_of_dumping.heading")}</span>
      </div>
      <div>
        <Title className="title">{t("terms_of_dumping.info")}</Title>
      </div>
      <div className="title">
        <span>{t("terms_of_dumping.infoText")}</span>
      </div>
      <div className="title">
        <List ul>
              <li key={t("terms_of_dumping.infoText1")}><img src={dot} alt=""/>{t("terms_of_dumping.infoText1")}</li>
              <li key={t("terms_of_dumping.infoText2")}><img src={dot} alt=""/>{t("terms_of_dumping.infoText2")}</li>
              <li key={t("terms_of_dumping.infoText3")}><img src={dot} alt=""/>{t("terms_of_dumping.infoText3")}</li>
              <li key={t("terms_of_dumping.infoText4")}><img src={dot} alt=""/>{t("terms_of_dumping.infoText4")}</li>
              <li key={t("terms_of_dumping.infoText5")}><img src={dot} alt=""/>{t("terms_of_dumping.infoText5")}</li>
        </List>
      </div>
        <div>
            <Title className="title">{t("terms_of_dumping.price")}</Title>
        </div>
        <div className="title">
            <span>{t("terms_of_dumping.priceText")}</span>
        </div>
        <div className="title">
            <List ul>
                <li key={t("terms_of_dumping.priceText1")}><img src={dot} alt=""/>{t("terms_of_dumping.priceText1")}</li>
                <li key={t("terms_of_dumping.priceText2")}><img src={dot} alt=""/>{t("terms_of_dumping.priceText2")}</li>
                <li key={t("terms_of_dumping.priceText3")}><img src={dot} alt=""/>{t("terms_of_dumping.priceText3")}</li>
            </List>
        </div>
        <div>
            <Title className="title">{t("terms_of_dumping.safe")}</Title>
        </div>
        <div className="title">
            <span>{t("terms_of_dumping.safeText")}</span>
        </div>
        <div className="title">
            <List ul>
                <li key={t("terms_of_dumping.safeText1")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText1")}</li>
                <li key={t("terms_of_dumping.safeText2")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText2")}</li>
                <li key={t("terms_of_dumping.safeText3")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText3")}</li>
                <li key={t("terms_of_dumping.safeText4")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText4")}</li>
                <li key={t("terms_of_dumping.safeText5")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText5")}</li>
                <li key={t("terms_of_dumping.safeText6")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText6")}</li>
                <li key={t("terms_of_dumping.safeText7")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText7")}</li>
                <li key={t("terms_of_dumping.safeText8")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText8")}</li>
                <li key={t("terms_of_dumping.safeText9")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText9")}</li>
                <li key={t("terms_of_dumping.safeText10")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText10")}</li>
                <li key={t("terms_of_dumping.safeText11")}><img src={dot} alt=""/>{t("terms_of_dumping.safeText11")}</li>
            </List>
        </div>
        <div>
            <Title className="title">{t("terms_of_dumping.guarantee")}</Title>
        </div>
        <div className="title">
            <List ul>
                <li key={t("terms_of_dumping.guaranteeText1")}><img src={dot} alt=""/>{t("terms_of_dumping.guaranteeText1")}</li>
                <li key={t("terms_of_dumping.guaranteeText2")}><img src={dot} alt=""/>{t("terms_of_dumping.guaranteeText2")}</li>
            </List>
        </div>
        <div>
            <Title className="title">{t("terms_of_dumping.tariffs")}</Title>
        </div>
        <div className="title">
            <span>{t("terms_of_dumping.tariffs1")}</span>
        </div>
        <div className="title">
            <List ul>
                <li key={t("terms_of_dumping.tariffsText1_1")}><img src={dot} alt=""/>{t("terms_of_dumping.tariffsText1_1")}</li>
                <li key={t("terms_of_dumping.tariffsText1_2")}><img src={dot} alt=""/>{t("terms_of_dumping.tariffsText1_2")}</li>
            </List>
        </div>
        <div className="title">
            <span>{t("terms_of_dumping.tariffs2")}</span>
        </div>
        <div className="title">
            <List ul>
                <li key={t("terms_of_dumping.tariffsText2_1")}><img src={dot} alt=""/>{t("terms_of_dumping.tariffsText2_1")}</li>
                <li key={t("terms_of_dumping.tariffsText2_2")}><img src={dot} alt=""/>{t("terms_of_dumping.tariffsText2_2")}</li>
            </List>
        </div>
    </DataContainer>
  );
};

export default Dumping;
