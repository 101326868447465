import React, { useState, useEffect } from "react";
import { TitleContainer, FilterBtn, SelectWrapper } from "./styled";
import CustomSelect from "../custom-select/CustomSelect";
import clearIcon from "../../assets/clearIcon.svg";
import { useTranslation } from "react-i18next";

const FiltersHeader = ({
  toggleShowFilters,
  handleSelectedFiltersChange,
  selectedFilters,
  selectedCategories,
  price,
  handleUnsetFilters,
  selectedBrands,
}) => {
  const { t } = useTranslation();
  const [filtersCount, setFiltersCount] = useState(0);
  // const [expandSort, setExpandSort] = useState(false);
  // const [selectedSortingType, setSelectedSortingType] = useState(null);
  // const options = [
  //   { id: 1, name: "option1" },
  //   { id: 2, name: "option2" },
  // ];

  useEffect(() => {
    let count = 0;
    if (selectedFilters) {
      count += Object.keys(selectedFilters)?.length;
    }
    if (price) {
      count += 1;
    }
    if (selectedCategories?.length) {
      count += 1;
    }
    if (selectedBrands?.length) {
      count += 1;
    }
    setFiltersCount(count);
  }, [selectedFilters, price, selectedCategories, selectedBrands]);

  // const handleSelect = (option) => {
  //   setSelectedSortingType(option);
  // };

  const handleDeleteFilters = (e) => {
    e.stopPropagation();
    handleUnsetFilters();
  };

  return (
    <TitleContainer>
      <FilterBtn onClick={toggleShowFilters}>
        <span>{t("texts.filters")}</span>
        <button
          onClick={handleDeleteFilters}
          // title={t("actions.clear_filters")}
        >
          <span>{filtersCount}</span>
          <img src={clearIcon} alt="Clear" />
        </button>
      </FilterBtn>
      {/* <SelectWrapper>
        <CustomSelect
          productPage={true}
          placeholder={"մեխանիկ"}
          expanded={expandSort}
          selectedOption={selectedSortingType}
          options={options}
          handleExpand={() => setExpandSort((prev) => !prev)}
          handleSelect={handleSelect}
          onClickAway={() => setExpandSort(false)}
        />
      </SelectWrapper> */}
    </TitleContainer>
  );
};

export default FiltersHeader;
