import React, {useEffect, useRef} from "react";
import {
    CardContainer,
    ImageContainer,
    DescriptionContainer,
    MoreButtonContainer,
    TitleContainer,
    LinkBtn,
} from "./styled";
import {useTranslation} from "react-i18next";

const NewsCard = ({imgSrc, newsTitle, newsDate, newsDescription, id}) => {
    const {t} = useTranslation();
    const ref = useRef(null);
    useEffect(() => {
        ref.current && (ref.current.innerHTML = newsDescription)
    }, [ref?.current, t, newsDescription])
    return (
        <>
            <CardContainer>
                <LinkBtn to={`/news/single/${id}`}>
                    <ImageContainer>
                        <img src={imgSrc} alt="news-image"/>
                    </ImageContainer>
                    <TitleContainer>
                        <p>{newsTitle}</p>
                    </TitleContainer>
                </LinkBtn>
            </CardContainer>
        </>
    );
};

export default NewsCard;
