import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { PageHeading } from "../cart/styled";
import { Container } from "./styled";
import AboutSection from "./about-section/AboutSection";
import ProsSection from "./pros-section";
import ServicesSection from "./services-section";
import ProcessSection from "./process-section";
import ContactUsSection from "./contact-us-section";

const ForBusiness = () => {
  const { t } = useTranslation();
  const nav = [{ to: "/cart", name: t("texts.for_business") }];

  return (
    <>
      <Breadcrumbs nav={nav} />
      <PageHeading>{t("texts.for_business")}</PageHeading>
      <Container>
        <AboutSection />
        <ProsSection />
        <ServicesSection />
        <ProcessSection />
        <ContactUsSection />
      </Container>
    </>
  );
};

export default ForBusiness;
