import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const showAuthSlice = createSlice({
  name: "showAuth",
  initialState,
  reducers: {
    makeAuthVisible: () => true,
    makeAuthHidden: () => false,
  },
});

export const { makeAuthHidden, makeAuthVisible } = showAuthSlice.actions;
export default showAuthSlice.reducer;
