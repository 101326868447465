import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const typo = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 15px 20px;
  color: #373e4b;
  &:hover {
    color: #000;
  }
  &::first-letter {
    text-transform: capitalize;
  }
  @media (max-width: 480px) {
    padding: 10px 20px;
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: 112px;
  right: 30px;
  min-width: 240px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 1024px) {
    top: 92px;
  }
  @media (max-width: 480px) {
    width: 100%;
    right: 0;
  }
`;

export const CustomLink = styled(NavLink)`
  ${typo}
  text-decoration: none;
  display: block;
`;

export const Button = styled.button`
  ${typo}
  width: 100%;
  text-align: left;
  background-color: transparent;
`;
