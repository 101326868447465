import React from "react";
import { Counter, Button, Input } from "./styled";
import { NumericFormat } from "react-number-format";

const CounterBasket = ({
  count,
  handleChange,
  handleBlur,
  unit,
  step,
  handleCountChange,
}) => {
  return (
    <Counter>
      <Button onClick={() => handleCountChange("dec")} disabled={count <= step}>-</Button>
      <NumericFormat
        customInput={Input}
        type="number"
        value={count}
        onKeyDown={(e) => {
            // (
            //     ((unit.type === "int" || !e.target.value.length) &&
            //         e.key === ".") ||
            //     (+e.target.value === 0 && e.key === "0") ||
            //     e.key === "e" ||
            //     e.key === "-" ||
            //     e.key === "+"
            // )
          if (
            (+e.target.value === 0 && e.key === "0") ||
            e.key === "e" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button onClick={() => handleCountChange("inc")}>+</Button>
    </Counter>
  );
};

export default CounterBasket;
