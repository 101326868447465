import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const CompanyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 20px auto;
`;

export const NavigationContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 20px;
  overflow: scroll;
  max-width: 100%;
  margin-bottom: 40px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NavigationButton = styled(NavLink)`
  text-decoration: none;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #373e4b;
  &.active {
    background-color: #373e4b;
    color: #fff;
  }
`;

export const NavigationButtonn = styled(NavLink)`
  text-decoration: none;
  padding: 10px 20px;
  background-color: ${props => props.hash ? "#373e4b" : "#ffffff"};
  border: 1px solid #373e4b;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: ${props => props.hash ? "#ffffff" : "#373e4b"};
`;

export const MainContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 60px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 30px 0;
  }
`;

export const SectionNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 0;
  @media (max-width: 768px) {
    position: unset;
    flex-direction: row;
    max-width: 100%;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    margin: 0 auto;
    width: fit-content;
    & a {
      min-width: max-content;
    }
  }
`;

export const SectionNavLink = styled(NavLink)`
  padding: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
  width: fit-content;
  cursor: pointer;
  &::first-letter {
    text-transform: uppercase;
  }
  &.active {
    background: #373e4b;
    border-radius: 10px;
    color: #ffe500;
  }
`;

export const InfoContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #373e4b;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;

export const InfoParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  white-space: pre-wrap;
  & .bold {
    font-weight: 700;
    display: block;
    margin: 15px 0;
  }
/*  & .highlighted {
    background-color: yellow;
    font-weight: 700;
  }*/
  & .title {
    margin-bottom: 20px;
  }
`;

export const ContactsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 200px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #373e4b;
  border-radius: 10px;
`;

export const ContactHeading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #373e4b;
  text-transform: uppercase;
`;

export const IconParagraphWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ContactParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373e4b;
`;

export const SocialsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin-top: ${props =>props.top && '40px'};
  text-transform: ${props =>props.top && 'uppercase'};
  &.b {
    font-size: 20px;
  }
`;

export const List = styled.ul`
  margin-top: 10px;
  padding: ${props=>!props.ul && "0 20px"};;
  list-style: ${props=>props.ul && "none"};
  & li {
    margin: 5px 0;
    ${props=>props.ul && `display: flex; align-items: flex-start; gap: 10px`};
    &::first-letter {
      text-transform: uppercase;
    }
    & .title {
      margin: 20px 0;
    }
  }
  &.inside {
    margin-left: 10px;
  }
`;

export const TablesWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  p {
    padding: 12% 17px 0;
    @media (max-width: 1100px) {
      padding: unset;
      margin: 20px auto;
    }
  }
  Table:first-child {
    min-width: 40%;
    width: 100%;
  },
  Table:nth-of-child(2), Table:nth-of-child(3) {
    min-width: 25%;
  },
`;

export const Table = styled.table`
  border-collapse: collapse;
  background: #F0F0F0;
  ${(props) => props.large && 
    `width: 80%;
    margin-top: 30px;
    * {height: unset !important};`};
  th {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 0;
    height: 65px;
    width: 50%;
  },
  td {
    padding: 10px;
    height: 75px;
  },
  td, th {
    border: 1px solid #000;
    text-align: center;
    padding: 0 30px;
      @media (max-width: 768px) {
        font-size: 10px;
        padding: 0 2px;
      }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  img{
    object-fit: contain;
  }
  > * {
    ${props => props.small ? 'width: 50%;' : 'width: 100%;'};
  }
`
