import styled from "styled-components";
import { typo } from "../styled";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  border: 1px solid #FCF602;
  border-radius: 10px;
  padding: 20px;
`;

export const Nav = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: ${props=>props.ul && "column"};
  }
`;

export const NavButton = styled.div`
  ${typo}
  font-weight: 700;
  background-color: transparent;
  &.upCase {
    text-transform: uppercase;
  }
  & img,
  & svg {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  &.active {
    & path {
      fill: #231f20;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 20px 0;
`;

export const Typo = styled.p`
  ${typo}
  font-weight: 700;
  margin-bottom: 15px;
`;

export const List = styled.ol`
  display: flex;
  column-gap: 50px;
  row-gap: 20px;
  //flex-wrap: wrap;
  list-style-position: inside;
  list-style: ${props => props.ul && "none"};
  flex-direction: ${props => props.ul && "column"};
  ${typo}
  & ul {
    list-style-type: none;
    margin-left: 20px;
    margin-top: 5px;
    & .bold {
      font-weight: 500;
    }
  }
  ${props=>!props.ul && `> li {
      width: 30%;
  }`};
  @media (max-width: 1180px) {
    flex-direction: column;
  }
`;
