import React, {useEffect, useState} from "react";
import {IconButton, ProductInfo, Tdata, Trow} from "../../cart/styled";
import CounterBasket from "../../../components/counter-basket/CounterBasket";
import {Button, Counter, Input} from "../../../components/counter-basket/styled";
import {NumericFormat} from "react-number-format";

const ReturnedItemCard = ({
                              total,
                              product,
                              itemsCount
                          }) => {

    return (
        <Trow>
            <Tdata>
                <ProductInfo to={`/products/${product?.product?.id}`}>
                    <img
                        width="140"
                        height="140"
                        src={product?.product?.image?.src}
                        alt="product"
                    />
                    <h5>{product?.product.name || "product name"}</h5>
                </ProductInfo>
            </Tdata>
            <Tdata className="bold">
                <span>{itemsCount} {product?.product?.unit?.name}</span>
            </Tdata>
            <Tdata className="bold">
                <span>{total}</span>
            </Tdata>
        </Trow>
    );
};

export default ReturnedItemCard;
