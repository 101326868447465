import React, {useEffect, useState} from "react";
import { ImageWrapper, SliderWrapper } from "./styled";
import Slider from "react-slick";
import { baseUrl } from "../../constants";

const BestBrands = ({ }) => {
  const [brandsData, setBrandsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(10)

  const fetchData = async () => {
      const response = await fetch(`${baseUrl}/brandes?page=${currentPage}`);
      const responseData = await response.json();
      if (responseData?.data?.data?.length > 0) {
        setLastPage(responseData?.data?.meta.last_page)
        setBrandsData((prevData) => [...prevData, ...responseData?.data?.data]);
      }
      return responseData?.data?.meta?.current_page
  }

  const settings = {
    initialSlide: 1,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 9,
    cssEase: 'linear',
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(()=>{
    currentPage <= lastPage && fetchData().then(page => setCurrentPage(page+1))
  },[currentPage])
  return (
      <div className="block">
        <SliderWrapper>
          <Slider {...settings} className="slider">
            {brandsData?.map((brand, index) => (
                <ImageWrapper className={"slide"} key={brand.name} to={`/best-brands/${brand.name}/1`}>
                  {brand?.src ? (
                      <img src={brand?.src} alt={brand?.name} />
                  ) : (
                      <h5>{brand?.name}</h5>
                  )}
                </ImageWrapper>
            ))}
          </Slider>
        </SliderWrapper>
      </div>
  );
};

export default BestBrands;