import React, { useState } from "react";
import PasswordInput from "../../components/auth/PasswordInput";
import { Label, Button } from "../../components/auth/styled";
import { ErrorText } from "../../shared/styled";
import { useTranslation } from "react-i18next";
import { deleteKey } from "../../utils/funcs/formHandlings";
import { Container, Form, LangsWrapper } from "./styled";
import { Heading } from "../../components/dialogs/styled";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../features/lang/langSlice";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import { baseUrl } from "../../constants";
import { ErrorMessage } from "../shinmag-club/styled";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const [lang, langs] = useSelector((state) => state.lang);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleChangeLanguage = (val) => {
    dispatch(changeLang(val));
    i18n.changeLanguage(val);
  };

  const handleChange = (e, key, setValue) => {
    setValue(e.target.value);
    deleteKey(key, errors);
    deleteKey("token", errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { token, password, passwordConfirmation };
    axios
      .post(`${baseUrl}/auth/reset/password`, data, {
        headers: {
          "Accept-Language": lang.val,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data.token);
        navigate("/personal-page/personal-data");
      })
      .catch((err) => setErrors(err?.response?.data?.errors));
  };

  return (
    <Container>
      <LangsWrapper>
        {langs.map((lang) => (
          <button
            key={lang.name}
            onClick={() => handleChangeLanguage(lang.val)}
          >
            <img src={lang.img} alt={lang.name} />
          </button>
        ))}
        <button key={lang.name} onClick={() => handleChangeLanguage(lang.val)}>
          <img src={lang.img} alt={lang.name} />
        </button>
      </LangsWrapper>
      <Form onSubmit={handleSubmit}>
        <Heading>{t("actions.recover_password")}</Heading>
        <div>
          <Label htmlFor="recover-password">{t("texts.password")}</Label>
          <PasswordInput
            show={showPassword}
            handleShow={() => setShowPassword(!showPassword)}
            id="recover-password"
            className={errors?.password && "error"}
            value={password}
            onChange={(e) => handleChange(e, "password", setPassword)}
          />
          {errors?.password && <ErrorText>{errors.password?.[0]}</ErrorText>}
        </div>
        <div>
          <Label htmlFor="recover-password-confirm">
            {t("texts.confirm_password")}
          </Label>
          <PasswordInput
            show={showPasswordConfirm}
            handleShow={() => setShowPasswordConfirm(!showPasswordConfirm)}
            id="recover-password-confirm"
            className={errors?.passwordConfirmation && "error"}
            value={passwordConfirmation}
            onChange={(e) =>
              handleChange(e, "passwordConfirmation", setPasswordConfirmation)
            }
          />
          {errors?.passwordConfirmation && (
            <ErrorText>{errors.passwordConfirmation?.[0]}</ErrorText>
          )}
        </div>
        {errors?.token && !errors?.password && !errors.passwordConfirmation && (
          <ErrorMessage className="sm">
            {t("texts.unexpected_error")}
          </ErrorMessage>
        )}
        <Button>{t("actions.confirm")}</Button>
      </Form>
    </Container>
  );
};

export default ResetPassword;
