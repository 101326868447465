import React from "react";
import { Skeleton } from "@mui/material";
import { SkeletonWrapper } from "./styled";

const BrandSkeleton = () => {
  return (
    <SkeletonWrapper>
      {Array.from({ length: 9 }).map((x, i) => (
        <Skeleton key={i} style={{ transform: "scale(1)" }} />
      ))}
    </SkeletonWrapper>
  );
};

export default BrandSkeleton;
