import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: stretch;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  //min-height: 400px;
  margin: 75px 0;
  @media (max-width: 1180px) {
    width: 100%;
  }
  //*:not(button *) {
  //  height: 100%;
  //}
  //img:not(.img-wrapper img){
  //  height: 100%;
  //}
  & .slick-list {
    & picture {
      max-width: 100%;
    }
    & img,
    & source {
      max-width: 100%;
      height: auto;
      //object-fit: cover;
    }
  }

  & .slick-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    /* background-color: #ffe500; */
    background-color: #fff;
    opacity: 0.6;
    border: none;
    transform: rotate(-45deg);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  & .slick-prev {
    z-index: 10;
    & img {
      transform: rotate(45deg);
    }
  }
  & .slick-next {
    right: 10px;
    left: unset;
    & img {
      transform: rotate(-135deg);
    }
  }
  & .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    /* background-color: #ffe500; */
    background-color: transparent;
    border-radius: 5px;
    transform: rotate(90deg);
  }
  @media (max-width: 1024px) {
    & .slick-arrow,
    .img-wrapper {
      width: 40px;
      height: 40px;
      & img {
        width: 15px;
      }
    }
  }
`;

export const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 1440px) {
    width: 30%;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    width: 100%;
  }
  /* @media (max-width: 420px) {
    flex-direction: column;
  } */
`;

export const CustomLink = styled(Link)`
  display: block;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  @media (max-width: 1024px) {
    aspect-ratio: 3/2;
    height: auto;
  }
`;
