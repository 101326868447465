import { Rating } from "@mui/material";
import React from "react";
import { Card, DF } from "./styled";

const Review = ({ review }) => {
  return (
    <Card>
      <DF>
        <p className="name">{review?.fullName || "name"}</p>
        <Rating readOnly defaultValue={+review?.rate || 0} />
      </DF>
      <DF className="m">
        <span>{review?.address}</span>
        <span className="it">{review?.createdAt}</span>
      </DF>
      <p className="comment">{review?.comment}</p>
    </Card>
  );
};

export default Review;
