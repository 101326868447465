import React from "react";
import {
  SubCategoryCardContainer,
  SubCategoryCardImageContainer,
  SubCategoryCardTitle,
} from "./subcategories.styled";

const SubCategoryCard = ({ src, title, href }) => {
  return (
    <SubCategoryCardContainer to={href}>
      <SubCategoryCardImageContainer>
        <img src={src} alt="" />
      </SubCategoryCardImageContainer>
      <SubCategoryCardTitle>{title}</SubCategoryCardTitle>
    </SubCategoryCardContainer>
  );
};

export default SubCategoryCard;
