import React, { useState } from "react";
import ProductCard from "../cards/ProductCard";
import Slider from "react-slick";
import { SlickArrowLeft, SlickArrowRight } from "./Arrows";
import { Container, Title } from "./styled";
import { useTranslation } from "react-i18next";
import ErrorDialog from "../dialogs/ErrorDialog";
import { CardGrid } from "../cards/styled";
import SkeletonCard from "../cards/SkeletonCard";
import EmptyList from "../empty-list";

const ProductsSlider = ({ title, products, isLoading }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (message) => {
    setError(true);
    setErrorMessage(message);
  };

  const settings = {
    infinite: false,
    slidesToShow: products?.length < 4 ? products?.length : 4,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: products?.length < 3 ? products?.length : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: products?.length < 2 ? products?.length : 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Container>
        <Title>
          {title === "similar"
            ? t("texts.similar_products")
            : title === "best_selling"
            ? t("texts.best_selling")
            : t("texts.you_may_also_need")}
        </Title>
        {!products?.length && isLoading ? (
          <CardGrid>
            {Array.from({ length: 3 }).map((x, i) => (
              <SkeletonCard key={i} />
            ))}
          </CardGrid>
        ) : !products?.length ? (
          <EmptyList />
        ) : (
          <Slider {...settings}>
            {[...products]?.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                slider={true}
                handleError={handleError}
              />
            ))}
          </Slider>
        )}
      </Container>
      {error && (
        <ErrorDialog
          slider="slider"
          open={error}
          handleClose={() => setError(false)}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default ProductsSlider;
