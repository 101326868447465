import React from "react";
import { Input } from "./accordion.styled";

const PriceInput = ({ id, value, onChange, onBlur }) => {
  return (
    <Input
      id={id}
      type="number"
      autoComplete="off"
      onKeyPress={(event) => {
        if (
          event.key === "." ||
          (+event.target.value === 0 && event.key === "0") ||
          event.key === "e" ||
          event.key === "-" ||
          event.key === "+"
        ) {
          event.preventDefault();
        }
      }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default PriceInput;
