import React from "react";
import { Dates, CustomButton } from "./styled";
import { useGetDates } from "../../pages/checkout/getDate";

const DatesList = ({ deliveryDate, handleChange }) => {
  const dates = useGetDates();
  return (
    <Dates>
      {dates.map((date) => (
        <CustomButton
          type="button"
          className={`date-btn ${deliveryDate.id === date.id && "bold"}`}
          key={date.id}
          onClick={() => handleChange(date)}
        >
          <span>{date.name}</span> <span className="date">{date.date}</span>
        </CustomButton>
      ))}
    </Dates>
  );
};

export default DatesList;
