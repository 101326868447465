import styled, { css } from "styled-components";
import { pad_x } from "../../shared/styled";

const typo = css`
  font-size: 16px;
  line-height: 18px;
  color: #fff;
`;

export const Container = styled.footer`
  width: 100%;
  background-color: #373e4b;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 10px;
  ${pad_x}
  @media (min-width: 1025px) {
    padding: 60px 20px;
    ${pad_x}
  }
`;

export const Content = styled.div`
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  &:first-child {
    border: none;
    & ul {
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
  @media (min-width: 1025px) {
    border-left: 1px solid #d9d9d9;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  @media (min-width: 1025px) {
    margin: 0 auto;
    list-style-type: none;
    &:first-child {
      margin-top: 0;
    }
  }
  @media (max-width: 1024px) {
    &.expandable {
      height: 38px;
      overflow: hidden;
      @media (max-width: 420px) {
        width: 100%;
      }
    }
    &.expanded {
      height: fit-content;
      & img {
        transform: rotate(180deg);
      }
    }
  }
`;

export const ListItem = styled.li`
  margin: 20px 0;
  ${typo}
  &.centered {
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin: 10px 0;
  }
  &.name {
    cursor: default;
    &::first-letter {
      text-transform: capitalize;
    }
    & button {
      background-color: inherit;
      border: 0;
      ${typo}
      @media (min-width: 1025px) {
        cursor: default;
        & img {
          display: none;
        }
      }
      & img {
        margin-left: 5px;
      }
      @media (max-width: 420px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }
  & a {
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    &::first-letter {
      text-transform: capitalize;
    }
    &:hover {
      color: #ffe500;
    }
  }
`;

export const ExternalLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  & img {
    width: 30px;
    height: 30px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  @media (min-width: 769px) {
    &.inner {
      display: none;
    }
  }
`;

export const Copyright = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  & .bold {
    font-weight: 500;
    color: #ffe600;
  }
`;
