import React, { useEffect, useState } from "react";
import {
  Container,
  PageHeading,
  Section,
  Table,
  Thead,
  Trow,
  Theader,
  Tbody,
  IconButton,
  TextButton,
  CartItems,
} from "./styled";
import CartItem from "./CartItem";
import deleteIcon from "../../assets/deleteIcon.svg";
import CartSummary from "../../components/cart-summary/CartSummary";
import { useNavigate } from "react-router";
import CartItemMobile from "./CartItemMobile";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import EmptyList from "../../components/empty-list";
import { useGetData, useLocalStorageGetItem } from "../../utils/hooks/hooks";
import axios from "axios";
import { baseUrl } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { changeCartLength } from "../../features/cart/cartSlice";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import SearchItemSkeleton from "../../components/searched-list/SearchItemSkeleton";

const Cart = () => {
  const [reload, setReload] = useState(false);
  const [products, setProducts] = useState([]);
  const token = useLocalStorageGetItem("token");
  const lang = useSelector((state) => state.lang[0]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: cart, isLoading } = useGetData("carts", [reload], true, token);
  const [initLoading, setInitLoading] = useState(true);
  const navigate = useNavigate();
  const ip = localStorage.getItem('ip')
  const { t } = useTranslation();
  const rows = [
    t("texts.product"),
    t("texts.price"),
    t("texts.amount"),
    t("texts.sum"),
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (cart) {
      setInitLoading(false);
      setProducts(cart?.list);
      if (!cart?.list?.length) {
        navigate("/");
      }
    }
  }, [cart]);

  useEffect(() => {
    setInitLoading(true);
  }, [lang]);

  const nav = [{ to: "/cart", name: t("texts.cart") }];

  let headers = {
    "Accept-Language": lang.val,
  };
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
  };

  const handleCountChange = (count, id) => {
    const ip = localStorage.getItem('ip')
    const data = { count, id };
    axios
      .post(`${baseUrl}/carts/change${ip ?`?ip=${ip}`:''}`, data, { headers })
      .then(() => {
        setReload((prev) => !prev);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ===
          "validation.cart.count.available.false"
            ? t("texts.unavailable_count")
            : t("texts.unexpected_error");
        setErrorMessage(message);
        setOpenErrorDialog(true);
        setReload((prev) => !prev);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}/carts/${id}${ip ?`?ip=${ip}`:''}`, { headers })
      .then(() => {
        setReload((prev) => !prev);
        dispatch(changeCartLength());
      })
      .catch(() => setOpenErrorDialog(true));
  };

  const handleClear = () => {
    axios
      .delete(`${baseUrl}/carts${ip ?`?ip=${ip}`:''}`, { headers })
      .then(() => {
        dispatch(changeCartLength());
        navigate("/");
      })
      .catch(() => setOpenErrorDialog(true));
  };

  const handleCreateOrder = () => {
    const ip = localStorage.getItem('ip')
    let items = [];
    products?.forEach((product) =>
      items.push({ id: product?.product?.id, count: +product?.count })
    );
    axios
      .post(
        `${baseUrl}/orders${ip ?`?ip=${ip}`:''}`,
        {
          items,
        },
        { headers }
      )
      .then((res) => res.data.data)
      .then((data) => {
        navigate(`/checkout/${data.id}`);
      })
      .catch(() => setOpenErrorDialog(true));
  };

  return (
    <Container>
      <Breadcrumbs nav={nav} />
      <PageHeading>{t("texts.cart")}</PageHeading>
      {isLoading && initLoading ? (
        <>
          <SearchItemSkeleton />
          <SearchItemSkeleton />
        </>
      ) : !products?.length ? (
        <EmptyList />
      ) : (
        <Section>
          <section className="table-section">
            <Table>
              <Thead>
                <Trow>
                  {rows.map((row, index) => (
                    <Theader key={index}>{row}</Theader>
                  ))}
                </Trow>
              </Thead>
              <Tbody>
                {products.map((prod) => (
                  <CartItem
                      total={prod?.total}
                    key={prod?.product?.id}
                    initCount={prod?.count}
                    product={prod?.product}
                    deleteIcon={deleteIcon}
                    handleDelete={handleDelete}
                    handleCountChange={handleCountChange}
                    reload={reload}
                  />
                ))}
              </Tbody>
            </Table>
            <IconButton className="with-text fright" onClick={handleClear}>
              <img src={deleteIcon} alt="x" width="18" height="18" />
              <span> {t("actions.clear_cart")}</span>
            </IconButton>
          </section>
          <CartItems>
            {products.map((prod) => (
              <CartItemMobile
                key={prod?.product?.id}
                initCount={prod?.count}
                product={prod?.product}
                deleteIcon={deleteIcon}
                handleDelete={handleDelete}
                handleCountChange={handleCountChange}
                reload={reload}
              />
            ))}
          </CartItems>
          <section className="summary-section">
            <CartSummary
              price={cart?.subtotal}
              total={cart?.total}
              discount={cart?.discount}
              actionName={t("actions.continue")}
              onClick={handleCreateOrder}
              place="cart"
              products={products}
            />
          </section>
        </Section>
      )}
      {openErrorDialog && (
        <ErrorDialog
          open={openErrorDialog}
          handleClose={handleCloseErrorDialog}
          message={errorMessage}
        />
      )}
    </Container>
  );
};

export default Cart;
