import React from "react";
import NewsCard from "../cards/NewsCard";
import { CardGrid } from "../cards/styled";
import { useGetData } from "../../utils/hooks/hooks";
import moment from "moment/moment";
import SkeletonCard from "../cards/SkeletonCard";
import { BtnLink } from "../../shared/styled";
import { useTranslation } from "react-i18next";
import EmptyList from "../empty-list";

const HomeNews = ({articles, isLoading}) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <CardGrid>
          {Array.from({ length: 3 }).map((x, i) => (
            <SkeletonCard key={i} />
          ))}
        </CardGrid>
      ) : !!articles?.length ? (
        <CardGrid>
          {articles.map((article) => (
            <NewsCard
              key={article?.id || article?.uuid}
              id={article?.id || article?.uuid}
              imgSrc={article?.images?.main?.src}
              newsTitle={article?.title}
              newsDate={moment(article?.created).format("DD-MM-YYYY")}
              newsDescription={article?.content}
            />
          ))}
        </CardGrid>
      ) : (
        <EmptyList />
      )}
      {!!articles?.length > 4 && (
        <BtnLink to="/news/1">{t("actions.see_more")}</BtnLink>
      )}
    </>
  );
};

export default HomeNews;
